// @ts-nocheck
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { QueryBuilder } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { RootStoreType } from './index'

/**
 * CompoundResponseBase
 * auto generated base class for the model CompoundResponseModel.
 */
export const CompoundResponseModelBase = ModelBase.named('CompoundResponse')
  .props({
    __typename: types.optional(
      types.literal('CompoundResponse'),
      'CompoundResponse'
    ),
    compoundStringified: types.union(types.undefined, types.string)
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class CompoundResponseModelSelector extends QueryBuilder {
  get compoundStringified() {
    return this.__attr(`compoundStringified`)
  }
}
export function selectFromCompoundResponse() {
  return new CompoundResponseModelSelector()
}

export const compoundResponseModelPrimitives =
  selectFromCompoundResponse().compoundStringified
