import React from 'react'
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material'
import { ThemeProvider } from 'styled-components'
import { SnackbarProvider } from 'notistack'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import WarningsNotifier, { WarningStyle } from './components/WarningsNotifier'
import { RootStoreType, StoreContext } from '@sayr/client-models'
import { WarningActionFunctions } from './components/WarningsNotifier'
import CssReset from './components/CssReset'
import FrameBeforeApp from './components/FrameBeforeApp'
import App from './components/App'
import { observer } from 'mobx-react-lite'

import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

const queryClient = new QueryClient()

function MainFrame({
  loaded,
  store,
  actions
}: {
  loaded: boolean
  store: RootStoreType
  actions: { [action: string]: () => void }
}) {
  const customTheme = createTheme({
    palette: {
      mode: store.preferences.darkMode ? 'dark' : 'light',
      primary: { main: '#f29500' },
      secondary: { main: '#ffcf00' }
    },
    typography: {
      fontFamily:
        'Gotham Rounded SSm A, Gotham Rounded SSm B, Open Sans, Helvetica, Arial, sans-serif',
      allVariants: { fontWeight: 300 },
      h1: { fontSize: '2.2rem' },
      h2: { fontSize: '2rem' },
      h3: { fontSize: '1.4rem' },
      h4: { fontSize: '1.2rem' }
    }
  })

  return (
    <React.StrictMode>
      <StoreContext.Provider value={store}>
        <QueryClientProvider client={queryClient}>
          <WarningActionFunctions.Provider value={actions}>
            <CssReset />
            <MuiThemeProvider theme={customTheme}>
              <ThemeProvider theme={customTheme}>
                <SnackbarProvider>
                  <WarningStyle />
                  <WarningsNotifier />
                  {loaded && store.loggedInUser?.user?.nickName ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <App />
                    </LocalizationProvider>
                  ) : (
                    <FrameBeforeApp loaded={loaded} />
                  )}
                </SnackbarProvider>
              </ThemeProvider>
            </MuiThemeProvider>
          </WarningActionFunctions.Provider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </StoreContext.Provider>
    </React.StrictMode>
  )
}

export default observer(MainFrame)
