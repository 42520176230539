import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Breadcrumbs,
  ConnectedPages,
  Heading2,
  Heading3,
  Heading4,
  Paragraph,
  InfoContainer,
  InfoLink,
  Section,
  Hr,
  InfoPageLayout,
  LayoutSeparator,
  Link,
  AsanaImage,
  ListItem,
  OrderedList,
  UnorderedList
} from '../../../../components/InfoTypography'

export function InfoHeadstand() {
  return (
    <InfoContainer style={{ wordBreak: 'break-word' }}>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <InfoLink target="">Information</InfoLink>
        <InfoLink target="practice-guide">Practice Guide</InfoLink>
        <InfoLink target="practice-guide/asanas">Asanas</InfoLink>
        <Link $disabled>Headstand</Link>
      </Breadcrumbs>
      <Heading2>
        1. Headstand (<span className="text-sanskrit">śirṣāsana</span>)
      </Heading2>
      <Hr />
      <InfoPageLayout>
        <Section>
          <Paragraph>
            The Headstand is practised as the first of the 12 Basic Asanas,
            after the Pranayama (breathing exercises) and Sun Salutation (warm
            up exercise). It is called “King” of Asanas, of which there are
            estimated 84,000 different ones. To Begin:
          </Paragraph>
          <AsanaImage src="/images/asanas/sirshasana.png" alt="Headstand" />
          <Heading3>
            <span className="text-sanskrit">śaśāṅkāsana</span>, Child's Pose
          </Heading3>
          <Paragraph>
            Sit on your heels with the forehead on the floor. Hands are relaxed
            palms upward next to feet. Relax in this position for a few moments.
            Relax in this position for a few moments, mentally preparing
            yourself for the Headstand.
          </Paragraph>
          <AsanaImage src="/images/asanas/child-pose.png" alt="Child's Pose" />
          <Heading3>Dolphin, Preparatory Exercise</Heading3>
          <Paragraph>
            Sit up on heels. Clasp hold of opposite elbow with each hand. Bring
            arms to ground. Interlock fingers, forming triangle on ground with
            arms. Straighten the knees so that you are standing on the toes,
            bringing the hips up. Keep head up off the ground. Rock forward and
            back, bringing the chin in front of the hands then pushing the body
            back as far as possible.
          </Paragraph>
          <Paragraph>
            Do 2-3 rounds of 8 or 12 rocks. Relaxing in Child's Pose between
            rounds.
          </Paragraph>
          <Paragraph>
            The purpose of the Dolphin is to strengthen the arms and shoulders,
            preparing them for the Headstand. Even after you are able to do the
            Headstand, it is a good idea to continue practising the Dolphin as
            it helps to increase the holding time.
          </Paragraph>
          <Heading2>Technique</Heading2>
          <Heading3>The Eight Steps</Heading3>
          <OrderedList>
            <ListItem>
              From the Child's Pose, sit up on the heels, knees together. Place
              each hand on the opposite elbow, measuring the distance. Bring the
              arms to the ground under the shoulders.
            </ListItem>
            <ListItem>
              Bring the hands together so that the arms form a tripod on the
              ground, interlocking the fingers.
            </ListItem>
            <ListItem>
              Place the back of the head against the hands and raise the hips.
              (Make sure that the elbows are stationary).
            </ListItem>
            <ListItem>
              Walk the feet forward towards the head. Keep the hips up and knees
              straight.
            </ListItem>
            <ListItem>
              Bend the knees in towards the chest. Bring the heels up to the
              buttocks.
            </ListItem>
            <ListItem>
              Straighten the back, making sure that the spine retains its
              natural curve. Be able to balance in this position for at least 30
              seconds before going further.
            </ListItem>
            <ListItem>
              Keeping the knees bent and together, slowly straighten the hips
              until the best knees are pointing up towards the ceiling.
            </ListItem>
            <ListItem>Straighten the knees.</ListItem>
          </OrderedList>
          <Heading3>Holding the position:</Heading3>
          <Paragraph>
            Start with 5 seconds, gradually increasing the time to 15 minutes.
            Optimum time is 5-10 minutes on a daily basis.
            <br />
            While in the position, make the breath smooth and rhythmic.
          </Paragraph>
          <Heading3>To come out of the position:</Heading3>
          <OrderedList>
            <ListItem>Bend the knees, bringing them into the chest.</ListItem>
            <ListItem>Bend the hips bringing the feet to the floor.</ListItem>
            <ListItem>
              Relax in the Child's Pose before lifting head off the ground.
            </ListItem>
          </OrderedList>
          <Heading3>Common Mistakes:</Heading3>
          <OrderedList>
            <ListItem>
              <Paragraph>
                Weight on the head and/ or the neck
                <br />
                To Correct: Make sure that 90% of weight is on the elbows. Make
                sure that the elbows stay firmly on the ground and don't slip
                out to sides. If you are having difficulties, practise the
                Dolphin.
              </Paragraph>
            </ListItem>
            <ListItem>
              <Paragraph>
                Back over-arched; legs dropping back
                <br />
                To Correct: keep knees straight; heels towards ceiling. Tighten
                abdominal muscles.
              </Paragraph>
            </ListItem>
          </OrderedList>
          <Heading3>Do not practice headstand if you: </Heading3>
          <UnorderedList>
            <ListItem>Have high blood pressure</ListItem>
            <ListItem>Suffer from Glaucoma</ListItem>
            <ListItem>Are 4 or more months pregnant</ListItem>
          </UnorderedList>
          <Heading3>Benefits of the Headstand</Heading3>
          <Heading4>Physical benefits</Heading4>
          <UnorderedList>
            <ListItem>
              The “King” of the Asanas, Sishasan gives a much deserved rest to
              the heart by inverting the body and keeping it in a straight line.
              This counteracts the pull of gravity. This allows the heart a
              rest, as it needs only to pump the blood with enough force for it
              to reach the toes; gravity will return the blood to the heart.
            </ListItem>
            <ListItem>The Headstand is a panacea for all human ills.</ListItem>
            <ListItem>
              People who practise Sirshan on a regular basis tend to have slow
              rates of respiration and heartbeat. This indicates that the
              Respiratory and Circulatory systems are strong and flexible .
            </ListItem>
            <ListItem>
              The aorta, carotids and subclavian arteries receive an increase of
              arterial (freshly oxygenated) blood.
            </ListItem>
            <ListItem>
              The brain, spinal cord and sympathetic nervous system are supplied
              with an increased flow of blood rich in nutrients.
            </ListItem>
            <ListItem>
              As a result of the brain receiving a rich supply of nutrients, all
              body functions are toned and enhanced.
            </ListItem>
            <ListItem>
              Disorders of the nerves, eyes, ears, nose and throat are improved.
            </ListItem>
            <ListItem>
              Persons suffering from varicose veins will feel a relief in the
              Headstand as stagnant blood will drain from the lower extremities.
              Sirshasan is also a remedy for renal colic, and stubborn
              constipation.
            </ListItem>
            <ListItem>
              Pressure is relieved on the lower back. When the body is inverted,
              the cervical and thoracic (upper) parts of the back take more
              pressure; the lumbar and sacral areas (lower back) are relieved of
              much of their usual burden.
            </ListItem>
            <ListItem>
              The Headstand combats dropped stomach and nervous or hepatic
              asthma.
            </ListItem>
            <ListItem>
              Inverting the body enhances deep breathing, bringing increased
              oxygen supply to the brain.
            </ListItem>
            <ListItem>
              The Headstand is one of the most powerful asanas for the body and
              mind.
            </ListItem>
          </UnorderedList>
          <Heading4>Mental benefits</Heading4>
          <UnorderedList>
            <ListItem>Memory and intellectual capacity are increased.</ListItem>
            <ListItem>
              Improved concentration; the headstand is particularly beneficial
              to people who need strong powers of concentration in their work:
              students, politicians, artists, scientists and writers.
            </ListItem>
            <ListItem>Improved Sensory faculties (eyesight, hearing).</ListItem>
            <ListItem>
              The person who practises the Headstand on a regular basis will
              rarely suffer from nervous disorders.
            </ListItem>
          </UnorderedList>
          <Heading4>Psychic benefits</Heading4>
          <UnorderedList>
            <ListItem>
              “He who practises the Headstand for three hours daily conquers
              time.” ~Yoga Tatwa Upanishad
            </ListItem>
            <ListItem>
              Useful in keeping up Brahmacharya because seminal energy is
              transmuted into Ojas-Shakti and stored in the brain. This is
              sex-sublimation.
            </ListItem>
          </UnorderedList>
          <Heading3>Variations of the Headstand</Heading3>
          <Heading4>Variation 1</Heading4>
          <Paragraph>
            Unclasp the hands and transfer weight to the left side. Place right
            hand where elbow was. Repeat on other side. Interlock the legs. This
            greatly increases the venous blood flow to the heart.
          </Paragraph>
          <Heading4>Variation 2</Heading4>
          <Paragraph>
            Position hands as in variation 1. Shift weight to left side and
            stretch right arm in from of you palm down. Repeat on other side.
          </Paragraph>
          <Heading3>Relaxation Position</Heading3>
          <Paragraph>
            This should be practiced before and after each of the other asanas.
            Lie flat on the back. Feet are at least half a metre apart.
          </Paragraph>
          <Paragraph>
            Arms are out to a 45 degree (approx) angle to the body; hands are
            palms upwards. Eyes are closed. When you first lie down, shake out
            the shoulders to relieve the tension. Then roll the head slowly from
            side to side once or twice to relax the neck. Bring the head back to
            centre. Focus the mind on the breath. Inhale, the abdomen expands,
            filling the lungs with air. Exhale, the air is pushed out of the
            lungs and abdomen contracts. Relax for at least a minute before
            Asanas.
          </Paragraph>
        </Section>
        <ConnectedPages>
          <LayoutSeparator />
          <Section>
            <Paragraph>
              <strong>Connected Articles:</strong>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas">Asanas</InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/sun-salutations">
                Sun Salutations (
                <span className="text-sanskrit">sūrya namaskāra</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/shoulderstand">
                2. Shoulderstand (
                <span className="text-sanskrit">sarvāṅgāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/plough">
                3. Plough (<span className="text-sanskrit">halāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/fish">
                4. Fish (<span className="text-sanskrit">matsyāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/forward-bend">
                5. Forward Bend (
                <span className="text-sanskrit">pascimottanāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/cobra">
                6. Cobra (<span className="text-sanskrit">bhujaṅgāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/locust">
                7. Locust (<span className="text-sanskrit">śalabhāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/bow">
                8. Bow (<span className="text-sanskrit">dhanurāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/half">
                9. Half Spinal Twist (
                <span className="text-sanskrit">ardha matsyendrāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/crow">
                10. Crow (<span className="text-sanskrit">kakāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/hands">
                11. Hands to Feet (
                <span className="text-sanskrit">pāda hastāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/triangle">
                12. Triangle (<span className="text-sanskrit">trikonāsana</span>
                )
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/savasana">
                Final Relaxation (
                <span className="text-sanskrit">śavāsana</span>)
              </InfoLink>
            </Paragraph>
          </Section>
        </ConnectedPages>
      </InfoPageLayout>
    </InfoContainer>
  )
}
