import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Breadcrumbs,
  ConnectedPages,
  Heading2,
  Heading3,
  Paragraph,
  ListItem,
  InfoContainer,
  InfoLink,
  Section,
  Hr,
  InfoPageLayout,
  LayoutSeparator,
  Link,
  LeadText,
  Heading4,
  UnorderedList
} from '../../../../components/InfoTypography'

export function InfoKapalabhati() {
  return (
    <InfoContainer style={{ wordBreak: 'break-word' }}>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <InfoLink target="">Information</InfoLink>
        <InfoLink target="practice-guide">Practice Guide</InfoLink>
        <InfoLink target="practice-guide/breathing-techniques">
          Breathing Techniques
        </InfoLink>
        <Link $disabled>Kapalabhati</Link>
      </Breadcrumbs>
      <Heading2>Kapalabhati</Heading2>
      <Hr />
      <InfoPageLayout>
        <Section>
          <LeadText>
            In Sanskrit <span className="text-sanskrit">kapala कपल</span> means
            skull; <span className="text-sanskrit">bhati भति</span> means
            shining.
            <br /> <span className="text-sanskrit">kapalabhati</span> is
            considered to be so cleansing to the entire system that, when
            practised on a regular basis, the skull (and the face) shine with
            good health and inner radiance. Although a breathing exercpise,
            Kapalabhati is technically considered to be one of the{' '}
            <span className="text-sanskrit">ṣat kriya</span>s - 6 cleansing
            exercises. <br />
            See “Complete Illustrated Book of Yoga” for further information.
          </LeadText>
          <Heading3>How to do Kapalabhati</Heading3>
          <Paragraph>
            Sit in a cross-legged position with back straight:
          </Paragraph>
          <Paragraph>
            Quickly contract the abdominal muscles pulling the abdomen in
            sharply, exhaling through the nose. The diaphragm is contracted and
            pulled up into the chest cavity, pushing the air out of the lungs.
          </Paragraph>
          <Paragraph>
            Relax the abdominal muscles, allowing the diaphragm to descend to
            the abdominal cavity. The lungs will automatically expand and
            inflate with air.
          </Paragraph>
          <Paragraph>
            If kapalabhati is done properly, exhalation takes one-fourth of the
            time of inhalation. <br />
            Passive inhalation and sudden expulsion of breath follow each other
            continuously until a round is completed.
          </Paragraph>
          <Paragraph>
            Beginners may undertake 3 rounds of 20-30 pumpings each. This may
            gradually be increased to 5 rounds of 50-100 pumpings. Make sure
            that the face is kept relaxed. Back and shoulders do not move.
          </Paragraph>
          <Heading3>Benefits of Kapalabhati</Heading3>
          <Heading4>Physical benefits </Heading4>
          <UnorderedList>
            <ListItem>
              Kapalabhati cleanses the nasal passages, lungs and entire
              Respiratory System.
            </ListItem>
            <ListItem>
              It strengthens and increases the capacity of the lungs and
              intercostal (ribcage) muscles.
            </ListItem>
            <ListItem>
              Kapalabhati helps to drain the sinuses and eliminate accumulated
              mucus.
            </ListItem>
            <ListItem>
              Bronchial congestion is removed, as is spasm of the bronchial
              tubes. Consequently, asthma is relieved and cured over a period of
              time.
              <br />
              <span style={{ color: 'darkred' }}>
                NOTE: Kapalabhati should not be practiced when an asthmatic
                attack is in progress.
              </span>
            </ListItem>
            <ListItem>
              As the lungs are cleansed, excess carbon dioxide is eliminated.
              This permits the red-blood cells to suck in more oxygen,
              increasing the richness of blood.
            </ListItem>
            <ListItem>
              The blood is purified and toned; the body gets an increased supply
              of oxygen to all cells.
            </ListItem>
            <ListItem>
              The abdominal contractions of kapalabhati massage the liver,
              spleen, pancreas, stomach and heart, thus invigorating them.
            </ListItem>
            <ListItem>
              Abdominal muscles are strengthened; digestion is improved.
            </ListItem>
            <ListItem>
              The regular practitioner of kapalabhati enjoys blooming vigour and
              health.
            </ListItem>
          </UnorderedList>
          <Heading4>Mental / Psychic Benefits</Heading4>
          <UnorderedList>
            <ListItem>
              Kapalabhati refreshes and invigorates the mind.{' '}
            </ListItem>
            <ListItem>
              It brings an increase in alertness as a result of the increase of
              oxygen to the brain.
            </ListItem>
            <ListItem>It creates a feeling of exhilaration.</ListItem>
            <ListItem>Kapalabhati activates pranic energy.</ListItem>
            <ListItem>
              It increases the supply of stored-up prana in the solar-plexus
              region.
            </ListItem>
          </UnorderedList>
          <Heading3>Common Mistakes</Heading3>
          <UnorderedList>
            <ListItem>
              Moving the diaphragm in the opposite direction. Many people will
              contract the abdominal muscles while inhaling.
            </ListItem>
            <ListItem>
              Contracting the shoulders to push the air out when exhaling.
            </ListItem>
            <ListItem>
              Contracting the facial muscles to force the air out of the nose.
            </ListItem>
          </UnorderedList>
        </Section>
        <ConnectedPages>
          <LayoutSeparator />
          <Section>
            <Paragraph>
              <strong>Connected Articles:</strong>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/breathing-techniques">
                Breathing Techniques
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/breathing-techniques/anuloma-viloma">
                Anuloma Viloma Pranayama
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/introduction/proper-breathing">
                Proper Breathing
              </InfoLink>
            </Paragraph>
          </Section>
        </ConnectedPages>
      </InfoPageLayout>
    </InfoContainer>
  )
}
