import React from 'react'
import { observer } from 'mobx-react-lite'
import Typography from '@mui/material/Typography'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'

import { StoreContext } from '@sayr/client-models'
import { PrimaryButton } from '../../components/common/Buttons'
import {
  FormTextField,
  Field,
  FormError,
  Wrapper
} from '../../components/common/Forms'

type NicknameInput = { nickname: string }

const Form = styled.form`
  display: grid;
`

const SmallPrimaryButton = styled(PrimaryButton)`
  justify-self: start;
`

function ChooseNickName() {
  const store = React.useContext(StoreContext)
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<NicknameInput>({
    mode: 'onChange'
  })

  function onSubmit(data: NicknameInput) {
    store
      .mutateUserUpdateNickname({ nickname: data.nickname.trim() })
      .then(result => {
        if (result.userUpdateNickname)
          store.loggedInUser?.user?.setNickname(data.nickname)
      })
  }

  const nickname = register('nickname', {
    required: true,
    validate: value =>
      !!value
        .trim()
        .match(/^[a-zA-Z\u00C0-\u017F]+(\s[a-zA-Z\u00C0-\u017F]+)+$/)
  })
  const initialValue = store.loggedInUser?.user?.googleDisplayName || ''

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h3">What is your full name?</Typography>
      <br />
      <Wrapper bottomSpacing>
        <Field>
          <FormTextField
            autoFocus
            fullWidth
            defaultValue={initialValue}
            name={nickname.name}
            onBlur={nickname.onBlur}
            onChange={nickname.onChange}
            inputRef={nickname.ref}
          />
          {errors.nickname && <FormError>Name not valid</FormError>}
        </Field>
      </Wrapper>
      <SmallPrimaryButton type="submit">Submit</SmallPrimaryButton>
    </Form>
  )
}

export default observer(ChooseNickName)
