// @ts-nocheck
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { QueryBuilder } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { MessageEdgeModel, MessageEdgeModelType } from './MessageEdgeModel'
import { MessageEdgeModelSelector } from './MessageEdgeModel.base'
import { PageInfoModel, PageInfoModelType } from './PageInfoModel'
import { PageInfoModelSelector } from './PageInfoModel.base'
import { RootStoreType } from './index'

/**
 * MessageConnectionBase
 * auto generated base class for the model MessageConnectionModel.
 */
export const MessageConnectionModelBase = ModelBase.named('MessageConnection')
  .props({
    __typename: types.optional(
      types.literal('MessageConnection'),
      'MessageConnection'
    ),
    pageInfo: types.union(
      types.undefined,
      types.late((): any => PageInfoModel)
    ),
    edges: types.union(
      types.undefined,
      types.array(types.late((): any => MessageEdgeModel))
    )
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class MessageConnectionModelSelector extends QueryBuilder {
  pageInfo(
    builder:
      | string
      | PageInfoModelSelector
      | ((selector: PageInfoModelSelector) => PageInfoModelSelector)
      | undefined
  ) {
    return this.__child(`pageInfo`, PageInfoModelSelector, builder)
  }
  edges(
    builder:
      | string
      | MessageEdgeModelSelector
      | ((selector: MessageEdgeModelSelector) => MessageEdgeModelSelector)
      | undefined
  ) {
    return this.__child(`edges`, MessageEdgeModelSelector, builder)
  }
}
export function selectFromMessageConnection() {
  return new MessageConnectionModelSelector()
}

export const messageConnectionModelPrimitives = selectFromMessageConnection()
