import { values, entries } from 'mobx'
import { Instance, types, getParent, SnapshotIn } from 'mobx-state-tree'
// import { ViewType } from './ViewModel'

export interface WarningTypes extends Instance<typeof WarningModel> {}
export interface WarningsType extends Instance<typeof WarningsModel> {}
export interface WarningCreationType extends SnapshotIn<typeof WarningModel> {}

export const WarningModel = types
  .model('WarningModel', {
    key: types.identifier,
    message: types.string,
    autoHideDuration: types.optional(types.maybeNull(types.number), 5000),
    allowDismiss: types.optional(types.boolean, true),
    dismissed: types.optional(types.boolean, false),
    // these actions are shwon as buttons,
    // and the functions executed are stored in a WarningActionFunctions
    // context.
    actions: types.array(
      types.model('Action', {
        functionKey: types.string,
        title: types.string
      })
    )
  })
  .actions(self => ({
    dismiss() {
      self.dismissed = true
    },
    updateMessageAndStatus(message: string, dismissed: boolean) {
      self.message = message
      self.dismissed = dismissed
      ;(getParent(self, 2) as WarningsType).updateWarningsCounter()
    }
  }))

export const WarningsModel = types
  .model('WarningsModel', {
    internalList: types.map(WarningModel),
    warningUpdateCounter: types.optional(types.number, 1)
  })
  .views(self => ({
    // the list of all warnings
    list(): WarningTypes[] {
      const internalList = entries(self.internalList) as unknown as [
        string,
        WarningTypes
      ][]

      const list = Array.from(internalList).map(a => a[1])

      //   const announcementsSnackbar = (
      //     getRoot(self) as RootStoreType
      //   ).announcements.snackbar()

      //   if (announcementsSnackbar)
      //     list.push(
      //       WarningModel.create({
      //         message: announcementsSnackbar,
      //         key: `announcements`,
      //         allowDismiss: false,
      //         autoHideDuration: null,
      //         action: {
      //           onClick: 'open announcements page',
      //           actionText: 'See Details'
      //         },
      //         dismissed: false
      //       })
      //     )
      return list
    }
  }))
  .actions(self => ({
    add(warning: WarningCreationType) {
      if (!self.internalList.get(warning.key)) {
        self.internalList.put(WarningModel.create(warning))
        self.warningUpdateCounter++
      }
    },
    dismissOne(key: string) {
      if (
        (values(self.internalList) as unknown as WarningTypes[]).find(
          warning => warning.key === key
        )
      ) {
        const warning = self.internalList.get(key)

        if (warning && warning.allowDismiss) {
          warning.dismissed = true
          self.warningUpdateCounter++
        }
      }
    },
    removeOne(key: string) {
      const warning = self.internalList.get(key)

      if (warning) {
        self.internalList.delete(key)
        self.warningUpdateCounter++
      }
    },
    updateWarningsCounter() {
      self.warningUpdateCounter++
    },
    clearAll() {
      self.internalList.clear()
      self.warningUpdateCounter++
    }
  }))
