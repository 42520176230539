import { types } from 'mobx-state-tree'

export const StatusModel = types
  .model('StatusModel', {
    online: types.optional(types.boolean, navigator.onLine),
    serverOnline: types.optional(types.boolean, true)
  })
  .actions(self => ({
    setOnlineStatus(value: boolean) {
      self.online = value
    },
    setServerOnlineStatus(value: boolean) {
      self.serverOnline = value
    }
  }))
