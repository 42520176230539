// @ts-nocheck
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import {
  MessageConnectionModel,
  MessageConnectionModelType
} from './MessageConnectionModel'
import { MessageConnectionModelSelector } from './MessageConnectionModel.base'
import { UserResponseModel, UserResponseModelType } from './UserResponseModel'
import { UserResponseModelSelector } from './UserResponseModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  user: UserResponseModelType
}

/**
 * ChatResponseBase
 * auto generated base class for the model ChatResponseModel.
 */
export const ChatResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named('ChatResponse')
    .props({
      __typename: types.optional(types.literal('ChatResponse'), 'ChatResponse'),
      lastReadTimestamp: types.union(types.undefined, types.frozen()),
      messages: types.union(
        types.undefined,
        types.late((): any => MessageConnectionModel)
      ),
      user: types.union(
        types.undefined,
        MSTGQLRef(types.late((): any => UserResponseModel))
      ),
      id: types.identifier
    })
    .views(self => ({
      get store() {
        return self.__getStore<RootStoreType>()
      }
    }))
)

export class ChatResponseModelSelector extends QueryBuilder {
  get lastReadTimestamp() {
    return this.__attr(`lastReadTimestamp`)
  }
  get id() {
    return this.__attr(`id`)
  }
  messages(
    builder:
      | string
      | MessageConnectionModelSelector
      | ((
          selector: MessageConnectionModelSelector
        ) => MessageConnectionModelSelector)
      | undefined
  ) {
    return this.__child(`messages`, MessageConnectionModelSelector, builder)
  }
  user(
    builder:
      | string
      | UserResponseModelSelector
      | ((selector: UserResponseModelSelector) => UserResponseModelSelector)
      | undefined
  ) {
    return this.__child(`user`, UserResponseModelSelector, builder)
  }
}
export function selectFromChatResponse() {
  return new ChatResponseModelSelector()
}

export const chatResponseModelPrimitives =
  selectFromChatResponse().lastReadTimestamp
