import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Breadcrumbs,
  ConnectedPages,
  Heading2,
  Heading3,
  Paragraph,
  ListItem,
  InfoContainer,
  InfoLink,
  Section,
  Hr,
  InfoPageLayout,
  LayoutSeparator,
  Link,
  OrderedList,
  LeadText
} from '../../../../components/InfoTypography'

export function InfoHandsToFeet() {
  return (
    <InfoContainer style={{ wordBreak: 'break-word' }}>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <InfoLink target="">Information</InfoLink>
        <InfoLink target="practice-guide">Practice Guide</InfoLink>
        <InfoLink target="practice-guide/meditation">Meditation</InfoLink>
        <Link $disabled>Technique</Link>
      </Breadcrumbs>
      <Heading2>The Technique of Meditation - Step by Step</Heading2>
      <Hr />
      <InfoPageLayout>
        <Section>
          <LeadText>
            One cannot learn to meditate anymore than one can learn to sleep.
            One falls into both states. There are certain points to remember
            regarding the techniques and stages of meditation.
          </LeadText>
          <br />
          <Heading3>15 Points of Meditation</Heading3>
          <OrderedList>
            <ListItem>
              <strong>Regularity of time, place and practice</strong> are most
              important. Regularity conditions the mind to slow down its
              activities with a minimum of delay.
            </ListItem>
            <ListItem>
              <strong>The most effective times </strong>
              are early dawn and dusk , when the atmosphere is charged with
              special spiritual force. If it is not feasible to sit for
              meditation at these times, choose an hour when you are not
              involved with daily activities, and a time the mind is apt to be
              calm.
            </ListItem>
            <ListItem>
              <strong>Place of Meditation</strong>
              <br />
              Try to have a separate room for meditation. If this is impossible,
              screen off a portion of a room; do not allow others to enter it.
              The area should be used only for meditation, and should be kept
              free from other vibrations and associations. Incense should be
              burned morning and evening. The focal point of the room should be
              a picture or image of the chosen deity or inspirational figure,
              with the meditation mat placed before it. As meditation is
              repeated, the powerful vibrations set up will be lodged in the
              room. In six months the peace and purity of the atmosphere will be
              felt; it will have a magnetic aura. In times of stress you can sit
              in the room, do repetition of Mantra for half an hour, and
              experience comfort and relief.
            </ListItem>
            <ListItem>
              <strong>Sitting Position</strong>
              <br />
              When sitting, face North or East in order to take advantage of
              favorable magnetic vibrations. Sit in a steady, cross-legged
              position with spine and neck erect but not tense.
            </ListItem>
            <ListItem>
              Before beginning, <strong>command the mind to be quiet</strong>{' '}
              for a specific length of time. Forget the past, present and
              future.
            </ListItem>
            <ListItem>
              Consciously regulate the breath. Begin with five minutes of{' '}
              <strong>deep abdominal breathing</strong> to bring oxygen to the
              brain. Then slow it down to an imperceptible rate.
            </ListItem>
            <ListItem>
              <strong>Keep the breathing rhythmic</strong>. Inhale for three
              seconds and exhale for three seconds. Regulation of breath also
              regulates the flow of Prana, the vital energy.
            </ListItem>
            <ListItem>
              <strong>Allow the mind to wander at first</strong>. It will jump
              around, but will eventually become concentrated, along with the
              concentration of Prana.
            </ListItem>
            <ListItem>
              <strong>Do not force the mind to be still</strong>, as this will
              set in motion additional brain waves, hindering meditation. If the
              mind persists in wandering, simply dissociate from it, and watch
              it, objectively, as though you were watching a movie. It will
              gradually slow down.
            </ListItem>
            <ListItem>
              <strong>Select a focal point</strong> on which the mind may rest.
              For people who are intellectual by nature, this may be the Ajna
              Chakra, the point between the eyebrows. For more emotional people,
              use the Anahata or Heart Chakra. Never change this focal point.
            </ListItem>
            <ListItem>
              <strong>Focus on a neutral or uplifting object</strong>, holding
              the image in place of concentration. If using a Mantra, repeat it
              mentally, and co-ordinate repetition with the breath. If you do
              not have a personalized Mantra, use OM. although mental repetition
              is stronger, the Mantra may be repeated aloud if one becomes
              drowsy. Never change the Mantra.
            </ListItem>
            <ListItem>
              <strong>Repetition will lead to pure thought</strong>, in which
              sound vibration merges with thought vibration, without awareness
              of meaning. Vocal repetition progresses through mental repetition
              to telepathic language, and from there to pure thought.
            </ListItem>
            <ListItem>
              <strong>With practice</strong>, duality disappears and Samadhi, or
              the superconscious state, is reached. Do not become impatient, as
              this takes a long time.
            </ListItem>
            <ListItem>
              In Samadhi one rests in the state of Bliss in which the{' '}
              <strong>Knower, the Knowledge, and the Known become One</strong>.
              This is the superconscious state reached by mystics of all faiths
              and persuasions.
            </ListItem>
            <ListItem>
              Begin the practice of meditation with twenty minute periods and
              increase to one hour. If the body is overcome by jerking or
              tremors control them and keep the energy internalized.
            </ListItem>
          </OrderedList>
          <Heading3>Progression in the Practice</Heading3>
          <Paragraph>
            If you meditate for half an hour daily, you will be able to face
            life with peace and spiritual strength.
            <br /> Meditation is the most powerful mental and nerve tonic.
            <br /> Divine energy freely flows to the adept during meditation,
            and exerts a benign influence on the mind, nerves, sense organs and
            body.
            <br /> It opens the door to intuitive knowledge and realms of
            eternal bliss.
            <br /> The mind becomes calm and steady.
          </Paragraph>
        </Section>
        <ConnectedPages>
          <LayoutSeparator />
          <Section>
            <Paragraph>
              <strong>Connected Articles:</strong>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/meditation/introduction">
                Introduction to Meditation
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/introduction/proper-thinking">
                Proper Thinking
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/breathing-techniques/pranayama">
                Pranayama
              </InfoLink>
            </Paragraph>
          </Section>
        </ConnectedPages>
      </InfoPageLayout>
    </InfoContainer>
  )
}
