import * as React from 'react'
import styled, { keyframes } from 'styled-components'
import { observer } from 'mobx-react-lite'

import { RootStoreType, StoreContext } from '@sayr/client-models'
import Drawer from './Drawer'
import AppBar from './AppBar'
import ChatPage from '../pages/chat/ChatPage'
import AnnouncementsPage from '../pages/announcements/AnnouncementsPage'
import AnnouncementEditPage from '../pages/announcements/AnnouncementEditPage'
import RegistrationsPage from '../pages/registrations/Registrations'
import { Users } from '../pages/registrations/Users'
import { AnnouncementStats } from '../pages/registrations/AnnouncementStats'
import { Homepage } from '../pages/Homepage'
import { Admin } from '../pages/admin/Admin'
import { Seasons } from '../pages/admin/Seasons'
import { CreateSeason } from '../pages/admin/CreateSeason'
import { WarningSetup } from '../pages/admin/WarningSetup'
import { getPageTitle } from '../services/pageTitles'
import { WarningPage } from '../pages/admin/WarningPage'
import { AshramMap } from '../pages/map/AshramMap'
import { InfoHomepage } from '../pages/info/InfoHomepage'
import { InfoTripLogistics } from '../pages/info/tripLogistics/TripLogistics'
import { InfoBeforeArrival } from '../pages/info/tripLogistics/BeforeArrival'
import { InfoCheckInProcess } from '../pages/info/tripLogistics/CheckInProcess'
import { InfoPackingNecessities } from '../pages/info/tripLogistics/PackingNecessities'
import { InfoPracticeGuide } from '../pages/info/practice-guide/PracticeGuide'
import { InfoInvocationsAndMantras } from '../pages/info/practice-guide/InvocationsAndMantras'
import { InfoMeditation } from '../pages/info/practice-guide/meditation/Meditation'
import { InfoIntroduction } from '../pages/info/practice-guide/intro/Introduction'
import { InfoFivePointsOfYoga } from '../pages/info/practice-guide/intro/FivePointsOfYoga'
import { InfoProperExercise } from '../pages/info/practice-guide/intro/ProperExercise'
import { InfoProperBreathing } from '../pages/info/practice-guide/intro/ProperBreathing'
import { InfoProperRelaxation } from '../pages/info/practice-guide/intro/ProperRelaxation'
import { InfoProperDiet } from '../pages/info/practice-guide/intro/ProperDiet'
import { InfoProperThinking } from '../pages/info/practice-guide/intro/ProperThinking'
import { InfoMeditationIntroduction } from '../pages/info/practice-guide/meditation/Introduction'
import { InfoMeditationTechnique } from '../pages/info/practice-guide/meditation/Technique'
import { InfoBreathingTechniques } from '../pages/info/practice-guide/breathingTechniques/BreathingTechniques'
import { InfoKapalabhati } from '../pages/info/practice-guide/breathingTechniques/Kapalabhati'
import { InfoAnulomaViloma } from '../pages/info/practice-guide/breathingTechniques/AnulomaViloma'
import { InfoAsanas } from '../pages/info/practice-guide/asanas/Asanas'
import { InfoSunSalutations } from '../pages/info/practice-guide/asanas/SunSalutations'
import { InfoHeadstand } from '../pages/info/practice-guide/asanas/Headstand'
import { InfoShoulderstand } from '../pages/info/practice-guide/asanas/Shoulderstand'
import { InfoPlough } from '../pages/info/practice-guide/asanas/Plough'
import { InfoFish } from '../pages/info/practice-guide/asanas/Fish'
import { InfoForwardBend } from '../pages/info/practice-guide/asanas/ForwardBend'
import { InfoCobra } from '../pages/info/practice-guide/asanas/Cobra'
import { InfoLocust } from '../pages/info/practice-guide/asanas/Locust'
import { InfoBow } from '../pages/info/practice-guide/asanas/Bow'
import { InfoHalfSpinalTwist } from '../pages/info/practice-guide/asanas/HalfSpinalTwist'
import { InfoCrow } from '../pages/info/practice-guide/asanas/Crow'
import { InfoHandsToFeet } from '../pages/info/practice-guide/asanas/HandsToFeet'
import { InfoTriangle } from '../pages/info/practice-guide/asanas/Triangle'
import { InfoSavasana } from '../pages/info/practice-guide/asanas/Savasana'

const scaleFrom0 = keyframes`
 0% {
   transform:scale(0);
 }

 100% {
   transform:scale(1);
 }`

const BackgroundStyle = styled.div.attrs({ className: 'background' })`
  display: grid;
  height: 100%;
  position: relative;
  overflow-y: auto;

  &::before {
    animation: ${scaleFrom0} 1.6s cubic-bezier(0.83, 0, 0.17, 1);
    background-image: url(/logo.svg);
    background-size: 100% 100%;
    filter: ${({ theme }) => theme.palette.mode === 'dark' && `grayscale()`};
    content: '';
    height: 100%;
    opacity: ${({ theme }) => (theme.palette.mode === 'dark' ? 0.08 : 0.1)};
    position: absolute;
    transition: opacity 1s cubic-bezier(0.83, 0, 0.17, 1);
    width: 100%;
    z-index: 0;
  }
`

const ScrollableBackground = styled.div`
  position: relative;
  overflow: auto;
`

const Background = ({ children }: { children: React.ReactNode }) => (
  <BackgroundStyle>
    <ScrollableBackground className="scrollable-background">
      {children}
    </ScrollableBackground>
  </BackgroundStyle>
)

const AppWrapper = styled.div<{ online: boolean }>`
  width: 100%;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(
    var(--vh, 1vh) * 100
  ); // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  background-color: ${({ theme: { palette }, online }) =>
    online ? palette.background.paper : palette.grey['300']};
  color: ${({ theme: { palette } }) =>
    palette.mode === 'dark' ? palette.grey['300'] : 'inherit'};
`

function setGlobalVhProperty() {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  const vh = window.innerHeight * 0.01
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

function App() {
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  // const mainRef = React.createRef<HTMLElement>()
  const store = React.useContext(StoreContext)

  React.useEffect(() => {
    setGlobalVhProperty()
    window.addEventListener('resize', setGlobalVhProperty)
    return () => window.removeEventListener('resize', setGlobalVhProperty)
  }, [])

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ['Tab', 'Shift', 'Space', 'Enter'].some(
          k => k === (event as React.KeyboardEvent).key
        )
      ) {
        return
      }
      setDrawerOpen(open)
    }

  return (
    <>
      <AppWrapper online={store.status.online && store.status.serverOnline}>
        {store.loggedInUser && (
          <>
            <AppBar
              toggleDrawer={toggleDrawer}
              pageTitle={getPageTitle(store.view)}
            />
            <Background>{inside(store)}</Background>
          </>
        )}
        {/* {!store.loggedInUser && store.view.page === '/login' ? (
                <LoginPage />
              ) : !store.loggedInUser && store.view.page === '/' ? (
                <LoginCoverPage />
              ) : !store.loggedInUser && store.view.page === '/manualSignup' ? (
                <ManualSignUpPage />
              ) : (
                store.loggedInUser && (
                  <>
                    <AppBar
                      toggleDrawer={toggleDrawer}
                      pageTitle={getPageTitle()}
                    />
                    <Background>
                      <Main ref={mainRef} tabIndex={-1}>
                        {store.view.page === '/root' && <h1>Dashboard</h1>}
                        {store.view.page === '/announcements' && (
                          <AnnouncementsPage />
                        )}
                        {['/announcements/new', '/announcements/edit'].some(
                          page => page === store.view.page
                        ) && <AnnouncementEditPage />}
                        {store.view.page === '/chat' && <ChatPage />}
                        {store.view.page === '/info-section' && (
                          <InfoSectionPage />
                        )}
                        {store.view.page ===
                          '/info-section/covid-19-guidelines' && (
                          <InfoCovid19GuidelinesPage />
                        )}
                        {store.view.page === '/info-section/practice-guide' && (
                          <InfoPracticeGuide />
                        )}

                        {store.view.page === '/info-section/abc/123' && (
                          <div>
                            Specific page inside two levels of navigation
                          </div>
                        )}
                        {store.view.page === '/map' && <div>Map</div>}
                        {store.view.page === '/my-bookings' && (
                          <div>Account Details Page</div>
                        )}
                        {store.view.page.match(/\/registrations(\/|$)/) && (
                          <RegistrationsPage />
                        )}
                        {store.view.page === '/settings' && <SettingsPage />}
                        {store.view.page === '/activities' && (
                          <div>Activities Page</div>
                        )}
                      </Main>
                    </Background>
                    <AnimatedBottomNavigation />
                  </>
                )
              )} */}
      </AppWrapper>
      <Drawer open={drawerOpen} toggleDrawer={toggleDrawer} />
    </>
  )
}

const inside = (store: RootStoreType) => {
  if (!store.loggedInUser) return <>Not logged in...</>
  if (store.view.page === '/chat') return <ChatPage />
  else if (store.view.page === '/announcements') return <AnnouncementsPage />
  else if (
    ['/announcements/new', '/announcements/edit'].some(
      page => page === store.view.page
    )
  )
    return <AnnouncementEditPage />
  else if (store.view.page === '/') return <Homepage />
  else if (store.view.page.match(/\/registrations(\/|$)/))
    return <RegistrationsPage />
  else if (store.view.page === '/announcements/stats')
    return <AnnouncementStats />
  else if (store.view.page === '/users') return <Users />
  else if (store.view.page === '/admin') return <Admin />
  else if (store.view.page === '/admin/additional-data-seasons')
    return <Seasons />
  else if (store.view.page === '/admin/additional-data-seasons/new')
    return <CreateSeason />
  else if (store.view.page === '/admin/warning-setup') return <WarningSetup />
  else if (store.view.page === '/admin/warnings') return <WarningPage />
  else if (store.view.page === '/info') return <InfoHomepage />
  else if (store.view.page === '/info/trip-logistics')
    return <InfoTripLogistics />
  else if (store.view.page === '/info/trip-logistics/before-arrrival')
    return <InfoBeforeArrival />
  else if (store.view.page === '/info/trip-logistics/packing-necessities')
    return <InfoPackingNecessities />
  else if (store.view.page === '/info/trip-logistics/check-in-process')
    return <InfoCheckInProcess />
  else if (store.view.page === '/info/practice-guide')
    return <InfoPracticeGuide />
  else if (store.view.page === '/info/practice-guide/introduction')
    return <InfoIntroduction />
  else if (
    store.view.page === '/info/practice-guide/introduction/five-points-of-yoga'
  )
    return <InfoFivePointsOfYoga />
  else if (
    store.view.page === '/info/practice-guide/introduction/proper-exercise'
  )
    return <InfoProperExercise />
  else if (
    store.view.page === '/info/practice-guide/introduction/proper-breathing'
  )
    return <InfoProperBreathing />
  else if (
    store.view.page === '/info/practice-guide/introduction/proper-relaxation'
  )
    return <InfoProperRelaxation />
  else if (store.view.page === '/info/practice-guide/introduction/proper-diet')
    return <InfoProperDiet />
  else if (store.view.page === '/info/practice-guide/invocations-and-mantras')
    return <InfoInvocationsAndMantras />
  else if (
    store.view.page === '/info/practice-guide/introduction/proper-thinking'
  )
    return <InfoProperThinking />
  else if (store.view.page === '/info/practice-guide/invocations-and-mantras')
    return <InfoInvocationsAndMantras />
  else if (store.view.page === '/info/practice-guide/meditation')
    return <InfoMeditation />
  else if (store.view.page === '/info/practice-guide/meditation/introduction')
    return <InfoMeditationIntroduction />
  else if (store.view.page === '/info/practice-guide/meditation/technique')
    return <InfoMeditationTechnique />
  else if (store.view.page === '/info/practice-guide/breathing-techniques')
    return <InfoBreathingTechniques />
  else if (
    store.view.page === '/info/practice-guide/breathing-techniques/kapalabhati'
  )
    return <InfoKapalabhati />
  else if (store.view.page === '/info/practice-guide/asanas')
    return <InfoAsanas />
  else if (store.view.page === '/info/practice-guide/asanas/sun-salutations')
    return <InfoSunSalutations />
  else if (store.view.page === '/info/practice-guide/asanas/headstand')
    return <InfoHeadstand />
  else if (store.view.page === '/info/practice-guide/asanas/shoulderstand')
    return <InfoShoulderstand />
  else if (store.view.page === '/info/practice-guide/asanas/plough')
    return <InfoPlough />
  else if (store.view.page === '/info/practice-guide/asanas/fish')
    return <InfoFish />
  else if (store.view.page === '/info/practice-guide/asanas/forward-bend')
    return <InfoForwardBend />
  else if (store.view.page === '/info/practice-guide/asanas/cobra')
    return <InfoCobra />
  else if (store.view.page === '/info/practice-guide/asanas/locust')
    return <InfoLocust />
  else if (store.view.page === '/info/practice-guide/asanas/bow')
    return <InfoBow />
  else if (store.view.page === '/info/practice-guide/asanas/half-spinal-twist')
    return <InfoHalfSpinalTwist />
  else if (store.view.page === '/info/practice-guide/asanas/crow')
    return <InfoCrow />
  else if (store.view.page === '/info/practice-guide/asanas/hands-to-feet')
    return <InfoHandsToFeet />
  else if (store.view.page === '/info/practice-guide/asanas/triangle')
    return <InfoTriangle />
  else if (store.view.page === '/info/practice-guide/asanas/savasana')
    return <InfoSavasana />
  else if (
    store.view.page ===
    '/info/practice-guide/breathing-techniques/anuloma-viloma'
  )
    return <InfoAnulomaViloma />
  else if (store.view.page === '/ashram-map') return <AshramMap />
  else
    return (
      <div style={{ marginTop: '2rem', textAlign: 'center' }}>
        Sorry, not implemented yet...
      </div>
    )
}

export default observer(App)
