/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from 'mobx-state-tree'

/**
 * Typescript enum
 */

export enum ProgramTypes {
  KARMA_YOGA = 'KARMA_YOGA',
  ATTC = 'ATTC',
  SPEAKER = 'SPEAKER',
  TTC = 'TTC',
  VISITING_STAFF = 'VISITING_STAFF',
  VACATION = 'VACATION',
  BABIES = 'BABIES',
  CHILDREN = 'CHILDREN',
  COMMUTERS = 'COMMUTERS',
  TTC_COMPLETION = 'TTC_COMPLETION'
}

/**
 * ProgramTypes
 *
 * types of program categories
 */
export const ProgramTypesEnumType = types.enumeration('ProgramTypes', [
  'KARMA_YOGA',
  'ATTC',
  'SPEAKER',
  'TTC',
  'VISITING_STAFF',
  'VACATION',
  'BABIES',
  'CHILDREN',
  'COMMUTERS',
  'TTC_COMPLETION'
])
