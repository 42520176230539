import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Breadcrumbs,
  Heading2,
  Heading3,
  Paragraph,
  InfoContainer,
  InfoLink,
  Section,
  Hr,
  InfoPageLayout,
  LayoutSeparator,
  Link,
  ConnectedPages,
  Heading4
} from '../../../../components/InfoTypography'

export function InfoProperThinking() {
  return (
    <InfoContainer style={{ wordBreak: 'break-word' }}>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <InfoLink target="">Information</InfoLink>
        <InfoLink target="practice-guide">Practice Guide</InfoLink>
        <InfoLink target="practice-guide/introduction">Introduction</InfoLink>
        <Link $disabled>5. Proper Thinking</Link>
      </Breadcrumbs>
      <Heading2>5. Proper Thinking</Heading2>
      <Hr />
      <InfoPageLayout>
        <Section>
          <Heading3>Positive Thinking and Meditation</Heading3>
          <Heading4 className="text-sanskrit">Vedānta and Dhyāna</Heading4>
          <Paragraph>
            When the surface of a lake is still, one can see to the bottom very
            clearly. This is impossible when the surface is agitated by waves.
            <br />
            In the same way, when the mind is still, with no thoughts or
            desires, you can see the “Self”.
            <br /> This is called “Yoga”.
          </Paragraph>
          <Paragraph>
            We can control the mental agitation by two means: by concentrating
            the mind either externally or internally.
            <br /> Internally, we focus on the “Self” or the consciousness of “I
            am”.
            <br /> Externally, we focus on anything other than the “Self” or “I
            am”.
          </Paragraph>
          <Paragraph>
            When we take up some recreation such as golf, through concentration
            on putting the ball into the hole, the other thoughts are slowed
            down or stilled.
            <br /> We feel we have played a good game when we have achieved
            perfect concentration.
            <br /> The happiness we experience comes, not because of the ball
            being put into the hole eighteen times, but because we have achieved
            perfect concentration eighteen times.
            <br /> At that time, all the worries and problems of the world
            disappeared.
          </Paragraph>
          <Paragraph>
            The mental ability to concentrate is inherent to all; it is not
            extraordinary or mysterious.
            <br /> Meditation is not something that a Yogi has to teach you; you
            already have the ability to shut out thoughts.
          </Paragraph>
          <Paragraph>
            The only difference between this and meditation (the positive way),
            is that generally we have learned to focus the mind externally on
            objects.
            <br /> When the mind is fully concentrated, time passes unnoticed,
            as if it did not exist.
            <br /> When the mind is focused, there is no time! Time is nothing
            but a modification of the mind.
            <br /> Time, Space, and Causation and all external experiences are
            mental creations.
          </Paragraph>
          <Paragraph>
            All happiness achieved through the mind is temporary and fleeting;
            it is limited by nature.
            <br /> To achieve that state of lasting happiness and absolute
            peace, we must first know how to calm the mind, to concentrate and
            go beyond the mind.
            <br /> By turning the mind's concentration inward, upon the Self, we
            can deepen that experience of perfect concentration.
            <br /> This is the state of “Meditation”.
          </Paragraph>
        </Section>
        <ConnectedPages>
          <LayoutSeparator />
          <Section>
            <Paragraph>
              <strong>Connected Articles:</strong>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/introduction/five-points-of-yoga">
                General Introduction
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/introduction/proper-exercise">
                1. Proper Exercise
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/introduction/proper-breathing">
                2. Proper Breathing
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/introduction/proper-relaxation">
                3. Proper Relaxation
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/introduction/proper-diet">
                4. Proper Diet
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/meditation">Meditation</InfoLink>
            </Paragraph>
          </Section>
        </ConnectedPages>
      </InfoPageLayout>
    </InfoContainer>
  )
}
