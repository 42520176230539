import * as React from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { clone, getSnapshot } from 'mobx-state-tree'
import { observer } from 'mobx-react-lite'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Skeleton from '@mui/material/Skeleton'
import InputAdornment from '@mui/material/InputAdornment'
import List from '@mui/material/List'
import Switch from '@mui/material/Switch'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Tooltip from '@mui/material/Tooltip'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import TextField from '@mui/material/TextField'
import { FieldError, useForm, Controller, SubmitHandler } from 'react-hook-form'

import { PrimaryButton, SecondaryButton } from '../../components/common/Buttons'
import {
  Field,
  Wrapper,
  FormTextField,
  FormError
} from '../../components/common/Forms'
import {
  AnnouncementResponseModelType,
  Audience,
  StoreContext
} from '@sayr/client-models'

import minMax from 'dayjs/plugin/minMax'
import {
  DateTimeTextField,
  StyledListSubheader,
  NewAnnouncementWrapper,
  StyledInput,
  AudienceInputLabel,
  MultilineListItem,
  ElevatedPaper,
  AudienceSegmentName,
  AudienceCountChip,
  PrimaryButtonWithMargin,
  StyledFormControlLabel,
  FormBottomFields,
  FormBottomButtons
} from './AnnouncementEditPageStyles'

dayjs.extend(minMax)

type FormInputs = {
  draftSubject: string
  draftBodyText: string
  draftAudience: AnnouncementResponseModelType['audience']
  draftAudienceCourseId: number | null | undefined
  draftPublishStart: Dayjs | null
  draftPublishEnd: Dayjs
  draftPriority: boolean
}

const audiencesMap: {
  key: AnnouncementResponseModelType['audience']
  label: string
}[] = [
  {
    key: 'ALL_RESIDENTS_AND_COMMUTERS',
    label: 'All current residents & day visitors'
  },
  {
    key: 'ALL_RESIDENTS',
    label: 'All current residents (anyone taking lodging)'
  },
  {
    key: 'ALL_RESIDENTS_GUESTS',
    label: 'All paying guests including children'
  },
  {
    key: 'ALL_RESIDENTS_GUESTS_NO_CHILDREN',
    label: 'All paying guests excluding children'
  },
  { key: 'ALL_STAFF_KARMA_YOGIS', label: 'All staff & karma yogis' },
  { key: 'ALL_KARMA_YOGIS', label: 'All karma yogis' },
  { key: 'ALL_STAFF', label: 'All staff' },
  { key: 'ALL_SPEAKERS', label: 'All speakers' },
  {
    key: 'STUDENTS_IN_COURSE',
    label: `Students in Course `
  }
]

function getAudienceTargetLabel(audienceKey: string | undefined) {
  const audienceRecord = audiencesMap.find(k => k.key === audienceKey)
  if (!audienceRecord) return ''

  return audienceRecord.label
}

function EditAnnouncementComponent(
  props: {
    save: SubmitHandler<FormInputs>
    subject: string
    setSubject: (newSubject: string) => void
    bodyText: string
    setBodyText: (newBodyText: string) => void
    audience: AnnouncementResponseModelType['audience']
    setAudience: (
      newAudience: AnnouncementResponseModelType['audience']
    ) => void
    audienceCourseId: number | null | undefined

    publishStart: Date | null
    setPublishStart: (newPublishStart: Dayjs | null) => void
    publishEnd: Date
    setPublishEnd: (newPublishStart: Dayjs) => void
    highPriority: boolean
    togglePriority: () => void
    sendAlert: boolean
    toggleNotify: () => void
    reset: () => void
  } & (
    | { mode: 'edit'; originalPublishStart: Date; originalPublishEnd: Date }
    | { mode: 'new' }
  )
) {
  const {
    save,
    subject,
    setSubject,
    bodyText,
    setBodyText,
    audience,
    setAudience,
    // audienceCourseId,
    publishStart,
    setPublishStart,
    publishEnd,
    setPublishEnd,
    highPriority,
    togglePriority,
    sendAlert,
    toggleNotify,
    reset,
    mode
  } = props

  // use React Hook Form to validate input when "touched"
  const {
    clearErrors,
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    setValue,
    setError,
    trigger
  } = useForm<FormInputs>({ mode: 'onTouched' })
  const store = React.useContext(StoreContext)
  const [populationLoaded, setPopulationLoaded] = React.useState(false)

  // emulate loading data delay
  React.useEffect(() => {
    const intervalId = setTimeout(() => setPopulationLoaded(true), 4500)
    return () => clearInterval(intervalId)
  }, [])

  const [audienceDialogOpen, setAudienceDialogOpen] = React.useState(false)

  const shouldBeAbleToArchive = mode === 'edit' && dayjs().isBefore(publishEnd)
  const shouldBeAbleToDuplicate = mode === 'edit'

  function publishStartDisabledLogic() {
    if (props.mode === 'edit')
      return dayjs().isAfter(props.originalPublishStart)
    else return false
  }

  function publishEndDisabledLogic() {
    if (props.mode === 'edit') return dayjs().isAfter(props.originalPublishEnd)
    else return false
  }

  function getMinDateForPublishEnd() {
    if (props.mode === 'edit')
      return publishEndDisabledLogic()
        ? dayjs(0)
        : dayjs.max(dayjs(), dayjs(props.originalPublishStart))
    else return dayjs(publishStart) || dayjs()
  }

  const setAndClose = (value: Audience) => {
    if (value) {
      setAudience(value)
      setValue('draftAudience', value)
      setAudienceDialogOpen(false)
      clearErrors('draftAudience')
    }
  }

  type AudienceTarget = {
    name: Audience
    count: number
    waitWithSkeleton?: boolean
  }
  const audienceTargets: AudienceTarget[] = [
    { name: Audience.ALL_RESIDENTS_AND_COMMUTERS, count: 270 },
    { name: Audience.ALL_RESIDENTS, count: 259 },
    { name: Audience.ALL_RESIDENTS_GUESTS, count: 184 },
    {
      name: Audience.ALL_RESIDENTS_GUESTS_NO_CHILDREN,
      count: 182,
      waitWithSkeleton: populationLoaded
    },
    {
      name: Audience.ALL_STAFF_KARMA_YOGIS,
      count: 122,
      waitWithSkeleton: populationLoaded
    },
    {
      name: Audience.ALL_KARMA_YOGIS,
      count: 68,
      waitWithSkeleton: populationLoaded
    },
    { name: Audience.ALL_STAFF, count: 54, waitWithSkeleton: populationLoaded },
    {
      name: Audience.ALL_SPEAKERS,
      count: 9,
      waitWithSkeleton: populationLoaded
    }
  ]

  function renderAudienceTargetItem(targetItem: AudienceTarget, i: number) {
    return (
      <AudienceTargetItem
        key={i}
        name={targetItem.name}
        count={targetItem.count}
        setSelected={setAndClose}
        value={audience}
        disabled={mode === 'edit'}
        waitWithSkeleton={
          targetItem.waitWithSkeleton === undefined
            ? true
            : targetItem.waitWithSkeleton
        }
      />
    )
  }

  return (
    <NewAnnouncementWrapper>
      <form onSubmit={handleSubmit(save)}>
        <Wrapper bottomSpacing>
          <Tooltip title="Maximum 80 characters">
            <Field>
              <Controller
                name="draftSubject"
                defaultValue={subject}
                control={control}
                rules={{
                  required: true,
                  maxLength: 80
                }}
                shouldUnregister={true}
                render={({ field }) => (
                  <FormTextField
                    label="Subject"
                    placeholder="Enter a one-line subject here"
                    fullWidth
                    value={field.value}
                    disabled={mode === 'edit'}
                    inputRef={field.ref}
                    onBlur={field.onBlur}
                    onChange={e => {
                      setSubject(e.target.value) // update to store
                      field.onChange(e) // register change in the form hook
                    }}
                    autoComplete="off"
                  />
                )}
              />
              {errors.draftSubject?.type === 'required' && (
                <FormError>* This field is required</FormError>
              )}
              {errors.draftSubject?.type === 'maxLength' && (
                <FormError>* Exceeds 80 characters limit</FormError>
              )}
            </Field>
          </Tooltip>
        </Wrapper>
        <Wrapper bottomSpacing>
          <Field>
            <Controller
              name="draftBodyText"
              control={control}
              defaultValue={bodyText}
              rules={{ required: true }}
              shouldUnregister={true}
              render={({ field }) => (
                <FormTextField
                  label="Body Text"
                  placeholder="Full announcement contents"
                  multiline
                  rows={6}
                  fullWidth
                  value={field.value}
                  disabled={mode === 'edit'}
                  inputRef={field.ref}
                  onBlur={field.onBlur}
                  onChange={e => {
                    setBodyText(e.target.value) // update to store
                    field.onChange(e) // register change in the form hook
                  }}
                  autoComplete="off"
                />
              )}
            />
            {errors.draftBodyText && (
              <FormError>* This field is required</FormError>
            )}
          </Field>
        </Wrapper>
        <Wrapper bottomSpacing={!!errors.draftAudience}>
          <Field>
            <AudienceInputLabel>
              Audience
              <br />
              <Controller
                name="draftAudience"
                control={control}
                defaultValue={audience}
                rules={{
                  required: true,
                  validate: {
                    validAudience: e => !!getAudienceTargetLabel(e ?? '')
                  }
                }}
                shouldUnregister={true}
                render={({ field }) => (
                  <StyledInput
                    fullWidth
                    value={getAudienceTargetLabel(field.value)}
                    inputRef={field.ref}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    onClick={() => setAudienceDialogOpen(true)}
                    onKeyDown={e => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        e.preventDefault()
                        setAudienceDialogOpen(true)
                      }
                      trigger('draftAudience')
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <ArrowDropDownIcon />
                      </InputAdornment>
                    }
                    autoComplete="off"
                  />
                )}
              />
            </AudienceInputLabel>
            <Dialog
              onClose={() => setAudienceDialogOpen(false)}
              aria-labelledby="simple-dialog-title"
              open={audienceDialogOpen}
            >
              <DialogTitle id="simple-dialog-title">
                Choose Announcement Audience
              </DialogTitle>
              <ElevatedPaper>
                <StyledListSubheader>Everyone present</StyledListSubheader>
                {audienceTargets.slice(0, 1).map(renderAudienceTargetItem)}
              </ElevatedPaper>
              <ElevatedPaper>
                <StyledListSubheader>Resident Audience</StyledListSubheader>
                <List>
                  {audienceTargets.slice(1, 2).map(renderAudienceTargetItem)}
                  <StyledListSubheader $secondary>
                    Resident Guests
                  </StyledListSubheader>
                  {audienceTargets.slice(2, 4).map(renderAudienceTargetItem)}
                </List>
                <StyledListSubheader $secondary>
                  Resident Staff/Karma Yogis
                </StyledListSubheader>
                {audienceTargets.slice(4, 7).map(renderAudienceTargetItem)}
                <StyledListSubheader $secondary>Speakers</StyledListSubheader>
                {audienceTargets.slice(7, 8).map(renderAudienceTargetItem)}
              </ElevatedPaper>
              <ElevatedPaper>
                <StyledListSubheader>Course Participants</StyledListSubheader>
                <PrimaryButtonWithMargin disabled>
                  Course Students...
                </PrimaryButtonWithMargin>
              </ElevatedPaper>
            </Dialog>
            {errors.draftAudience && (
              <FormError>* This field is required</FormError>
            )}
          </Field>
        </Wrapper>
        <Wrapper bottomSpacing={!!errors.draftPublishStart}>
          <Field minimumDesiredWidth="14rem">
            <Controller
              control={control}
              name="draftPublishStart"
              defaultValue={publishStart ? dayjs(publishStart) : null}
              rules={{
                validate: {
                  beforeNow: date => {
                    if (publishStartDisabledLogic()) return true
                    else
                      return date
                        ? dayjs().isBefore(dayjs(date.toString()))
                        : true
                  }
                }
              }}
              shouldUnregister={true}
              render={({ field }) => (
                <DateTimePicker
                  inputFormat="MM/DD/YYYY HH:mm a"
                  label="Start publishing on"
                  minDateTime={publishStartDisabledLogic() ? dayjs(0) : dayjs()}
                  value={publishStart}
                  disabled={publishStartDisabledLogic()}
                  inputRef={field.ref}
                  onChange={date => {
                    const valid = !!date && date.isValid()
                    setPublishStart(valid ? date : null)
                    trigger('draftPublishEnd')
                    field.onChange(date)
                  }}
                  renderInput={params => (
                    <Tooltip title="Choose a date or keep empty to start publishing now">
                      <TextField
                        autoComplete="off"
                        {...params}
                        onBlur={field.onBlur}
                        helperText={
                          errors.draftPublishStart
                            ? ''
                            : 'Keep blank to start publishing now.'
                        }
                      />
                    </Tooltip>
                  )}
                />
              )}
            />
            {(errors.draftPublishStart as FieldError)?.type === 'beforeNow' && (
              <FormError>* Past date</FormError>
            )}
          </Field>
          <Field minimumDesiredWidth="14rem">
            <Controller
              name="draftPublishEnd"
              control={control}
              defaultValue={dayjs(publishEnd)}
              rules={{
                required: true,
                validate: {
                  afterPublishStartDate: date => {
                    const publishStartDate = dayjs(
                      getValues('draftPublishStart') || undefined
                    )

                    return date
                      ? date.isAfter(publishStartDate.add(15, 'minutes'))
                      : true
                  }
                }
              }}
              shouldUnregister={true}
              render={({ field }) => (
                <DateTimePicker
                  inputFormat="MM/DD/YYYY HH:mm a"
                  label="Stop publishing on"
                  minDateTime={getMinDateForPublishEnd()}
                  value={publishEnd}
                  renderInput={params => (
                    <DateTimeTextField
                      {...params}
                      helperText=""
                      autoComplete="off"
                    />
                  )}
                  disabled={publishEndDisabledLogic()}
                  onChange={date => {
                    if (!date) {
                      console.error('publish end changed to null')
                      return
                    } // the picker is not clearable, so there will always be a date.

                    // the assumption is that date has to be non null
                    // since the picker is not clearable
                    if (dayjs().isBefore(date!)) {
                      setPublishEnd(date)
                      field.onChange(date)
                      trigger('draftPublishEnd')
                    } else setError('draftPublishEnd', { type: 'beforeNow' })
                  }}
                />
              )}
            />
            {(errors.draftPublishEnd as FieldError)?.type ===
              'afterPublishStartDate' && (
              <FormError>* Minimum 15 minute duration</FormError>
            )}
            {(errors.draftPublishEnd as FieldError)?.type === 'beforeNow' && (
              <FormError>* Past date</FormError>
            )}
          </Field>
        </Wrapper>
        <br />
        <Wrapper disableColumnGap>
          <FormBottomFields>
            <Tooltip title="High importance is for safety &amp; health concerns only">
              <StyledFormControlLabel
                label="Important"
                control={
                  <Controller
                    name="draftPriority"
                    control={control}
                    defaultValue={highPriority}
                    render={({ field }) => (
                      <Switch
                        checked={highPriority}
                        onChange={e => {
                          togglePriority()
                          field.onChange(e)
                        }}
                        inputRef={field.ref}
                        onBlur={field.onBlur}
                      />
                    )}
                  />
                }
              />
            </Tooltip>
            <Tooltip title="Send alert will issue an email and a push notification for the users who have registered to receive notifications in any of their devices. Push notifications currently do not work at all on Apple devices, and email notifications are subject to user preferences.">
              <StyledFormControlLabel
                label="Send Alert"
                control={
                  <>
                    <Switch
                      checked={sendAlert}
                      onChange={() => toggleNotify()}
                    />
                  </>
                }
              />
            </Tooltip>
          </FormBottomFields>
          <FormBottomButtons>
            <Tooltip title="Discard changes">
              <SecondaryButton
                onClick={() => {
                  if (window.history.length) window.history.back()
                  else store.view.openAnnouncementsPage()
                }}
              >
                Back
              </SecondaryButton>
            </Tooltip>
            {/* TODO:  checking if the announcement is active or archived should be on the parent element, given as a prop. */}
            {!store.announcements.archived.some(
              a => a.id === store.view.id
            ) && (
              <SecondaryButton onClick={reset}>
                {mode === 'edit' ? `Reset` : `Clear`}
              </SecondaryButton>
            )}
            {shouldBeAbleToArchive && (
              // todo: implement archiving
              <SecondaryButton
                type="submit"
                onClick={() => {
                  setPublishEnd(dayjs())
                  setValue('draftPublishEnd', dayjs())
                  handleSubmit(save)()
                }}
              >
                Archive
              </SecondaryButton>
            )}
            {shouldBeAbleToDuplicate && (
              <SecondaryButton
                onClick={() => {
                  const currentDraftSnapshot = getSnapshot(
                    store.announcements.editMode!.newDraft!
                  )
                  store.view.openAnnouncementsNewDraftPage()
                  if (
                    currentDraftSnapshot?.subject ||
                    currentDraftSnapshot?.bodyText
                  ) {
                    setTimeout(() =>
                      alert(
                        'You are currently editing a draft. Reset the draft first if you want to duplicate an archived announcement.'
                      )
                    )
                  } else if (store.announcements.editMode) {
                    store.announcements.editMode.clearDraft()
                    const newDraft = store.announcements.editMode.newDraft!
                    newDraft.setSubject(subject)
                    newDraft.setBodyText(bodyText)
                    if (audience) newDraft.setAudience(audience)
                    if (highPriority) newDraft.togglePriority()
                    if (sendAlert) newDraft.toggleNotify()
                  }
                }}
              >
                Duplicate
              </SecondaryButton>
            )}
            {!store.announcements.archived.some(
              a => a.id === store.view.id
            ) && <PrimaryButton type="submit">Save</PrimaryButton>}
          </FormBottomButtons>
        </Wrapper>
      </form>
    </NewAnnouncementWrapper>
  )
}

function AudienceTargetItem({
  name,
  count,
  setSelected,
  waitWithSkeleton = true,
  value,
  disabled = false
}: {
  name: Audience
  count: number
  setSelected: (name: Audience) => void
  waitWithSkeleton?: boolean
  value: AnnouncementResponseModelType['audience']
  disabled?: boolean
}) {
  return (
    <MultilineListItem
      selected={value === name}
      color="transparent"
      onClick={() => setSelected(name)}
      disabled={disabled}
    >
      <AudienceSegmentName>{getAudienceTargetLabel(name)}</AudienceSegmentName>
      {waitWithSkeleton ? (
        <AudienceCountChip label={count} />
      ) : (
        <Skeleton>
          <AudienceCountChip label={count} />
        </Skeleton>
      )}
    </MultilineListItem>
  )
}

const EditAnnouncement = observer(function EditAnnouncement() {
  const store = React.useContext(StoreContext)

  const announcement = store.announcements._all.find(
    announcement => announcement.id === store.view.id
  )

  const [announcementClone, setAnnouncementClone] =
    React.useState<AnnouncementResponseModelType>()
  const [displayEmptyPublishStart, setDisplayEmptyPublishStart] =
    React.useState(false)

  React.useEffect(() => {
    if (announcement) setAnnouncementClone(clone(announcement))
  }, [announcement])

  const saveEdit: SubmitHandler<FormInputs> = data => {
    if (!data) throw new Error('called "saveEdit" without arguments')
    if (!announcementClone)
      throw new Error('called "saveEdit" without announcementClone')

    store.mutateUpdateAnnouncement({
      changeArgs: {
        id: announcementClone.id,
        publishEnd: data.draftPublishEnd
      }
    })
    store.announcements.editMode?.clearDraft()

    store.view.openAnnouncementsPage()
  }

  if (!announcement)
    return (
      <div style={{ textAlign: 'center' }}>
        <div style={{ margin: '1rem 0.5rem' }}>Announcement not found</div>
        <SecondaryButton onClick={() => store.view.openAnnouncementsPage()}>
          Back to Announcements
        </SecondaryButton>
      </div>
    )

  if (!announcementClone) return <div>No clone</div>

  return (
    <EditAnnouncementComponent
      mode="edit"
      save={saveEdit}
      subject={announcementClone.subject || ''}
      setSubject={() => {}}
      setBodyText={() => {}}
      bodyText={announcementClone.body || ''}
      audience={announcementClone.audience}
      audienceCourseId={announcementClone.audienceCourseId}
      setAudience={() => {}}
      publishStart={
        displayEmptyPublishStart ? null : announcementClone.publishStart
      }
      originalPublishStart={announcement.publishStart}
      setPublishStart={(newPublishStart: Dayjs | null) => {
        if (newPublishStart) {
          announcementClone.setPublishStart(newPublishStart)
          setDisplayEmptyPublishStart(false)
        } else setDisplayEmptyPublishStart(true)
      }}
      publishEnd={announcementClone.publishEnd}
      originalPublishEnd={announcement.publishEnd}
      setPublishEnd={announcementClone.setPublishEnd}
      highPriority={!!announcementClone.highPriority}
      togglePriority={() => {}}
      sendAlert={!!announcementClone.sendAlert}
      toggleNotify={
        // allow sending an alert only if one hasn't already been sent
        announcement.sendAlert
          ? () => {}
          : () => announcementClone.toggleNotify()
      }
      reset={() => {
        setAnnouncementClone(clone(announcement))
      }}
    />
  )
})

const AnnouncementDraftPage = observer(function AnnouncementDraftPage() {
  const store = React.useContext(StoreContext)

  const saveDraft: SubmitHandler<FormInputs> = data => {
    if (!data) throw new Error('called "saveDraft" without arguments')
    if (!data.draftAudience)
      throw new Error('called "saveDraft" without audience')

    store.mutateCreateAnnouncement({
      newAnnouncement: {
        audience: Audience[data.draftAudience],
        body: data.draftBodyText,
        highPriority: data.draftPriority,
        publishEnd: (data.draftPublishEnd as Dayjs).toDate(),
        sendAlert: false,
        subject: data.draftSubject,
        publishStart: (data.draftPublishStart as Dayjs)?.toDate() ?? null
      }
    })
    store.announcements.editMode?.clearDraft()
    store.view.openAnnouncementsPage()
  }

  if (!store.announcements.editMode?.newDraft)
    return <div>Cannot create a new announcement</div>
  if (store.view.page === '/announcements/new')
    return (
      <EditAnnouncementComponent
        mode="new"
        save={saveDraft}
        subject={store.announcements.editMode.newDraft.subject}
        setSubject={(newSubject: string) =>
          store.announcements.editMode?.newDraft?.setSubject(newSubject)
        }
        setBodyText={(newBodyText: string) =>
          store.announcements.editMode?.newDraft?.setBodyText(newBodyText)
        }
        bodyText={store.announcements.editMode.newDraft.bodyText}
        audience={store.announcements.editMode.newDraft.audience}
        audienceCourseId={
          store.announcements.editMode.newDraft.audienceCourseId
        }
        setAudience={(newAudience: AnnouncementResponseModelType['audience']) =>
          store.announcements.editMode?.newDraft?.setAudience(newAudience)
        }
        publishStart={store.announcements.editMode.newDraft.publishStart}
        setPublishStart={(newPublishStart: Dayjs | null) =>
          store.announcements.editMode?.newDraft?.setPublishStart(
            newPublishStart
          )
        }
        publishEnd={store.announcements.editMode.newDraft.publishEnd}
        setPublishEnd={(newDate: Dayjs) =>
          store.announcements.editMode?.newDraft?.setPublishEnd(newDate)
        }
        highPriority={store.announcements.editMode.newDraft.highPriority}
        togglePriority={() =>
          store.announcements.editMode?.newDraft?.togglePriority()
        }
        sendAlert={store.announcements.editMode.newDraft.sendAlert}
        toggleNotify={() =>
          store.announcements.editMode?.newDraft?.toggleNotify()
        }
        reset={() => store.announcements.editMode?.clearDraft()}
      />
    )
  if (store.view.page === '/announcements/edit') return <EditAnnouncement />

  // never executed. Page is either '/announcements/new' or '/announcements/edit'
  return <></>
})

export default AnnouncementDraftPage
