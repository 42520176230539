import { getSnapshot, Instance, types } from 'mobx-state-tree'
import {
  MessageConnectionModelType,
  MessageEdgeModelType,
  UserResponseModelType
} from '.'
import { ChatResponseModelBase } from './ChatResponseModel.base'

/* The TypeScript type of an instance of ChatResponseModel */
export interface ChatResponseModelType
  extends Instance<typeof ChatResponseModel.Type> {}

/* A graphql query fragment builders for ChatResponseModel */
export {
  selectFromChatResponse,
  chatResponseModelPrimitives,
  ChatResponseModelSelector
} from './ChatResponseModel.base'

/**
 * ChatResponseModel
 */
export const ChatResponseModel = ChatResponseModelBase.props({
  lastReadTimestampShown: types.optional(types.Date, new Date(0))
})
  .views(self => ({
    get $lastReadTimestamp() {
      return new Date(self.lastReadTimestamp)
    },
    get $messages() {
      return self.messages as MessageConnectionModelType | undefined
    },
    get $user() {
      return self.user as UserResponseModelType | undefined
    }
  }))
  .views(self => ({
    get orderedMessages() {
      return (
        self.$messages?.$edges
          .slice()
          .sort((a, b) => (a.$node.$timestamp > b.$node.$timestamp ? 1 : -1)) ||
        []
      )
    },
    get unreadCount() {
      if (self.$lastReadTimestamp.getTime() === 0) return 0

      return (
        self.$messages?.$edges.reduce(
          (count, message) =>
            count +
            (message.$node.$timestamp > self.$lastReadTimestamp ? 1 : 0),
          0
        ) || 0
      )
    },
    get unreadCountShown() {
      if (self.lastReadTimestampShown.getTime() === 0) return 0

      return (
        self.$messages?.$edges.reduce(
          (count, message) =>
            count +
            (message.$node.$timestamp > self.lastReadTimestampShown ? 1 : 0),
          0
        ) || 0
      )
    }
  }))
  .actions(self => ({
    addMessages(messages: MessageEdgeModelType[]) {
      messages.forEach(message => {
        if (
          message.node?._id &&
          !self.$messages?.$edges.find(
            edge => message.node._id === edge.$node?._id
          )
        )
          self.$messages?.$edges.push(message)
      })
    },
    setAllMessagesRead() {
      // self.lastReadTimestamp = self.messages
      //   .map(m => dayjs(m.timestamp))
      //   .reduce((max, t) => (max.isBefore(t) ? t : max), dayjs(0))
      //   .toDate()
      console.log('setting all messages as read')
    },
    setToSeeAllMessagesRead() {
      // self.lastReadTimestampShown = self.messages
      //   .map(m => dayjs(m.timestamp))
      //   .reduce((max, t) => (max.isBefore(t) ? t : max), dayjs(0))
      //   .toDate()
      console.log('setting to show all messages as read')
    },
    sendReadConfirmation() {
      // placeholder for graphql modification query, view model stays the same
    }
  }))
  .actions(self => ({
    async loadNextMessages() {
      if (!self.$messages?.$pageInfo.endCursor) return

      // get old values, since the query automatically updates the store.
      const snapshot = getSnapshot(self)
      const { chatGetMessages } = await self.store.queryChatGetMessages(
        {
          chatId: self.id,
          first: 10,
          after: self.$messages.$pageInfo.endCursor
        },
        s =>
          s.lastReadTimestamp.messages(m =>
            m
              .edges(e =>
                e.cursor.node(
                  n => n.content.author(a => a.email.id.profilePhoto)._id
                )
              )
              .pageInfo(p => p.endCursor.hasNextPage)
          ),
        { fetchPolicy: 'no-cache' }
      )

      // add again the old values
      self.addMessages(snapshot.messages.edges)

      if (chatGetMessages.$messages?.$edges) {
        self.$messages.updatePageInfo(chatGetMessages.$messages.$pageInfo)
      } else console.error('loaded but not further messages')
    }
  }))
