import React from 'react'
import { observer } from 'mobx-react-lite'
import Accordion from '@mui/material/Accordion'
import AccordionActions from '@mui/material/AccordionActions'
import AddIcon from '@mui/icons-material/Add'
import CircularProgress from '@mui/material/CircularProgress'
import FlagIcon from '@mui/icons-material/Flag'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import useMediaQuery from '@mui/material/useMediaQuery'
import LinearProgress from '@mui/material/LinearProgress'

import dayjs from 'dayjs'
import { PrimaryButton, SecondaryButton } from '../../components/common/Buttons'
import {
  Important,
  HighPriorityContainer,
  ReadStatsButton,
  breakpointSplitHead,
  StyledAccordionDetails,
  ScrollablePageContentWrapper,
  Section,
  StyledAccordionSummary,
  AllSectionHeading,
  UnreadSectionHeading,
  ReadSectionHeading,
  AnnouncementHead,
  EditLine,
  AnnouncementInfo,
  Buttons,
  NoAnnouncementsTitle,
  EmptyPagePaper,
  StyledFormControlLabel,
  StyledToggleButton
} from './AnnouncementsPageStyles'
import SearchBar from '../../components/common/SearchBar'
import {
  StoreContext,
  AnnouncementResponseModelType
} from '@sayr/client-models'

const StatsButton = observer(function StatsButton({
  announcement
}: {
  announcement: AnnouncementResponseModelType
}) {
  const store = React.useContext(StoreContext)
  if (announcement.stats) {
    return (
      <ReadStatsButton
        variant="outlined"
        onClick={() => store.view.openAnnouncementsStatsPage(announcement.id)}
      >
        <Typography variant="caption">Confirmations:</Typography>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '1ch'
          }}
        >
          <LinearProgress
            variant="determinate"
            value={announcement.readPercentage}
          />
          <Typography
            variant="caption"
            component="div"
            color="textSecondary"
          >{`${Math.round(announcement.readPercentage)}%`}</Typography>
        </div>
      </ReadStatsButton>
    )
  } else {
    return (
      <ReadStatsButton disabled>
        <Typography variant="caption">
          Confirmation Stats Unavailable
        </Typography>
      </ReadStatsButton>
    )
  }
})

export function Announcement({
  announcement,
  keepExpanded = false,
  initialExpanded = false
}: {
  announcement: AnnouncementResponseModelType
  keepExpanded?: boolean
  initialExpanded?: boolean
}) {
  const { id, subject, body, publishStart, highPriority } = announcement
  const status = 'unread'
  const [expanded, setExpanded] = React.useState(
    initialExpanded || status === 'unread'
  )
  const smallScreen = useMediaQuery('(max-width: 23.125em)')
  const mediumScreen = useMediaQuery(breakpointSplitHead)
  const store = React.useContext(StoreContext)
  return (
    <Accordion
      expanded={keepExpanded || expanded}
      onChange={(_event, expanded) => setExpanded(expanded)}
    >
      <StyledAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
        $expanded={expanded}
        $keepExpanded={keepExpanded}
      >
        <AnnouncementHead $highPriority={!!highPriority}>
          <Typography className="announcement-summary">{subject}</Typography>
          {store.announcements.editMode && (
            <Typography>{announcement.audience}</Typography>
          )}
          {mediumScreen ? (
            <AnnouncementInfo>
              {renderInfo(highPriority, status, smallScreen, publishStart)}
            </AnnouncementInfo>
          ) : (
            renderInfo(highPriority, status, smallScreen, publishStart)
          )}
        </AnnouncementHead>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <Typography style={{ whiteSpace: 'pre-wrap' }} component="pre">
          {body}
        </Typography>
      </StyledAccordionDetails>
      <AccordionActions>
        {store.announcements.editMode ? (
          <Buttons>
            <StatsButton announcement={announcement} />
            <PrimaryButton
              onClick={() =>
                store.view.openAnnouncementsEditPage(announcement.id)
              }
            >
              {store.announcements.archived.some(a => a.id === id)
                ? 'View'
                : 'Edit'}
            </PrimaryButton>
          </Buttons>
        ) : (
          <>
            <SecondaryButton
              onClick={() => {
                store.view.openChatPage()
                // if (status === 'unread') announcement.toggle()
                alert('setting announcement as read')
              }}
              children="Respond"
            />
            {status === 'unread' && (
              <PrimaryButton
                children="Confirm as read"
                onClick={() =>
                  store.mutateConfirmAnnouncementRead(
                    {
                      announcementId: announcement.id
                    },
                    () =>
                      store.announcements
                        .announcementById(announcement.id)
                        ?.setAsRead()
                  )
                }
              />
            )}
          </>
        )}
      </AccordionActions>
    </Accordion>
  )
}

function HighPriority({ withAnnotation }: { withAnnotation: boolean }) {
  return (
    <HighPriorityContainer>
      <FlagIcon color="primary" />
      {withAnnotation && <Important color="primary">Important</Important>}
    </HighPriorityContainer>
  )
}

function renderInfo(
  highPriority: AnnouncementResponseModelType['highPriority'],
  status: 'read' | 'unread',
  smallScreen: boolean,
  publishStart: AnnouncementResponseModelType['publishStart']
) {
  return (
    <>
      {highPriority && (
        <HighPriority withAnnotation={status === 'unread' && !smallScreen} />
      )}
      <Typography>{dayjs(publishStart).format('MMM D, YYYY')}</Typography>
    </>
  )
}

function AnnouncementsPage() {
  const store = React.useContext(StoreContext)
  const loggedInType = store.loggedInUser?.user?.$permissions
    ?.manageAnnouncements
    ? 'staff'
    : 'guest'
  const [view, setView] = React.useState<'active' | 'archived'>('active')
  const [searchTerm, setSearchTerm] = React.useState('')
  const smallScreen = useMediaQuery('(max-width: 37.5em)')
  const verySmallScreen = useMediaQuery('(max-width: 24em)')
  const [loadingArchived, setLoadingArchived] = React.useState(false)

  const viewedAnnouncements =
    view === 'active'
      ? store.announcements.active
      : store.announcements.archived

  // query whenever toggling the view into archived announcements.
  React.useEffect(() => {
    if (view === 'archived') {
      if (store.announcements.archived.length === 0) setLoadingArchived(true)
      store.queryGetArchivedAnnouncements()
    }
  }, [view, store, store.announcements.archived.length])

  React.useEffect(() => {
    if (store.announcements.archived.length) setLoadingArchived(false)
  }, [store.announcements.archived.length])

  return (
    <ScrollablePageContentWrapper>
      {loggedInType === 'staff' && (
        <EditLine $editModeOpen={!!store.announcements.editMode}>
          {!!store.announcements.editMode && (
            <>
              <Tooltip title="Create a new announcement">
                <IconButton
                  style={{ backgroundColor: '#eee' }}
                  onClick={() => store.view.openAnnouncementsNewDraftPage()}
                  size="small"
                >
                  <AddIcon color="primary" />
                </IconButton>
              </Tooltip>
              <ToggleButtonGroup
                size="small"
                value={view}
                exclusive
                onChange={() =>
                  setView(view => (view === 'active' ? 'archived' : 'active'))
                }
                aria-label="view selection"
                style={{ flexWrap: 'wrap' }}
              >
                <StyledToggleButton value="active" aria-label="active">
                  {smallScreen ? 'Active' : 'Active/Scheduled'}
                </StyledToggleButton>
                <StyledToggleButton value="archived" aria-label="archived">
                  Archived
                </StyledToggleButton>
              </ToggleButtonGroup>
            </>
          )}
          <StyledFormControlLabel
            label={verySmallScreen ? 'Edit' : 'Edit Mode'}
            control={
              <Switch
                checked={!!store.announcements.editMode}
                onChange={() =>
                  !!store.announcements.editMode
                    ? store.announcements.exitEditMode()
                    : store.announcements.enterIntoEditMode()
                }
              />
            }
          />
        </EditLine>
      )}
      {!!store.announcements.editMode ? (
        <Section $classPrefix={view}>
          {view === 'archived' && loadingArchived ? (
            <div
              style={{
                marginTop: '1rem',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              <SearchBar
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              />
              <br />
            </>
          )}
          {!!viewedAnnouncements.length && <AllSectionHeading />}
          {viewedAnnouncements
            .filter(a => a.subject?.match(new RegExp(searchTerm.trim(), 'i')))
            .map(announcement => (
              <Announcement
                announcement={announcement}
                key={announcement.id}
                initialExpanded={view === 'active'}
              />
            ))}
        </Section>
      ) : (
        <>
          {!viewedAnnouncements.length && (
            <Section $classPrefix="no">
              <EmptyPagePaper>
                <NoAnnouncementsTitle>
                  There are no posted {view} announcements at the moment.
                  {view === 'active'
                    ? " We'll let you know when something important comes up."
                    : ''}
                </NoAnnouncementsTitle>
              </EmptyPagePaper>
            </Section>
          )}
          {store.announcements.unread.length ? (
            <Section $classPrefix="unread">
              <UnreadSectionHeading />
              {store.announcements.unread.map(announcement => (
                <Announcement
                  announcement={announcement}
                  key={announcement.id}
                />
              ))}
            </Section>
          ) : undefined}
          {store.announcements.read.length ? (
            <Section $classPrefix="read">
              <ReadSectionHeading />
              {store.announcements.read.map(announcement => (
                <Announcement
                  announcement={announcement}
                  key={announcement.id}
                />
              ))}
            </Section>
          ) : undefined}
        </>
      )}
    </ScrollablePageContentWrapper>
  )
}

export default observer(AnnouncementsPage)
