import React from 'react'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { StoreContext } from '@sayr/client-models'
import {
  StyledPageContentWrapper,
  MediaCard,
  StyledCardMedia,
  Breadcrumbs,
  Link,
  InfoLink
} from '../../../components/InfoTypography'

export function InfoTripLogistics() {
  const store = React.useContext(StoreContext)
  return (
    <StyledPageContentWrapper>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <InfoLink target="">Information</InfoLink>
        <Link $disabled>Trip Logistics</Link>
      </Breadcrumbs>
      <MediaCard
        topic="Before Arrival"
        description="Everything you need to know prior to your travel to the ashram."
        action={() => {
          store.view.openInfoPage('trip-logistics/before-arrrival')
        }}
      >
        <StyledCardMedia
          image="https://online.sivanandabahamas.org/wp-content/uploads/2020/06/CKarmaYogi-1024x579.jpg"
          title="Krishna walking on the beach with a yoga mat"
        />
      </MediaCard>
      <MediaCard
        topic="Packing Necessities"
        description="Packing recommendations in terms of clothing, amenities, practice accessories and
          accesories for day to day use."
        action={() => {
          store.view.openInfoPage('trip-logistics/packing-necessities')
        }}
      >
        <StyledCardMedia
          image="/images/suitcases.jpg"
          title="lots of suitcases"
        />
      </MediaCard>
      <MediaCard
        topic="The Check-in Process"
        description="How does the check-in process look like? How early can you get your room? And how to coordinate your travel arrangement back to the airport."
        action={() => {
          store.view.openInfoPage('trip-logistics/check-in-process')
        }}
      >
        <StyledCardMedia
          image="/images/sign-document.jpg"
          title="Document Signing"
        />
      </MediaCard>
    </StyledPageContentWrapper>
  )
}
