import React from 'react'
import Link from '@mui/material/Link'
import { CellProps, Column } from 'react-table'

import {
  GetAllSeasonsQuery,
  SeasonTypes,
  useGetAllSeasonsQuery,
  useRegFollowUpGetInHouseQuery
} from '../../../graphql/generated/typesAndHooks'
import { DataGrid } from '../../../components/common/dataGrid/DataGrid'
import { DataGridContainer } from '../../../components/common/dataGrid/DataGridStyles'
import {
  Balance,
  getSeasonFromBatch,
  SmallAvatar,
  UpdateableAttributeCheckbox,
  ReportRowWithAttributes
} from './FollowUpUtils'

const getColumnsBySeasons = (
  seasons: GetAllSeasonsQuery['getAllSeasons'] | undefined
) => {
  const attributes =
    seasons
      ?.filter(season => season.seasonType === SeasonTypes.MonitoredAttributes)
      .flatMap(season => season.monitoredAttributes)
      .filter(
        attr =>
          attr?.displayed === 'during stay' ||
          attr?.displayed === 'before or during stay'
      )
      .map<Column<ReportRowWithAttributes>>(attr => {
        return {
          Header: attr?.key || 'Cannot get Header',
          accessor: attr?.key || 'no-key',
          Cell: UpdateableAttributeCheckbox as (
            props: CellProps<ReportRowWithAttributes>
          ) => JSX.Element
        }
      }) || []

  const uniqueAttributes = attributes.reduce<Column<ReportRowWithAttributes>[]>(
    (uniqueColumns, column) => {
      if (uniqueColumns.find(uc => uc.accessor === column.accessor))
        return uniqueColumns
      return uniqueColumns.concat(column)
    },
    []
  )

  return (
    [
      {
        Header: '',
        accessor: 'headshot',
        Cell: React.memo(({ value }: { value: string }) => {
          return <SmallAvatar src={value} />
        }),
        width: 50,
        disableSortBy: true,
        disableResizing: true
      },
      {
        Header: 'RG Link',
        accessor: 'rgLink',
        width: 140,
        Cell: React.memo(({ value }: any) => (
          <Link href={value} target="_blank">
            Details
          </Link>
        ))
      },
      {
        Header: 'Arrival Date',
        accessor: 'arrivalDate',
        width: 120
      },
      {
        Header: 'Departure Date',
        accessor: 'departureDate'
      },
      { Header: 'First Name', accessor: 'firstName' },
      { Header: 'Last Name', accessor: 'lastName' },
      { Header: 'Spiritual Name', accessor: 'spiritualName' },
      { Header: 'Program', accessor: 'program' },
      { Header: 'Room', accessor: 'room' },
      { Header: 'Notes', accessor: 'notes' },
      { Header: 'Balance', accessor: 'balance', Cell: Balance }
    ] as Column<ReportRowWithAttributes>[]
  ).concat(uniqueAttributes)
}

export function FollowUpOnInHouse() {
  // load monitored attributes season for registration follow up
  const { data: monitoredAttrSeasonsData } = useGetAllSeasonsQuery()

  const { data: queryData, isLoading } = useRegFollowUpGetInHouseQuery()

  const columns = React.useMemo(() => {
    if (queryData && monitoredAttrSeasonsData) {
      const relevantSeasons = Array.from(
        new Set(
          queryData.RegFollowUpGetInHouse.map(r => ({
            arrivalDate: r.arrivalDate,
            programTypes: r.programTypes
          })).map(({ arrivalDate, programTypes }) => {
            return getSeasonFromBatch({
              seasons: monitoredAttrSeasonsData,
              arrivalDate,
              programTypes
            })
          })
        )
      )
        .filter(s => s)
        .map(s => s as GetAllSeasonsQuery['getAllSeasons'][number])
        .filter(s => !s?.deleted)
      const cols = getColumnsBySeasons(relevantSeasons)
      return cols
    } else return getColumnsBySeasons(undefined)
  }, [monitoredAttrSeasonsData, queryData])

  if (!queryData) return <div>No data yet...</div>
  if (!monitoredAttrSeasonsData) return <div>No Seasonal data yet...</div>
  const data = queryData.RegFollowUpGetInHouse.map<ReportRowWithAttributes>(
    r => {
      return {
        regId: r.regId,
        arrivalDate: r.arrivalDate || '',
        departureDate: r.departureDate || '',
        headshot: r.headshot || '',
        rgLink: r.adminLink || '',
        balance: r.balance,
        firstName: r.firstName || '',
        lastName: r.lastName || '',
        spiritualName: r.spiritualName || '',
        notes: r.registrationNotes || '',
        program: r.program || '',
        programTypes: r.programTypes,
        room: r.room || '',
        ...Object.fromEntries(
          r.monitoredAttributes.map(attr => [attr.key, attr])
        ),
        flightNumber: '',
        flightTime: '',
        highlightRow: !r.personId
      }
    }
  )

  return (
    <DataGridContainer>
      <DataGrid
        columns={columns}
        data={data}
        disableResize={false}
        withGlobalFilter={true}
        queryKey={useRegFollowUpGetInHouseQuery.getKey()}
        skipPageReset
        seasons={monitoredAttrSeasonsData}
        filterableColumns={['firstName', 'lastName', 'spiritualName', 'notes']}
        removeSelectColumn
        queryIdentifier="RegFollowUpGetInHouse"
        isLoading={isLoading}
      />
    </DataGridContainer>
  )
}
