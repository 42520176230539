import { Instance, applySnapshot } from 'mobx-state-tree'
import { MessageEdgeModelType, PageInfoModelType } from '.'
import { MessageConnectionModelBase } from './MessageConnectionModel.base'

/* The TypeScript type of an instance of MessageConnectionModel */
export interface MessageConnectionModelType
  extends Instance<typeof MessageConnectionModel.Type> {}

/* A graphql query fragment builders for MessageConnectionModel */
export {
  selectFromMessageConnection,
  messageConnectionModelPrimitives,
  MessageConnectionModelSelector
} from './MessageConnectionModel.base'

/**
 * MessageConnectionModel
 */
export const MessageConnectionModel = MessageConnectionModelBase.views(
  self => ({
    get $edges() {
      return self.edges as MessageEdgeModelType[]
    },
    get $pageInfo() {
      return self.pageInfo as PageInfoModelType
    }
  })
).actions(self => ({
  updatePageInfo(pageInfo: PageInfoModelType) {
    applySnapshot(self.$pageInfo, pageInfo)
  }
}))
