import styled from 'styled-components'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Breadcrumbs,
  ConnectedPages,
  Heading2,
  Heading3,
  Paragraph,
  InfoContainer,
  InfoLink,
  Section,
  Hr,
  InfoPageLayout,
  LayoutSeparator,
  Link,
  AsanaImage
} from '../../../../components/InfoTypography'

export const PositionCard = styled.div`
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  margin-top: 3rem;
  width: fit-content;
  padding: 1rem 2rem;
`

export function InfoSunSalutations() {
  return (
    <InfoContainer style={{ wordBreak: 'break-word' }}>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <InfoLink target="">Information</InfoLink>
        <InfoLink target="practice-guide">Practice Guide</InfoLink>
        <InfoLink target="practice-guide/asanas">Asanas</InfoLink>
        <Link $disabled>Sun Salutations</Link>
      </Breadcrumbs>
      <Heading2>
        The Sun Salutation (
        <span className="text-sanskrit">sūrya namaskāra</span>)
      </Heading2>
      <Hr />
      <InfoPageLayout>
        <Section>
          <Paragraph>
            This exercise is called the Sun Salutation because it is best
            practised early in the morning facing the Sun. It combines asanas
            and pranayama.
          </Paragraph>
          <Heading3>Starting Position</Heading3>
          Stand erect with feet together and hands by the sides. Take a deep
          breath.
          <PositionCard>
            <AsanaImage
              src="/images/asanas/surya-namaskara-0.png"
              alt="Starting Position"
            />
          </PositionCard>
          <Heading3>Position 1</Heading3>
          Exhale; bring the hands together at the chest in the “prayer
          position,” centering the body.
          <PositionCard>
            <AsanaImage
              src="/images/asanas/surya-namaskara-1.png"
              alt="Position 1"
            />
          </PositionCard>
          <Heading3>Position 2</Heading3>
          Inhale, stretch the arms over the head. Arch back, dropping the head
          back.
          <PositionCard>
            <AsanaImage
              src="/images/asanas/surya-namaskara-2.png"
              alt="Position 2"
            />
          </PositionCard>
          <Heading3>Position 3</Heading3>
          Exhale and bend forward. Put the hands on the floor next to the feet.
          Make sure that the fingers are in line with the toes. Bring the head
          in to the knees.
          <PositionCard>
            <AsanaImage
              src="/images/asanas/surya-namaskara-3.png"
              alt="Position 3"
            />
          </PositionCard>
          <Heading3>Position 4</Heading3>
          Inhale; stretch the right foot back as far as possible. Put the right
          knee one the floor. Stretch the head upwards.
          <PositionCard>
            <AsanaImage
              src="/images/asanas/surya-namaskara-4.png"
              alt="Position 4"
            />
          </PositionCard>
          <Heading3>Position 5</Heading3>
          Retain the breath; bring both legs back so that the entire body is in
          a straight line from head to heels (“Push-up” position).
          <PositionCard>
            <AsanaImage
              src="/images/asanas/surya-namaskara-5.png"
              alt="Position 5"
            />
          </PositionCard>
          <Heading3>Position 6</Heading3>
          Exhale, drop the knees to the floor. Lower the chest straight down so
          that it is on the ground between the hands. Bring the forehead (or
          chin) to the floor.
          <PositionCard>
            <AsanaImage
              src="/images/asanas/surya-namaskara-6.png"
              alt="Position 6"
            />
          </PositionCard>
          <Heading3>Position 7</Heading3>
          Inhale; slide the body forward. Arch up and back. Legs and hips remain
          on the ground (Cobra position).
          <PositionCard>
            <AsanaImage
              src="/images/asanas/surya-namaskara-7.png"
              alt="Position 7"
            />
          </PositionCard>
          <Heading3>Position 8</Heading3>
          Exhale; raise the hips. Drop the head between the arms; stretch the
          heels towards the floor (Inverted-V position).
          <PositionCard>
            <AsanaImage
              src="/images/asanas/surya-namaskara-8.png"
              alt="Position 8"
            />
          </PositionCard>
          <Heading3>Position 9</Heading3>
          Inhale, bring the right foot forward between the hands (fingers and
          toes in line). Drop the left knee to the floor and stretch the head up
          (same as Position 4).
          <PositionCard>
            <AsanaImage
              src="/images/asanas/surya-namaskara-9.png"
              alt="Position 9"
            />
          </PositionCard>
          <Heading3>Position 10</Heading3>
          Exhale; bring the left leg forward next to the right leg. Keep the
          hips up as high as possible and bring the forehead in towards the
          knees (same as Position 3).
          <PositionCard>
            <AsanaImage
              src="/images/asanas/surya-namaskara-10.png"
              alt="Position 10"
            />
          </PositionCard>
          <Heading3>Position 11</Heading3>
          Inhale; stretch up. Bring the arms straight up over the head and arch
          back (same as Position 2).
          <PositionCard>
            <AsanaImage
              src="/images/asanas/surya-namaskara-11.png"
              alt="Position 11"
            />
          </PositionCard>
          <Heading3>Position 12</Heading3>
          Exhale; drop the arms down next to the sides and relax.
          <PositionCard>
            <AsanaImage
              src="/images/asanas/surya-namaskara-12.png"
              alt="Position 12"
            />
          </PositionCard>
        </Section>
        <ConnectedPages>
          <LayoutSeparator />
          <Section>
            <Paragraph>
              <strong>Connected Articles:</strong>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas">Asanas</InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/headstand">
                1. Headstand (<span className="text-sanskrit">śirṣāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/shoulderstand">
                2. Shoulderstand (
                <span className="text-sanskrit">sarvāṅgāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/plough">
                3. Plough (<span className="text-sanskrit">halāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/fish">
                4. Fish (<span className="text-sanskrit">matsyāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/forward-bend">
                5. Forward Bend (
                <span className="text-sanskrit">pascimottanāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/cobra">
                6. Cobra (<span className="text-sanskrit">bhujaṅgāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/locust">
                7. Locust (<span className="text-sanskrit">śalabhāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/bow">
                8. Bow (<span className="text-sanskrit">dhanurāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/half">
                9. Half Spinal Twist (
                <span className="text-sanskrit">ardha matsyendrāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/crow">
                10. Crow (<span className="text-sanskrit">kakāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/hands">
                11. Hands to Feet (
                <span className="text-sanskrit">pāda hastāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/triangle">
                12. Triangle (<span className="text-sanskrit">trikonāsana</span>
                )
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/savasana">
                Final Relaxation
              </InfoLink>
              (<span className="text-sanskrit">śavāsana</span>)
            </Paragraph>
          </Section>
        </ConnectedPages>
      </InfoPageLayout>
    </InfoContainer>
  )
}
