// @ts-nocheck
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { UserResponseModel, UserResponseModelType } from './UserResponseModel'
import { UserResponseModelSelector } from './UserResponseModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  user: UserResponseModelType
}

/**
 * LoginResponseBase
 * auto generated base class for the model LoginResponseModel.
 */
export const LoginResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named('LoginResponse')
    .props({
      __typename: types.optional(
        types.literal('LoginResponse'),
        'LoginResponse'
      ),
      accessToken: types.union(types.undefined, types.string),
      user: types.union(
        types.undefined,
        MSTGQLRef(types.late((): any => UserResponseModel))
      )
    })
    .views(self => ({
      get store() {
        return self.__getStore<RootStoreType>()
      }
    }))
)

export class LoginResponseModelSelector extends QueryBuilder {
  get accessToken() {
    return this.__attr(`accessToken`)
  }
  user(
    builder:
      | string
      | UserResponseModelSelector
      | ((selector: UserResponseModelSelector) => UserResponseModelSelector)
      | undefined
  ) {
    return this.__child(`user`, UserResponseModelSelector, builder)
  }
}
export function selectFromLoginResponse() {
  return new LoginResponseModelSelector()
}

export const loginResponseModelPrimitives =
  selectFromLoginResponse().accessToken
