import React from 'react'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Breadcrumbs,
  InfoLink,
  Link,
  MediaCard,
  StyledCardMedia,
  StyledPageContentWrapper
} from '../../../../components/InfoTypography'
import { StoreContext } from '@sayr/client-models'

export function InfoBreathingTechniques() {
  const store = React.useContext(StoreContext)
  return (
    <StyledPageContentWrapper>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <InfoLink target="">Information</InfoLink>
        <InfoLink target="practice-guide">Practice Guide</InfoLink>
        <Link $disabled>Breathing Techniques</Link>
      </Breadcrumbs>
      <MediaCard
        topic="Kapalabhati - Cleansing Breathing Exercise"
        description=""
        action={() => {
          store.view.openInfoPage(
            'practice-guide/breathing-techniques/kapalabhati'
          )
        }}
      >
        <StyledCardMedia
          image="https://online.sivanandabahamas.org/wp-content/uploads/2020/06/CKarmaYogi-1024x579.jpg"
          title="Krishna walking on the beach with a yoga mat"
        />
      </MediaCard>
      <MediaCard
        topic="Anuloma Viloma - Alternate Nostril Breathing"
        description=""
        action={() => {
          store.view.openInfoPage(
            'practice-guide/breathing-techniques/anuloma-viloma'
          )
        }}
      >
        <StyledCardMedia
          image="https://online.sivanandabahamas.org/wp-content/uploads/2020/06/CKarmaYogi-1024x579.jpg"
          title="Krishna walking on the beach with a yoga mat"
        />
      </MediaCard>
    </StyledPageContentWrapper>
  )
}
