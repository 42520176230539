import ReactDOM from 'react-dom'
import { createHttpClient } from 'mst-gql'
import { SubscriptionClient } from 'subscriptions-transport-ws'

import { RootStore, RootStoreType } from '@sayr/client-models'
import reportWebVitals from './reportWebVitals'
import { register } from './serviceWorkerRegistration'
import onStart from './loaders/onStart'
import MainFrame from './MainFrame'
import { makeInstallable } from './loaders/makeInstallable'

type Actions = { [action: string]: () => void }

console.log('server', process.env.REACT_APP_SERVER_BASE_URL)
register()

makeInstallable()
const gqlHttpClient = createHttpClient(
  `${process.env.REACT_APP_SERVER_BASE_URL}/graphql`,
  {
    credentials: 'include',
    mode: 'cors'
  }
)

const accessTokenForWS = { token: '' }

const gqlWsClient = new SubscriptionClient(
  `${process.env.REACT_APP_SERVER_BASE_URL!.replace(/^http/, 'ws')}/graphql`,
  {
    reconnect: true,
    connectionParams: accessTokenForWS, // will be set when token is refreshed
    lazy: true // wait for first subscribe before initializing with access token
  }
)

gqlWsClient.onDisconnected(() =>
  console.log(new Date().toISOString(), 'I am disconnected')
)
gqlWsClient.onConnected(() =>
  console.log(new Date().toISOString(), 'I am connected')
)
gqlWsClient.onReconnected(() => {
  console.log(new Date().toISOString(), 'I am reconnected')
  rootStore.clearAndReload()
})

const rootElement = document.getElementById('root')

let rootStore = RootStore.create(undefined, { gqlHttpClient, gqlWsClient })

;(window as unknown as Window & { store: RootStoreType }).store = rootStore

const actions: Actions = {}

;(window as unknown as Window & { actions: Actions }).actions = actions

let loaded = false

function render() {
  ReactDOM.render(
    <MainFrame loaded={loaded} store={rootStore} actions={actions} />,
    rootElement
  )
}

function renderWithHotModuleReloading() {
  try {
    render()

    if (module.hot) {
      module.hot.accept(['./MainFrame.tsx'], () => {
        // Componenent definition changed, re-render app
        render()
      })
    }
  } catch (e) {
    console.error(e)
    localStorage.clear()
  }
}
function protectedOnStart() {
  onStart(rootStore, gqlHttpClient, actions, accessTokenForWS)
    .then(() => {
      loaded = true
      renderWithHotModuleReloading()
    })
    .catch(e => {
      console.error('here is a catched error', e)
      const key = 'failedToStart'
      const tryAgainFunctionKey = key + 'TryAgain'

      // try again in 4 seconds
      const tryAgain = setTimeout(() => protectedOnStart(), 4000)

      // add the warning snackbar
      rootStore.warnings.add({
        key,
        message: `Could not start the application because ${
          (e as Error).message === 'Failed to fetch'
            ? 'the server is unreachable. Will try again in a moment.'
            : `of an error ${(e as Error).message}`
        }`,
        autoHideDuration: null,
        actions: [{ title: 'retry', functionKey: tryAgainFunctionKey }]
      })

      // store the action functions
      actions[tryAgainFunctionKey] = () => {
        clearTimeout(tryAgain)
        rootStore.warnings.removeOne(key)
        protectedOnStart()
      }
    })
}

protectedOnStart() // run initializations without waiting
renderWithHotModuleReloading()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
