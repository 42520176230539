import React from 'react'
import styled, { keyframes, css } from 'styled-components'
import { lighten } from 'polished'
import dayjs, { Dayjs } from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { Column, CellProps } from 'react-table'
import { QueryKey } from 'react-query'
import Avatar from '@mui/material/Avatar'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import InputLabel from '@mui/material/InputLabel'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Popover from '@mui/material/Popover'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import Switch from '@mui/material/Switch'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import AddIcon from '@mui/icons-material/Add'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined'
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined'
import MoneyOffIcon from '@mui/icons-material/MoneyOff'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import {
  CovidTestType,
  GetAllSeasonsQuery,
  ProgramTypes,
  useGetAllSeasonsQuery,
  useGetRegistrationTestsQuery,
  SeasonTypes
} from '../../../graphql/generated/typesAndHooks'
import { useTestMutations } from './testMutationHooks'
import {
  GetRegistrationTestsQueryWithMutating,
  TestCalendarCell,
  TestCalendarRow
} from './covidTypes'
import { matchSeasonByDates } from '../../../services/seasonService'
import { DataGrid } from '../../../components/common/dataGrid/DataGrid'
import { DataGridContainer } from '../../../components/common/dataGrid/DataGridStyles'
import { SmallSelect } from '../../../components/common/Selects'
import {
  PrimaryButton,
  SecondaryButton
} from '../../../components/common/Buttons'
import {
  MultipleSelectProgramTypes,
  MultipleSelectCovidTestType
} from '../../../components/common/MultipleSelectWithChips'

dayjs.extend(isSameOrBefore)

type CurrentEditing = {
  test: TestCalendarCell
  name: string
  regId: number
  url: string
  arrivalDate: string
  departureDate: string
} | null

const mapProgramTypesToUI = {
  ATTC: { bgColor: lighten(0.3, '#87b628'), label: 'ATTC' },
  BABIES: { bgColor: lighten(0.3, '#a97194'), label: 'Babies' },
  CHILDREN: { bgColor: lighten(0.3, '#5f49c5'), label: 'Children' },
  COMMUTERS: { bgColor: lighten(0.3, '#888'), label: 'Commuters' },
  KARMA_YOGA: { bgColor: lighten(0.3, '#f9a43a'), label: 'Karma Yogi' },
  SPEAKER: { bgColor: lighten(0.3, '#cf5c5e'), label: 'Speaker' },
  TTC: { bgColor: lighten(0.3, '#45aead'), label: 'TTC' },
  TTC_COMPLETION: { bgColor: lighten(0.3, '#944'), label: 'TTC Completion' },
  VACATION: { bgColor: lighten(0.3, '#b4ae3d'), label: 'Vacation' },
  VISITING_STAFF: { bgColor: lighten(0.3, '#207294'), label: 'Visiting Staff' }
}

const mapTypeToUI = {
  COMPANY_ANTIGEN: { label: 'Company Antigen Test', avatar: 'A' },
  COMPANY_FAST_PCR: { label: 'Company FastPCR Test', avatar: 'F' },
  COMPANY_PCR: { label: 'Company PCR Test', avatar: 'P' },
  COMPANY_SELF_TEST_KIT: { label: 'Company Self Test Kit', avatar: 'S' },
  IN_HOUSE: { label: 'In House Test', avatar: 'I' }
}

const StyledDataGridContainer = styled(DataGridContainer)`
  /* align tests in a column */
  [role='cell'] {
    align-items: stretch;
    flex-direction: column;
    justify-content: start;
    padding: 0;
  }
`

const TestStatusComponent = styled.div<{ test: TestCalendarCell }>`
  align-items: center;
  background-color: ${({ test }) =>
    test.stayCapacity !== null &&
    mapProgramTypesToUI[test.stayCapacity].bgColor};
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  height: 100%;
  justify-content: center;
  position: relative;

  & > *:not(:first-child) {
    margin-left: -0.8rem;
  }

  & svg.MuiSvgIcon-root {
    font-size: 1.1rem;
  }

  & .MuiAvatar-root {
    font-size: 0.9rem;
  }
`

const InactiveCover = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: grey;
  opacity: 0.7;
`

const rotateAnimation = keyframes`
  to {
    transform: rotate(360deg);
  }
`

const TestTypeAvatar = styled(Avatar)<{ $rotate?: boolean }>`
  && {
    width: 1.1rem;
    height: 1.1rem;
    animation: ${({ $rotate }) =>
      $rotate &&
      css`
        ${rotateAnimation} 1.5s linear infinite
      `};
  }
`

const RotatingIconButton = styled(IconButton)<{ $rotate?: boolean }>`
  animation: ${({ $rotate }) =>
    $rotate &&
    css`
      ${rotateAnimation} 1.5s linear infinite
    `};
`

const DialogBodyComponent = styled.div`
  align-items: start;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  padding: 1rem;
`

const DialogBodyRow = styled.div`
  align-items: center;
  display: flex;
  gap: 0.2rem;
`

const CellTestsComponent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const NoTestComponent = styled.div`
  height: 100%;
  position: relative;
  width: 100%;

  &:hover div {
    opacity: 1;
  }
`

const ShowAddButtonOnHover = styled.div`
  align-items: center;
  background-color: orange;
  display: flex;
  height: 100%;
  justify-content: center;
  opacity: 0;
  position: absolute;
  transition: opacity 0.1s;
  width: 100%;
`

const NameComponent = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  overflow: auto;
  padding: 0.1rem 0.2rem;
`

const DialogHeading = styled.div<{
  $programType: ProgramTypes | null
}>`
  background-color: ${({ $programType }) =>
    $programType && mapProgramTypesToUI[$programType].bgColor};
  padding: 1rem 1.5rem;
`

const NarrowTextField = styled(TextField)`
  & input {
    width: 7rem;
  }
`

function NoTest({
  test,
  queryKey,
  name
}: {
  test: TestCalendarCell
  queryKey: QueryKey
  name: string
  departureDate: string
}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const handleClose = () => setAnchorEl(null)
  const popperProps = { anchorEl, handleClose, test, queryKey }

  return (
    <Tooltip
      title={`${name} ${
        mapProgramTypesToUI[test.stayCapacity as ProgramTypes]?.label || ''
      }`}
    >
      <NoTestComponent>
        {test.stayCapacity !== null && ( // only give option to add test when within a stay.
          <ShowAddButtonOnHover>
            <RotatingIconButton
              onClick={event => setAnchorEl(event.currentTarget)}
            >
              <AddIcon />
            </RotatingIconButton>
          </ShowAddButtonOnHover>
        )}
        <AddNewTestPopper {...popperProps} />
      </NoTestComponent>
    </Tooltip>
  )
}

// A cell in the table, can contain multiple tests
function CellTests(props: CellProps<TestCalendarRow, TestCalendarCell[]>) {
  if (!props.value) return 'No Value!!!!'
  return (
    <CellTestsComponent className="holding-multiple-test-statuses">
      {props.value.map(c => (
        <TestStatus
          key={c.id}
          queryKey={props.queryKey}
          test={c}
          regId={c.regId}
          name={props.row.original.name}
          url={props.row.original.rgLink}
          setCurrentEditing={props.setCurrentEditing}
          setDialogOpen={props.setDialogOpen}
          arrivalDate={props.row.original.arrivalDate}
          departureDate={props.row.original.departureDate}
          seasons={props.seasons}
        />
      ))}
    </CellTestsComponent>
  )
}

function AddNewTestPopper({
  anchorEl,
  handleClose,
  test,
  queryKey
}: {
  anchorEl: HTMLButtonElement | null
  handleClose: () => void
  test: TestCalendarCell
  queryKey: QueryKey
}) {
  const { addTestMutation } = useTestMutations(test.regId, test, queryKey)

  return (
    <Popover
      id="add-test-popper"
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
    >
      <DialogTitle>Choose test type:</DialogTitle>
      <List>
        {Object.entries(mapTypeToUI).map(([type, presentation]) => (
          <ListItem
            key={type}
            button
            onClick={() => {
              addTestMutation.mutate({
                type,
                regId: test.regId,
                date: test.date
              })
              handleClose()
            }}
          >
            <ListItemAvatar>
              <Avatar>{presentation.avatar}</Avatar>
            </ListItemAvatar>
            <ListItemText primary={presentation.label} />
          </ListItem>
        ))}
      </List>
    </Popover>
  )
}

function TestStatus({
  queryKey,
  test,
  regId,
  setCurrentEditing,
  setDialogOpen,
  name,
  url,
  arrivalDate,
  departureDate,
  seasons
}: {
  queryKey: QueryKey
  test: TestCalendarCell
  regId: number
  name: string
  url: string
  arrivalDate: string
  departureDate: string
  seasons: GetAllSeasonsQuery['getAllSeasons']
  setCurrentEditing: (currentEditing: CurrentEditing) => void
  setDialogOpen: (openState: boolean) => void
}) {
  const { updateTestMutation, pushTestMutation, toggleChargeMutation } =
    useTestMutations(regId, test, queryKey)

  if (!test) return <div>no value</div>

  return (
    <TestStatusComponent test={test}>
      {test.type === null ? (
        <NoTest
          name={name}
          test={test}
          queryKey={queryKey}
          departureDate={departureDate}
        />
      ) : (
        <>
          <RotatingIconButton
            onClick={() =>
              pushTestMutation.mutate({ id: test.id, direction: 'backward' })
            }
          >
            <ArrowBackIosOutlinedIcon />
          </RotatingIconButton>
          <Tooltip title={mapTypeToUI[test.type].label}>
            <span>
              <RotatingIconButton disabled $rotate={test.mutating?.type}>
                <TestTypeAvatar $rotate={test.mutating?.type}>
                  {mapTypeToUI[test.type].avatar}
                </TestTypeAvatar>
              </RotatingIconButton>
            </span>
          </Tooltip>
          {test.outcome === null ? (
            <>
              <Tooltip title="Test Not Perfomed Yet">
                <RotatingIconButton
                  onClick={() =>
                    updateTestMutation.mutate({
                      id: test.id,
                      outcome: 'false'
                    })
                  }
                >
                  {test.mutating?.outcome ? (
                    <CircularProgress size="1rem" />
                  ) : (
                    <CircleOutlinedIcon />
                  )}
                </RotatingIconButton>
              </Tooltip>
            </>
          ) : test.outcome ? (
            <Tooltip title="Positive Result">
              <RotatingIconButton
                $rotate={test.mutating?.outcome}
                onClick={() =>
                  updateTestMutation.mutate({ id: test.id, outcome: 'null' })
                }
              >
                <AddCircleOutlineOutlinedIcon style={{ color: 'red' }} />
              </RotatingIconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Negative Result">
              <RotatingIconButton
                $rotate={test.mutating?.outcome}
                onClick={() =>
                  updateTestMutation.mutate({ id: test.id, outcome: 'true' })
                }
              >
                <RemoveCircleOutlineOutlinedIcon style={{ color: 'green' }} />
              </RotatingIconButton>
            </Tooltip>
          )}
          {test.confirmedByGuest ? (
            <Tooltip title="Confirmed by Guest">
              <RotatingIconButton
                $rotate={test.mutating?.confirmed}
                onClick={() =>
                  updateTestMutation.mutate({
                    id: test.id,
                    confirmedByGuest: false
                  })
                }
              >
                <CheckCircleIcon style={{ color: 'green' }} />
              </RotatingIconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Not confirmed by guest yet">
              <RotatingIconButton
                $rotate={test.mutating?.confirmed}
                onClick={() =>
                  updateTestMutation.mutate({
                    id: test.id,
                    confirmedByGuest: true
                  })
                }
              >
                <WatchLaterIcon
                  style={{ color: test.outcome !== null || dayjs().startOf('day').isAfter(test.date)? '' : 'orange' }}
                />
              </RotatingIconButton>
            </Tooltip>
          )}
          <Tooltip
            title={test.freeOfCharge ? 'Free of Charge' : 'Subject to Cost'}
          >
            <span>
              <RotatingIconButton
                disabled={isToggleFreeDisabled(seasons, arrivalDate, test)}
                $rotate={test.mutating?.free}
                onClick={() =>
                  updateTestMutation.mutate({
                    id: test.id,
                    freeOfCharge: !test.freeOfCharge
                  })
                }
              >
                {test.freeOfCharge ? <MoneyOffIcon /> : <AttachMoneyIcon />}
              </RotatingIconButton>
            </span>
          </Tooltip>
          {!test.freeOfCharge && test.chargedAmount !== 0 ? (
            <Tooltip
              title={
                <Link
                  target="_blank"
                  underline="hover"
                  href={`${process.env.REACT_APP_RG_SITE}/wp-admin/admin.php?page=rs-transactions&action=edit&transaction=${test.chargedInTransaction}`}
                >
                  Charged
                </Link>
              }
            >
              <RotatingIconButton
                $rotate={test.mutating?.charged}
                onClick={() => toggleChargeMutation.mutate({ id: test.id })}
              >
                <PaidOutlinedIcon style={{ color: 'green' }} />
              </RotatingIconButton>
            </Tooltip>
          ) : !test.freeOfCharge && test.chargedAmount === 0 ? (
            <Tooltip title="Not yet charged">
              <RotatingIconButton
                $rotate={test.mutating?.charged}
                onClick={() => toggleChargeMutation.mutate({ id: test.id })}
              >
                <PaidOutlinedIcon
                  style={{ color: test.outcome === null ? '' : 'orange' }}
                />
              </RotatingIconButton>
            </Tooltip>
          ) : test.freeOfCharge && test.chargedAmount !== 0 ? (
            <Tooltip
              title={
                <Link
                  target="_blank"
                  underline="hover"
                  href={`${process.env.REACT_APP_RG_SITE}/wp-admin/admin.php?page=rs-transactions&action=edit&transaction=${test.chargedInTransaction}`}
                >
                  Charged unnecessarily
                </Link>
              }
            >
              <RotatingIconButton
                $rotate={test.mutating?.charged}
                onClick={() => toggleChargeMutation.mutate({ id: test.id })}
              >
                <PaidOutlinedIcon style={{ color: 'red' }} />
              </RotatingIconButton>
            </Tooltip>
          ) : (
            <Tooltip title="No payment required">
              <RotatingIconButton
                $rotate={test.mutating?.charged}
                onClick={() => toggleChargeMutation.mutate({ id: test.id })}
              >
                <PaidOutlinedIcon style={{ color: 'green' }} />
              </RotatingIconButton>
            </Tooltip>
          )}
          <IconButton
            onClick={() =>
              pushTestMutation.mutate({ id: test.id, direction: 'forward' })
            }
          >
            <ArrowForwardIosOutlinedIcon />
          </IconButton>
          <Tooltip
            title={
              test.notes ? (
                <>
                  <span>{test.notes}</span>
                  <br />
                  <span>Click for more options</span>
                </>
              ) : (
                'More options'
              )
            }
          >
            <RotatingIconButton
              $rotate={test.mutating?.notes}
              onClick={() => {
                setCurrentEditing({
                  test,
                  name,
                  url,
                  regId,
                  arrivalDate,
                  departureDate
                })
                setDialogOpen(true)
              }}
            >
              <MoreHorizIcon style={{ color: test.notes ? 'red' : '' }} />
            </RotatingIconButton>
          </Tooltip>
        </>
      )}
      {test.mutating?.general && (
        <>
          <InactiveCover />
          <CircularProgress
            style={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)'
            }}
            size="2rem"
          />
        </>
      )}
    </TestStatusComponent>
  )
}

const testCalendarColumns = ({
  minimumDate,
  maximumDate,
  screenSize
}: {
  minimumDate: string
  maximumDate: string
  screenSize: 'tiny' | 'small' | 'regular'
}): Column<TestCalendarRow>[] =>
  (
    [
      {
        Header: 'Name',
        accessor: 'name',
        sticky: 'left',
        width:
          screenSize === 'regular' ? 250 : screenSize === 'small' ? 180 : 120,
        Cell: (cell: CellProps<TestCalendarRow>) => (
          <NameComponent>
            <Tooltip
              title={`${dayjs(cell.row.original.arrivalDate).format(
                'ddd, MMM D, YYYY'
              )} - ${dayjs(cell.row.original.departureDate).format(
                'ddd, MMM D, YYYY'
              )}`}
            >
              <Link
                className="cell-name"
                color="inherit"
                href={cell.row.original.rgLink}
                target="_blank"
                underline="hover"
              >
                {cell.row.original.name}
              </Link>
            </Tooltip>
          </NameComponent>
        )
      }
    ] as any[]
  ).concat(
    ...new Array(dayjs(maximumDate).diff(minimumDate, 'days') + 1)
      .fill(null)
      .map((_, i) => {
        const date = dayjs(minimumDate).add(i, 'days')
        return {
          Header: date.format('MM-DD ddd'),
          accessor: 'date-' + date.format('YYYY-MM-DD'),
          Cell: CellTests,
          sticky: '',
          width: 180
        }
      })
  )

export function CovidTestCalendarView() {
  const [from, setFrom] = React.useState<Dayjs>(dayjs().subtract(1, 'day'))
  const [to, setTo] = React.useState<Dayjs>(dayjs().add(2, 'day'))
  const [programFilter, setProgramFilter] = React.useState<ProgramTypes[]>([])
  const [typesFilter, setTypesFilter] = React.useState<CovidTestType[]>([])
  const [onlyDoneOnFilter] = React.useState('')
  const [onlyNotDoneOnFilter] = React.useState('')
  const smallScreen = useMediaQuery('(max-width: 31.25em)')
  const verySmallScreen = useMediaQuery('(max-width: 25em)')

  const screenSize = verySmallScreen
    ? 'tiny'
    : smallScreen
    ? 'small'
    : 'regular'

  const [{ minimumDate, maximumDate }, setDateRange] = React.useState<{
    minimumDate: string
    maximumDate: string
  }>({
    maximumDate: to.format('YYYY-MM-DD'),
    minimumDate: from.format('YYYY-MM-DD')
  })

  React.useEffect(() => {
    if (dayjs(from).isSameOrBefore(to))
      setDateRange({
        maximumDate: to.format('YYYY-MM-DD'),
        minimumDate: from.format('YYYY-MM-DD')
      })
  }, [from, to])

  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [currentEditing, setCurrentEditing] =
    React.useState<CurrentEditing>(null)

  const columns = React.useMemo(
    () => testCalendarColumns({ minimumDate, maximumDate, screenSize }),
    [maximumDate, minimumDate, screenSize]
  )

  const { data: dataFromQuery, isLoading } = useGetRegistrationTestsQuery(
    {
      minimumDate,
      maximumDate,
      programFilter,
      typesFilter,
      onlyDoneOnFilter: '',
      onlyNotDoneOnFilter: ''
    },
    { refetchInterval: 60000 }
  )

  const { data: seasonsData } = useGetAllSeasonsQuery()

  const data =
    (
      dataFromQuery as GetRegistrationTestsQueryWithMutating
    )?.getRegistrationTests.map<TestCalendarRow>(r => {
      const individualTests = r.tests.map<TestCalendarCell>(t => {
        return {
          id: t.id,
          chargedAmount: t.chargedAmount,
          confirmedByGuest: t.confirmedByGuest,
          freeOfCharge: t.freeOfCharge,
          stayCapacity: t.stayCapacity || null,
          outcome: t.outcome === undefined ? null : t.outcome,
          regId: r.regId,
          type: t.type || null,
          chargedInTransaction: t.chargedInTransaction || 0,
          date: t.date,
          mutating: t.mutating,
          notes: t.notes
        }
      })

      const testsByDay = individualTests.reduce<
        { date: string; tests: TestCalendarCell[] }[]
      >((allTests, test) => {
        if (allTests.find(t => t.date === test.date))
          return allTests.map(td => {
            if (td.date === test.date)
              return { date: td.date, tests: td.tests.concat(test) }
            return td
          })
        else return allTests.concat({ date: test.date, tests: [test] })
      }, [])

      const cellsInObj = testsByDay.reduce<
        Omit<TestCalendarRow, 'name' | 'rgLink' | 'regId'>
      >((cells, dayTests) => {
        return {
          ...cells,
          [`date-${dayTests.date}`]: dayTests.tests
        }
      }, {})

      const testCalendarRow: TestCalendarRow = Object.assign<
        TestCalendarRow,
        TestCalendarRow
      >(
        cellsInObj as TestCalendarRow,
        {
          name: r.name,
          rgLink: r.rgLink,
          regId: r.regId,
          arrivalDate: r.arrivalDate,
          departureDate: r.departureDate
        } as TestCalendarRow
      )

      return testCalendarRow
    }) || []

  return (
    <StyledDataGridContainer>
      <DataGrid
        columns={columns}
        data={data}
        renderButtons={() => (
          <>
            <DatePicker
              inputFormat="MM/DD/YYYY"
              label="From"
              value={from}
              onChange={date => {
                if (date) setFrom(date)
              }}
              renderInput={params => (
                <Tooltip title="Starting date range">
                  <NarrowTextField
                    autoComplete="off"
                    {...params}
                    size="small"
                  />
                </Tooltip>
              )}
            />
            <DatePicker
              minDate={from}
              inputFormat="MM/DD/YYYY"
              label="To"
              value={to}
              onChange={date => {
                if (date) setTo(date)
              }}
              renderInput={params => (
                <Tooltip title="Ending date range">
                  <NarrowTextField
                    autoComplete="off"
                    {...params}
                    size="small"
                  />
                </Tooltip>
              )}
            />
            <MultipleSelectProgramTypes
              selectedProgramTypes={programFilter}
              setSelectedProgramTypes={setProgramFilter}
              name="select-program-types-filter"
            />
            <MultipleSelectCovidTestType
              selectedCovidTestTypes={typesFilter}
              setSelectedCovidTestTypes={setTypesFilter}
              name="select-covid-test-types-filter"
            />
            <SecondaryButton
              onClick={() => {
                if (dataFromQuery)
                  navigator.clipboard.writeText(
                    dataFromQuery.getRegistrationTests
                      .map(r => `${r.name} <${r.email}>`)
                      .join(', ')
                  )
              }}
            >
              Copy Emails
            </SecondaryButton>
            <Tooltip title="For HTML destinations only">
              <SecondaryButton onClick={() => copyToClipboard(data)}>
                Copy to Clipboard
              </SecondaryButton>
            </Tooltip>
          </>
        )}
        nonSortable
        withGlobalFilter
        removeSelectColumn
        skipPageReset
        filterableColumns={['name']}
        isLoading={isLoading}
        queryKey={useGetRegistrationTestsQuery.getKey({
          maximumDate,
          minimumDate,
          programFilter,
          typesFilter,
          onlyDoneOnFilter,
          onlyNotDoneOnFilter
        })}
        seasons={seasonsData?.getAllSeasons || []}
        setCurrentEditing={setCurrentEditing}
        setDialogOpen={setDialogOpen}
        removeTooltipOnColumns={['name']}
      />

      <br />
      <TestEditingDialog
        key={JSON.stringify(currentEditing?.test)}
        open={dialogOpen}
        imperativeClose={() => setDialogOpen(false)}
        test={currentEditing?.test}
        name={currentEditing?.name}
        regId={currentEditing?.regId}
        url={currentEditing?.url}
        queryKey={useGetRegistrationTestsQuery.getKey({
          maximumDate,
          minimumDate,
          programFilter,
          typesFilter,
          onlyDoneOnFilter,
          onlyNotDoneOnFilter
        })}
        seasons={seasonsData?.getAllSeasons || []}
        arrivalDate={currentEditing?.arrivalDate}
        departureDate={currentEditing?.departureDate}
      />
    </StyledDataGridContainer>
  )
}

function TestEditingDialog(props: {
  open: boolean
  imperativeClose: () => void
  test?: TestCalendarCell
  name?: string
  regId?: number
  url?: string
  queryKey: QueryKey
  seasons: GetAllSeasonsQuery['getAllSeasons']
  arrivalDate?: string
  departureDate?: string
}) {
  const {
    imperativeClose,
    open,
    test,
    name,
    regId,
    url,
    queryKey,
    seasons,
    arrivalDate
  } = props

  const { deleteTestMutation, updateTestMutation } = useTestMutations(
    regId,
    test,
    queryKey
  )

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const handleClose = () => setAnchorEl(null)

  const [selectedType, setSelectedType] = React.useState(test?.type || null)
  const [selectedOutcome, setSelectedOutcome] = React.useState(
    !test ? null : test.outcome
  )
  const [selectedConfirmed, setSelectedConfirmed] = React.useState(
    test?.confirmedByGuest || false
  )
  const [selectedFreeOfCharge, setSelectedFreeOfCharge] = React.useState(
    test?.freeOfCharge || false
  )

  const [selectedNotes, setSelectedNotes] = React.useState(test?.notes || '')

  if (!test) {
    return <></>
  }

  const popperProps = { anchorEl, handleClose, test, queryKey }

  if (!test.type) {
    return (
      <Dialog onClose={() => imperativeClose()} open={open}>
        <DialogTitle>
          <>
            <Link href={url || '#'} target="_blank">
              {name || ''}
            </Link>
            <br />
            {dayjs(test?.date).format('LL')}
            <br />
            <Typography>No test</Typography>
          </>
        </DialogTitle>
      </Dialog>
    )
  }

  return (
    <Dialog onClose={() => imperativeClose()} open={open}>
      <DialogHeading $programType={test.stayCapacity}>
        <>
          <Typography component="h2" variant="h6">
            <Link
              color="inherit"
              href={url || '#'}
              target="_blank"
              underline="hover"
            >
              {name || ''}
            </Link>
          </Typography>
          <Typography component="h3" variant="body1">
            {dayjs(test.date).format('LL')}
          </Typography>
          <Typography component="h4" variant="body2">
            {test.stayCapacity
              ? mapProgramTypesToUI[test.stayCapacity].label
              : ''}
          </Typography>
        </>
      </DialogHeading>
      <DialogBodyComponent>
        <FormControl fullWidth>
          <InputLabel id="change-test-type">Test Type</InputLabel>
          <SmallSelect
            labelId="change-test-type"
            value={selectedType}
            label="Test Type"
            onChange={e => setSelectedType(e.target.value as CovidTestType)}
          >
            {Object.entries(mapTypeToUI).map(([type, presentation]) => (
              <MenuItem value={type} key={type}>
                <div style={{ display: 'flex' }}>
                  <ListItemAvatar>
                    <Avatar>{presentation.avatar}</Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={presentation.label} />
                </div>
              </MenuItem>
            ))}
          </SmallSelect>
        </FormControl>
        <FormControl>
          <InputLabel id="change-test-outcome">Test Outcome</InputLabel>
          <SmallSelect
            labelId="change-test-outcome"
            value={
              selectedOutcome === null ? 'null' : selectedOutcome.toString()
            }
            label="Test Outcome"
            onChange={e =>
              setSelectedOutcome(
                e.target.value === 'null'
                  ? null
                  : e.target.value === 'true'
                  ? true
                  : false
              )
            }
          >
            <MenuItem value="null">
              <DialogBodyRow>
                <RotatingIconButton>
                  <CircleOutlinedIcon />
                </RotatingIconButton>
                <Typography>Test Not Perfomed Yet</Typography>
              </DialogBodyRow>
            </MenuItem>
            <MenuItem value="false">
              <DialogBodyRow>
                <RotatingIconButton>
                  <RemoveCircleOutlineOutlinedIcon style={{ color: 'green' }} />
                </RotatingIconButton>
                <Typography>Negative Result</Typography>
              </DialogBodyRow>
            </MenuItem>
            <MenuItem value="true">
              <DialogBodyRow>
                <RotatingIconButton>
                  <AddCircleOutlineOutlinedIcon style={{ color: 'red' }} />
                </RotatingIconButton>
                <Typography>Positive Result</Typography>
              </DialogBodyRow>
            </MenuItem>
          </SmallSelect>
        </FormControl>
        <DialogBodyRow>
          <IconButton onClick={() => setSelectedConfirmed(v => !v)}>
            {selectedConfirmed ? (
              <CheckCircleIcon style={{ color: 'green' }} />
            ) : (
              <WatchLaterIcon
                style={{ color: test.outcome !== null || dayjs().startOf('day').isAfter(test.date) ? '' : 'orange' }}
              />
            )}
          </IconButton>
          <FormControlLabel
            control={
              <Switch
                checked={selectedConfirmed}
                onChange={e => setSelectedConfirmed(e.target.checked)}
              />
            }
            label="Confirmed by Guest"
          />
        </DialogBodyRow>
        <DialogBodyRow>
          <IconButton
            disabled={isToggleFreeDisabled(seasons, arrivalDate, test)}
            onClick={() => setSelectedFreeOfCharge(v => !v)}
          >
            {selectedFreeOfCharge ? <MoneyOffIcon /> : <AttachMoneyIcon />}
          </IconButton>
          <FormControlLabel
            control={
              <Switch
                checked={selectedFreeOfCharge}
                onChange={e => setSelectedFreeOfCharge(e.target.checked)}
              />
            }
            label={selectedFreeOfCharge ? 'Free of Charge' : 'Subject to Cost'}
          />
        </DialogBodyRow>
        <DialogBodyRow>
          <IconButton disabled>
            {!selectedFreeOfCharge && test.chargedAmount !== 0 ? (
              <PaidOutlinedIcon style={{ color: 'green' }} />
            ) : !selectedFreeOfCharge && test.chargedAmount === 0 ? (
              <PaidOutlinedIcon
                style={{ color: test.outcome === null ? '' : 'orange' }}
              />
            ) : selectedFreeOfCharge && test.chargedAmount !== 0 ? (
              <PaidOutlinedIcon style={{ color: 'red' }} />
            ) : (
              <PaidOutlinedIcon style={{ color: 'green' }} />
            )}
          </IconButton>
          {!selectedFreeOfCharge && test.chargedAmount !== 0 ? (
            <Link
              target="_blank"
              underline="hover"
              href={`${process.env.REACT_APP_RG_SITE}/wp-admin/admin.php?page=rs-transactions&action=edit&transaction=${test.chargedInTransaction}`}
            >
              Charged
            </Link>
          ) : !selectedFreeOfCharge && test.chargedAmount === 0 ? (
            <Typography>Not yet charged</Typography>
          ) : selectedFreeOfCharge && test.chargedAmount !== 0 ? (
            <Link
              target="_blank"
              underline="hover"
              href={`${process.env.REACT_APP_RG_SITE}/wp-admin/admin.php?page=rs-transactions&action=edit&transaction=${test.chargedInTransaction}`}
            >
              Charged unnecessarily
            </Link>
          ) : (
            <Typography>No payment required</Typography>
          )}
        </DialogBodyRow>
        <DialogBodyRow>
          <TextField
            label="Notes"
            variant="outlined"
            value={selectedNotes}
            onChange={e => setSelectedNotes(e.target.value)}
          />
        </DialogBodyRow>
        <DialogBodyRow>
          <IconButton
            onClick={() => {
              deleteTestMutation.mutate({ id: test.id })
              imperativeClose()
            }}
          >
            <DeleteIcon />
          </IconButton>
          <Typography>Delete</Typography>
        </DialogBodyRow>
        <DialogBodyRow>
          <IconButton onClick={event => setAnchorEl(event.currentTarget)}>
            <AddIcon />
          </IconButton>
          <Typography>Add new test</Typography>
        </DialogBodyRow>
        <PrimaryButton
          disabled={
            !(
              selectedType !== test.type ||
              selectedConfirmed !== test.confirmedByGuest ||
              selectedOutcome !== test.outcome ||
              selectedFreeOfCharge !== test.freeOfCharge ||
              selectedNotes !== test.notes
            )
          }
          onClick={() => {
            updateTestMutation.mutate({
              id: test.id,
              type: selectedType !== test.type ? selectedType : undefined,
              confirmedByGuest:
                selectedConfirmed !== test.confirmedByGuest
                  ? selectedConfirmed
                  : undefined,
              outcome:
                selectedOutcome !== test.outcome
                  ? selectedOutcome === null
                    ? 'null'
                    : selectedOutcome.toString()
                  : undefined,
              freeOfCharge:
                selectedFreeOfCharge !== test.freeOfCharge
                  ? selectedFreeOfCharge
                  : undefined,
              notes: selectedNotes !== test.notes ? selectedNotes : undefined
            })
            imperativeClose()
          }}
        >
          Update
        </PrimaryButton>
      </DialogBodyComponent>
      <AddNewTestPopper {...popperProps} />
    </Dialog>
  )
}

function isToggleFreeDisabled(
  seasons: GetAllSeasonsQuery['getAllSeasons'],
  arrivalDate: string | undefined,
  test: TestCalendarCell
): boolean {
  return !seasons
    .filter(
      matchSeasonByDates({
        arrivalDate: arrivalDate || dayjs().format('YYYY-MM-DD'),
        currentDate: test.date
      })
    )
    .filter(season =>
      season.programTypes.includes(test.stayCapacity as ProgramTypes)
    )
    .find(
      season =>
        season.seasonType === SeasonTypes.CovidTestCosts &&
        season.testType === test.type
    )?.cost
}

function copyToClipboard(data: TestCalendarRow[]) {
  const rows = data.map(r => {
    const tdName = document.createElement('td')
    const tdArrivalDate = document.createElement('td')
    const tdDepartureDate = document.createElement('td')
    tdName.innerHTML = `<a href="${r.rgLink}">${r.name}</a>`
    tdArrivalDate.innerHTML = r.arrivalDate
    tdDepartureDate.innerHTML = r.departureDate

    const tr = document.createElement('tr')
    tr.insertAdjacentElement('beforeend', tdName)
    tr.insertAdjacentElement('beforeend', tdArrivalDate)
    tr.insertAdjacentElement('beforeend', tdDepartureDate)

    const cells = Object.entries(r)
      .filter(entry => entry[0].match(/^date-/))
      .map(entry => {
        const values = entry[1]
        const td = document.createElement('td')

        td.innerHTML = Array.isArray(values)
          ? values
              .map(value =>
                value.type === null
                  ? ''
                  : `${value.stayCapacity}<br>${
                      value.type
                    }<br><strong>Result: </strong>${
                      value.outcome === null
                        ? ''
                        : value.outcome
                        ? 'Positive'
                        : 'Negative'
                    }<br>${
                      value.freeOfCharge ? 'Free' : 'Payment Required'
                    }<br>${value.notes}`
              )
              .join('<br>')
          : ''

        return td
      })
    cells.forEach(c => tr.insertAdjacentElement('beforeend', c))

    return tr
  })

  const tableHead = document.createElement('thead')
  const theadRow = document.createElement('tr')
  theadRow.insertAdjacentHTML(
    'beforeend',
    '<th>Name</th><th>Arrival Date</th><th>Departure Date</th>'
  )
  const dates = Object.keys(data?.[0] || {})
    .filter(r => r.match(/^date-/))
    .map(date => {
      const th = document.createElement('th')
      th.innerText = date.replace(/^date-/, '')
      return th
    })
  dates.forEach(date => theadRow.insertAdjacentElement('beforeend', date))
  tableHead.insertAdjacentElement('beforeend', theadRow)
  const tableBody = document.createElement('tbody')
  rows.forEach(r => tableBody.insertAdjacentElement('beforeend', r))
  const type = 'text/html'
  const table = document.createElement('table')
  table.insertAdjacentElement('beforeend', tableHead)
  table.insertAdjacentElement('beforeend', tableBody)
  const blob = new Blob([table.outerHTML], { type })
  const clipboardItems = [new ClipboardItem({ [type]: blob })]

  navigator.clipboard.write(clipboardItems)
}
