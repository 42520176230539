// @ts-nocheck
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { QueryBuilder } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { RootStoreType } from './index'

/**
 * PermissionsResponseBase
 * auto generated base class for the model PermissionsResponseModel.
 */
export const PermissionsResponseModelBase = ModelBase.named(
  'PermissionsResponse'
)
  .props({
    __typename: types.optional(
      types.literal('PermissionsResponse'),
      'PermissionsResponse'
    ),
    inviteToApp: types.union(types.undefined, types.null, types.boolean),
    upgradeOtherUsers: types.union(types.undefined, types.null, types.boolean),
    viewPeopleLists: types.union(types.undefined, types.null, types.boolean),
    manageAnnouncements: types.union(
      types.undefined,
      types.null,
      types.boolean
    ),
    chatAsFrontDesk: types.union(types.undefined, types.null, types.boolean),
    sendEmails: types.union(types.undefined, types.null, types.boolean),
    generalPopulation: types.union(types.undefined, types.null, types.boolean),
    accounting: types.union(types.undefined, types.null, types.boolean),
    adminFollowUpRead: types.union(types.undefined, types.null, types.boolean),
    adminFollowUpWrite: types.union(types.undefined, types.null, types.boolean),
    accessWarnings: types.union(types.undefined, types.null, types.boolean)
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class PermissionsResponseModelSelector extends QueryBuilder {
  get inviteToApp() {
    return this.__attr(`inviteToApp`)
  }
  get upgradeOtherUsers() {
    return this.__attr(`upgradeOtherUsers`)
  }
  get viewPeopleLists() {
    return this.__attr(`viewPeopleLists`)
  }
  get manageAnnouncements() {
    return this.__attr(`manageAnnouncements`)
  }
  get chatAsFrontDesk() {
    return this.__attr(`chatAsFrontDesk`)
  }
  get sendEmails() {
    return this.__attr(`sendEmails`)
  }
  get generalPopulation() {
    return this.__attr(`generalPopulation`)
  }
  get accounting() {
    return this.__attr(`accounting`)
  }
  get adminFollowUpRead() {
    return this.__attr(`adminFollowUpRead`)
  }
  get adminFollowUpWrite() {
    return this.__attr(`adminFollowUpWrite`)
  }
  get accessWarnings() {
    return this.__attr(`accessWarnings`)
  }
}
export function selectFromPermissionsResponse() {
  return new PermissionsResponseModelSelector()
}

export const permissionsResponseModelPrimitives =
  selectFromPermissionsResponse().inviteToApp.upgradeOtherUsers.viewPeopleLists
    .manageAnnouncements.chatAsFrontDesk.sendEmails.generalPopulation.accounting
    .adminFollowUpRead.adminFollowUpWrite.accessWarnings
