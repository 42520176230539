import { ArrivingToday } from './ArrivingToday'
import { ArrivingTomorrow } from './ArrivingTomorrow'
import { DeparturesToday } from './DeparturesToday'
import { DeparturesTomorrow } from './DeparturesTomorrow'
import { CashFlowReport } from './CashFlowReport'
import { FollowUpOnArrivals } from './followUp/FollowUpOnArrivals'
import { FollowUpOnInHouse } from './followUp/FollowUpOnInHouse'
import { PopulationReport } from './PopulationReport'
import { CovidTestCalendarView } from './covidTests/CovidTestCalendarView'

function RegistrationsList({ filter }: { filter?: string }) {
  if (filter === 'arriving-today') return <ArrivingToday />
  if (filter === 'arriving-tomorrow') return <ArrivingTomorrow />
  if (filter === 'departures-today') return <DeparturesToday />
  if (filter === 'departures-tomorrow') return <DeparturesTomorrow />
  else if (filter === 'cash-flow-report') return <CashFlowReport />
  else if (filter === 'population-report') return <PopulationReport />
  else if (filter === 'follow-up-on-arrivals') return <FollowUpOnArrivals />
  else if (filter === 'follow-up-on-in-house') return <FollowUpOnInHouse />
  else if (filter === 'covid-test-calendar-view')
    return <CovidTestCalendarView />
  else return <></>
}

export default RegistrationsList
