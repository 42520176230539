import React from 'react'
import { Column } from 'react-table'
import dayjs from 'dayjs'
import CircularProgress from '@mui/material/CircularProgress'
import { observer } from 'mobx-react-lite'
import Avatar from '@mui/material/Avatar'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Link from '@mui/material/Link'
import RefreshIcon from '@mui/icons-material/Refresh'
import styled from 'styled-components'
import { Compound, CompoundUtils, Person, PersonUtils } from '@sayr/base'
import {
  useQuery,
  StoreContext,
  CompoundResponseModelType
} from '@sayr/client-models'
import { DataGrid } from '../../components/common/dataGrid/DataGrid'
import { DataGridContainer } from '../../components/common/dataGrid/DataGridStyles'

import { PrimaryButton } from '../../components/common/Buttons'

type CashFlowRow = {
  stayDates: string
  rgLink: string
  headshot: string
  name: string
  program: string
  totalAmount: string
  realPaymentsTotal: string
  creditRemaining: string
  creditUsed: string
  voucherUsed: string
  balanceRemaining: string
  lodging: string
  notes: string
}

const SmallAvatar = styled(Avatar)`
  width: 80%;
  height: 80%;
  border-radius: 50%;
`

const cashFlowReportColumns = [
  {
    Header: '',
    accessor: 'headshot',
    Cell: React.memo(({ value }: { value: string }) => {
      return <SmallAvatar src={value} />
    }),
    width: 70,
    disableSortBy: true,
    disableResizing: true
  },
  {
    Header: 'RG Link',
    accessor: 'rgLink',
    width: 140,
    Cell: React.memo(({ value }: any) => (
      <Link href={value} target="_blank">
        Details
      </Link>
    ))
  },
  {
    Header: 'Stay Dates',
    accessor: 'stayDates',
    width: 120
  },
  {
    Header: 'Name',
    accessor: 'name'
  },
  { Header: 'Program', accessor: 'program' },
  { Header: 'Lodging', accessor: 'lodging' },
  {
    Header: 'Total Amount with tax',
    accessor: 'totalAmount',
    sortType: (a, b) => {
      const aAsNumber = a.values.totalAmount?.replaceAll(/[^\d.-]/g, '') || ''
      if (isNaN(aAsNumber)) return a > b ? 1 : -1
      return +aAsNumber >
        +(b.values.totalAmount?.replaceAll(/[^\d.-]/g, '') || '')
        ? 1
        : -1
    }
  },
  {
    Header: 'Actual Payments',
    accessor: 'realPaymentsTotal',
    sortType: (a, b) => {
      const aAsNumber =
        a.values.realPaymentsTotal?.replaceAll(/[^\d.-]/g, '') || ''
      if (isNaN(aAsNumber)) return a > b ? 1 : -1
      return +aAsNumber >
        +(b.values.realPaymentsTotal?.replaceAll(/[^\d.-]/g, '') || '')
        ? 1
        : -1
    }
  },
  {
    Header: 'Personal Credit Remaining',
    accessor: 'creditRemaining',
    sortType: (a, b) => {
      const aAsNumber =
        a.values.creditRemaining?.replaceAll(/[^\d.-]/g, '') || ''
      if (isNaN(aAsNumber)) return a > b ? 1 : -1
      return +aAsNumber >
        +(b.values.creditRemaining?.replaceAll(/[^\d.-]/g, '') || '')
        ? 1
        : -1
    }
  },
  {
    Header: 'Voucher Used',
    accessor: 'voucherUsed',
    sortType: (a, b) => {
      const aAsNumber = a.values.voucherUsed?.replaceAll(/[^\d.-]/g, '') || ''
      if (isNaN(aAsNumber)) return a > b ? 1 : -1
      return +aAsNumber >
        +(b.values.voucherUsed?.replaceAll(/[^\d.-]/g, '') || '')
        ? 1
        : -1
    }
  },
  {
    Header: 'Credit Used',
    accessor: 'creditUsed',
    sortType: (a, b) => {
      const aAsNumber = a.values.creditUsed?.replaceAll(/[^\d.-]/g, '') || ''
      if (isNaN(aAsNumber)) return a > b ? 1 : -1
      return +aAsNumber >
        +(b.values.creditUsed?.replaceAll(/[^\d.-]/g, '') || '')
        ? 1
        : -1
    }
  },
  {
    Header: 'Balance Remaining',
    accessor: 'balanceRemaining',
    sortType: (a, b) => {
      const aAsNumber =
        a.values.balanceRemaining?.replaceAll(/[^\d.-]/g, '') || ''
      if (isNaN(aAsNumber)) return a > b ? 1 : -1
      return +aAsNumber >
        +(b.values.balanceRemaining?.replaceAll(/[^\d.-]/g, '') || '')
        ? 1
        : -1
    }
  },
  { Header: 'Notes', accessor: 'notes' }
] as Column<CashFlowRow>[]

export const CashFlowReport = observer(() => {
  const [from, setFrom] = React.useState<string | null>(null)
  const [to, setTo] = React.useState<string | null>(null)
  console.log('report')
  const columns = React.useMemo(() => cashFlowReportColumns, [])
  const store = React.useContext(StoreContext)
  const voucherTransactionsQuery = useQuery(s =>
    s.queryGetVoucherTransactions()
  )
  const {
    data: queryData,
    loading,
    setQuery
  } = useQuery<{
    getStaysWithinDates: CompoundResponseModelType[]
  }>()

  if (loading)
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '2rem'
        }}
      >
        <CircularProgress />
        <p>{`Loading registrations from ${dayjs(from).format(
          'YYYY-MM-DD'
        )} to ${dayjs(to).format('YYYY-MM-DD')}.`}</p>
      </div>
    )
  if (!voucherTransactionsQuery.data)
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '2rem'
        }}
      >
        <CircularProgress />
        <p>Loading voucher transactions...</p>
      </div>
    )

  const compounds =
    queryData?.getStaysWithinDates.map<Compound & { person_details: Person[] }>(
      c => JSON.parse(c.compoundStringified || '{}')
    ) || []

  const data = compounds
    .filter(
      compound =>
        CompoundUtils.getAllRoomStays(compound, store.programs.lodgingPrograms)
          .length
    )
    .flatMap<CashFlowRow>(compound => {
      const stays = CompoundUtils.getAllRoomStays(
        compound,
        store.programs.lodgingPrograms
      )
      const earliestArrival = stays
        .map(stay => stay.start_date)
        .sort((a, b) => (a > b ? 1 : -1))?.[0]
      const latestDeparture = stays
        .map(stay => stay.end_date)
        .sort((a, b) => (a < b ? 1 : -1))?.[0]
      const highestValueStay = stays
        .map(stay => {
          const stayValue = stay.items
            .filter(
              item =>
                !item.category.match(
                  /^applied-personal-credit|transfer-to-personal-credit$/
                )
            )
            .reduce(
              (aggregate, item) =>
                aggregate -
                item.credit_amount +
                item.charge_amount +
                (item.charge_amount ? item.discount_amount : 0),
              0
            )
          return { stay, stayValue }
        })
        .sort((a, b) => {
          const returnValue =
            a.stay.parent_registration_id === a.stay.id
              ? 1
              : b.stay.parent_registration_id === b.stay.id
              ? -1
              : 0

          return returnValue
        })
        .sort((a, b) => (a.stayValue < b.stayValue ? 1 : -1))[0].stay

      const voucherTransactionIds =
        voucherTransactionsQuery.data?.getVoucherTransactions.map(
          t => t.id || 0
        ) || []

      const compoundItems = CompoundUtils.getAllItems(compound)
      const personVoucherUsedInAnyCompound = compound.person_details.map(
        person => PersonUtils.getVouchersUsed(person, voucherTransactionIds)
      )

      const voucherUsed = personVoucherUsedInAnyCompound
        .flatMap(p => p.transactionsUsingVoucher)
        .filter(t =>
          compoundItems.some(ci => ci.associated_transaction === t.id)
        )
        .reduce(
          (total, singleCreditUse) => total + singleCreditUse.usedAmount,
          0
        )

      return {
        rgLink: highestValueStay?.admin_link || '',

        stayDates: `${earliestArrival} to ${latestDeparture}`,

        headshot:
          compound.person_details
            .find(p => highestValueStay.person_id === p.id)
            ?.questions['headshot-url']?.replace(/dl=0$/, 'raw=1') || '',

        name:
          ((arg: Person | undefined) =>
            [arg?.full_name, arg?.questions['spiritual-name']].join(' '))(
            compound.person_details.find(
              p => highestValueStay.person_id === p.id
            )
          ).trim() ||
          [
            highestValueStay.first_name,
            highestValueStay.last_name,
            highestValueStay.questions.spiritual_name
          ]
            .join(' ')
            .trim(),
        program: highestValueStay.program,

        lodging: highestValueStay.lodging || '',

        totalAmount: (
          CompoundUtils.getRealItemTotal(compound) +
          CompoundUtils.getTaxTotal(compound)
        ).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD'
        }),

        realPaymentsTotal: CompoundUtils.getRealPaymentTotal(
          compound
        ).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),

        creditRemaining: compound.person_details.length
          ? compound.person_details
              .reduce(
                (aggregate, person) =>
                  aggregate + PersonUtils.getPersonalCredit(person),
                0
              )
              .toLocaleString('en-US', { style: 'currency', currency: 'USD' })
          : 'Not matched to person',

        voucherUsed: compound.person_details.length
          ? voucherUsed.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD'
            })
          : 'Not matched to person',

        creditUsed: compound.person_details.length
          ? CompoundUtils.getRealCreditPayments(compound).toLocaleString(
              'en-US',
              { style: 'currency', currency: 'USD' }
            )
          : 'Not matched to person',

        balanceRemaining: CompoundUtils.getTotalBalance(
          compound
        ).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),

        notes: CompoundUtils.getAllRoomStays(
          compound,
          store.programs.lodgingPrograms
        )
          .map(r => [
            r.questions.reg_notes,
            r.questions.comments,
            r.questions.personal_comments
          ])
          .concat(
            compound.person_details.map(p => p.questions['personal-comments'])
          )
          .join(' ')
      }
    })
    .filter(
      row => row.stayDates.slice(0, 10) >= dayjs(from).format('YYYY-MM-DD')
    )

  return (
    <DataGridContainer>
      <DataGrid
        columns={columns}
        data={data}
        nonSortable={false}
        disableResize={false}
        withGlobalFilter={true}
        renderButtons={() => (
          <>
            <DatePicker
              inputFormat="MM/DD/YYYY"
              label="From"
              value={from}
              onChange={date => {
                setFrom(date)
              }}
              renderInput={params => (
                <Tooltip title="Starting date range">
                  <TextField autoComplete="off" {...params} />
                </Tooltip>
              )}
            />
            <DatePicker
              minDate={from}
              inputFormat="MM/DD/YYYY"
              label="To"
              value={to}
              onChange={date => {
                setTo(date)
              }}
              renderInput={params => (
                <Tooltip title="Ending date range">
                  <TextField autoComplete="off" {...params} />
                </Tooltip>
              )}
            />
            <PrimaryButton
              onClick={() => {
                if (from && to)
                  setQuery(s =>
                    s.queryGetStaysWithinDates({
                      from: dayjs(from).format('YYYY-MM-DD'),
                      to: dayjs(to).format('YYYY-MM-DD')
                    })
                  )
              }}
            >
              <RefreshIcon />
            </PrimaryButton>
          </>
        )}
      />
    </DataGridContainer>
  )
})
