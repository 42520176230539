import React from 'react'
import styled from 'styled-components'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Popover from '@mui/material/Popover'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography, { TypographyProps } from '@mui/material/Typography'
import { QueryKey, useQueryClient } from 'react-query'
import { Column, Row } from 'react-table'

import { StoreContext } from '@sayr/client-models'
import {
  useUserGetTrackSetupQuery,
  useUserUpdateTrackWarningMutation,
  UserGetTrackSetupQuery,
  WarningTypes,
  useUserGetMonitorSetupQuery,
  useUserAddMonitoredEntityMutation,
  useUserDeleteMonitoredEntityMutation,
  MonitoringTypes,
  UserGetMonitorSetupQuery,
  useUserGetWarnOnChangesToPastDataStatusQuery,
  useUserSetWarnOnChangesToPastDataMutation
} from '../../graphql/generated/typesAndHooks'
import { DataGridContainer } from '../../components/common/dataGrid/DataGridStyles'
import { DataGrid } from '../../components/common/dataGrid/DataGrid'
import { PrimaryButton, SecondaryButton } from '../../components/common/Buttons'
import { FormBottomButtons } from '../announcements/AnnouncementEditPageStyles'
import { ListItemButton } from '@mui/material'

const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(min-content, max-content) 1fr;
  width: max-content;
`

const SmallFormControlLabel = styled(FormControlLabel)`
  && span {
    font-size: 80%;
  }
`

const StyledDataGridContainer = styled(DataGridContainer)``

const StyledDialog = styled(Dialog)`
  & [role='dialog'] {
    padding: 1rem 2rem;
  }
`

const StyledTextField = styled(TextField)`
  width: 9rem;
`

const HeadingTypography = styled(Typography)<
  TypographyProps<'h4', { component: 'h4' }>
>`
  background-color: #fbfbfb;
  border-radius: 4px;
  && {
    margin-top: 2rem;
  }
  padding-left: 0.5rem;
  position: sticky;
  top: 0;
  z-index: 2;
`

const StyledListItem = styled(ListItemButton)<{ $selected: boolean }>`
  && {
    background-color: ${({ theme, $selected }) =>
      $selected && theme.palette.primary.main};
  }
`

const warningLabelMap: { warningType: WarningTypes; label: string }[] = [
  {
    warningType: WarningTypes.OceanviewWithoutBedArrangement,
    label: 'Oceanview Deluxe Registration without Bed Arrangement'
  },
  { warningType: WarningTypes.OvercrowdedRoom, label: 'Overcrowded Room' },
  { warningType: WarningTypes.WrongCharge, label: 'Wrong Charge' },
  { warningType: WarningTypes.InvalidData, label: 'Invalid Data' },
  { warningType: WarningTypes.GapInStay, label: 'Gap in Stay' },
  { warningType: WarningTypes.PlannedRoomMove, label: 'Planned Room Move' },
  {
    warningType: WarningTypes.IncorrectRegStatus,
    label: 'Incorrect Registration Status'
  },
  { warningType: WarningTypes.OpenBalance, label: 'Open Balance' },
  {
    warningType: WarningTypes.UnmatchedRegistration,
    label: 'Registration Unmatched to Person'
  }
]

type Monitor = UserGetMonitorSetupQuery['userGetMonitorSetup'][number]

const columns: Column<Monitor>[] = [
  {
    accessor: 'type',
    Header: 'Monitor Type',
    Cell: ({ value }) => (
      <>
        {String(value)[0].toUpperCase() + String(value).slice(1).toLowerCase()}
      </>
    )
  },
  {
    accessor: 'id',
    Header: 'id',
    Cell: r => (
      <a target="_blank" rel="noreferrer" href={getHrefForMonitoredItem(r.row)}>
        {r.row.original.id}
      </a>
    )
  },
  { accessor: 'name', Header: 'Name' }
]

export function WarningSetup() {
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] =
    React.useState(false)

  const { data, isLoading } = useUserGetTrackSetupQuery()
  const { data: monitorData, isLoading: isMonitorDataLoading } =
    useUserGetMonitorSetupQuery()

  const { data: warnData } = useUserGetWarnOnChangesToPastDataStatusQuery()

  const store = React.useContext(StoreContext)
  const pastDataWarnMutation = useUserSetWarnOnChangesToPastDataMutation({
    onSettled: () => {
      queryClient.invalidateQueries(
        useUserGetWarnOnChangesToPastDataStatusQuery.getKey()
      )
      store.warnings.add({
        key: Math.random().toString(36),
        message: `Updated setup regarding warning on change to past data.`
      })
    }
  })

  const queryClient = useQueryClient()

  const deleteMonitorMutation = useUserDeleteMonitoredEntityMutation({
    onSettled: () => {
      setTimeout(
        () =>
          queryClient.invalidateQueries(useUserGetMonitorSetupQuery.getKey()),
        2000
      )
    }
  })

  if (isLoading || !data || isMonitorDataLoading || !monitorData || !warnData)
    return <CircularProgress />

  return (
    <div style={{ marginLeft: '1rem', marginBottom: '5rem' }}>
      <Heading>Keep me informed on:</Heading>
      <TrackingFields
        queryKey={useUserGetTrackSetupQuery.getKey()}
        data={data}
      />
      <Heading>Warn me on:</Heading>
      <FormControlLabel
        control={
          <Checkbox
            checked={warnData.userGetWarnOnChangesToPastDataStatus}
            onChange={() =>
              pastDataWarnMutation.mutate({
                status: !warnData.userGetWarnOnChangesToPastDataStatus
              })
            }
          />
        }
        label="Change in registration past data (lodging, dates, program, status, transactions)"
      />
      <br />
      <FormControlLabel
        control={<Checkbox checked={false} />}
        label="A credit is expired"
      />
      <Heading>Monitor:</Heading>

      <StyledDataGridContainer>
        <DataGrid
          columns={columns}
          data={monitorData.userGetMonitorSetup}
          disablePagination
          renderBatchActions={selectedFlatRows => {
            return (
              <>
                <PrimaryButton onClick={() => setConfirmDeleteDialogOpen(true)}>
                  <DeleteIcon />
                  <Typography>Stop monitoring selected records</Typography>
                </PrimaryButton>
                <StyledDialog open={confirmDeleteDialogOpen}>
                  <DialogTitle>Confirm - Remove Monitoring</DialogTitle>
                  <ul style={{ marginBottom: '1rem' }}>
                    {selectedFlatRows.map((r, i) => (
                      <li key={i}>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={getHrefForMonitoredItem(r)}
                        >
                          {r.original.type} - {r.original.id} -{' '}
                          {r.original.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                  <FormBottomButtons>
                    <SecondaryButton>Cancel</SecondaryButton>
                    <PrimaryButton
                      autoFocus
                      onClick={() => {
                        selectedFlatRows.forEach(r =>
                          deleteMonitorMutation.mutate({
                            entityId: r.original.id,
                            monitoringType: r.original.type
                          })
                        )

                        setConfirmDeleteDialogOpen(false)
                      }}
                    >
                      Confirm
                    </PrimaryButton>
                  </FormBottomButtons>
                </StyledDialog>
              </>
            )
          }}
        />
      </StyledDataGridContainer>
      <div
        style={{
          maxWidth: '50rem',
          textAlign: 'right'
        }}
      >
        <AddEntityToMonitorForm />
      </div>
    </div>
  )
}

function AddEntityToMonitorForm() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [newMonitoredID, setNewMonitoredID] = React.useState(0)
  const [selectionState, setSelectionState] =
    React.useState<MonitoringTypes | null>(null)

  const queryClient = useQueryClient()
  React.useEffect(() => {
    if (!anchorEl) {
      setSelectionState(null)
      setNewMonitoredID(0)
    }
  }, [anchorEl])
  const addMonitorMutation = useUserAddMonitoredEntityMutation({
    onSettled: () => {
      queryClient.invalidateQueries(useUserGetMonitorSetupQuery.getKey())
    }
  })

  return (
    <>
      <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
        <AddCircleOutlineOutlinedIcon />
      </IconButton>

      <Popover
        id="add-monitoring-item"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <DialogTitle>Monitor a:</DialogTitle>
          <List>
            <StyledListItem
              $selected={selectionState === MonitoringTypes.Person}
              onClick={() => setSelectionState(MonitoringTypes.Person)}
            >
              <Tooltip
                title={
                  <>
                    <p>Get notified on:</p>
                    <ul style={{ marginLeft: '1rem' }}>
                      <li>Changes to a person's personal details</li>
                      <li>Changes to a person's credit transaction</li>
                      <li>Changes to any of the person's registrations</li>
                    </ul>
                  </>
                }
              >
                <ListItemText>Person</ListItemText>
              </Tooltip>
            </StyledListItem>
            <StyledListItem
              $selected={selectionState === MonitoringTypes.Registration}
              onClick={() => setSelectionState(MonitoringTypes.Registration)}
            >
              <Tooltip title="Get notified on any change to this registration, including change of roommate.">
                <ListItemText>Registration</ListItemText>
              </Tooltip>
            </StyledListItem>
            <StyledListItem
              $selected={selectionState === MonitoringTypes.Program}
              onClick={() => setSelectionState(MonitoringTypes.Program)}
            >
              <Tooltip
                title={
                  <>
                    <p>Get notified on:</p>
                    <ul style={{ marginLeft: '1rem' }}>
                      <li>
                        Changes to any detail of the program (except
                        Registration Questions &amp; options, email templates or
                        text customizations)
                      </li>
                      <li>
                        Cardinal changes to any of its registrations (change of
                        date, room, lodging or validity)
                      </li>
                    </ul>
                  </>
                }
              >
                <ListItemText>Program</ListItemText>
              </Tooltip>
            </StyledListItem>
            <ListItem button disabled>
              <ListItemText>Room</ListItemText>
            </ListItem>
          </List>
          {selectionState !== null && (
            <>
              <div>
                <StyledTextField
                  type="number"
                  value={newMonitoredID}
                  onChange={e => setNewMonitoredID(+e.target.value)}
                />
              </div>
              <PrimaryButton
                style={{ alignSelf: 'end', margin: '1rem 0.3rem 0.3rem' }}
                onClick={() => {
                  addMonitorMutation.mutate({
                    monitoringType: selectionState,
                    entityId: newMonitoredID
                  })

                  setAnchorEl(null)
                }}
              >
                Add
              </PrimaryButton>
            </>
          )}
        </div>
      </Popover>
    </>
  )
}

function Heading({ children }: React.PropsWithChildren) {
  return (
    <HeadingTypography variant="h6" component="h4">
      {children}
    </HeadingTypography>
  )
}

function TrackingFields({
  queryKey,
  data
}: {
  queryKey: QueryKey
  data: UserGetTrackSetupQuery
}) {
  const props = { queryKey, data }
  return (
    <Container>
      {warningLabelMap.map(w => (
        <FieldCheckbox
          key={w.warningType}
          warningType={w.warningType}
          {...props}
        />
      ))}
    </Container>
  )
}

function FieldCheckbox({
  queryKey,
  warningType,
  data
}: {
  queryKey: QueryKey
  warningType: WarningTypes
  data: UserGetTrackSetupQuery
}) {
  const track = data.userGetTrackSetup.find(t => t.type === warningType)
  const value = !!track
  const valueSendAlert = track?.sendAlert || false
  const label =
    warningLabelMap.find(w => w.warningType === warningType)?.label || ''

  const store = React.useContext(StoreContext)
  const queryClient = useQueryClient()

  const updateTrackingMutation = useUserUpdateTrackWarningMutation({
    onSuccess: (_, { warningType }) => {
      store.warnings.add({
        key: Math.random().toString(36),
        message: `Updated tracking setup for ${
          warningLabelMap.find(w => w.warningType === warningType)?.label
        }.`
      })
    },
    onError: () => {
      store.warnings.add({
        key: Math.random().toString(36),
        message: 'An error occured while updating your tracking setup.'
      })
    },
    onSettled: () => queryClient.invalidateQueries(queryKey)
  })

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={value}
            onChange={() =>
              updateTrackingMutation.mutate({ warningType, value: !value })
            }
          />
        }
        label={label}
      />
      <Tooltip title="Send me an email alert whenever a new warning is issued.">
        <SmallFormControlLabel
          control={
            <Checkbox
              size="small"
              color="secondary"
              checked={valueSendAlert}
              onChange={() =>
                updateTrackingMutation.mutate({
                  warningType,
                  sendAlert: !valueSendAlert
                })
              }
              disabled={!value}
            />
          }
          label="Send Alert"
        />
      </Tooltip>
    </>
  )
}

function getHrefForMonitoredItem(r: Row<Monitor>) {
  return r.original.type === MonitoringTypes.Person
    ? `${process.env.REACT_APP_RG_SITE}/wp-admin/admin.php?person=${r.original.id}&page=registrations&action=edit`
    : r.original.type === MonitoringTypes.Registration
    ? `${process.env.REACT_APP_RG_SITE}/wp-admin/admin.php?registration=${r.original.id}&page=registrations&action=edit`
    : `${process.env.REACT_APP_RG_SITE}/wp-admin/admin.php?page=rs-programs&action=edit&program=${r.original.id}`
}
