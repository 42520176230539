import * as React from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'
import Chip from '@mui/material/Chip'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import {
  ProgramTypes,
  CovidTestType
} from '../../graphql/generated/typesAndHooks'

const StyledFormControl = styled(FormControl)`
  && {
    margin: 0.5rem 0;
    min-width: 11rem;
  }
`

const StyledMenuItem = styled(MenuItem)<{ selected: boolean }>`
  && {
    font-weight: ${({ selected }) => selected && 500};
  }
`

const ChipContainer = styled.div.attrs({ className: 'chip-container' })`
  display: flex;
  gap: 0.6rem;
`

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

export const MultipleSelectProgramTypes = React.forwardRef<
  HTMLInputElement,
  {
    selectedProgramTypes: ProgramTypes[]
    setSelectedProgramTypes: (types: ProgramTypes[]) => void
    onBlur?:
      | React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
      | undefined
    name: string
  }
>(({ selectedProgramTypes, setSelectedProgramTypes, onBlur, name }, ref) => {
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value }
    } = event
    setSelectedProgramTypes(
      // On autofill we get a stringified value.
      (typeof value === 'string' ? value.split(',') : value) as ProgramTypes[]
    )
  }

  function renderProgramName(name: ProgramTypes) {
    return name
      .split('_')
      .map(c =>
        c.match(/ttc/i) ? c : c[0].toUpperCase() + c.slice(1).toLowerCase()
      )
      .join(' ')
  }

  return (
    <StyledFormControl>
      <InputLabel id="select-program-types">Program Types</InputLabel>
      <Select
        labelId="select-program-types"
        multiple
        value={selectedProgramTypes}
        onChange={handleChange}
        input={<OutlinedInput label="Program Types" inputRef={ref} />}
        renderValue={selected => (
          <ChipContainer>
            {selected.map(value => (
              <Chip key={value} label={renderProgramName(value)} />
            ))}
          </ChipContainer>
        )}
        MenuProps={MenuProps}
        onBlur={onBlur}
        name={name}
      >
        {[
          ProgramTypes.Attc,
          ProgramTypes.Babies,
          ProgramTypes.Children,
          ProgramTypes.Commuters,
          ProgramTypes.KarmaYoga,
          ProgramTypes.Speaker,
          ProgramTypes.Ttc,
          ProgramTypes.TtcCompletion,
          ProgramTypes.Vacation,
          ProgramTypes.VisitingStaff
        ].map(name => (
          <StyledMenuItem
            key={name}
            value={name}
            selected={selectedProgramTypes.includes(name)}
          >
            {renderProgramName(name)}
          </StyledMenuItem>
        ))}
      </Select>
    </StyledFormControl>
  )
})

export const MultipleSelectWeekdayChip = React.forwardRef<
  HTMLInputElement,
  {
    selectedWeekdays: number[]
    setSelectedWeekdays: (days: number[]) => void
    onBlur?:
      | React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
      | undefined
    name: string
  }
>(({ selectedWeekdays, setSelectedWeekdays, onBlur, name }, ref) => {
  const handleChange = (event: SelectChangeEvent<number[]>) => {
    const {
      target: { value }
    } = event
    setSelectedWeekdays(
      // On autofill we get a stringified value.
      (typeof value === 'string' ? value.split(',') : value).map(v => +v)
    )
  }

  function renderWeekdayName(day: number) {
    return dayjs().day(day).format('dddd')
  }

  return (
    <StyledFormControl>
      <InputLabel id="select-weekdays">Weekdays</InputLabel>
      <Select
        labelId="select-weekdays"
        multiple
        value={selectedWeekdays}
        onChange={handleChange}
        input={<OutlinedInput label="Weekdays" inputRef={ref} />}
        renderValue={selected => (
          <ChipContainer>
            {selected.map(value => (
              <Chip key={value} label={renderWeekdayName(+value)} />
            ))}
          </ChipContainer>
        )}
        MenuProps={MenuProps}
        onBlur={onBlur}
        name={name}
      >
        {[0, 1, 2, 3, 4, 5, 6].map(day => (
          <StyledMenuItem
            key={day}
            value={day}
            selected={selectedWeekdays.includes(day)}
          >
            {renderWeekdayName(day)}
          </StyledMenuItem>
        ))}
      </Select>
    </StyledFormControl>
  )
})

export const MultipleSelectCovidTestType = React.forwardRef<
  HTMLInputElement,
  {
    selectedCovidTestTypes: CovidTestType[]
    setSelectedCovidTestTypes: (covidTetTypes: CovidTestType[]) => void
    onBlur?:
      | React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
      | undefined
    name: string
  }
>(
  (
    { selectedCovidTestTypes, setSelectedCovidTestTypes, onBlur, name },
    ref
  ) => {
    const handleChange = (event: SelectChangeEvent<CovidTestType[]>) => {
      const {
        target: { value }
      } = event
      setSelectedCovidTestTypes(
        // On autofill we get a stringified value.
        (typeof value === 'string'
          ? value.split(',')
          : value) as CovidTestType[]
      )
    }

    function renderCovidTestName(name: CovidTestType) {
      return name as string
    }

    return (
      <StyledFormControl>
        <InputLabel id="select-covid-test-types">Covid Test Types</InputLabel>
        <Select
          labelId="select-covid-test-types"
          multiple
          value={selectedCovidTestTypes}
          onChange={handleChange}
          input={<OutlinedInput label="Covid Test Types" inputRef={ref} />}
          renderValue={selected => (
            <ChipContainer>
              {selected.map(value => (
                <Chip key={value} label={renderCovidTestName(value)} />
              ))}
            </ChipContainer>
          )}
          MenuProps={MenuProps}
          onBlur={onBlur}
          name={name}
        >
          {[
            CovidTestType.CompanyAntigen,
            CovidTestType.CompanyFastPcr,
            CovidTestType.CompanyPcr,
            CovidTestType.CompanySelfTestKit,
            CovidTestType.InHouse
          ].map(covidTestType => (
            <StyledMenuItem
              key={covidTestType}
              value={covidTestType}
              selected={selectedCovidTestTypes.includes(covidTestType)}
            >
              {renderCovidTestName(covidTestType)}
            </StyledMenuItem>
          ))}
        </Select>
      </StyledFormControl>
    )
  }
)
