import { types, getRoot, Instance } from 'mobx-state-tree'
import { ViewType } from './ViewModel'
import dayjs, { Dayjs } from 'dayjs'
import { now } from 'mobx-utils'
import { AnnouncementResponseModelType } from './AnnouncementResponseModel'
import { AudienceEnumType } from './AudienceEnum'
import { ObservableMap } from 'mobx'

const AnnouncementDraftModel = types
  .model('AnnouncementDraftModel', {
    subject: types.string,
    bodyText: types.string,
    publishStart: types.maybeNull(types.Date),
    publishEnd: types.optional(types.Date, dayjs().add(2, 'days').toDate()),
    highPriority: types.boolean,
    sendAlert: types.boolean,
    audience: types.maybe(AudienceEnumType),
    audienceCourseId: types.maybe(types.number)
  })
  .actions(self => ({
    setSubject(newSubject: string) {
      self.subject = newSubject
    },
    setBodyText(newBodyText: string) {
      self.bodyText = newBodyText
    },
    setAudience(newAudience: typeof self.audience) {
      self.audience = newAudience
    },
    setPublishStart(newDate: Dayjs | null) {
      self.publishStart = newDate?.toDate() || null
    },
    setPublishEnd(newDate: Dayjs) {
      self.publishEnd = newDate.toDate()
    },
    togglePriority() {
      self.highPriority = !self.highPriority
    },
    toggleNotify() {
      self.sendAlert = !self.sendAlert
    }
  }))

const EditModeModel = types
  .model('EditModeModel', {
    newDraft: types.maybe(AnnouncementDraftModel)
  })
  .actions(self => ({
    clearDraft() {
      self.newDraft = AnnouncementDraftModel.create({
        subject: '',
        bodyText: '',
        highPriority: false,
        publishStart: undefined,
        publishEnd: dayjs().add(2, 'days').toDate(),
        audience: undefined,
        sendAlert: false
      })
    }
  }))

const AnnouncementsProps = types
  .model({
    editMode: types.maybe(EditModeModel)
  })
  .views(self => ({
    get _all() {
      const all =
        Array.from(
          (
            (getRoot(self) as any).announcementResponses as ObservableMap<
              string,
              AnnouncementResponseModelType
            >
          ).values()
        ) || []

      return !!self.editMode
        ? all
        : all.filter(
            a =>
              a.$stats?.some(
                s =>
                  s.$person._id ===
                  (getRoot(self) as any).loggedInUser?.user?.id
              ) &&
              a.$publishStart.isBefore(now(60000)) &&
              a.$publishEnd.isAfter(now(60000))
          )
    }
  }))
  .views(self => ({
    get active() {
      return self._all.filter(a =>
        dayjs(now(60000)).add(1, 'minute').isBefore(a.publishEnd)
      )
    },
    get archived() {
      return self._all.filter(
        a => !dayjs(now(60000)).add(1, 'minute').isBefore(a.publishEnd)
      )
    }
  }))
  .views(self => ({
    announcementById(id: string) {
      return self._all.find(a => a.id === id)
    },
    get unread() {
      console.log('get unread', self.active)
      return self.active
        .filter(
          a =>
            !a.$stats?.find(
              s =>
                s.$person._id === (getRoot(self) as any).loggedInUser?.user?.id
            )?.readTimestamp
        )
        .sort(compareByTimestamp)
    },
    get read() {
      console.log('get read', self.active)
      return self.active
        .filter(
          a =>
            a.stats?.find(
              s =>
                s.$person._id === (getRoot(self) as any).loggedInUser?.user?.id
            )?.readTimestamp
        )
        .sort(compareByTimestamp)
    }
  }))
  .volatile(_self => ({ initialDelay: true }))
  .views(self => ({
    snackbar() {
      const importantUnreadCount = self.unread.filter(
        a => a.highPriority
      ).length

      return !self.initialDelay &&
        importantUnreadCount &&
        ((getRoot(self) as any).view as ViewType).page !== '/announcements'
        ? `${importantUnreadCount} new important announcement${
            importantUnreadCount > 1 ? `s` : ''
          }`
        : ''
    }
  }))
  .actions(self => ({
    endSnackbarInitialDelay() {
      self.initialDelay = false
    },
    enterIntoEditMode() {
      if (!self.editMode) {
        self.editMode = EditModeModel.create({
          newDraft: {
            subject: '',
            bodyText: '',
            highPriority: false,
            audience: undefined,
            sendAlert: false
          }
        })
      }
    },
    exitEditMode() {
      self.editMode = undefined
    }
  }))

export const AnnouncementsModel = AnnouncementsProps

function compareByTimestamp(
  a: AnnouncementResponseModelType,
  b: AnnouncementResponseModelType
) {
  return b.$publishStart > a.$publishStart ? 1 : -1
}

export interface AnnouncementsInstanceType
  extends Instance<typeof AnnouncementsModel> {}
