import * as React from 'react'
import { useForm } from 'react-hook-form'
import * as EmailValidator from 'email-validator'
import { PasswordMeter } from 'password-meter'
import styled from 'styled-components'
import VisibilityIcon from '@mui/icons-material/Visibility'
import Alert from '@mui/material/Alert'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import PasswordStrengthMeter from '../../components/common/PasswordStrengthMeter'
import { observer } from 'mobx-react-lite'
import {
  Field,
  FormError,
  FormTextField,
  Wrapper
} from '../../components/common/Forms'
import { PrimaryButton } from '../../components/common/Buttons'
import { Form, PageState } from './LoginCommon'
import { StoreContext } from '@sayr/client-models'

const StyledVisibilityIcon = styled(VisibilityIcon)`
  transform: translateY(-0.2rem);
`

export const PasswordWrapper = styled(Wrapper)`
  gap: 0.5rem;
`

const SignupProgressReport = styled.div.attrs({ className: 'progress-report' })`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

// IconButton which enables showing the password for improved accessibility
export function ShowPasswordIcon({
  callback
}: {
  callback: React.Dispatch<React.SetStateAction<boolean>>
}) {
  return (
    <IconButton
      style={{ padding: 0 }}
      aria-label="show password"
      // show when icon is touched
      onTouchStart={() => {
        callback(false)
      }}
      // hide once the icon stops being touched
      onTouchEnd={() => {
        callback(true)
      }}
      onMouseDown={() => {
        callback(false)
      }}
      onMouseUp={() => {
        callback(true)
      }}
      onKeyDown={() => {
        callback(false)
      }}
      onKeyUp={() => {
        callback(true)
      }}
      onBlur={() => {
        callback(true)
      }}
    >
      <StyledVisibilityIcon />
    </IconButton>
  )
}

export function isPasswordStrong(password: string) {
  return new PasswordMeter().getResult(password).percent > 60
}

type FormInputs = {
  signupEmail: string
  signupPassword: string
  repeatPassword: string
}

function ManualSignup({
  setPageState
}: {
  setPageState: (newState: PageState) => void
}) {
  const store = React.useContext(StoreContext)
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields, submitCount },
    setValue,
    trigger,
    watch
  } = useForm<FormInputs>({
    mode: 'onTouched'
  })

  const [state, setState] = React.useState<
    'editing' | 'processing' | 'processed' | 'failed'
  >('editing')

  async function onSubmit(data: FormInputs) {
    setState('processing')
    const registerResult = await store.mutateUserCreateNew({
      email: data.signupEmail,
      password: data.signupPassword
    })
    if (registerResult.userCreateNew) setState('processed')
    else setState('failed')
  }

  const [passwordHidden, setPasswordHidden] = React.useState(true)
  const [repeatPasswordHidden, setRepeatPasswordHidden] = React.useState(true)

  const email = register('signupEmail', {
    required: true,
    validate: async value => {
      // the if email pattern is valid
      if (!EmailValidator.validate(value)) return 'Email is not valid'

      // check if email already exists
      const { userDoesExist } = await store.queryUserDoesExist({
        email: value
      })
      if (userDoesExist) return 'already exists'

      return true
    }
  })

  const password = register('signupPassword', {
    required: true,
    validate: () => isPasswordStrong(passwordWatch)
  })

  const passwordWatch = watch('signupPassword', '')

  const repeatPassword = register('repeatPassword', {
    required: true,
    validate: value => value === passwordWatch
  })

  return (
    <>
      <Typography
        style={{
          marginTop: '2rem',
          marginLeft: '1rem',
          marginBottom: '1rem'
        }}
        variant="h3"
      >
        Create an Account
      </Typography>
      <Form style={{ marginLeft: '1rem' }} onSubmit={handleSubmit(onSubmit)}>
        <Wrapper
          style={{ width: '15.5rem' }}
          bottomSpacing={!!errors.signupEmail}
        >
          <Field>
            <FormTextField
              autoFocus
              variant="outlined"
              disabled={state !== 'editing'}
              fullWidth
              size="small"
              id="email"
              label="Email"
              autoComplete="username"
              name={email.name}
              onChange={e => {
                e.target.value = e.target.value.toLowerCase()
                email.onChange(e)
              }}
              onBlur={email.onBlur}
              inputRef={email.ref}
            />
            {errors.signupEmail && (
              <FormError>{errors.signupEmail.message}</FormError>
            )}
          </Field>
        </Wrapper>
        <PasswordWrapper
          bottomSpacing={state === 'editing' && !!dirtyFields.signupPassword}
        >
          <Field style={{ flexBasis: '15.5rem', flexGrow: 0 }}>
            <Tooltip
              title={
                submitCount && errors.signupPassword
                  ? 'Choose a stronger password'
                  : ''
              }
            >
              <FormTextField
                aria-describedby="password-strength-progress"
                variant="outlined"
                disabled={state !== 'editing'}
                fullWidth
                size="small"
                label="Password"
                type={passwordHidden ? 'password' : 'text'}
                id="password"
                autoComplete="new-password"
                name={password.name}
                onChange={e => {
                  password.onChange(e)
                  // trigger validation on repeatPassword field
                  setTimeout(() => trigger('repeatPassword'))
                }}
                onBlur={password.onBlur}
                inputRef={password.ref}
              />
            </Tooltip>
            {state === 'editing' && dirtyFields.signupPassword && (
              <PasswordStrengthMeter password={passwordWatch} />
            )}
          </Field>
          {state === 'editing' && (
            <ShowPasswordIcon callback={setPasswordHidden} />
          )}
        </PasswordWrapper>
        <PasswordWrapper bottomSpacing={!!errors.repeatPassword}>
          <Field style={{ flexBasis: '15.5rem', flexGrow: 0 }}>
            <FormTextField
              variant="outlined"
              disabled={state !== 'editing'}
              fullWidth
              size="small"
              label="Repeat Password"
              type={repeatPasswordHidden ? 'password' : 'text'}
              id="signup-repeat-password"
              autoComplete="new-password"
              name={repeatPassword.name}
              onChange={e => {
                repeatPassword.onChange(e)
                setValue('repeatPassword', e.target.value)
                setTimeout(() => trigger('repeatPassword'))
              }}
              onBlur={repeatPassword.onBlur}
              inputRef={repeatPassword.ref}
            />
            {errors.repeatPassword && (
              <FormError>
                {errors.repeatPassword.type === 'required'
                  ? 'Empty Password'
                  : 'The passwords do not match'}
              </FormError>
            )}
          </Field>
          {state === 'editing' && (
            <ShowPasswordIcon callback={setRepeatPasswordHidden} />
          )}
        </PasswordWrapper>
        <PrimaryButton
          style={{ width: '15.5rem' }}
          disabled={state !== 'editing'}
          type="submit"
        >
          Sign Up
        </PrimaryButton>
        <Typography style={{ width: '15.5rem' }} variant="caption">
          By joining, you agree to our{' '}
          <Link href="https://sivanandabahamas.org/terms-conditions/">
            Terms and Privacy Policy
          </Link>
        </Typography>
      </Form>
      <SignupProgressReport>
        {state === 'processing' && (
          <>
            <CircularProgress />
            <Typography>Processing your registration...</Typography>
          </>
        )}
        {state === 'processed' && (
          <Alert severity="success">
            Great, please check your email to verify the registration.
          </Alert>
        )}
        {state === 'failed' && (
          <Alert severity="error">
            Apologies, we could not register you at this time.
          </Alert>
        )}
      </SignupProgressReport>
      <Link
        component="button"
        onClick={() => setPageState('standard-login-page')}
      >
        Back to login page
      </Link>
    </>
  )
}

export default observer(ManualSignup)
