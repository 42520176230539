import React from 'react'
import { produce } from 'immer'
import dayjs from 'dayjs'
import { useQueryClient, QueryKey } from 'react-query'
import {
  CovidTestType,
  GetRegistrationTestsQuery,
  useRegistrationTestAddMutation,
  useRegistrationTestDeleteMutation,
  useRegistrationTestUpdateMutation,
  useRegistrationTestPushMutation,
  useRegistrationTestToggleChargeMutation
} from '../../../graphql/generated/typesAndHooks'
import {
  GetRegistrationTestsQueryWithMutating,
  RegistrationTestsWithMutating,
  TestCalendarCell
} from './covidTypes'
import { StoreContext } from '@sayr/client-models'

// custom hook for mutations and optimistic updates
export function useTestMutations(
  regId: number | undefined,
  test: TestCalendarCell | undefined,
  queryKey: QueryKey
) {
  const store = React.useContext(StoreContext)

  const queryClient = useQueryClient()

  const addTestMutation = useRegistrationTestAddMutation<
    Error,
    { previousValue: GetRegistrationTestsQueryWithMutating }
  >({
    onMutate: async ({ type }) => {
      if (!test || !regId) return
      await queryClient.cancelQueries(queryKey)

      const previousValue =
        queryClient.getQueryData<GetRegistrationTestsQueryWithMutating>(
          queryKey
        )

      if (!previousValue || !previousValue.getRegistrationTests)
        throw new Error('old does not exist')

      // optimistic update
      const newValue = produce(previousValue, draft => {
        const record = draft.getRegistrationTests.find(r => r.regId === regId)

        const testsOnDay = record?.tests.filter(t => t.date === test.date) || []
        const emptyTest = testsOnDay.find(t => t.type === null)

        if (emptyTest) {
          // the current record for registration/date has one record with a null type.
          emptyTest.type = type as CovidTestType
          emptyTest.mutating = { general: true }
        } else {
          // the current record already has one test, adding one more.
          record?.tests.push({
            type: type as CovidTestType,
            id: '',
            outcome: null,
            confirmedByGuest: false,
            regId,
            date: test.date || '',
            freeOfCharge: false,
            stayCapacity: test.stayCapacity,
            chargedInTransaction: 0,
            chargedAmount: 0,
            mutating: { general: true },
            notes: ''
          })
        }
      })

      queryClient.setQueryData(queryKey, newValue)
      return { previousValue } // for potential rollback
    },

    onError: (_err, vars, context) => {
      // in case the optimistic update was too optimistic
      console.error('add test mutation error', _err, vars, context)
      store.warnings.add({
        key: Math.random().toString(36),
        message: _err.message
      })
      if (context) queryClient.setQueryData(queryKey, context.previousValue)
    },

    // once settled, refetch query to catch other updates done in the interim
    onSettled: () => queryClient.invalidateQueries(queryKey)
  })

  const deleteTestMutation = useRegistrationTestDeleteMutation<
    Error,
    { previousValue: GetRegistrationTestsQuery }
  >({
    onMutate: async () => {
      if (!test || !regId) return
      await queryClient.cancelQueries(queryKey)

      const previousValue =
        queryClient.getQueryData<GetRegistrationTestsQuery>(queryKey)

      if (!previousValue || !previousValue.getRegistrationTests)
        throw new Error('old does not exist')

      const newValue = produce(previousValue, draft => {
        const record = draft.getRegistrationTests.find(r => r.regId === regId)

        const index = record?.tests.findIndex(t => t.id === test.id) || -1

        if (index !== -1) record?.tests.splice(index, 1)

        if (record) fillEmptyCells(record, regId, test, queryKey)
      })

      queryClient.setQueryData(queryKey, newValue)

      return { previousValue }
    },

    onError: (_err, vars, context) => {
      console.error('delete dest mutation error', _err, vars, context)
      store.warnings.add({
        key: Math.random().toString(36),
        message: _err.message
      })

      if (context) queryClient.setQueryData(queryKey, context.previousValue)
    },

    onSettled: () => queryClient.invalidateQueries(queryKey)
  })

  const updateTestMutation = useRegistrationTestUpdateMutation<
    Error,
    { previousValue: GetRegistrationTestsQuery }
  >({
    onMutate: async ({
      id,
      confirmedByGuest,
      freeOfCharge,
      outcome,
      type,
      notes
    }) => {
      if (!test || !regId) return
      await queryClient.cancelQueries(queryKey)

      const previousValue =
        queryClient.getQueryData<GetRegistrationTestsQueryWithMutating>(
          queryKey
        )

      if (!previousValue || !previousValue.getRegistrationTests)
        throw new Error('old does not exist')

      const newValue = produce(previousValue, draft => {
        const record = draft.getRegistrationTests.find(r => r.regId === regId)

        const testsOnDay = record?.tests.filter(t => t.date === test.date) || []
        const currentTest = testsOnDay.find(t => t.id === id)

        if (currentTest) {
          if (!currentTest.mutating) currentTest.mutating = {}
          if (confirmedByGuest !== undefined && confirmedByGuest !== null) {
            currentTest.confirmedByGuest = confirmedByGuest
            currentTest.mutating.confirmed = true
          }
          if (freeOfCharge !== undefined && freeOfCharge !== null) {
            currentTest.freeOfCharge = freeOfCharge
            currentTest.mutating.free = true
          }
          if (outcome !== undefined) {
            currentTest.outcome =
              outcome === 'null' ? null : outcome === 'true' ? true : false
            currentTest.mutating.outcome = true
          }
          if (type !== undefined) {
            currentTest.type = type as CovidTestType
            currentTest.mutating.type = true
          }
          if (notes !== undefined) {
            currentTest.notes = notes || ''
            currentTest.mutating.notes = true
          }
        }
      })

      queryClient.setQueryData(queryKey, newValue)

      return { previousValue }
    },

    onError: (_err, vars, context) => {
      console.error('update test mutation error', _err, vars, context)
      store.warnings.add({
        key: Math.random().toString(36),
        message: _err.message
      })

      if (context) queryClient.setQueryData(queryKey, context.previousValue)
    },

    onSettled: () => queryClient.invalidateQueries(queryKey)
  })

  const toggleChargeMutation = useRegistrationTestToggleChargeMutation<
    Error,
    { previousValue: GetRegistrationTestsQuery }
  >({
    onMutate: async ({ id }) => {
      if (!test || !regId) return
      await queryClient.cancelQueries(queryKey)

      const previousValue =
        queryClient.getQueryData<GetRegistrationTestsQueryWithMutating>(
          queryKey
        )

      if (!previousValue || !previousValue.getRegistrationTests)
        throw new Error('old does not exist')

      const newValue = produce(previousValue, draft => {
        const record = draft.getRegistrationTests.find(r => r.regId === regId)

        const testsOnDay = record?.tests.filter(t => t.date === test.date) || []
        const currentTest = testsOnDay.find(t => t.id === id)

        if (currentTest) {
          if (!currentTest.mutating) currentTest.mutating = {}
          currentTest.mutating.charged = true
        }
      })

      queryClient.setQueryData(queryKey, newValue)

      return { previousValue }
    },

    onError: (_err, vars, context) => {
      console.error('toggle test charge mutation error', _err, vars, context)
      store.warnings.add({
        key: Math.random().toString(36),
        message: _err.message
      })

      if (context) queryClient.setQueryData(queryKey, context.previousValue)
    },

    onSettled: () => queryClient.invalidateQueries(queryKey)
  })

  const pushTestMutation = useRegistrationTestPushMutation<
    Error,
    { previousValue: GetRegistrationTestsQuery }
  >({
    onMutate: async ({ id, direction }) => {
      if (!test || !regId) return
      await queryClient.cancelQueries(queryKey)

      const previousValue =
        queryClient.getQueryData<GetRegistrationTestsQueryWithMutating>(
          queryKey
        )

      if (!previousValue || !previousValue.getRegistrationTests)
        throw new Error('old does not exist')

      const newValue = produce(previousValue, draft => {
        const record = draft.getRegistrationTests.find(r => r.regId === regId)
        if (!record) return

        const testsOnDay = record.tests.filter(t => t.date === test.date) || []

        const currentTest = testsOnDay.find(t => t.id === id)
        const futureTests =
          record.tests.filter(t => dayjs(t.date).isAfter(test.date)) || []

        function getNewDate(date: string) {
          return dayjs(date)
            .add(direction === 'forward' ? 1 : -1, 'day')
            .format('YYYY-MM-DD')
        }

        if (currentTest) {
          currentTest.date = getNewDate(currentTest.date)
          currentTest.mutating = { general: true }

          futureTests
            .filter(
              t =>
                currentTest.type === CovidTestType.InHouse &&
                t.type === CovidTestType.InHouse
            )
            .forEach(t => {
              t.date = getNewDate(t.date)

              t.mutating = { general: true }
            })

          fillEmptyCells(record, regId, test, queryKey)

          // in case we push backward and the previous record has no test, remove it.
          record.tests = record.tests.filter((t, _i, arr) => {
            return !(
              t.type === null &&
              arr.some(
                otherTest =>
                  otherTest.type !== null &&
                  otherTest.date === t.date &&
                  otherTest.id !== t.id
              )
            )
          })
        }
      })

      queryClient.setQueryData(queryKey, newValue)

      return { previousValue }
    },

    onError: (_err, vars, context) => {
      console.error('monitored attribute mutation error', _err, vars, context)
      store.warnings.add({
        key: Math.random().toString(36),
        message: _err.message
      })

      if (context) queryClient.setQueryData(queryKey, context.previousValue)
    },

    onSettled: () => queryClient.invalidateQueries(queryKey)
  })

  return {
    addTestMutation,
    deleteTestMutation,
    updateTestMutation,
    toggleChargeMutation,
    pushTestMutation
  }
}

function fillEmptyCells(
  record: RegistrationTestsWithMutating,
  regId: number,
  test: TestCalendarCell,
  queryKey: QueryKey
) {
  const firstDate = (
    queryKey as { minimumDate: string; maximumDate: string }[]
  )[1].minimumDate
  const lastDate = (
    queryKey as { minimumDate: string; maximumDate: string }[]
  )[1].maximumDate

  const emptyDates: string[] = new Array(
    dayjs(lastDate).diff(firstDate, 'days') + 1
  )
    .fill(null)
    .map((_, i) => dayjs(firstDate).add(i, 'days').format('YYYY-MM-DD'))
    .filter(day => !record.tests.some(t => t.date === day))

  emptyDates.forEach(emptyDate =>
    record.tests.push({
      type: null,
      id: '',
      outcome: null,
      confirmedByGuest: false,
      regId,
      date: emptyDate,
      freeOfCharge: false,
      stayCapacity: test.stayCapacity,
      chargedInTransaction: 0,
      chargedAmount: 0,
      mutating: undefined,
      notes: ''
    })
  )
}
