import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Breadcrumbs,
  Heading2,
  Heading3,
  Paragraph,
  InfoContainer,
  InfoLink,
  Section,
  Hr,
  InfoPageLayout,
  LayoutSeparator,
  ConnectedPages,
  Link
} from '../../../../components/InfoTypography'

export function InfoProperDiet() {
  return (
    <InfoContainer style={{ wordBreak: 'break-word' }}>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <InfoLink target="">Information</InfoLink>
        <InfoLink target="practice-guide">Practice Guide</InfoLink>
        <InfoLink target="practice-guide/introduction">Introduction</InfoLink>
        <Link $disabled>4. Proper Diet</Link>
      </Breadcrumbs>
      <Heading2>4. Proper Diet</Heading2>
      <Hr />
      <InfoPageLayout>
        <Section>
          <Heading3>Vegetarian</Heading3>
          <Paragraph>
            The Yogic diet is a vegetarian one, consisting of pure, simple,
            natural foods which are easily digested and promote health. <br />
            Simple meals aid the digestion and assimilation of foods.
            <br /> Nutritional requirements fall under five categories: protein,
            carbohydrates, minerals, fats and vitamins. <br />
            One should have a certain knowledge of dietetics in order to balance
            their diet.
            <br /> Eating foods first-hand from nature, grown in fertile soil
            (preferably organic, free from chemicals and pesticides) will help
            ensure a better supply of these nutritional needs.
            <br /> Processing, refining, and overcooking destroy much food
            value.
          </Paragraph>
          <Paragraph>
            There is a cycle in Nature known as the “food cycle” or “food
            chain”.
            <br /> The Sun is the source of energy for all life on our planet;
            it nourished the plants (the top of the food chain) which are then
            eaten by animals (vegetarian), which are then eaten by other animals
            (carnivores).
            <br /> The food at the top of the food chain, being directly
            nourished by the Sun, has the greatest life promoting properties.
            <br /> The food value of animal flesh is termed a “second-hand”
            source of nutrition, and is inferior in nature.
            <br /> All natural foods (fruits, vegetables, seeds, nuts and
            grains) have, in varying quantities, different proportions of these
            essential nutrients.
            <br /> As a source of protein, these are easily assimilated by the
            body.
            <br />
            However, second-hand sources are often more difficult to digest and
            are of less value to the body’s metabolism.
            <br /> Many people worry about whether they are getting enough
            protein, but neglect other factors.
            <br /> The quality of the protein is more important than the
            quantity alone.
            <br /> Dairy products, legumes, nuts and seeds provide the
            vegetarian with an adequate supply of protein.
            <br /> The high protein requirement still being used by many Health
            Departments is based on antiquated data and has been scientifically
            disproved many times in the laboratory.
          </Paragraph>
          <Paragraph>
            A healthy motto is: “Eat to live, not live to eat.” It is best if we
            understand that the purpose of eating is to supply our being with
            the lifeforce, or Prana, the vital life energy.
            <br /> So the greatest nutritional plan for the Yoga student is the
            simple diet of natural fresh foods.
          </Paragraph>
          <Paragraph>
            However, the true Yogic diet is actually even more selective than
            this.
            <br /> The Yogi is concerned with the subtle effect that food has on
            his mind and astral body.
            <br /> He therefore avoids foods which are overly stimulating,
            preferring those which rener the mind calme and the intellect sharp.
            <br /> One who seriously takes to the path of Yoga would avoid
            ingesting meats, fish, eggs, garlic, coffee, tea (except herbal),
            alcohol and drugs.
          </Paragraph>
          <Paragraph>
            Any change in diet should be made gradually.
            <br /> Start by substituting larger portions of vegetables, grains,
            seeds and nuts until finally all flesh products have been completely
            eliminated from the diet.
          </Paragraph>
          <Paragraph>
            The Yogic diet will help you to attain a high standard of health,
            keen intellect and serenity of mind.
          </Paragraph>
        </Section>
        <ConnectedPages>
          <LayoutSeparator />
          <Section>
            <Paragraph>
              <strong>Connected Articles:</strong>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/introduction/five-points-of-yoga">
                General Introduction
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/introduction/proper-exercise">
                1. Proper Exercise
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/introduction/proper-breathing">
                2. Proper Breathing
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/introduction/proper-relaxation">
                3. Proper Relaxation
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/introduction/proper-thinking">
                5. Proper Thinking
              </InfoLink>
            </Paragraph>
          </Section>
        </ConnectedPages>
      </InfoPageLayout>
    </InfoContainer>
  )
}
