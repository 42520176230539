export type ProgramImage = {
  url: string
  width: number
  height: number
  alt: string
}

/**
 * Only applicable if the *pricing_type* is either **tiered** or **lodging**.
 *
 * This information is not exposed in the API and has to be scraped.
 *
 * - **frequency** - how many times to multiply the amount (usually set to 1)
 */
export type ProgramPackageBuilder = {
  package_builder?: {
    amount: number
    description: string
    category: string
    frequency: number | 'PER_NIGHT'
  }[]
}

/**
 * - **tiered** - Allows the Package Builder. In addition - allows different prices to be defined.
 * - **lodging** - Allows the Package Builder. In addition - allows to select lodging types and how to charge for them.
 * - **sliding-scale** - allow guest to choose their range
 * - **special** - free
 * - **suggested-donation** - guest chooses their donation. A label below details the suggested donation amount.
 *
 * The price set in the Package Builder is not exposed in the API
 */
export type ProgramPricing =
  | ({
      pricing_type: 'tiered'
      pricing_options: {
        price: string
        description: string
      }[]
    } & ProgramPackageBuilder)
  | ({
      pricing_type: 'lodging'
      pricing_options: {
        [lodgingKey: string]: {
          lodging_id: number
          lodging_url: string
          price: number
          name: string
          max_occupancy: number
          image_url: string
          description: string
        }
      }
    } & ProgramPackageBuilder)
  | {
      pricing_type: 'sliding-scale' | 'special' | 'suggested-donation'
      pricing_options: never[]
    }

export type ProgramBase = {
  id: number
  type: 'program'
  self_url: string
  name: string
  excerpt: string
  content: string
  date_type: 'fixed' | 'dateless' | 'hotel'
  start_date: string | null
  end_date: string | null
  list_until_date: string
  registration_open_date: string | null
  package_nights: number | null
  package_exclude_days: null
  public: boolean
  program_type: 'program'
  status: 'confirmed'
  post_status: 'publish'
  registrations_url: string
  transactions_url: string
  payments_url: string
  items_url: string
  teachers_url: string
  venue_uses_url: string
  categories: string[]
  notes: string[]
  external_code: string | null
  program_link: string
  registration_link: string | null
  admin_link: string
  location: string
  address: string
  available_spaces: null
  images: {
    thumbnail?: ProgramImage
    medium?: ProgramImage
    large?: ProgramImage
    full?: ProgramImage
  }
  program_info: {
    datetime_title: string
    datetime_details: string
    location_title: string
    location: string
    location_address_title: string
    contact_title: string
    contact_name: string
    contact_email: string
    contact_phone: string
    alternate_url: string
    extra_display_fields: string[]
    location_address: string
  }
  hide_from_marketplace: number
  deposit_percentage: number
  max_capacity: number | null
  currency: string
  online_event_page_link: string
  online_course_page_link: string
  is_online: boolean
  program_registration_status: 'open' | 'closed'
  program_has_capacity: boolean
  updated_at_local_time: string
  updated_at_gmt: string
}

export type Program = ProgramBase & ProgramPricing

export enum ProgramTypes {
  KARMA_YOGA = 'KARMA_YOGA',
  ATTC = 'ATTC',
  SPEAKER = 'SPEAKER',
  TTC = 'TTC',
  VISITING_STAFF = 'VISITING_STAFF',
  VACATION = 'VACATION',
  BABIES = 'BABIES',
  CHILDREN = 'CHILDREN',
  COMMUTERS = 'COMMUTERS',
  TTC_COMPLETION = 'TTC_COMPLETION'
}
