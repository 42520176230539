export const graphqlEndpoint = `${process.env.REACT_APP_SERVER_BASE_URL}/graphql`

const authentication = { bearer: '' }

export function setGQLAuthentication(bearer: string) {
  authentication.bearer = bearer
}

export function getFetchParams() {
  return {
    headers: {
      credentials: 'include',
      'content-type': 'application/json',
      authentication: authentication.bearer
    }
  }
}
