import * as React from 'react'
import { useForm } from 'react-hook-form'
import Link from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { observer } from 'mobx-react-lite'
import dayjs from 'dayjs'
import { Field, FormError, FormTextField } from '../../components/common/Forms'
import { PrimaryButton } from '../../components/common/Buttons'
import { StoreContext } from '@sayr/client-models'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import {
  isPasswordStrong,
  PasswordWrapper,
  ShowPasswordIcon
} from './ManualSignUpPage'
import { Form } from './LoginCommon'
import PasswordStrengthMeter from '../../components/common/PasswordStrengthMeter'

type FormInputs = {
  signupPassword: string
  repeatPassword: string
}

function ChangePasswordPage({ token }: { token: string }) {
  const store = React.useContext(StoreContext)
  const { exp, email } = jwtDecode<JwtPayload & { email: string }>(token)
  if (!exp) throw new Error('Token invalid')

  const tokenExpires = dayjs(exp * 1000)
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields, submitCount },
    setValue,
    trigger,
    watch
  } = useForm<FormInputs>({
    mode: 'onTouched'
  })

  async function onSubmit(data: FormInputs) {
    store.changePasswordWithToken(
      store.view.id || '',
      email,
      data.signupPassword
    )
  }

  const password = register('signupPassword', {
    required: true,
    validate: () => isPasswordStrong(passwordWatch)
  })

  const passwordWatch = watch('signupPassword', '')

  const repeatPassword = register('repeatPassword', {
    required: true,
    validate: value => value === passwordWatch
  })

  const [passwordHidden, setPasswordHidden] = React.useState(true)
  const [repeatPasswordHidden, setRepeatPasswordHidden] = React.useState(true)

  const [now, setNow] = React.useState(new Date())
  React.useEffect(() => {
    const interval = setInterval(() => setNow(new Date()), 1000)

    return () => clearInterval(interval)
  }, [])

  if (dayjs().isAfter(tokenExpires))
    return (
      <>
        <Typography
          style={{
            marginTop: '2rem',
            marginLeft: '1rem',
            marginBottom: '1rem'
          }}
          variant="h3"
        >
          Change your password
        </Typography>
        <Typography variant="body2">The token has expired.</Typography>
        <Link
          component="button"
          variant="body2"
          onClick={() => window.location.replace('/')}
        >
          Click here to try again.
        </Link>
      </>
    )

  return (
    <>
      <Typography
        style={{
          marginTop: '2rem',
          marginLeft: '1rem',
          marginBottom: '1rem'
        }}
        variant="h3"
      >
        Change your password
      </Typography>
      <Typography variant="body2">Choose a new password:</Typography>
      <Form style={{ marginLeft: '1rem' }} onSubmit={handleSubmit(onSubmit)}>
        <PasswordWrapper bottomSpacing={!!dirtyFields.signupPassword}>
          <Field style={{ flexBasis: '15.5rem', flexGrow: 0 }}>
            {/* this component is for the browser to pick the username when remembering passwords */}
            <FormTextField
              variant="outlined"
              fullWidth
              size="small"
              name="email"
              autoComplete="username"
              id="email"
              type="text"
              value={email}
              disabled
            />
          </Field>
          <Field style={{ flexBasis: '15.5rem', flexGrow: 0 }}>
            <Tooltip
              title={
                submitCount && errors.signupPassword
                  ? 'Choose a stronger password'
                  : ''
              }
            >
              <FormTextField
                autoFocus
                aria-describedby="password-strength-progress"
                variant="outlined"
                fullWidth
                size="small"
                label="Password"
                type={passwordHidden ? 'password' : 'text'}
                id="password"
                autoComplete="new-password"
                name={password.name}
                onChange={e => {
                  password.onChange(e)
                  // trigger validation on repeatPassword field
                  setTimeout(() => trigger('repeatPassword'))
                }}
                onBlur={password.onBlur}
                inputRef={password.ref}
              />
            </Tooltip>
            {dirtyFields.signupPassword && (
              <PasswordStrengthMeter password={passwordWatch} />
            )}
          </Field>
          <ShowPasswordIcon callback={setPasswordHidden} />
        </PasswordWrapper>
        <PasswordWrapper bottomSpacing={!!errors.repeatPassword}>
          <Field style={{ flexBasis: '15.5rem', flexGrow: 0 }}>
            <FormTextField
              variant="outlined"
              fullWidth
              size="small"
              label="Repeat Password"
              type={repeatPasswordHidden ? 'password' : 'text'}
              id="signup-repeat-password"
              autoComplete="new-password"
              name={repeatPassword.name}
              onChange={e => {
                repeatPassword.onChange(e)
                setValue('repeatPassword', e.target.value)
                setTimeout(() => trigger('repeatPassword'))
              }}
              onBlur={repeatPassword.onBlur}
              inputRef={repeatPassword.ref}
            />
            {errors.repeatPassword && (
              <FormError>
                {errors.repeatPassword.type === 'required'
                  ? 'Empty Password'
                  : 'The passwords do not match'}
              </FormError>
            )}
          </Field>
          <ShowPasswordIcon callback={setRepeatPasswordHidden} />
        </PasswordWrapper>

        <PrimaryButton style={{ width: '15.5rem' }} type="submit">
          Submit
        </PrimaryButton>
      </Form>
      <Typography>
        Token is valid for {dayjs(tokenExpires).diff(now, 'seconds')} seconds
      </Typography>
    </>
  )
}

export default observer(ChangePasswordPage)
