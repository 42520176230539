// @ts-nocheck
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { QueryBuilder } from 'mst-gql'
import { ModelBase } from './ModelBase'
import {
  AnnouncementStatsResponseModel,
  AnnouncementStatsResponseModelType
} from './AnnouncementStatsResponseModel'
import { AnnouncementStatsResponseModelSelector } from './AnnouncementStatsResponseModel.base'
import { AudienceEnumType } from './AudienceEnum'
import { RootStoreType } from './index'

/**
 * AnnouncementResponseBase
 * auto generated base class for the model AnnouncementResponseModel.
 */
export const AnnouncementResponseModelBase = ModelBase.named(
  'AnnouncementResponse'
)
  .props({
    __typename: types.optional(
      types.literal('AnnouncementResponse'),
      'AnnouncementResponse'
    ),
    id: types.identifier,
    subject: types.union(types.undefined, types.string),
    body: types.union(types.undefined, types.string),
    publishStart: types.union(types.undefined, types.frozen()),
    publishEnd: types.union(types.undefined, types.frozen()),
    sendAlert: types.union(types.undefined, types.boolean),
    highPriority: types.union(types.undefined, types.boolean),
    audience: types.union(types.undefined, AudienceEnumType),
    /** In case the audience is STUDENTS_IN_COURSE, this field designates the course ID. */
    audienceCourseId: types.union(types.undefined, types.null, types.integer),
    createdAt: types.union(types.undefined, types.frozen()),
    createdByUser: types.union(types.undefined, types.string),
    stats: types.union(
      types.undefined,
      types.array(types.late((): any => AnnouncementStatsResponseModel))
    )
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class AnnouncementResponseModelSelector extends QueryBuilder {
  get id() {
    return this.__attr(`id`)
  }
  get subject() {
    return this.__attr(`subject`)
  }
  get body() {
    return this.__attr(`body`)
  }
  get publishStart() {
    return this.__attr(`publishStart`)
  }
  get publishEnd() {
    return this.__attr(`publishEnd`)
  }
  get sendAlert() {
    return this.__attr(`sendAlert`)
  }
  get highPriority() {
    return this.__attr(`highPriority`)
  }
  get audience() {
    return this.__attr(`audience`)
  }
  get audienceCourseId() {
    return this.__attr(`audienceCourseId`)
  }
  get createdAt() {
    return this.__attr(`createdAt`)
  }
  get createdByUser() {
    return this.__attr(`createdByUser`)
  }
  stats(
    builder:
      | string
      | AnnouncementStatsResponseModelSelector
      | ((
          selector: AnnouncementStatsResponseModelSelector
        ) => AnnouncementStatsResponseModelSelector)
      | undefined
  ) {
    return this.__child(
      `stats`,
      AnnouncementStatsResponseModelSelector,
      builder
    )
  }
}
export function selectFromAnnouncementResponse() {
  return new AnnouncementResponseModelSelector()
}

export const announcementResponseModelPrimitives =
  selectFromAnnouncementResponse().subject.body.publishStart.publishEnd
    .sendAlert.highPriority.audience.audienceCourseId.createdAt.createdByUser
