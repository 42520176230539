import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Breadcrumbs,
  Heading2,
  Heading3,
  Paragraph,
  InfoContainer,
  InfoLink,
  Section,
  Hr,
  InfoPageLayout,
  LayoutSeparator,
  Link,
  ConnectedPages
} from '../../../../components/InfoTypography'

export function InfoMeditationIntroduction() {
  return (
    <InfoContainer style={{ wordBreak: 'break-word' }}>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <InfoLink target="">Information</InfoLink>
        <InfoLink target="practice-guide">Practice Guide</InfoLink>
        <InfoLink target="practice-guide/meditation">Meditation</InfoLink>
        <Link $disabled>Introduction</Link>
      </Breadcrumbs>
      <Heading2>An Introduction to the Technique of Meditation</Heading2>
      <Hr />
      <InfoPageLayout>
        <Section>
          <Heading3>What is Meditation?</Heading3>
          <Paragraph>
            Meditation is an experience that cannot be described, just as
            colours cannot be described to a blind man. All ordinary experience
            is limited by Time, Space and Causation. Our normal awareness and
            understanding do not transcend these bounds.
          </Paragraph>
          <Paragraph>
            Finite experience, which is measured in terms of past, present, and
            future, cannot be transcendental. Concepts of time are illusory, for
            they have no permanence. The present, immeasurably small and
            fleeting, cannot be grasped. Past and future and non-existent in the
            present. We live in illusion.
          </Paragraph>
          <Paragraph>
            The meditative state transcends all such limitations. In it there is
            neither past nor future, but only the consciousness of 'I am' in the
            eternal NOW. it is only possible when all mental modifications are
            stilled.
          </Paragraph>
          <Paragraph>
            The closest analogous state that we can experience is deep sleep, in
            which there is neither time, nor space, nor causation. Meditation,
            however, differs from deep sleep, for it works profound changes in
            the psyche. By curbing and stilling the oscillations of the mind,
            meditation brings mental peace.
          </Paragraph>
          <Heading3>Benefits of the Practice of Meditation</Heading3>
          <Paragraph>
            On the physical level, meditation helps to prolong the body’s
            anabolic process of growth and repair, and to reduce the catabolic
            or decaying process. Ordinarily the anabolic process predominates
            until the age of 18. From 18 to 35 there is balance between the two,
            and after 35 the catabolic process dominates. Meditation can
            significantly reduce the catabolic decline. This is because of the
            innate receptivity of body cells.{' '}
          </Paragraph>
          <Paragraph>
            Each of our body cells is governed by the instinctive subconscious
            mind. They have both an individual and a collective consciousness.
            When the thoughts and desires pour into the body, the cells are
            activated; the body always obeys the group demand. It has been
            scientifically proven that positive thoughts bring positive results
            to cells. As meditation brings about a prolonged positive state of
            mind, it rejuvenated body cells and retards decay.
          </Paragraph>
        </Section>
        <ConnectedPages>
          <LayoutSeparator />
          <Section>
            <Paragraph>
              <strong>Connected Articles:</strong>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/meditation/technique">
                Meditation Technique
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/introduction/proper-thinking">
                Proper Thinking
              </InfoLink>
            </Paragraph>
          </Section>
        </ConnectedPages>
      </InfoPageLayout>
    </InfoContainer>
  )
}
