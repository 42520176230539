import React from 'react'
import dayjs from 'dayjs'
import { Column } from 'react-table'
import Avatar from '@mui/material/Avatar'
import Link from '@mui/material/Link'
import styled from 'styled-components'

import { DataGrid } from '../../components/common/dataGrid/DataGrid'
import { DataGridContainer } from '../../components/common/dataGrid/DataGridStyles'

import { useGetDeparturesQuery } from '../../graphql/generated/typesAndHooks'

type DeparturesRow = {
  headshot: string
  firstName: string
  lastName: string
  rgLink: string
  spiritualName: string
  status: string
  room: string
  balance: number
  comments: string
}

const SmallAvatar = styled(Avatar)`
  width: 90%;
  height: 90%;
  border-radius: 50%;
`

const departuresColumns = [
  {
    Header: 'Avatar',
    accessor: 'headshot',
    Cell: React.memo(({ value }: { value: string }) => {
      return <SmallAvatar src={value} />
    }),
    width: 70,
    disableSortBy: true,
    disableResizing: true
  },
  {
    Header: 'RG Link',
    accessor: 'rgLink',
    width: 110,
    Cell: React.memo(({ value }: any) => (
      <Link href={value} target="_blank">
        Details
      </Link>
    ))
  },
  { Header: 'Status', accessor: 'status' },
  {
    Header: 'First Name',
    accessor: 'firstName'
  },
  {
    Header: 'Last Name',
    accessor: 'lastName'
  },
  {
    Header: 'Spiritual Name',
    accessor: 'spiritualName'
  },
  {
    Header: 'Room',
    accessor: 'room',
    width: 140
  },
  { Header: 'Arrival Date', accessor: 'arrivalDate' },
  {
    Header: 'Balance',
    accessor: 'balance',
    width: 100
  },
  { Header: 'Comments', accessor: 'comments' },
  { Header: 'Nights', accessor: 'nights', width: 80 }
] as Column<DeparturesRow>[]

export function DeparturesTomorrow() {
  const columns = React.useMemo(() => departuresColumns, [])

  const { data } = useGetDeparturesQuery({
    date: dayjs().add(1, 'day').format('YYYY-MM-DD')
  })

  if (!data) return <>...</>

  return (
    <DataGridContainer>
      <DataGrid
        columns={columns}
        data={data.getDepartures}
        nonSortable={false}
        disableResize={false}
        withGlobalFilter={true}
        removeSelectColumn
      />
    </DataGridContainer>
  )
}
