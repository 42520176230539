import { graphqlEndpoint, getFetchParams } from '../config'
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions
} from 'react-query'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}

function fetcher<TData, TVariables>(query: string, variables?: TVariables) {
  return async (): Promise<TData> => {
    const res = await fetch(graphqlEndpoint as string, {
      method: 'POST',
      ...getFetchParams(),
      body: JSON.stringify({ query, variables })
    })

    const json = await res.json()

    if (json.errors) {
      const { message } = json.errors[0]

      throw new Error(message)
    }

    return json.data
  }
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  DateTime: any
}

export type AnnouncementResponse = {
  __typename?: 'AnnouncementResponse'
  audience: Audience
  /** In case the audience is STUDENTS_IN_COURSE, this field designates the course ID. */
  audienceCourseId?: Maybe<Scalars['Int']>
  body: Scalars['String']
  createdAt: Scalars['DateTime']
  createdByUser: Scalars['String']
  highPriority: Scalars['Boolean']
  id: Scalars['ID']
  publishEnd: Scalars['DateTime']
  publishStart: Scalars['DateTime']
  sendAlert: Scalars['Boolean']
  stats: Array<AnnouncementStatsResponse>
  subject: Scalars['String']
}

export type AnnouncementStatsResponse = {
  __typename?: 'AnnouncementStatsResponse'
  person: PersonResponse
  readTimestamp?: Maybe<Scalars['DateTime']>
}

export type ArrivingRegResponse = {
  __typename?: 'ArrivingRegResponse'
  arrivalDate: Scalars['String']
  balance: Scalars['Float']
  comments: Scalars['String']
  departureDate: Scalars['String']
  firstName: Scalars['String']
  flightNumber: Scalars['String']
  fromRoom: Scalars['String']
  headshot: Scalars['String']
  id: Scalars['ID']
  landingTime: Scalars['String']
  lastName: Scalars['String']
  programType: Scalars['String']
  rgLink: Scalars['String']
  room: Scalars['String']
  roomSetUp: Scalars['String']
  spiritualName: Scalars['String']
  status: Scalars['String']
}

/** types of allowed audiences for an announcement */
export enum Audience {
  AllKarmaYogis = 'ALL_KARMA_YOGIS',
  AllResidents = 'ALL_RESIDENTS',
  AllResidentsAndCommuters = 'ALL_RESIDENTS_AND_COMMUTERS',
  AllResidentsGuests = 'ALL_RESIDENTS_GUESTS',
  AllResidentsGuestsNoChildren = 'ALL_RESIDENTS_GUESTS_NO_CHILDREN',
  AllSpeakers = 'ALL_SPEAKERS',
  AllStaff = 'ALL_STAFF',
  AllStaffKarmaYogis = 'ALL_STAFF_KARMA_YOGIS',
  StudentsInCourse = 'STUDENTS_IN_COURSE'
}

export type ChatConnection = {
  __typename?: 'ChatConnection'
  edges: Array<ChatEdge>
  pageInfo: PageInfo
}

export type ChatEdge = {
  __typename?: 'ChatEdge'
  /** Used in `before` and `after` args */
  cursor: Scalars['String']
  node: ChatResponse
}

export type ChatResponse = {
  __typename?: 'ChatResponse'
  id: Scalars['ID']
  lastReadTimestamp: Scalars['DateTime']
  messages: MessageConnection
  user: UserResponse
}

export type CompoundResponse = {
  __typename?: 'CompoundResponse'
  compoundStringified: Scalars['String']
}

/** types of tests */
export enum CovidTestType {
  CompanyAntigen = 'COMPANY_ANTIGEN',
  CompanyFastPcr = 'COMPANY_FAST_PCR',
  CompanyPcr = 'COMPANY_PCR',
  CompanySelfTestKit = 'COMPANY_SELF_TEST_KIT',
  InHouse = 'IN_HOUSE'
}

export type CreateAnnouncementInput = {
  audience: Audience
  /** In case the audience is STUDENTS_IN_COURSE, this field designates the course ID. */
  audienceCourseId?: InputMaybe<Scalars['Int']>
  body: Scalars['String']
  highPriority: Scalars['Boolean']
  publishEnd: Scalars['DateTime']
  publishStart?: InputMaybe<Scalars['DateTime']>
  sendAlert: Scalars['Boolean']
  subject: Scalars['String']
}

export type InHouseRegResponse = {
  __typename?: 'InHouseRegResponse'
  arrivalDate: Scalars['String']
  balance: Scalars['Float']
  comments: Scalars['String']
  departureDate: Scalars['String']
  firstName: Scalars['String']
  headshot: Scalars['String']
  id: Scalars['ID']
  lastName: Scalars['String']
  programType: Scalars['String']
  rgLink: Scalars['String']
  room: Scalars['String']
  spiritualName: Scalars['String']
  status: Scalars['String']
}

export type ItemIdResponse = {
  __typename?: 'ItemIdResponse'
  id: Scalars['Int']
}

export type LoginResponse = {
  __typename?: 'LoginResponse'
  accessToken: Scalars['String']
  user: UserResponse
}

export type MessageConnection = {
  __typename?: 'MessageConnection'
  edges: Array<MessageEdge>
  pageInfo: PageInfo
}

export type MessageEdge = {
  __typename?: 'MessageEdge'
  /** Used in `before` and `after` args */
  cursor: Scalars['String']
  node: MessageResponse
}

export type MessageResponse = {
  __typename?: 'MessageResponse'
  _id: Scalars['String']
  author: UserResponse
  chat: UserResponse
  content: Scalars['String']
}

export type MonitorSetupResponse = {
  __typename?: 'MonitorSetupResponse'
  id: Scalars['Int']
  name: Scalars['String']
  type: MonitoringTypes
}

export type MonitoredAttributeMetaDataInput = {
  displayed: Scalars['String']
  flagCountFrom: Scalars['String']
  key: Scalars['String']
  redFlagDay: Scalars['Int']
  yellowFlagDay: Scalars['Int']
}

export type MonitoredAttributeMetaDataResponse = {
  __typename?: 'MonitoredAttributeMetaDataResponse'
  displayed: Scalars['String']
  flagCountFrom: Scalars['String']
  key: Scalars['String']
  redFlagDay: Scalars['Int']
  yellowFlagDay: Scalars['Int']
}

export type MonitoredAttributeResponse = {
  __typename?: 'MonitoredAttributeResponse'
  key: Scalars['String']
  value: Scalars['Boolean']
}

/** identifier of entity to monitor */
export enum MonitoringTypes {
  Person = 'PERSON',
  Program = 'PROGRAM',
  Registration = 'REGISTRATION'
}

export type Mutation = {
  __typename?: 'Mutation'
  RegFollowUpUpdateMonitoredAttribute: Scalars['Boolean']
  /** post a simgle message into a chat */
  chatPostMessage: Scalars['Boolean']
  confirmAnnouncementRead: Scalars['Boolean']
  createAnnouncement: AnnouncementResponse
  regAdditionalDataSeasonCreate: Scalars['Boolean']
  regAdditionalDataSeasonDelete: Scalars['Boolean']
  registrationTestAdd: Scalars['Boolean']
  registrationTestDelete: Scalars['Boolean']
  registrationTestPush: Scalars['Boolean']
  registrationTestToggleCharge: Scalars['Boolean']
  registrationTestUpdate: Scalars['Boolean']
  updateAnnouncement: AnnouncementResponse
  userAddMonitoredEntity: Scalars['Boolean']
  /** Register new user by email and password */
  userCreateNew: Scalars['Boolean']
  userCreateSubscription: Scalars['Boolean']
  userDeleteMonitoredEntity: Scalars['Boolean']
  /**
   *
   *   Create an account for a user without a password.
   *   For staff with permission - they would have to sign in with a password or OAuth with a hint to their registered email.
   */
  userInvite: Scalars['Boolean']
  userLogin: LoginResponse
  userLogout: Scalars['Boolean']
  /** Issue an email, which once confirmed, the email will be chnaged */
  userPromptChangePassword: Scalars['Boolean']
  userRevokeRefreshTokensForUser: Scalars['Boolean']
  userSetWarnOnChangesToPastData: Scalars['Boolean']
  userUpdateNickname: Scalars['Boolean']
  userUpdatePermission: Array<UserResponse>
  userUpdateTrackWarning: Scalars['Boolean']
  warningUpdate: Scalars['Boolean']
}

export type MutationRegFollowUpUpdateMonitoredAttributeArgs = {
  attribute: Scalars['String']
  regId: Scalars['Int']
  value: Scalars['Boolean']
}

export type MutationChatPostMessageArgs = {
  newMessage: NewMessageInput
}

export type MutationConfirmAnnouncementReadArgs = {
  announcementId: Scalars['String']
}

export type MutationCreateAnnouncementArgs = {
  newAnnouncement: CreateAnnouncementInput
}

export type MutationRegAdditionalDataSeasonCreateArgs = {
  newSeason: NewSeasonInput
}

export type MutationRegAdditionalDataSeasonDeleteArgs = {
  _id: Scalars['String']
}

export type MutationRegistrationTestAddArgs = {
  date: Scalars['String']
  regId: Scalars['Int']
  type: Scalars['String']
}

export type MutationRegistrationTestDeleteArgs = {
  id: Scalars['String']
}

export type MutationRegistrationTestPushArgs = {
  direction: Scalars['String']
  id: Scalars['String']
}

export type MutationRegistrationTestToggleChargeArgs = {
  id: Scalars['String']
}

export type MutationRegistrationTestUpdateArgs = {
  confirmedByGuest?: InputMaybe<Scalars['Boolean']>
  freeOfCharge?: InputMaybe<Scalars['Boolean']>
  id: Scalars['String']
  notes?: InputMaybe<Scalars['String']>
  outcome?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
}

export type MutationUpdateAnnouncementArgs = {
  changeArgs: UpdateAnnouncementInput
}

export type MutationUserAddMonitoredEntityArgs = {
  id: Scalars['Float']
  monitoringType: Scalars['String']
}

export type MutationUserCreateNewArgs = {
  email: Scalars['String']
  password: Scalars['String']
}

export type MutationUserCreateSubscriptionArgs = {
  newSubscription: NewSubscriptionInput
}

export type MutationUserDeleteMonitoredEntityArgs = {
  id: Scalars['Float']
  monitoringType: Scalars['String']
}

export type MutationUserInviteArgs = {
  email: Scalars['String']
}

export type MutationUserLoginArgs = {
  email: Scalars['String']
  password: Scalars['String']
}

export type MutationUserPromptChangePasswordArgs = {
  email: Scalars['String']
}

export type MutationUserRevokeRefreshTokensForUserArgs = {
  userId: Scalars['String']
}

export type MutationUserSetWarnOnChangesToPastDataArgs = {
  status: Scalars['Boolean']
}

export type MutationUserUpdateNicknameArgs = {
  nickname: Scalars['String']
}

export type MutationUserUpdatePermissionArgs = {
  newPermissionSet: NewPermissionsInput
  userId: Scalars['String']
}

export type MutationUserUpdateTrackWarningArgs = {
  sendAlert?: InputMaybe<Scalars['Boolean']>
  value?: InputMaybe<Scalars['Boolean']>
  warningType: Scalars['String']
}

export type MutationWarningUpdateArgs = {
  ignoreState?: InputMaybe<Scalars['String']>
  notes?: InputMaybe<Scalars['String']>
  regId: Scalars['Float']
  type: Scalars['String']
}

export type NewMessageInput = {
  chatId: Scalars['String']
  content: Scalars['String']
}

export type NewPermissionsInput = {
  accessWarnings?: InputMaybe<Scalars['Boolean']>
  accounting?: InputMaybe<Scalars['Boolean']>
  adminFollowUpRead?: InputMaybe<Scalars['Boolean']>
  adminFollowUpWrite?: InputMaybe<Scalars['Boolean']>
  chatAsFrontDesk?: InputMaybe<Scalars['Boolean']>
  generalPopulation?: InputMaybe<Scalars['Boolean']>
  inviteToApp?: InputMaybe<Scalars['Boolean']>
  manageAnnouncements?: InputMaybe<Scalars['Boolean']>
  sendEmails?: InputMaybe<Scalars['Boolean']>
  upgradeOtherUsers?: InputMaybe<Scalars['Boolean']>
  viewPeopleLists?: InputMaybe<Scalars['Boolean']>
}

export type NewSeasonInput = {
  arrivalDateEnd: Scalars['String']
  arrivalDateStart: Scalars['String']
  cost?: InputMaybe<Scalars['Float']>
  dayGap?: InputMaybe<Scalars['Int']>
  daysOfWeek?: InputMaybe<Array<Scalars['Int']>>
  endDate: Scalars['String']
  monitoredAttributes?: InputMaybe<Array<MonitoredAttributeMetaDataInput>>
  precedence: Scalars['Int']
  programTypes: Array<Scalars['String']>
  seasonDateClassification: Scalars['String']
  seasonType: SeasonTypes
  startDate: Scalars['String']
  testType?: InputMaybe<CovidTestType>
}

export type NewSubscriptionInput = {
  authKey: Scalars['String']
  endpoint: Scalars['String']
  p256dhKey: Scalars['String']
  userAgent: Scalars['String']
  userId: Scalars['String']
}

export type PageInfo = {
  __typename?: 'PageInfo'
  endCursor?: Maybe<Scalars['String']>
  hasNextPage: Scalars['Boolean']
  hasPreviousPage: Scalars['Boolean']
  startCursor?: Maybe<Scalars['String']>
}

export type PermissionsResponse = {
  __typename?: 'PermissionsResponse'
  accessWarnings?: Maybe<Scalars['Boolean']>
  accounting?: Maybe<Scalars['Boolean']>
  adminFollowUpRead?: Maybe<Scalars['Boolean']>
  adminFollowUpWrite?: Maybe<Scalars['Boolean']>
  chatAsFrontDesk?: Maybe<Scalars['Boolean']>
  generalPopulation?: Maybe<Scalars['Boolean']>
  inviteToApp?: Maybe<Scalars['Boolean']>
  manageAnnouncements?: Maybe<Scalars['Boolean']>
  sendEmails?: Maybe<Scalars['Boolean']>
  upgradeOtherUsers?: Maybe<Scalars['Boolean']>
  viewPeopleLists?: Maybe<Scalars['Boolean']>
}

export type PersonResponse = {
  __typename?: 'PersonResponse'
  _id?: Maybe<Scalars['String']>
  email: Scalars['String']
  name: Scalars['String']
}

export type PopulationResponse = {
  __typename?: 'PopulationResponse'
  ATTC: Scalars['Int']
  BABIES: Scalars['Int']
  CHILDREN: Scalars['Int']
  COMMUTERS: Scalars['Int']
  KARMA_YOGA: Scalars['Int']
  SPEAKER: Scalars['Int']
  TTC: Scalars['Int']
  VACATION: Scalars['Int']
  VISITING_STAFF: Scalars['Int']
  date: Scalars['String']
  total: Scalars['Int']
}

export type ProgramResponse = {
  __typename?: 'ProgramResponse'
  _categories?: Maybe<Array<Scalars['String']>>
  admin_link?: Maybe<Scalars['String']>
  categories: Array<ProgramTypes>
  content?: Maybe<Scalars['String']>
  date_type?: Maybe<Scalars['String']>
  end_date?: Maybe<Scalars['String']>
  excerpt?: Maybe<Scalars['String']>
  external_code?: Maybe<Scalars['String']>
  id: Scalars['ID']
  list_until_date?: Maybe<Scalars['String']>
  location?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  notes?: Maybe<Array<Scalars['String']>>
  program_link?: Maybe<Scalars['String']>
  registration_link?: Maybe<Scalars['String']>
  self_url?: Maybe<Scalars['String']>
  start_date?: Maybe<Scalars['String']>
}

/** types of program categories */
export enum ProgramTypes {
  Attc = 'ATTC',
  Babies = 'BABIES',
  Children = 'CHILDREN',
  Commuters = 'COMMUTERS',
  KarmaYoga = 'KARMA_YOGA',
  Speaker = 'SPEAKER',
  Ttc = 'TTC',
  TtcCompletion = 'TTC_COMPLETION',
  Vacation = 'VACATION',
  VisitingStaff = 'VISITING_STAFF'
}

export type Query = {
  __typename?: 'Query'
  RegFollowUpGetArrivals: Array<RegFollowUpResponse>
  RegFollowUpGetInHouse: Array<RegFollowUpResponse>
  /** load messages for chat */
  chatGetMessages: ChatResponse
  /** get recent active chats connection */
  chatGetRecentChats: ChatConnection
  getActiveAnnouncements: Array<AnnouncementResponse>
  /** Get all seasons sorted by precedence DESC */
  getAllSeasons: Array<RegAdditionalDataSeasonResponse>
  getArchivedAnnouncements: Array<AnnouncementResponse>
  getArrivalRegs: Array<ArrivingRegResponse>
  getArrivingSoon: Array<CompoundResponse>
  getDepartures: Array<InHouseRegResponse>
  getGeneralPopulation: Array<PopulationResponse>
  getInHouse: Array<CompoundResponse>
  getInHouseRegs: Array<InHouseRegResponse>
  getLodgingPrograms: Array<ProgramResponse>
  getRegistrationTests: Array<RegistrationTests>
  getStaysWithinDates: Array<CompoundResponse>
  getVoucherTransactions: Array<ItemIdResponse>
  /** is the emailer ready, in terms of active authorized Google account configured to send email with the proper permissions */
  isUserConfirmedAsEmailer: Scalars['Boolean']
  /**
   *
   *   check if a user exists AND has a password, even if unverified.
   *   In case a user was invited or has only logged in via OAuth - this query considers the person as non-existent, meaning they can manual register with a new password.
   *
   */
  userDoesExist: Scalars['Boolean']
  userGetMonitorSetup: Array<MonitorSetupResponse>
  userGetTrackSetup: Array<TrackSetupResponse>
  userGetWarnOnChangesToPastDataStatus: Scalars['Boolean']
  /** get a list of all users */
  users: Array<UserResponse>
  warningsGet: Array<WarningResponse>
}

export type QueryRegFollowUpGetArrivalsArgs = {
  days: Scalars['Int']
}

export type QueryChatGetMessagesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  chatId: Scalars['String']
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryChatGetRecentChatsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryGetArrivalRegsArgs = {
  date: Scalars['String']
}

export type QueryGetDeparturesArgs = {
  date: Scalars['String']
}

export type QueryGetGeneralPopulationArgs = {
  maximumDate: Scalars['String']
  minimumDate: Scalars['String']
}

export type QueryGetRegistrationTestsArgs = {
  maximumDate: Scalars['String']
  minimumDate: Scalars['String']
  onlyDoneOnFilter: Scalars['String']
  onlyNotDoneOnFilter: Scalars['String']
  programFilter: Array<ProgramTypes>
  typesFilter: Array<CovidTestType>
}

export type QueryGetStaysWithinDatesArgs = {
  from: Scalars['String']
  to: Scalars['String']
}

export type QueryUserDoesExistArgs = {
  email: Scalars['String']
}

export type RegAdditionalDataSeasonResponse = {
  __typename?: 'RegAdditionalDataSeasonResponse'
  _id: Scalars['ID']
  arrivalDateEnd: Scalars['String']
  arrivalDateStart: Scalars['String']
  cost?: Maybe<Scalars['Float']>
  createdByUser: UserResponse
  dayGap?: Maybe<Scalars['Int']>
  daysOfWeek?: Maybe<Array<Scalars['Int']>>
  deleted: Scalars['Boolean']
  endDate: Scalars['String']
  monitoredAttributes?: Maybe<Array<MonitoredAttributeMetaDataResponse>>
  precedence: Scalars['Int']
  programTypes: Array<ProgramTypes>
  seasonDateClassification: Scalars['String']
  seasonType: SeasonTypes
  startDate: Scalars['String']
  testType?: Maybe<CovidTestType>
}

export type RegFollowUpResponse = {
  __typename?: 'RegFollowUpResponse'
  adminLink: Scalars['String']
  arrivalDate: Scalars['String']
  balance: Scalars['Float']
  departureDate: Scalars['String']
  firstName: Scalars['String']
  flightNumber: Scalars['String']
  flightTime: Scalars['String']
  headshot: Scalars['String']
  lastName: Scalars['String']
  monitoredAttributes: Array<MonitoredAttributeResponse>
  personId?: Maybe<Scalars['Int']>
  program: Scalars['String']
  programTypes: Array<ProgramTypes>
  regId: Scalars['Int']
  registrationNotes: Scalars['String']
  room: Scalars['String']
  spiritualName: Scalars['String']
}

export type RegistrationTests = {
  __typename?: 'RegistrationTests'
  arrivalDate: Scalars['String']
  departureDate: Scalars['String']
  email: Scalars['String']
  name: Scalars['String']
  regId: Scalars['Int']
  rgLink: Scalars['String']
  tests: Array<SingleTestResponse>
}

/** types of seasons */
export enum SeasonTypes {
  CovidTestCosts = 'COVID_TEST_COSTS',
  CovidTestFrequencyDaysOfWeek = 'COVID_TEST_FREQUENCY_DAYS_OF_WEEK',
  CovidTestFrequencyFixedGap = 'COVID_TEST_FREQUENCY_FIXED_GAP',
  MonitoredAttributes = 'MONITORED_ATTRIBUTES'
}

export type SingleTestResponse = {
  __typename?: 'SingleTestResponse'
  chargedAmount: Scalars['Float']
  chargedInTransaction?: Maybe<Scalars['Int']>
  confirmedByGuest: Scalars['Boolean']
  date: Scalars['String']
  freeOfCharge: Scalars['Boolean']
  id: Scalars['ID']
  notes: Scalars['String']
  outcome?: Maybe<Scalars['Boolean']>
  regId: Scalars['Int']
  stayCapacity?: Maybe<ProgramTypes>
  type?: Maybe<CovidTestType>
}

export type Subscription = {
  __typename?: 'Subscription'
  chatNewMessageInAnyChat: MessageEdge
  chatNewMessageInMyChat: MessageEdge
}

export type TrackSetupResponse = {
  __typename?: 'TrackSetupResponse'
  sendAlert: Scalars['Boolean']
  type: WarningTypes
}

export type UpdateAnnouncementInput = {
  id: Scalars['ID']
  publishEnd?: InputMaybe<Scalars['DateTime']>
  sendAlert?: InputMaybe<Scalars['Boolean']>
}

export type UserResponse = {
  __typename?: 'UserResponse'
  email: Scalars['String']
  googleDisplayName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  nickName?: Maybe<Scalars['String']>
  permissions?: Maybe<PermissionsResponse>
  profilePhoto?: Maybe<Scalars['String']>
}

export type WarningResponse = {
  __typename?: 'WarningResponse'
  active: Scalars['Boolean']
  arrivalDate: Scalars['String']
  discoveredOn: Scalars['DateTime']
  explanation: Scalars['String']
  lodging: Scalars['String']
  notes: Scalars['String']
  registrationComments: Scalars['String']
  registrationID: Scalars['Int']
  registrationLink: Scalars['String']
  registrationName: Scalars['String']
  type: WarningTypes
}

/** types of warnings to track */
export enum WarningTypes {
  GapInStay = 'GAP_IN_STAY',
  IncorrectRegStatus = 'INCORRECT_REG_STATUS',
  InvalidData = 'INVALID_DATA',
  OceanviewWithoutBedArrangement = 'OCEANVIEW_WITHOUT_BED_ARRANGEMENT',
  OpenBalance = 'OPEN_BALANCE',
  OvercrowdedRoom = 'OVERCROWDED_ROOM',
  PlannedRoomMove = 'PLANNED_ROOM_MOVE',
  UnmatchedRegistration = 'UNMATCHED_REGISTRATION',
  WrongCharge = 'WRONG_CHARGE'
}

export type GetArrivalRegsQueryVariables = Exact<{
  date: Scalars['String']
}>

export type GetArrivalRegsQuery = {
  __typename?: 'Query'
  getArrivalRegs: Array<{
    __typename?: 'ArrivingRegResponse'
    balance: number
    comments: string
    arrivalDate: string
    departureDate: string
    firstName: string
    flightNumber: string
    fromRoom: string
    headshot: string
    id: string
    landingTime: string
    lastName: string
    rgLink: string
    programType: string
    room: string
    roomSetUp: string
    spiritualName: string
    status: string
  }>
}

export type GetDeparturesQueryVariables = Exact<{
  date: Scalars['String']
}>

export type GetDeparturesQuery = {
  __typename?: 'Query'
  getDepartures: Array<{
    __typename?: 'InHouseRegResponse'
    arrivalDate: string
    balance: number
    comments: string
    departureDate: string
    firstName: string
    headshot: string
    id: string
    lastName: string
    programType: string
    rgLink: string
    room: string
    spiritualName: string
    status: string
  }>
}

export type GetInHouseRegsQueryVariables = Exact<{ [key: string]: never }>

export type GetInHouseRegsQuery = {
  __typename?: 'Query'
  getInHouseRegs: Array<{
    __typename?: 'InHouseRegResponse'
    arrivalDate: string
    balance: number
    comments: string
    departureDate: string
    firstName: string
    headshot: string
    id: string
    lastName: string
    programType: string
    rgLink: string
    room: string
    spiritualName: string
    status: string
  }>
}

export type RegFollowUpGetArrivalsQueryVariables = Exact<{
  days: Scalars['Int']
}>

export type RegFollowUpGetArrivalsQuery = {
  __typename?: 'Query'
  RegFollowUpGetArrivals: Array<{
    __typename?: 'RegFollowUpResponse'
    regId: number
    personId?: number | null
    adminLink: string
    arrivalDate: string
    balance: number
    departureDate: string
    firstName: string
    flightNumber: string
    flightTime: string
    headshot: string
    lastName: string
    program: string
    programTypes: Array<ProgramTypes>
    registrationNotes: string
    room: string
    spiritualName: string
    monitoredAttributes: Array<{
      __typename?: 'MonitoredAttributeResponse'
      key: string
      value: boolean
    }>
  }>
}

export type RegFollowUpGetInHouseQueryVariables = Exact<{
  [key: string]: never
}>

export type RegFollowUpGetInHouseQuery = {
  __typename?: 'Query'
  RegFollowUpGetInHouse: Array<{
    __typename?: 'RegFollowUpResponse'
    regId: number
    personId?: number | null
    adminLink: string
    arrivalDate: string
    balance: number
    departureDate: string
    firstName: string
    flightNumber: string
    flightTime: string
    headshot: string
    lastName: string
    program: string
    programTypes: Array<ProgramTypes>
    registrationNotes: string
    room: string
    spiritualName: string
    monitoredAttributes: Array<{
      __typename?: 'MonitoredAttributeResponse'
      key: string
      value: boolean
    }>
  }>
}

export type RegFollowUpUpdateMonitoredAttributeMutationVariables = Exact<{
  value: Scalars['Boolean']
  attribute: Scalars['String']
  regId: Scalars['Int']
}>

export type RegFollowUpUpdateMonitoredAttributeMutation = {
  __typename?: 'Mutation'
  RegFollowUpUpdateMonitoredAttribute: boolean
}

export type GetAllSeasonsQueryVariables = Exact<{ [key: string]: never }>

export type GetAllSeasonsQuery = {
  __typename?: 'Query'
  getAllSeasons: Array<{
    __typename?: 'RegAdditionalDataSeasonResponse'
    seasonType: SeasonTypes
    cost?: number | null
    testType?: CovidTestType | null
    dayGap?: number | null
    daysOfWeek?: Array<number> | null
    precedence: number
    programTypes: Array<ProgramTypes>
    seasonDateClassification: string
    arrivalDateStart: string
    arrivalDateEnd: string
    startDate: string
    endDate: string
    deleted: boolean
    monitoredAttributes?: Array<{
      __typename?: 'MonitoredAttributeMetaDataResponse'
      displayed: string
      key: string
      yellowFlagDay: number
      redFlagDay: number
      flagCountFrom: string
    }> | null
  }>
}

export type GetAllSeasonsWithIdQueryVariables = Exact<{ [key: string]: never }>

export type GetAllSeasonsWithIdQuery = {
  __typename?: 'Query'
  getAllSeasons: Array<{
    __typename?: 'RegAdditionalDataSeasonResponse'
    _id: string
    seasonType: SeasonTypes
    cost?: number | null
    testType?: CovidTestType | null
    dayGap?: number | null
    daysOfWeek?: Array<number> | null
    precedence: number
    deleted: boolean
    programTypes: Array<ProgramTypes>
    seasonDateClassification: string
    arrivalDateStart: string
    arrivalDateEnd: string
    startDate: string
    endDate: string
    createdByUser: {
      __typename?: 'UserResponse'
      email: string
      id: string
      nickName?: string | null
      profilePhoto?: string | null
    }
    monitoredAttributes?: Array<{
      __typename?: 'MonitoredAttributeMetaDataResponse'
      displayed: string
      key: string
      yellowFlagDay: number
      redFlagDay: number
      flagCountFrom: string
    }> | null
  }>
}

export type GetGeneralPopulationQueryVariables = Exact<{
  maximumDate: Scalars['String']
  minimumDate: Scalars['String']
}>

export type GetGeneralPopulationQuery = {
  __typename?: 'Query'
  getGeneralPopulation: Array<{
    __typename?: 'PopulationResponse'
    date: string
    total: number
    VACATION: number
    ATTC: number
    BABIES: number
    CHILDREN: number
    COMMUTERS: number
    KARMA_YOGA: number
    SPEAKER: number
    TTC: number
    VISITING_STAFF: number
  }>
}

export type IsUserConfirmedAsEmailerQueryVariables = Exact<{
  [key: string]: never
}>

export type IsUserConfirmedAsEmailerQuery = {
  __typename?: 'Query'
  isUserConfirmedAsEmailer: boolean
}

export type RegAdditionalDataSeasonCreateMutationVariables = Exact<{
  newSeason: NewSeasonInput
}>

export type RegAdditionalDataSeasonCreateMutation = {
  __typename?: 'Mutation'
  regAdditionalDataSeasonCreate: boolean
}

export type RegAdditionalDataSeasonDeleteMutationVariables = Exact<{
  id: Scalars['String']
}>

export type RegAdditionalDataSeasonDeleteMutation = {
  __typename?: 'Mutation'
  regAdditionalDataSeasonDelete: boolean
}

export type GetRegistrationTestsQueryVariables = Exact<{
  onlyNotDoneOnFilter: Scalars['String']
  onlyDoneOnFilter: Scalars['String']
  typesFilter: Array<CovidTestType> | CovidTestType
  programFilter: Array<ProgramTypes> | ProgramTypes
  minimumDate: Scalars['String']
  maximumDate: Scalars['String']
}>

export type GetRegistrationTestsQuery = {
  __typename?: 'Query'
  getRegistrationTests: Array<{
    __typename?: 'RegistrationTests'
    name: string
    email: string
    regId: number
    rgLink: string
    arrivalDate: string
    departureDate: string
    tests: Array<{
      __typename?: 'SingleTestResponse'
      id: string
      date: string
      stayCapacity?: ProgramTypes | null
      outcome?: boolean | null
      type?: CovidTestType | null
      regId: number
      chargedAmount: number
      chargedInTransaction?: number | null
      confirmedByGuest: boolean
      freeOfCharge: boolean
      notes: string
    }>
  }>
}

export type RegistrationTestAddMutationVariables = Exact<{
  type: Scalars['String']
  date: Scalars['String']
  regId: Scalars['Int']
}>

export type RegistrationTestAddMutation = {
  __typename?: 'Mutation'
  registrationTestAdd: boolean
}

export type RegistrationTestDeleteMutationVariables = Exact<{
  id: Scalars['String']
}>

export type RegistrationTestDeleteMutation = {
  __typename?: 'Mutation'
  registrationTestDelete: boolean
}

export type RegistrationTestUpdateMutationVariables = Exact<{
  id: Scalars['String']
  type?: InputMaybe<Scalars['String']>
  confirmedByGuest?: InputMaybe<Scalars['Boolean']>
  freeOfCharge?: InputMaybe<Scalars['Boolean']>
  outcome?: InputMaybe<Scalars['String']>
  notes?: InputMaybe<Scalars['String']>
}>

export type RegistrationTestUpdateMutation = {
  __typename?: 'Mutation'
  registrationTestUpdate: boolean
}

export type RegistrationTestPushMutationVariables = Exact<{
  direction: Scalars['String']
  id: Scalars['String']
}>

export type RegistrationTestPushMutation = {
  __typename?: 'Mutation'
  registrationTestPush: boolean
}

export type RegistrationTestToggleChargeMutationVariables = Exact<{
  id: Scalars['String']
}>

export type RegistrationTestToggleChargeMutation = {
  __typename?: 'Mutation'
  registrationTestToggleCharge: boolean
}

export type UserGetTrackSetupQueryVariables = Exact<{ [key: string]: never }>

export type UserGetTrackSetupQuery = {
  __typename?: 'Query'
  userGetTrackSetup: Array<{
    __typename?: 'TrackSetupResponse'
    type: WarningTypes
    sendAlert: boolean
  }>
}

export type UserUpdateTrackWarningMutationVariables = Exact<{
  warningType: Scalars['String']
  value?: InputMaybe<Scalars['Boolean']>
  sendAlert?: InputMaybe<Scalars['Boolean']>
}>

export type UserUpdateTrackWarningMutation = {
  __typename?: 'Mutation'
  userUpdateTrackWarning: boolean
}

export type UserGetMonitorSetupQueryVariables = Exact<{ [key: string]: never }>

export type UserGetMonitorSetupQuery = {
  __typename?: 'Query'
  userGetMonitorSetup: Array<{
    __typename?: 'MonitorSetupResponse'
    type: MonitoringTypes
    id: number
    name: string
  }>
}

export type UserAddMonitoredEntityMutationVariables = Exact<{
  entityId: Scalars['Float']
  monitoringType: Scalars['String']
}>

export type UserAddMonitoredEntityMutation = {
  __typename?: 'Mutation'
  userAddMonitoredEntity: boolean
}

export type UserDeleteMonitoredEntityMutationVariables = Exact<{
  entityId: Scalars['Float']
  monitoringType: Scalars['String']
}>

export type UserDeleteMonitoredEntityMutation = {
  __typename?: 'Mutation'
  userDeleteMonitoredEntity: boolean
}

export type UserGetWarnOnChangesToPastDataStatusQueryVariables = Exact<{
  [key: string]: never
}>

export type UserGetWarnOnChangesToPastDataStatusQuery = {
  __typename?: 'Query'
  userGetWarnOnChangesToPastDataStatus: boolean
}

export type UserSetWarnOnChangesToPastDataMutationVariables = Exact<{
  status: Scalars['Boolean']
}>

export type UserSetWarnOnChangesToPastDataMutation = {
  __typename?: 'Mutation'
  userSetWarnOnChangesToPastData: boolean
}

export type WarningsGetQueryVariables = Exact<{ [key: string]: never }>

export type WarningsGetQuery = {
  __typename?: 'Query'
  warningsGet: Array<{
    __typename?: 'WarningResponse'
    type: WarningTypes
    discoveredOn: any
    explanation: string
    notes: string
    registrationName: string
    registrationLink: string
    registrationID: number
    lodging: string
    arrivalDate: string
    registrationComments: string
    active: boolean
  }>
}

export type WarningUpdateMutationVariables = Exact<{
  regId: Scalars['Float']
  type: Scalars['String']
  ignoreState?: InputMaybe<Scalars['String']>
  notes?: InputMaybe<Scalars['String']>
}>

export type WarningUpdateMutation = {
  __typename?: 'Mutation'
  warningUpdate: boolean
}

export type UserCreateSubscriptionMutationVariables = Exact<{
  newSubscription: NewSubscriptionInput
}>

export type UserCreateSubscriptionMutation = {
  __typename?: 'Mutation'
  userCreateSubscription: boolean
}

export const GetArrivalRegsDocument = `
    query GetArrivalRegs($date: String!) {
  getArrivalRegs(date: $date) {
    balance
    comments
    arrivalDate
    departureDate
    firstName
    flightNumber
    fromRoom
    headshot
    id
    landingTime
    lastName
    rgLink
    programType
    rgLink
    room
    roomSetUp
    spiritualName
    status
  }
}
    `
export const useGetArrivalRegsQuery = <
  TData = GetArrivalRegsQuery,
  TError = unknown
>(
  variables: GetArrivalRegsQueryVariables,
  options?: UseQueryOptions<GetArrivalRegsQuery, TError, TData>
) =>
  useQuery<GetArrivalRegsQuery, TError, TData>(
    ['GetArrivalRegs', variables],
    fetcher<GetArrivalRegsQuery, GetArrivalRegsQueryVariables>(
      GetArrivalRegsDocument,
      variables
    ),
    options
  )

useGetArrivalRegsQuery.getKey = (variables: GetArrivalRegsQueryVariables) => [
  'GetArrivalRegs',
  variables
]
export const GetDeparturesDocument = `
    query GetDepartures($date: String!) {
  getDepartures(date: $date) {
    arrivalDate
    balance
    comments
    departureDate
    firstName
    headshot
    id
    lastName
    programType
    rgLink
    room
    spiritualName
    status
  }
}
    `
export const useGetDeparturesQuery = <
  TData = GetDeparturesQuery,
  TError = unknown
>(
  variables: GetDeparturesQueryVariables,
  options?: UseQueryOptions<GetDeparturesQuery, TError, TData>
) =>
  useQuery<GetDeparturesQuery, TError, TData>(
    ['GetDepartures', variables],
    fetcher<GetDeparturesQuery, GetDeparturesQueryVariables>(
      GetDeparturesDocument,
      variables
    ),
    options
  )

useGetDeparturesQuery.getKey = (variables: GetDeparturesQueryVariables) => [
  'GetDepartures',
  variables
]
export const GetInHouseRegsDocument = `
    query GetInHouseRegs {
  getInHouseRegs {
    arrivalDate
    balance
    comments
    departureDate
    firstName
    headshot
    id
    lastName
    programType
    rgLink
    room
    spiritualName
    status
  }
}
    `
export const useGetInHouseRegsQuery = <
  TData = GetInHouseRegsQuery,
  TError = unknown
>(
  variables?: GetInHouseRegsQueryVariables,
  options?: UseQueryOptions<GetInHouseRegsQuery, TError, TData>
) =>
  useQuery<GetInHouseRegsQuery, TError, TData>(
    variables === undefined
      ? ['GetInHouseRegs']
      : ['GetInHouseRegs', variables],
    fetcher<GetInHouseRegsQuery, GetInHouseRegsQueryVariables>(
      GetInHouseRegsDocument,
      variables
    ),
    options
  )

useGetInHouseRegsQuery.getKey = (variables?: GetInHouseRegsQueryVariables) =>
  variables === undefined ? ['GetInHouseRegs'] : ['GetInHouseRegs', variables]
export const RegFollowUpGetArrivalsDocument = `
    query RegFollowUpGetArrivals($days: Int!) {
  RegFollowUpGetArrivals(days: $days) {
    regId
    personId
    adminLink
    arrivalDate
    balance
    departureDate
    firstName
    flightNumber
    flightTime
    headshot
    lastName
    monitoredAttributes {
      key
      value
    }
    program
    programTypes
    registrationNotes
    room
    spiritualName
  }
}
    `
export const useRegFollowUpGetArrivalsQuery = <
  TData = RegFollowUpGetArrivalsQuery,
  TError = unknown
>(
  variables: RegFollowUpGetArrivalsQueryVariables,
  options?: UseQueryOptions<RegFollowUpGetArrivalsQuery, TError, TData>
) =>
  useQuery<RegFollowUpGetArrivalsQuery, TError, TData>(
    ['RegFollowUpGetArrivals', variables],
    fetcher<RegFollowUpGetArrivalsQuery, RegFollowUpGetArrivalsQueryVariables>(
      RegFollowUpGetArrivalsDocument,
      variables
    ),
    options
  )

useRegFollowUpGetArrivalsQuery.getKey = (
  variables: RegFollowUpGetArrivalsQueryVariables
) => ['RegFollowUpGetArrivals', variables]
export const RegFollowUpGetInHouseDocument = `
    query RegFollowUpGetInHouse {
  RegFollowUpGetInHouse {
    regId
    personId
    adminLink
    arrivalDate
    balance
    departureDate
    firstName
    flightNumber
    flightTime
    headshot
    lastName
    monitoredAttributes {
      key
      value
    }
    program
    programTypes
    registrationNotes
    room
    spiritualName
  }
}
    `
export const useRegFollowUpGetInHouseQuery = <
  TData = RegFollowUpGetInHouseQuery,
  TError = unknown
>(
  variables?: RegFollowUpGetInHouseQueryVariables,
  options?: UseQueryOptions<RegFollowUpGetInHouseQuery, TError, TData>
) =>
  useQuery<RegFollowUpGetInHouseQuery, TError, TData>(
    variables === undefined
      ? ['RegFollowUpGetInHouse']
      : ['RegFollowUpGetInHouse', variables],
    fetcher<RegFollowUpGetInHouseQuery, RegFollowUpGetInHouseQueryVariables>(
      RegFollowUpGetInHouseDocument,
      variables
    ),
    options
  )

useRegFollowUpGetInHouseQuery.getKey = (
  variables?: RegFollowUpGetInHouseQueryVariables
) =>
  variables === undefined
    ? ['RegFollowUpGetInHouse']
    : ['RegFollowUpGetInHouse', variables]
export const RegFollowUpUpdateMonitoredAttributeDocument = `
    mutation RegFollowUpUpdateMonitoredAttribute($value: Boolean!, $attribute: String!, $regId: Int!) {
  RegFollowUpUpdateMonitoredAttribute(
    value: $value
    attribute: $attribute
    regId: $regId
  )
}
    `
export const useRegFollowUpUpdateMonitoredAttributeMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    RegFollowUpUpdateMonitoredAttributeMutation,
    TError,
    RegFollowUpUpdateMonitoredAttributeMutationVariables,
    TContext
  >
) =>
  useMutation<
    RegFollowUpUpdateMonitoredAttributeMutation,
    TError,
    RegFollowUpUpdateMonitoredAttributeMutationVariables,
    TContext
  >(
    ['RegFollowUpUpdateMonitoredAttribute'],
    (variables?: RegFollowUpUpdateMonitoredAttributeMutationVariables) =>
      fetcher<
        RegFollowUpUpdateMonitoredAttributeMutation,
        RegFollowUpUpdateMonitoredAttributeMutationVariables
      >(RegFollowUpUpdateMonitoredAttributeDocument, variables)(),
    options
  )
export const GetAllSeasonsDocument = `
    query GetAllSeasons {
  getAllSeasons {
    seasonType
    cost
    testType
    dayGap
    daysOfWeek
    precedence
    programTypes
    seasonDateClassification
    arrivalDateStart
    arrivalDateEnd
    startDate
    endDate
    monitoredAttributes {
      displayed
      key
      yellowFlagDay
      redFlagDay
      flagCountFrom
    }
    deleted
  }
}
    `
export const useGetAllSeasonsQuery = <
  TData = GetAllSeasonsQuery,
  TError = unknown
>(
  variables?: GetAllSeasonsQueryVariables,
  options?: UseQueryOptions<GetAllSeasonsQuery, TError, TData>
) =>
  useQuery<GetAllSeasonsQuery, TError, TData>(
    variables === undefined ? ['GetAllSeasons'] : ['GetAllSeasons', variables],
    fetcher<GetAllSeasonsQuery, GetAllSeasonsQueryVariables>(
      GetAllSeasonsDocument,
      variables
    ),
    options
  )

useGetAllSeasonsQuery.getKey = (variables?: GetAllSeasonsQueryVariables) =>
  variables === undefined ? ['GetAllSeasons'] : ['GetAllSeasons', variables]
export const GetAllSeasonsWithIdDocument = `
    query GetAllSeasonsWithId {
  getAllSeasons {
    _id
    seasonType
    cost
    testType
    dayGap
    daysOfWeek
    precedence
    createdByUser {
      email
      id
      nickName
      profilePhoto
    }
    deleted
    programTypes
    seasonDateClassification
    arrivalDateStart
    arrivalDateEnd
    startDate
    endDate
    monitoredAttributes {
      displayed
      key
      yellowFlagDay
      redFlagDay
      flagCountFrom
    }
  }
}
    `
export const useGetAllSeasonsWithIdQuery = <
  TData = GetAllSeasonsWithIdQuery,
  TError = unknown
>(
  variables?: GetAllSeasonsWithIdQueryVariables,
  options?: UseQueryOptions<GetAllSeasonsWithIdQuery, TError, TData>
) =>
  useQuery<GetAllSeasonsWithIdQuery, TError, TData>(
    variables === undefined
      ? ['GetAllSeasonsWithId']
      : ['GetAllSeasonsWithId', variables],
    fetcher<GetAllSeasonsWithIdQuery, GetAllSeasonsWithIdQueryVariables>(
      GetAllSeasonsWithIdDocument,
      variables
    ),
    options
  )

useGetAllSeasonsWithIdQuery.getKey = (
  variables?: GetAllSeasonsWithIdQueryVariables
) =>
  variables === undefined
    ? ['GetAllSeasonsWithId']
    : ['GetAllSeasonsWithId', variables]
export const GetGeneralPopulationDocument = `
    query GetGeneralPopulation($maximumDate: String!, $minimumDate: String!) {
  getGeneralPopulation(maximumDate: $maximumDate, minimumDate: $minimumDate) {
    date
    total
    VACATION
    ATTC
    BABIES
    CHILDREN
    COMMUTERS
    KARMA_YOGA
    SPEAKER
    TTC
    VISITING_STAFF
  }
}
    `
export const useGetGeneralPopulationQuery = <
  TData = GetGeneralPopulationQuery,
  TError = unknown
>(
  variables: GetGeneralPopulationQueryVariables,
  options?: UseQueryOptions<GetGeneralPopulationQuery, TError, TData>
) =>
  useQuery<GetGeneralPopulationQuery, TError, TData>(
    ['GetGeneralPopulation', variables],
    fetcher<GetGeneralPopulationQuery, GetGeneralPopulationQueryVariables>(
      GetGeneralPopulationDocument,
      variables
    ),
    options
  )

useGetGeneralPopulationQuery.getKey = (
  variables: GetGeneralPopulationQueryVariables
) => ['GetGeneralPopulation', variables]
export const IsUserConfirmedAsEmailerDocument = `
    query isUserConfirmedAsEmailer {
  isUserConfirmedAsEmailer
}
    `
export const useIsUserConfirmedAsEmailerQuery = <
  TData = IsUserConfirmedAsEmailerQuery,
  TError = unknown
>(
  variables?: IsUserConfirmedAsEmailerQueryVariables,
  options?: UseQueryOptions<IsUserConfirmedAsEmailerQuery, TError, TData>
) =>
  useQuery<IsUserConfirmedAsEmailerQuery, TError, TData>(
    variables === undefined
      ? ['isUserConfirmedAsEmailer']
      : ['isUserConfirmedAsEmailer', variables],
    fetcher<
      IsUserConfirmedAsEmailerQuery,
      IsUserConfirmedAsEmailerQueryVariables
    >(IsUserConfirmedAsEmailerDocument, variables),
    options
  )

useIsUserConfirmedAsEmailerQuery.getKey = (
  variables?: IsUserConfirmedAsEmailerQueryVariables
) =>
  variables === undefined
    ? ['isUserConfirmedAsEmailer']
    : ['isUserConfirmedAsEmailer', variables]
export const RegAdditionalDataSeasonCreateDocument = `
    mutation RegAdditionalDataSeasonCreate($newSeason: NewSeasonInput!) {
  regAdditionalDataSeasonCreate(newSeason: $newSeason)
}
    `
export const useRegAdditionalDataSeasonCreateMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    RegAdditionalDataSeasonCreateMutation,
    TError,
    RegAdditionalDataSeasonCreateMutationVariables,
    TContext
  >
) =>
  useMutation<
    RegAdditionalDataSeasonCreateMutation,
    TError,
    RegAdditionalDataSeasonCreateMutationVariables,
    TContext
  >(
    ['RegAdditionalDataSeasonCreate'],
    (variables?: RegAdditionalDataSeasonCreateMutationVariables) =>
      fetcher<
        RegAdditionalDataSeasonCreateMutation,
        RegAdditionalDataSeasonCreateMutationVariables
      >(RegAdditionalDataSeasonCreateDocument, variables)(),
    options
  )
export const RegAdditionalDataSeasonDeleteDocument = `
    mutation RegAdditionalDataSeasonDelete($id: String!) {
  regAdditionalDataSeasonDelete(_id: $id)
}
    `
export const useRegAdditionalDataSeasonDeleteMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    RegAdditionalDataSeasonDeleteMutation,
    TError,
    RegAdditionalDataSeasonDeleteMutationVariables,
    TContext
  >
) =>
  useMutation<
    RegAdditionalDataSeasonDeleteMutation,
    TError,
    RegAdditionalDataSeasonDeleteMutationVariables,
    TContext
  >(
    ['RegAdditionalDataSeasonDelete'],
    (variables?: RegAdditionalDataSeasonDeleteMutationVariables) =>
      fetcher<
        RegAdditionalDataSeasonDeleteMutation,
        RegAdditionalDataSeasonDeleteMutationVariables
      >(RegAdditionalDataSeasonDeleteDocument, variables)(),
    options
  )
export const GetRegistrationTestsDocument = `
    query GetRegistrationTests($onlyNotDoneOnFilter: String!, $onlyDoneOnFilter: String!, $typesFilter: [CovidTestType!]!, $programFilter: [ProgramTypes!]!, $minimumDate: String!, $maximumDate: String!) {
  getRegistrationTests(
    onlyNotDoneOnFilter: $onlyNotDoneOnFilter
    onlyDoneOnFilter: $onlyDoneOnFilter
    typesFilter: $typesFilter
    programFilter: $programFilter
    minimumDate: $minimumDate
    maximumDate: $maximumDate
  ) {
    name
    email
    regId
    rgLink
    tests {
      id
      date
      stayCapacity
      outcome
      type
      regId
      chargedAmount
      chargedInTransaction
      confirmedByGuest
      freeOfCharge
      notes
    }
    arrivalDate
    departureDate
  }
}
    `
export const useGetRegistrationTestsQuery = <
  TData = GetRegistrationTestsQuery,
  TError = unknown
>(
  variables: GetRegistrationTestsQueryVariables,
  options?: UseQueryOptions<GetRegistrationTestsQuery, TError, TData>
) =>
  useQuery<GetRegistrationTestsQuery, TError, TData>(
    ['GetRegistrationTests', variables],
    fetcher<GetRegistrationTestsQuery, GetRegistrationTestsQueryVariables>(
      GetRegistrationTestsDocument,
      variables
    ),
    options
  )

useGetRegistrationTestsQuery.getKey = (
  variables: GetRegistrationTestsQueryVariables
) => ['GetRegistrationTests', variables]
export const RegistrationTestAddDocument = `
    mutation RegistrationTestAdd($type: String!, $date: String!, $regId: Int!) {
  registrationTestAdd(type: $type, date: $date, regId: $regId)
}
    `
export const useRegistrationTestAddMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    RegistrationTestAddMutation,
    TError,
    RegistrationTestAddMutationVariables,
    TContext
  >
) =>
  useMutation<
    RegistrationTestAddMutation,
    TError,
    RegistrationTestAddMutationVariables,
    TContext
  >(
    ['RegistrationTestAdd'],
    (variables?: RegistrationTestAddMutationVariables) =>
      fetcher<
        RegistrationTestAddMutation,
        RegistrationTestAddMutationVariables
      >(RegistrationTestAddDocument, variables)(),
    options
  )
export const RegistrationTestDeleteDocument = `
    mutation RegistrationTestDelete($id: String!) {
  registrationTestDelete(id: $id)
}
    `
export const useRegistrationTestDeleteMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    RegistrationTestDeleteMutation,
    TError,
    RegistrationTestDeleteMutationVariables,
    TContext
  >
) =>
  useMutation<
    RegistrationTestDeleteMutation,
    TError,
    RegistrationTestDeleteMutationVariables,
    TContext
  >(
    ['RegistrationTestDelete'],
    (variables?: RegistrationTestDeleteMutationVariables) =>
      fetcher<
        RegistrationTestDeleteMutation,
        RegistrationTestDeleteMutationVariables
      >(RegistrationTestDeleteDocument, variables)(),
    options
  )
export const RegistrationTestUpdateDocument = `
    mutation RegistrationTestUpdate($id: String!, $type: String, $confirmedByGuest: Boolean, $freeOfCharge: Boolean, $outcome: String, $notes: String) {
  registrationTestUpdate(
    id: $id
    type: $type
    confirmedByGuest: $confirmedByGuest
    freeOfCharge: $freeOfCharge
    outcome: $outcome
    notes: $notes
  )
}
    `
export const useRegistrationTestUpdateMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    RegistrationTestUpdateMutation,
    TError,
    RegistrationTestUpdateMutationVariables,
    TContext
  >
) =>
  useMutation<
    RegistrationTestUpdateMutation,
    TError,
    RegistrationTestUpdateMutationVariables,
    TContext
  >(
    ['RegistrationTestUpdate'],
    (variables?: RegistrationTestUpdateMutationVariables) =>
      fetcher<
        RegistrationTestUpdateMutation,
        RegistrationTestUpdateMutationVariables
      >(RegistrationTestUpdateDocument, variables)(),
    options
  )
export const RegistrationTestPushDocument = `
    mutation RegistrationTestPush($direction: String!, $id: String!) {
  registrationTestPush(direction: $direction, id: $id)
}
    `
export const useRegistrationTestPushMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    RegistrationTestPushMutation,
    TError,
    RegistrationTestPushMutationVariables,
    TContext
  >
) =>
  useMutation<
    RegistrationTestPushMutation,
    TError,
    RegistrationTestPushMutationVariables,
    TContext
  >(
    ['RegistrationTestPush'],
    (variables?: RegistrationTestPushMutationVariables) =>
      fetcher<
        RegistrationTestPushMutation,
        RegistrationTestPushMutationVariables
      >(RegistrationTestPushDocument, variables)(),
    options
  )
export const RegistrationTestToggleChargeDocument = `
    mutation RegistrationTestToggleCharge($id: String!) {
  registrationTestToggleCharge(id: $id)
}
    `
export const useRegistrationTestToggleChargeMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    RegistrationTestToggleChargeMutation,
    TError,
    RegistrationTestToggleChargeMutationVariables,
    TContext
  >
) =>
  useMutation<
    RegistrationTestToggleChargeMutation,
    TError,
    RegistrationTestToggleChargeMutationVariables,
    TContext
  >(
    ['RegistrationTestToggleCharge'],
    (variables?: RegistrationTestToggleChargeMutationVariables) =>
      fetcher<
        RegistrationTestToggleChargeMutation,
        RegistrationTestToggleChargeMutationVariables
      >(RegistrationTestToggleChargeDocument, variables)(),
    options
  )
export const UserGetTrackSetupDocument = `
    query UserGetTrackSetup {
  userGetTrackSetup {
    type
    sendAlert
  }
}
    `
export const useUserGetTrackSetupQuery = <
  TData = UserGetTrackSetupQuery,
  TError = unknown
>(
  variables?: UserGetTrackSetupQueryVariables,
  options?: UseQueryOptions<UserGetTrackSetupQuery, TError, TData>
) =>
  useQuery<UserGetTrackSetupQuery, TError, TData>(
    variables === undefined
      ? ['UserGetTrackSetup']
      : ['UserGetTrackSetup', variables],
    fetcher<UserGetTrackSetupQuery, UserGetTrackSetupQueryVariables>(
      UserGetTrackSetupDocument,
      variables
    ),
    options
  )

useUserGetTrackSetupQuery.getKey = (
  variables?: UserGetTrackSetupQueryVariables
) =>
  variables === undefined
    ? ['UserGetTrackSetup']
    : ['UserGetTrackSetup', variables]
export const UserUpdateTrackWarningDocument = `
    mutation UserUpdateTrackWarning($warningType: String!, $value: Boolean, $sendAlert: Boolean) {
  userUpdateTrackWarning(
    warningType: $warningType
    value: $value
    sendAlert: $sendAlert
  )
}
    `
export const useUserUpdateTrackWarningMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UserUpdateTrackWarningMutation,
    TError,
    UserUpdateTrackWarningMutationVariables,
    TContext
  >
) =>
  useMutation<
    UserUpdateTrackWarningMutation,
    TError,
    UserUpdateTrackWarningMutationVariables,
    TContext
  >(
    ['UserUpdateTrackWarning'],
    (variables?: UserUpdateTrackWarningMutationVariables) =>
      fetcher<
        UserUpdateTrackWarningMutation,
        UserUpdateTrackWarningMutationVariables
      >(UserUpdateTrackWarningDocument, variables)(),
    options
  )
export const UserGetMonitorSetupDocument = `
    query UserGetMonitorSetup {
  userGetMonitorSetup {
    type
    id
    name
  }
}
    `
export const useUserGetMonitorSetupQuery = <
  TData = UserGetMonitorSetupQuery,
  TError = unknown
>(
  variables?: UserGetMonitorSetupQueryVariables,
  options?: UseQueryOptions<UserGetMonitorSetupQuery, TError, TData>
) =>
  useQuery<UserGetMonitorSetupQuery, TError, TData>(
    variables === undefined
      ? ['UserGetMonitorSetup']
      : ['UserGetMonitorSetup', variables],
    fetcher<UserGetMonitorSetupQuery, UserGetMonitorSetupQueryVariables>(
      UserGetMonitorSetupDocument,
      variables
    ),
    options
  )

useUserGetMonitorSetupQuery.getKey = (
  variables?: UserGetMonitorSetupQueryVariables
) =>
  variables === undefined
    ? ['UserGetMonitorSetup']
    : ['UserGetMonitorSetup', variables]
export const UserAddMonitoredEntityDocument = `
    mutation UserAddMonitoredEntity($entityId: Float!, $monitoringType: String!) {
  userAddMonitoredEntity(id: $entityId, monitoringType: $monitoringType)
}
    `
export const useUserAddMonitoredEntityMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UserAddMonitoredEntityMutation,
    TError,
    UserAddMonitoredEntityMutationVariables,
    TContext
  >
) =>
  useMutation<
    UserAddMonitoredEntityMutation,
    TError,
    UserAddMonitoredEntityMutationVariables,
    TContext
  >(
    ['UserAddMonitoredEntity'],
    (variables?: UserAddMonitoredEntityMutationVariables) =>
      fetcher<
        UserAddMonitoredEntityMutation,
        UserAddMonitoredEntityMutationVariables
      >(UserAddMonitoredEntityDocument, variables)(),
    options
  )
export const UserDeleteMonitoredEntityDocument = `
    mutation UserDeleteMonitoredEntity($entityId: Float!, $monitoringType: String!) {
  userDeleteMonitoredEntity(id: $entityId, monitoringType: $monitoringType)
}
    `
export const useUserDeleteMonitoredEntityMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UserDeleteMonitoredEntityMutation,
    TError,
    UserDeleteMonitoredEntityMutationVariables,
    TContext
  >
) =>
  useMutation<
    UserDeleteMonitoredEntityMutation,
    TError,
    UserDeleteMonitoredEntityMutationVariables,
    TContext
  >(
    ['UserDeleteMonitoredEntity'],
    (variables?: UserDeleteMonitoredEntityMutationVariables) =>
      fetcher<
        UserDeleteMonitoredEntityMutation,
        UserDeleteMonitoredEntityMutationVariables
      >(UserDeleteMonitoredEntityDocument, variables)(),
    options
  )
export const UserGetWarnOnChangesToPastDataStatusDocument = `
    query UserGetWarnOnChangesToPastDataStatus {
  userGetWarnOnChangesToPastDataStatus
}
    `
export const useUserGetWarnOnChangesToPastDataStatusQuery = <
  TData = UserGetWarnOnChangesToPastDataStatusQuery,
  TError = unknown
>(
  variables?: UserGetWarnOnChangesToPastDataStatusQueryVariables,
  options?: UseQueryOptions<
    UserGetWarnOnChangesToPastDataStatusQuery,
    TError,
    TData
  >
) =>
  useQuery<UserGetWarnOnChangesToPastDataStatusQuery, TError, TData>(
    variables === undefined
      ? ['UserGetWarnOnChangesToPastDataStatus']
      : ['UserGetWarnOnChangesToPastDataStatus', variables],
    fetcher<
      UserGetWarnOnChangesToPastDataStatusQuery,
      UserGetWarnOnChangesToPastDataStatusQueryVariables
    >(UserGetWarnOnChangesToPastDataStatusDocument, variables),
    options
  )

useUserGetWarnOnChangesToPastDataStatusQuery.getKey = (
  variables?: UserGetWarnOnChangesToPastDataStatusQueryVariables
) =>
  variables === undefined
    ? ['UserGetWarnOnChangesToPastDataStatus']
    : ['UserGetWarnOnChangesToPastDataStatus', variables]
export const UserSetWarnOnChangesToPastDataDocument = `
    mutation UserSetWarnOnChangesToPastData($status: Boolean!) {
  userSetWarnOnChangesToPastData(status: $status)
}
    `
export const useUserSetWarnOnChangesToPastDataMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UserSetWarnOnChangesToPastDataMutation,
    TError,
    UserSetWarnOnChangesToPastDataMutationVariables,
    TContext
  >
) =>
  useMutation<
    UserSetWarnOnChangesToPastDataMutation,
    TError,
    UserSetWarnOnChangesToPastDataMutationVariables,
    TContext
  >(
    ['UserSetWarnOnChangesToPastData'],
    (variables?: UserSetWarnOnChangesToPastDataMutationVariables) =>
      fetcher<
        UserSetWarnOnChangesToPastDataMutation,
        UserSetWarnOnChangesToPastDataMutationVariables
      >(UserSetWarnOnChangesToPastDataDocument, variables)(),
    options
  )
export const WarningsGetDocument = `
    query WarningsGet {
  warningsGet {
    type
    discoveredOn
    explanation
    notes
    registrationName
    registrationLink
    registrationID
    lodging
    arrivalDate
    registrationComments
    active
  }
}
    `
export const useWarningsGetQuery = <TData = WarningsGetQuery, TError = unknown>(
  variables?: WarningsGetQueryVariables,
  options?: UseQueryOptions<WarningsGetQuery, TError, TData>
) =>
  useQuery<WarningsGetQuery, TError, TData>(
    variables === undefined ? ['WarningsGet'] : ['WarningsGet', variables],
    fetcher<WarningsGetQuery, WarningsGetQueryVariables>(
      WarningsGetDocument,
      variables
    ),
    options
  )

useWarningsGetQuery.getKey = (variables?: WarningsGetQueryVariables) =>
  variables === undefined ? ['WarningsGet'] : ['WarningsGet', variables]
export const WarningUpdateDocument = `
    mutation WarningUpdate($regId: Float!, $type: String!, $ignoreState: String, $notes: String) {
  warningUpdate(
    regId: $regId
    type: $type
    ignoreState: $ignoreState
    notes: $notes
  )
}
    `
export const useWarningUpdateMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    WarningUpdateMutation,
    TError,
    WarningUpdateMutationVariables,
    TContext
  >
) =>
  useMutation<
    WarningUpdateMutation,
    TError,
    WarningUpdateMutationVariables,
    TContext
  >(
    ['WarningUpdate'],
    (variables?: WarningUpdateMutationVariables) =>
      fetcher<WarningUpdateMutation, WarningUpdateMutationVariables>(
        WarningUpdateDocument,
        variables
      )(),
    options
  )
export const UserCreateSubscriptionDocument = `
    mutation UserCreateSubscription($newSubscription: NewSubscriptionInput!) {
  userCreateSubscription(newSubscription: $newSubscription)
}
    `
export const useUserCreateSubscriptionMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UserCreateSubscriptionMutation,
    TError,
    UserCreateSubscriptionMutationVariables,
    TContext
  >
) =>
  useMutation<
    UserCreateSubscriptionMutation,
    TError,
    UserCreateSubscriptionMutationVariables,
    TContext
  >(
    ['UserCreateSubscription'],
    (variables?: UserCreateSubscriptionMutationVariables) =>
      fetcher<
        UserCreateSubscriptionMutation,
        UserCreateSubscriptionMutationVariables
      >(UserCreateSubscriptionDocument, variables)(),
    options
  )
