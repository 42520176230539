/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from 'mobx-state-tree'

/**
 * Typescript enum
 */

export enum Audience {
  ALL_RESIDENTS_AND_COMMUTERS = 'ALL_RESIDENTS_AND_COMMUTERS',
  ALL_RESIDENTS = 'ALL_RESIDENTS',
  ALL_RESIDENTS_GUESTS = 'ALL_RESIDENTS_GUESTS',
  ALL_RESIDENTS_GUESTS_NO_CHILDREN = 'ALL_RESIDENTS_GUESTS_NO_CHILDREN',
  ALL_STAFF_KARMA_YOGIS = 'ALL_STAFF_KARMA_YOGIS',
  ALL_KARMA_YOGIS = 'ALL_KARMA_YOGIS',
  ALL_STAFF = 'ALL_STAFF',
  ALL_SPEAKERS = 'ALL_SPEAKERS',
  STUDENTS_IN_COURSE = 'STUDENTS_IN_COURSE'
}

/**
 * Audience
 *
 * types of allowed audiences for an announcement
 */
export const AudienceEnumType = types.enumeration('Audience', [
  'ALL_RESIDENTS_AND_COMMUTERS',
  'ALL_RESIDENTS',
  'ALL_RESIDENTS_GUESTS',
  'ALL_RESIDENTS_GUESTS_NO_CHILDREN',
  'ALL_STAFF_KARMA_YOGIS',
  'ALL_KARMA_YOGIS',
  'ALL_STAFF',
  'ALL_SPEAKERS',
  'STUDENTS_IN_COURSE'
])
