// @ts-nocheck
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { ObservableMap } from 'mobx'
import { types } from 'mobx-state-tree'
import {
  MSTGQLStore,
  configureStoreMixin,
  QueryOptions,
  withTypedRefs
} from 'mst-gql'

import { UserResponseModel, UserResponseModelType } from './UserResponseModel'
import {
  userResponseModelPrimitives,
  UserResponseModelSelector
} from './UserResponseModel.base'
import {
  PermissionsResponseModel,
  PermissionsResponseModelType
} from './PermissionsResponseModel'
import {
  permissionsResponseModelPrimitives,
  PermissionsResponseModelSelector
} from './PermissionsResponseModel.base'
import {
  AnnouncementResponseModel,
  AnnouncementResponseModelType
} from './AnnouncementResponseModel'
import {
  announcementResponseModelPrimitives,
  AnnouncementResponseModelSelector
} from './AnnouncementResponseModel.base'
import {
  AnnouncementStatsResponseModel,
  AnnouncementStatsResponseModelType
} from './AnnouncementStatsResponseModel'
import {
  announcementStatsResponseModelPrimitives,
  AnnouncementStatsResponseModelSelector
} from './AnnouncementStatsResponseModel.base'
import {
  PersonResponseModel,
  PersonResponseModelType
} from './PersonResponseModel'
import {
  personResponseModelPrimitives,
  PersonResponseModelSelector
} from './PersonResponseModel.base'
import {
  ChatConnectionModel,
  ChatConnectionModelType
} from './ChatConnectionModel'
import {
  chatConnectionModelPrimitives,
  ChatConnectionModelSelector
} from './ChatConnectionModel.base'
import { PageInfoModel, PageInfoModelType } from './PageInfoModel'
import {
  pageInfoModelPrimitives,
  PageInfoModelSelector
} from './PageInfoModel.base'
import { ChatEdgeModel, ChatEdgeModelType } from './ChatEdgeModel'
import {
  chatEdgeModelPrimitives,
  ChatEdgeModelSelector
} from './ChatEdgeModel.base'
import { ChatResponseModel, ChatResponseModelType } from './ChatResponseModel'
import {
  chatResponseModelPrimitives,
  ChatResponseModelSelector
} from './ChatResponseModel.base'
import {
  MessageConnectionModel,
  MessageConnectionModelType
} from './MessageConnectionModel'
import {
  messageConnectionModelPrimitives,
  MessageConnectionModelSelector
} from './MessageConnectionModel.base'
import { MessageEdgeModel, MessageEdgeModelType } from './MessageEdgeModel'
import {
  messageEdgeModelPrimitives,
  MessageEdgeModelSelector
} from './MessageEdgeModel.base'
import {
  MessageResponseModel,
  MessageResponseModelType
} from './MessageResponseModel'
import {
  messageResponseModelPrimitives,
  MessageResponseModelSelector
} from './MessageResponseModel.base'
import {
  ProgramResponseModel,
  ProgramResponseModelType
} from './ProgramResponseModel'
import {
  programResponseModelPrimitives,
  ProgramResponseModelSelector
} from './ProgramResponseModel.base'
import {
  ArrivingRegResponseModel,
  ArrivingRegResponseModelType
} from './ArrivingRegResponseModel'
import {
  arrivingRegResponseModelPrimitives,
  ArrivingRegResponseModelSelector
} from './ArrivingRegResponseModel.base'
import {
  InHouseRegResponseModel,
  InHouseRegResponseModelType
} from './InHouseRegResponseModel'
import {
  inHouseRegResponseModelPrimitives,
  InHouseRegResponseModelSelector
} from './InHouseRegResponseModel.base'
import {
  CompoundResponseModel,
  CompoundResponseModelType
} from './CompoundResponseModel'
import {
  compoundResponseModelPrimitives,
  CompoundResponseModelSelector
} from './CompoundResponseModel.base'
import {
  PopulationResponseModel,
  PopulationResponseModelType
} from './PopulationResponseModel'
import {
  populationResponseModelPrimitives,
  PopulationResponseModelSelector
} from './PopulationResponseModel.base'
import {
  ItemIdResponseModel,
  ItemIdResponseModelType
} from './ItemIdResponseModel'
import {
  itemIdResponseModelPrimitives,
  ItemIdResponseModelSelector
} from './ItemIdResponseModel.base'
import {
  RegFollowUpResponseModel,
  RegFollowUpResponseModelType
} from './RegFollowUpResponseModel'
import {
  regFollowUpResponseModelPrimitives,
  RegFollowUpResponseModelSelector
} from './RegFollowUpResponseModel.base'
import {
  MonitoredAttributeResponseModel,
  MonitoredAttributeResponseModelType
} from './MonitoredAttributeResponseModel'
import {
  monitoredAttributeResponseModelPrimitives,
  MonitoredAttributeResponseModelSelector
} from './MonitoredAttributeResponseModel.base'
import {
  RegAdditionalDataSeasonResponseModel,
  RegAdditionalDataSeasonResponseModelType
} from './RegAdditionalDataSeasonResponseModel'
import {
  regAdditionalDataSeasonResponseModelPrimitives,
  RegAdditionalDataSeasonResponseModelSelector
} from './RegAdditionalDataSeasonResponseModel.base'
import {
  MonitoredAttributeMetaDataResponseModel,
  MonitoredAttributeMetaDataResponseModelType
} from './MonitoredAttributeMetaDataResponseModel'
import {
  monitoredAttributeMetaDataResponseModelPrimitives,
  MonitoredAttributeMetaDataResponseModelSelector
} from './MonitoredAttributeMetaDataResponseModel.base'
import {
  RegistrationTestsModel,
  RegistrationTestsModelType
} from './RegistrationTestsModel'
import {
  registrationTestsModelPrimitives,
  RegistrationTestsModelSelector
} from './RegistrationTestsModel.base'
import {
  SingleTestResponseModel,
  SingleTestResponseModelType
} from './SingleTestResponseModel'
import {
  singleTestResponseModelPrimitives,
  SingleTestResponseModelSelector
} from './SingleTestResponseModel.base'
import {
  LoginResponseModel,
  LoginResponseModelType
} from './LoginResponseModel'
import {
  loginResponseModelPrimitives,
  LoginResponseModelSelector
} from './LoginResponseModel.base'
import {
  NotificationSubscriptionModel,
  NotificationSubscriptionModelType
} from './NotificationSubscriptionModel'
import {
  notificationSubscriptionModelPrimitives,
  NotificationSubscriptionModelSelector
} from './NotificationSubscriptionModel.base'

import { Audience } from './AudienceEnum'
import { ProgramTypes } from './ProgramTypesEnum'
import { SeasonTypes } from './SeasonTypesEnum'
import { CovidTestType } from './CovidTestTypeEnum'

export type NewPermissionsInput = {
  inviteToApp?: boolean
  upgradeOtherUsers?: boolean
  viewPeopleLists?: boolean
  manageAnnouncements?: boolean
  chatAsFrontDesk?: boolean
  sendEmails?: boolean
  generalPopulation?: boolean
  accounting?: boolean
  adminFollowUpRead?: boolean
  adminFollowUpWrite?: boolean
  accessWarnings?: boolean
}
export type NewSubscriptionInput = {
  userId: string
  userAgent: string
  endpoint: string
  p256dhKey: string
  authKey: string
}
export type CreateAnnouncementInput = {
  subject: string
  body: string
  publishStart?: any
  publishEnd: any
  sendAlert: boolean
  highPriority: boolean
  audience: Audience
  audienceCourseId?: number
}
export type UpdateAnnouncementInput = {
  id: string
  publishEnd?: any
  sendAlert?: boolean
}
export type NewMessageInput = {
  chatId: string
  content: string
}
export type NewSeasonInput = {
  precedence: number
  programTypes: string[]
  seasonDateClassification: string
  startDate: string
  endDate: string
  arrivalDateStart: string
  arrivalDateEnd: string
  seasonType: SeasonTypes
  monitoredAttributes?: MonitoredAttributeMetaDataInput[]
  dayGap?: number
  daysOfWeek?: number[]
  testType?: CovidTestType
  cost?: number
}
export type MonitoredAttributeMetaDataInput = {
  key: string
  displayed: string
  yellowFlagDay: number
  redFlagDay: number
  flagCountFrom: string
}
/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  userResponses: ObservableMap<string, UserResponseModelType>
  announcementResponses: ObservableMap<string, AnnouncementResponseModelType>
  chatResponses: ObservableMap<string, ChatResponseModelType>
  programResponses: ObservableMap<string, ProgramResponseModelType>
  arrivingRegResponses: ObservableMap<string, ArrivingRegResponseModelType>
  inHouseRegResponses: ObservableMap<string, InHouseRegResponseModelType>
  singleTestResponses: ObservableMap<string, SingleTestResponseModelType>
  notificationSubscriptions: ObservableMap<
    string,
    NotificationSubscriptionModelType
  >
}

/**
 * Enums for the names of base graphql actions
 */
export enum RootStoreBaseQueries {
  queryUsers = 'queryUsers',
  queryUserDoesExist = 'queryUserDoesExist',
  queryIsUserConfirmedAsEmailer = 'queryIsUserConfirmedAsEmailer',
  queryGetActiveAnnouncements = 'queryGetActiveAnnouncements',
  queryGetArchivedAnnouncements = 'queryGetArchivedAnnouncements',
  queryChatGetRecentChats = 'queryChatGetRecentChats',
  queryChatGetMessages = 'queryChatGetMessages',
  queryGetLodgingPrograms = 'queryGetLodgingPrograms',
  queryGetArrivalRegs = 'queryGetArrivalRegs',
  queryGetInHouseRegs = 'queryGetInHouseRegs',
  queryGetDepartures = 'queryGetDepartures',
  queryGetInHouse = 'queryGetInHouse',
  queryGetStaysWithinDates = 'queryGetStaysWithinDates',
  queryGetArrivingSoon = 'queryGetArrivingSoon',
  queryGetGeneralPopulation = 'queryGetGeneralPopulation',
  queryGetVoucherTransactions = 'queryGetVoucherTransactions',
  queryRegFollowUpGetArrivals = 'queryRegFollowUpGetArrivals',
  queryRegFollowUpGetInHouse = 'queryRegFollowUpGetInHouse',
  queryGetAllSeasons = 'queryGetAllSeasons',
  queryGetRegistrationTests = 'queryGetRegistrationTests'
}
export enum RootStoreBaseMutations {
  mutateUserCreateNew = 'mutateUserCreateNew',
  mutateUserLogin = 'mutateUserLogin',
  mutateUserLogout = 'mutateUserLogout',
  mutateUserRevokeRefreshTokensForUser = 'mutateUserRevokeRefreshTokensForUser',
  mutateUserUpdatePermission = 'mutateUserUpdatePermission',
  mutateUserCreateSubscription = 'mutateUserCreateSubscription',
  mutateUserInvite = 'mutateUserInvite',
  mutateUserPromptChangePassword = 'mutateUserPromptChangePassword',
  mutateUserUpdateNickname = 'mutateUserUpdateNickname',
  mutateUserUpdateTrackWarning = 'mutateUserUpdateTrackWarning',
  mutateCreateAnnouncement = 'mutateCreateAnnouncement',
  mutateUpdateAnnouncement = 'mutateUpdateAnnouncement',
  mutateConfirmAnnouncementRead = 'mutateConfirmAnnouncementRead',
  mutateChatPostMessage = 'mutateChatPostMessage',
  mutateRegFollowUpUpdateMonitoredAttribute = 'mutateRegFollowUpUpdateMonitoredAttribute',
  mutateRegAdditionalDataSeasonCreate = 'mutateRegAdditionalDataSeasonCreate',
  mutateRegAdditionalDataSeasonDelete = 'mutateRegAdditionalDataSeasonDelete',
  mutateRegistrationTestAdd = 'mutateRegistrationTestAdd',
  mutateRegistrationTestDelete = 'mutateRegistrationTestDelete',
  mutateRegistrationTestUpdate = 'mutateRegistrationTestUpdate',
  mutateRegistrationTestPush = 'mutateRegistrationTestPush',
  mutateRegistrationTestToggleCharge = 'mutateRegistrationTestToggleCharge'
}

/**
 * Store, managing, among others, all the objects received through graphQL
 */
export const RootStoreBase = withTypedRefs<Refs>()(
  MSTGQLStore.named('RootStore')
    .extend(
      configureStoreMixin(
        [
          ['UserResponse', () => UserResponseModel],
          ['PermissionsResponse', () => PermissionsResponseModel],
          ['AnnouncementResponse', () => AnnouncementResponseModel],
          ['AnnouncementStatsResponse', () => AnnouncementStatsResponseModel],
          ['PersonResponse', () => PersonResponseModel],
          ['ChatConnection', () => ChatConnectionModel],
          ['PageInfo', () => PageInfoModel],
          ['ChatEdge', () => ChatEdgeModel],
          ['ChatResponse', () => ChatResponseModel],
          ['MessageConnection', () => MessageConnectionModel],
          ['MessageEdge', () => MessageEdgeModel],
          ['MessageResponse', () => MessageResponseModel],
          ['ProgramResponse', () => ProgramResponseModel],
          ['ArrivingRegResponse', () => ArrivingRegResponseModel],
          ['InHouseRegResponse', () => InHouseRegResponseModel],
          ['CompoundResponse', () => CompoundResponseModel],
          ['PopulationResponse', () => PopulationResponseModel],
          ['ItemIdResponse', () => ItemIdResponseModel],
          ['RegFollowUpResponse', () => RegFollowUpResponseModel],
          ['MonitoredAttributeResponse', () => MonitoredAttributeResponseModel],
          [
            'RegAdditionalDataSeasonResponse',
            () => RegAdditionalDataSeasonResponseModel
          ],
          [
            'MonitoredAttributeMetaDataResponse',
            () => MonitoredAttributeMetaDataResponseModel
          ],
          ['RegistrationTests', () => RegistrationTestsModel],
          ['SingleTestResponse', () => SingleTestResponseModel],
          ['LoginResponse', () => LoginResponseModel],
          ['NotificationSubscription', () => NotificationSubscriptionModel]
        ],
        [
          'UserResponse',
          'AnnouncementResponse',
          'ChatResponse',
          'ProgramResponse',
          'ArrivingRegResponse',
          'InHouseRegResponse',
          'SingleTestResponse',
          'NotificationSubscription'
        ],
        'js'
      )
    )
    .props({
      userResponses: types.optional(
        types.map(types.late((): any => UserResponseModel)),
        {}
      ),
      announcementResponses: types.optional(
        types.map(types.late((): any => AnnouncementResponseModel)),
        {}
      ),
      chatResponses: types.optional(
        types.map(types.late((): any => ChatResponseModel)),
        {}
      ),
      programResponses: types.optional(
        types.map(types.late((): any => ProgramResponseModel)),
        {}
      ),
      arrivingRegResponses: types.optional(
        types.map(types.late((): any => ArrivingRegResponseModel)),
        {}
      ),
      inHouseRegResponses: types.optional(
        types.map(types.late((): any => InHouseRegResponseModel)),
        {}
      ),
      singleTestResponses: types.optional(
        types.map(types.late((): any => SingleTestResponseModel)),
        {}
      ),
      notificationSubscriptions: types.optional(
        types.map(types.late((): any => NotificationSubscriptionModel)),
        {}
      )
    })
    .actions(self => ({
      // get a list of all users
      queryUsers(
        variables?: {},
        resultSelector:
          | string
          | ((
              qb: UserResponseModelSelector
            ) => UserResponseModelSelector) = userResponseModelPrimitives.toString(),
        options: QueryOptions = {}
      ) {
        return self.query<{ users: UserResponseModelType[] }>(
          `query users { users {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new UserResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options
        )
      },
      // check if a user exists AND has a password, even if unverified. In case a user was invited or has only logged in via OAuth - this query considers the person as non-existent, meaning they can manual register with a new password.
      queryUserDoesExist(
        variables: { email: string },
        options: QueryOptions = {}
      ) {
        return self.query<{ userDoesExist: boolean }>(
          `query userDoesExist($email: String!) { userDoesExist(email: $email) }`,
          variables,
          options
        )
      },
      // is the emailer ready, in terms of active authorized Google account configured to send email with the proper permissions
      queryIsUserConfirmedAsEmailer(
        variables?: {},
        options: QueryOptions = {}
      ) {
        return self.query<{ isUserConfirmedAsEmailer: boolean }>(
          `query isUserConfirmedAsEmailer { isUserConfirmedAsEmailer }`,
          variables,
          options
        )
      },
      queryGetActiveAnnouncements(
        variables?: {},
        resultSelector:
          | string
          | ((
              qb: AnnouncementResponseModelSelector
            ) => AnnouncementResponseModelSelector) = announcementResponseModelPrimitives.toString(),
        options: QueryOptions = {}
      ) {
        return self.query<{
          getActiveAnnouncements: AnnouncementResponseModelType[]
        }>(
          `query getActiveAnnouncements { getActiveAnnouncements {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new AnnouncementResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options
        )
      },
      queryGetArchivedAnnouncements(
        variables?: {},
        resultSelector:
          | string
          | ((
              qb: AnnouncementResponseModelSelector
            ) => AnnouncementResponseModelSelector) = announcementResponseModelPrimitives.toString(),
        options: QueryOptions = {}
      ) {
        return self.query<{
          getArchivedAnnouncements: AnnouncementResponseModelType[]
        }>(
          `query getArchivedAnnouncements { getArchivedAnnouncements {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new AnnouncementResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options
        )
      },
      // get recent active chats connection
      queryChatGetRecentChats(
        variables: {
          before?: string
          after?: string
          first?: number
          last?: number
        },
        resultSelector:
          | string
          | ((
              qb: ChatConnectionModelSelector
            ) => ChatConnectionModelSelector) = chatConnectionModelPrimitives.toString(),
        options: QueryOptions = {}
      ) {
        return self.query<{ chatGetRecentChats: ChatConnectionModelType }>(
          `query chatGetRecentChats($before: String, $after: String, $first: Int, $last: Int) { chatGetRecentChats(before: $before, after: $after, first: $first, last: $last) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new ChatConnectionModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options
        )
      },
      // load messages for chat
      queryChatGetMessages(
        variables: {
          before?: string
          after?: string
          first?: number
          last?: number
          chatId: string
        },
        resultSelector:
          | string
          | ((
              qb: ChatResponseModelSelector
            ) => ChatResponseModelSelector) = chatResponseModelPrimitives.toString(),
        options: QueryOptions = {}
      ) {
        return self.query<{ chatGetMessages: ChatResponseModelType }>(
          `query chatGetMessages($before: String, $after: String, $first: Int, $last: Int, $chatId: String!) { chatGetMessages(before: $before, after: $after, first: $first, last: $last, chatId: $chatId) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new ChatResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options
        )
      },
      queryGetLodgingPrograms(
        variables?: {},
        resultSelector:
          | string
          | ((
              qb: ProgramResponseModelSelector
            ) => ProgramResponseModelSelector) = programResponseModelPrimitives.toString(),
        options: QueryOptions = {}
      ) {
        return self.query<{ getLodgingPrograms: ProgramResponseModelType[] }>(
          `query getLodgingPrograms { getLodgingPrograms {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new ProgramResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options
        )
      },
      queryGetArrivalRegs(
        variables: { date: string },
        resultSelector:
          | string
          | ((
              qb: ArrivingRegResponseModelSelector
            ) => ArrivingRegResponseModelSelector) = arrivingRegResponseModelPrimitives.toString(),
        options: QueryOptions = {}
      ) {
        return self.query<{ getArrivalRegs: ArrivingRegResponseModelType[] }>(
          `query getArrivalRegs($date: String!) { getArrivalRegs(date: $date) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new ArrivingRegResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options
        )
      },
      queryGetInHouseRegs(
        variables?: {},
        resultSelector:
          | string
          | ((
              qb: InHouseRegResponseModelSelector
            ) => InHouseRegResponseModelSelector) = inHouseRegResponseModelPrimitives.toString(),
        options: QueryOptions = {}
      ) {
        return self.query<{ getInHouseRegs: InHouseRegResponseModelType[] }>(
          `query getInHouseRegs { getInHouseRegs {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new InHouseRegResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options
        )
      },
      queryGetDepartures(
        variables: { date: string },
        resultSelector:
          | string
          | ((
              qb: InHouseRegResponseModelSelector
            ) => InHouseRegResponseModelSelector) = inHouseRegResponseModelPrimitives.toString(),
        options: QueryOptions = {}
      ) {
        return self.query<{ getDepartures: InHouseRegResponseModelType[] }>(
          `query getDepartures($date: String!) { getDepartures(date: $date) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new InHouseRegResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options
        )
      },
      queryGetInHouse(
        variables?: {},
        resultSelector:
          | string
          | ((
              qb: CompoundResponseModelSelector
            ) => CompoundResponseModelSelector) = compoundResponseModelPrimitives.toString(),
        options: QueryOptions = {}
      ) {
        return self.query<{ getInHouse: CompoundResponseModelType[] }>(
          `query getInHouse { getInHouse {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new CompoundResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options
        )
      },
      queryGetStaysWithinDates(
        variables: { to: string; from: string },
        resultSelector:
          | string
          | ((
              qb: CompoundResponseModelSelector
            ) => CompoundResponseModelSelector) = compoundResponseModelPrimitives.toString(),
        options: QueryOptions = {}
      ) {
        return self.query<{ getStaysWithinDates: CompoundResponseModelType[] }>(
          `query getStaysWithinDates($to: String!, $from: String!) { getStaysWithinDates(to: $to, from: $from) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new CompoundResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options
        )
      },
      queryGetArrivingSoon(
        variables?: {},
        resultSelector:
          | string
          | ((
              qb: CompoundResponseModelSelector
            ) => CompoundResponseModelSelector) = compoundResponseModelPrimitives.toString(),
        options: QueryOptions = {}
      ) {
        return self.query<{ getArrivingSoon: CompoundResponseModelType[] }>(
          `query getArrivingSoon { getArrivingSoon {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new CompoundResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options
        )
      },
      queryGetGeneralPopulation(
        variables: { maximumDate: string; minimumDate: string },
        resultSelector:
          | string
          | ((
              qb: PopulationResponseModelSelector
            ) => PopulationResponseModelSelector) = populationResponseModelPrimitives.toString(),
        options: QueryOptions = {}
      ) {
        return self.query<{
          getGeneralPopulation: PopulationResponseModelType[]
        }>(
          `query getGeneralPopulation($maximumDate: String!, $minimumDate: String!) { getGeneralPopulation(maximumDate: $maximumDate, minimumDate: $minimumDate) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new PopulationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options
        )
      },
      queryGetVoucherTransactions(
        variables?: {},
        resultSelector:
          | string
          | ((
              qb: ItemIdResponseModelSelector
            ) => ItemIdResponseModelSelector) = itemIdResponseModelPrimitives.toString(),
        options: QueryOptions = {}
      ) {
        return self.query<{
          getVoucherTransactions: ItemIdResponseModelType[]
        }>(
          `query getVoucherTransactions { getVoucherTransactions {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new ItemIdResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options
        )
      },
      queryRegFollowUpGetArrivals(
        variables: { days: number },
        resultSelector:
          | string
          | ((
              qb: RegFollowUpResponseModelSelector
            ) => RegFollowUpResponseModelSelector) = regFollowUpResponseModelPrimitives.toString(),
        options: QueryOptions = {}
      ) {
        return self.query<{
          RegFollowUpGetArrivals: RegFollowUpResponseModelType[]
        }>(
          `query RegFollowUpGetArrivals($days: Int!) { RegFollowUpGetArrivals(days: $days) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new RegFollowUpResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options
        )
      },
      queryRegFollowUpGetInHouse(
        variables?: {},
        resultSelector:
          | string
          | ((
              qb: RegFollowUpResponseModelSelector
            ) => RegFollowUpResponseModelSelector) = regFollowUpResponseModelPrimitives.toString(),
        options: QueryOptions = {}
      ) {
        return self.query<{
          RegFollowUpGetInHouse: RegFollowUpResponseModelType[]
        }>(
          `query RegFollowUpGetInHouse { RegFollowUpGetInHouse {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new RegFollowUpResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options
        )
      },
      // Get all seasons sorted by precedence DESC
      queryGetAllSeasons(
        variables?: {},
        resultSelector:
          | string
          | ((
              qb: RegAdditionalDataSeasonResponseModelSelector
            ) => RegAdditionalDataSeasonResponseModelSelector) = regAdditionalDataSeasonResponseModelPrimitives.toString(),
        options: QueryOptions = {}
      ) {
        return self.query<{
          getAllSeasons: RegAdditionalDataSeasonResponseModelType[]
        }>(
          `query getAllSeasons { getAllSeasons {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(
                new RegAdditionalDataSeasonResponseModelSelector()
              ).toString()
            : resultSelector
        }
      } }`,
          variables,
          options
        )
      },
      queryGetRegistrationTests(
        variables: {
          onlyNotDoneOnFilter: string
          onlyDoneOnFilter: string
          typesFilter: CovidTestType[]
          programFilter: ProgramTypes[]
          minimumDate: string
          maximumDate: string
        },
        resultSelector:
          | string
          | ((
              qb: RegistrationTestsModelSelector
            ) => RegistrationTestsModelSelector) = registrationTestsModelPrimitives.toString(),
        options: QueryOptions = {}
      ) {
        return self.query<{
          getRegistrationTests: RegistrationTestsModelType[]
        }>(
          `query getRegistrationTests($onlyNotDoneOnFilter: String!, $onlyDoneOnFilter: String!, $typesFilter: [CovidTestType!]!, $programFilter: [ProgramTypes!]!, $minimumDate: String!, $maximumDate: String!) { getRegistrationTests(onlyNotDoneOnFilter: $onlyNotDoneOnFilter, onlyDoneOnFilter: $onlyDoneOnFilter, typesFilter: $typesFilter, programFilter: $programFilter, minimumDate: $minimumDate, maximumDate: $maximumDate) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new RegistrationTestsModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options
        )
      },
      // Register new user by email and password
      mutateUserCreateNew(
        variables: { password: string; email: string },
        optimisticUpdate?: () => void
      ) {
        return self.mutate<{ userCreateNew: boolean }>(
          `mutation userCreateNew($password: String!, $email: String!) { userCreateNew(password: $password, email: $email) }`,
          variables,
          optimisticUpdate
        )
      },
      mutateUserLogin(
        variables: { password: string; email: string },
        resultSelector:
          | string
          | ((
              qb: LoginResponseModelSelector
            ) => LoginResponseModelSelector) = loginResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void
      ) {
        return self.mutate<{ userLogin: LoginResponseModelType }>(
          `mutation userLogin($password: String!, $email: String!) { userLogin(password: $password, email: $email) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new LoginResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate
        )
      },
      mutateUserLogout(variables?: {}, optimisticUpdate?: () => void) {
        return self.mutate<{ userLogout: boolean }>(
          `mutation userLogout { userLogout }`,
          variables,
          optimisticUpdate
        )
      },
      mutateUserRevokeRefreshTokensForUser(
        variables: { userId: string },
        optimisticUpdate?: () => void
      ) {
        return self.mutate<{ userRevokeRefreshTokensForUser: boolean }>(
          `mutation userRevokeRefreshTokensForUser($userId: String!) { userRevokeRefreshTokensForUser(userId: $userId) }`,
          variables,
          optimisticUpdate
        )
      },
      mutateUserUpdatePermission(
        variables: { newPermissionSet: NewPermissionsInput; userId: string },
        resultSelector:
          | string
          | ((
              qb: UserResponseModelSelector
            ) => UserResponseModelSelector) = userResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void
      ) {
        return self.mutate<{ userUpdatePermission: UserResponseModelType[] }>(
          `mutation userUpdatePermission($newPermissionSet: NewPermissionsInput!, $userId: String!) { userUpdatePermission(newPermissionSet: $newPermissionSet, userId: $userId) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new UserResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate
        )
      },
      mutateUserCreateSubscription(
        variables: { newSubscription: NewSubscriptionInput },
        resultSelector:
          | string
          | ((
              qb: NotificationSubscriptionModelSelector
            ) => NotificationSubscriptionModelSelector) = notificationSubscriptionModelPrimitives.toString(),
        optimisticUpdate?: () => void
      ) {
        return self.mutate<{
          userCreateSubscription: NotificationSubscriptionModelType
        }>(
          `mutation userCreateSubscription($newSubscription: NewSubscriptionInput!) { userCreateSubscription(newSubscription: $newSubscription) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(
                new NotificationSubscriptionModelSelector()
              ).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate
        )
      },
      // Create an account for a user without a password. For staff with permission - they would have to sign in with a password or OAuth with a hint to their registered email.
      mutateUserInvite(
        variables: { email: string },
        optimisticUpdate?: () => void
      ) {
        return self.mutate<{ userInvite: boolean }>(
          `mutation userInvite($email: String!) { userInvite(email: $email) }`,
          variables,
          optimisticUpdate
        )
      },
      // Issue an email, which once confirmed, the email will be chnaged
      mutateUserPromptChangePassword(
        variables: { email: string },
        optimisticUpdate?: () => void
      ) {
        return self.mutate<{ userPromptChangePassword: boolean }>(
          `mutation userPromptChangePassword($email: String!) { userPromptChangePassword(email: $email) }`,
          variables,
          optimisticUpdate
        )
      },
      mutateUserUpdateNickname(
        variables: { nickname: string },
        optimisticUpdate?: () => void
      ) {
        return self.mutate<{ userUpdateNickname: boolean }>(
          `mutation userUpdateNickname($nickname: String!) { userUpdateNickname(nickname: $nickname) }`,
          variables,
          optimisticUpdate
        )
      },
      mutateUserUpdateTrackWarning(
        variables: {
          sendAlert?: boolean
          value?: boolean
          warningType: string
        },
        optimisticUpdate?: () => void
      ) {
        return self.mutate<{ userUpdateTrackWarning: boolean }>(
          `mutation userUpdateTrackWarning($sendAlert: Boolean, $value: Boolean, $warningType: String!) { userUpdateTrackWarning(sendAlert: $sendAlert, value: $value, warningType: $warningType) }`,
          variables,
          optimisticUpdate
        )
      },
      mutateCreateAnnouncement(
        variables: { newAnnouncement: CreateAnnouncementInput },
        resultSelector:
          | string
          | ((
              qb: AnnouncementResponseModelSelector
            ) => AnnouncementResponseModelSelector) = announcementResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void
      ) {
        return self.mutate<{
          createAnnouncement: AnnouncementResponseModelType
        }>(
          `mutation createAnnouncement($newAnnouncement: CreateAnnouncementInput!) { createAnnouncement(newAnnouncement: $newAnnouncement) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new AnnouncementResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate
        )
      },
      mutateUpdateAnnouncement(
        variables: { changeArgs: UpdateAnnouncementInput },
        resultSelector:
          | string
          | ((
              qb: AnnouncementResponseModelSelector
            ) => AnnouncementResponseModelSelector) = announcementResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void
      ) {
        return self.mutate<{
          updateAnnouncement: AnnouncementResponseModelType
        }>(
          `mutation updateAnnouncement($changeArgs: UpdateAnnouncementInput!) { updateAnnouncement(changeArgs: $changeArgs) {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new AnnouncementResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate
        )
      },
      mutateConfirmAnnouncementRead(
        variables: { announcementId: string },
        optimisticUpdate?: () => void
      ) {
        return self.mutate<{ confirmAnnouncementRead: boolean }>(
          `mutation confirmAnnouncementRead($announcementId: String!) { confirmAnnouncementRead(announcementId: $announcementId) }`,
          variables,
          optimisticUpdate
        )
      },
      // post a simgle message into a chat
      mutateChatPostMessage(
        variables: { newMessage: NewMessageInput },
        optimisticUpdate?: () => void
      ) {
        return self.mutate<{ chatPostMessage: boolean }>(
          `mutation chatPostMessage($newMessage: NewMessageInput!) { chatPostMessage(newMessage: $newMessage) }`,
          variables,
          optimisticUpdate
        )
      },
      mutateRegFollowUpUpdateMonitoredAttribute(
        variables: { value: boolean; attribute: string; regId: number },
        optimisticUpdate?: () => void
      ) {
        return self.mutate<{ RegFollowUpUpdateMonitoredAttribute: boolean }>(
          `mutation RegFollowUpUpdateMonitoredAttribute($value: Boolean!, $attribute: String!, $regId: Int!) { RegFollowUpUpdateMonitoredAttribute(value: $value, attribute: $attribute, regId: $regId) }`,
          variables,
          optimisticUpdate
        )
      },
      mutateRegAdditionalDataSeasonCreate(
        variables: { newSeason: NewSeasonInput },
        optimisticUpdate?: () => void
      ) {
        return self.mutate<{ regAdditionalDataSeasonCreate: boolean }>(
          `mutation regAdditionalDataSeasonCreate($newSeason: NewSeasonInput!) { regAdditionalDataSeasonCreate(newSeason: $newSeason) }`,
          variables,
          optimisticUpdate
        )
      },
      mutateRegAdditionalDataSeasonDelete(
        variables: { id: string },
        optimisticUpdate?: () => void
      ) {
        return self.mutate<{ regAdditionalDataSeasonDelete: boolean }>(
          `mutation regAdditionalDataSeasonDelete($id: String!) { regAdditionalDataSeasonDelete(_id: $id) }`,
          variables,
          optimisticUpdate
        )
      },
      mutateRegistrationTestAdd(
        variables: { type: string; date: string; regId: number },
        optimisticUpdate?: () => void
      ) {
        return self.mutate<{ registrationTestAdd: boolean }>(
          `mutation registrationTestAdd($type: String!, $date: String!, $regId: Int!) { registrationTestAdd(type: $type, date: $date, regId: $regId) }`,
          variables,
          optimisticUpdate
        )
      },
      mutateRegistrationTestDelete(
        variables: { id: string },
        optimisticUpdate?: () => void
      ) {
        return self.mutate<{ registrationTestDelete: boolean }>(
          `mutation registrationTestDelete($id: String!) { registrationTestDelete(id: $id) }`,
          variables,
          optimisticUpdate
        )
      },
      mutateRegistrationTestUpdate(
        variables: {
          notes?: string
          outcome?: string
          freeOfCharge?: boolean
          confirmedByGuest?: boolean
          type?: string
          id: string
        },
        optimisticUpdate?: () => void
      ) {
        return self.mutate<{ registrationTestUpdate: boolean }>(
          `mutation registrationTestUpdate($notes: String, $outcome: String, $freeOfCharge: Boolean, $confirmedByGuest: Boolean, $type: String, $id: String!) { registrationTestUpdate(notes: $notes, outcome: $outcome, freeOfCharge: $freeOfCharge, confirmedByGuest: $confirmedByGuest, type: $type, id: $id) }`,
          variables,
          optimisticUpdate
        )
      },
      mutateRegistrationTestPush(
        variables: { direction: string; id: string },
        optimisticUpdate?: () => void
      ) {
        return self.mutate<{ registrationTestPush: boolean }>(
          `mutation registrationTestPush($direction: String!, $id: String!) { registrationTestPush(direction: $direction, id: $id) }`,
          variables,
          optimisticUpdate
        )
      },
      mutateRegistrationTestToggleCharge(
        variables: { id: string },
        optimisticUpdate?: () => void
      ) {
        return self.mutate<{ registrationTestToggleCharge: boolean }>(
          `mutation registrationTestToggleCharge($id: String!) { registrationTestToggleCharge(id: $id) }`,
          variables,
          optimisticUpdate
        )
      },
      subscribeChatNewMessageInMyChat(
        variables?: {},
        resultSelector:
          | string
          | ((
              qb: MessageEdgeModelSelector
            ) => MessageEdgeModelSelector) = messageEdgeModelPrimitives.toString(),
        onData?: (item: any) => void,
        onError?: (error: Error) => void
      ) {
        return self.subscribe<{ chatNewMessageInMyChat: MessageEdgeModelType }>(
          `subscription chatNewMessageInMyChat { chatNewMessageInMyChat {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new MessageEdgeModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          onData,
          onError
        )
      },
      subscribeChatNewMessageInAnyChat(
        variables?: {},
        resultSelector:
          | string
          | ((
              qb: MessageEdgeModelSelector
            ) => MessageEdgeModelSelector) = messageEdgeModelPrimitives.toString(),
        onData?: (item: any) => void,
        onError?: (error: Error) => void
      ) {
        return self.subscribe<{
          chatNewMessageInAnyChat: MessageEdgeModelType
        }>(
          `subscription chatNewMessageInAnyChat { chatNewMessageInAnyChat {
        ${
          typeof resultSelector === 'function'
            ? resultSelector(new MessageEdgeModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          onData,
          onError
        )
      }
    }))
)
