import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Breadcrumbs,
  ConnectedPages,
  Heading2,
  Heading3,
  Heading4,
  Paragraph,
  InfoContainer,
  InfoLink,
  Section,
  Hr,
  InfoPageLayout,
  LayoutSeparator,
  Link,
  AsanaImage,
  ListItem,
  UnorderedList
} from '../../../../components/InfoTypography'

export function InfoFish() {
  return (
    <InfoContainer style={{ wordBreak: 'break-word' }}>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <InfoLink target="">Information</InfoLink>
        <InfoLink target="practice-guide">Practice Guide</InfoLink>
        <InfoLink target="practice-guide/asanas">Asanas</InfoLink>
        <Link $disabled>Fish</Link>
      </Breadcrumbs>
      <Heading2>
        4. Fish (<span className="text-sanskrit">matsyāsana</span>)
      </Heading2>
      <Hr />
      <InfoPageLayout>
        <Section>
          <Paragraph>
            As this asana will facilitate floating on the water, it is called
            the Fish pose or Matsyasan. As counter position to the
            Shoudlerstand, the Fish gives a backward bending stretch to the
            cervical, thoracic and lumbar regions of the spine. To derive
            maximum benefit, the Fish should follow immediately after the
            Shoulderstand. It relieves any congestion and cramp which may have
            been caused by the Shoulderstand.
          </Paragraph>
          <AsanaImage src="/images/asanas/matsyasana.png" alt="Fish Asana" />
          <Heading3>The Fish, with straight legs</Heading3>
          <Heading4>Coming in the position </Heading4>
          <UnorderedList>
            <ListItem>
              Lying flat on the back, bring the feet together.
            </ListItem>
            <ListItem>
              Place the hands, palms flat on the floor beneath the buttocks so
              that you are sitting on your hands.{' '}
            </ListItem>
            <ListItem>
              Pushing with the elbows, lift the chest until you are sitting
              halfway up.
            </ListItem>
            <ListItem>
              Drop the head back so that the top of the head is on the ground.
              The head should be touching the ground, but the weight of the body
              is on the elbows.
            </ListItem>
          </UnorderedList>
          <Heading4>Holding the position</Heading4>
          <Paragraph>
            Pushing with the elbows, arch the chest as high as possible. Feel
            the expansion of the ribcage and, taking advantage of this, breathe
            as deeply as possible, expanding the ribcage with each inhalation.
            The fish is held for half as long as the Shoulderstand. (For
            example, if the Shoulderstand was held for 3 minutes, the Fish would
            be held for 1.5 minutes).
          </Paragraph>
          <Heading4>Coming out of the position</Heading4>
          <Paragraph>
            Lift the head, sliding it back. Straighten the back and lower the
            body to the ground. Relax in the corpse pose.
          </Paragraph>
          <Heading3>Variation of the Fish, with the legs crossed</Heading3>
          <UnorderedList>
            <ListItem>Sit in a simple cross-legged position.</ListItem>
            <ListItem>
              Bringing the arms around the outside of the knees, catch hold of
              the toes.
            </ListItem>
            <ListItem>Lie back.</ListItem>
            <ListItem>
              Arch the back up, shift the buttocks slightly forward onto the
              feet. Both knees should be on the ground. Do not release the toes.
              <br />
              Arch the back as high as possible keeping the buttocks on the
              heels. Try to keep the majority of weight on the elbows rather
              than the head or neck.
            </ListItem>
          </UnorderedList>
          <Heading3>Variation of the Fish, in the Lotus</Heading3>
          <UnorderedList>
            <ListItem>
              Sit up; bring the legs into the tightest Lotus possible.
            </ListItem>
            <ListItem>Catch hold of the toes.</ListItem>
            <ListItem>Lie back.</ListItem>
            <ListItem>
              Arch up into the Fish. Try to keep both knees on the ground.
            </ListItem>
          </UnorderedList>
          <Paragraph>
            After completing the Fish rest and relax in the corpse pose. Shake
            out the shoulders to relieve any tension then roll the neck slowly
            from side to side once or twice to relax the neck.
          </Paragraph>
          <Heading3>Common Mistakes</Heading3>
          <Paragraph>
            Never do Pranayama in the Fish. breathing should be deep, but smooth
            and silent. When doing the Fish in a cross-legged or Lotus position,
            do not allow either knee to come up. The body should be aligned with
            both knees on the ground.
          </Paragraph>
          <Heading3>Incorrect straight legged Fish pose</Heading3>
          <UnorderedList>
            <ListItem>Body not straight</ListItem>
            <ListItem>Buttocks lifting off</ListItem>
            <ListItem>hands Knees bent</ListItem>
          </UnorderedList>
          <Heading3>Incorrect crossed leg Fish pose</Heading3>
          <UnorderedList>
            <ListItem>
              Back of head on the ground instead of top of head
            </ListItem>
            <ListItem>Knees lifting off ground</ListItem>
          </UnorderedList>
          <Heading3>Incorrect Fish in Lotus</Heading3>
          <UnorderedList>
            <ListItem>Hands not grasping toes.</ListItem>
            <ListItem>Not a tight Lotus.</ListItem>
            <ListItem>One or both knees lifting.</ListItem>
          </UnorderedList>
          <Heading3>Benefits of the Fish</Heading3>
          <Heading4>Physical Benefits</Heading4>
          <UnorderedList>
            <ListItem>
              The Fish removes stiffness from the cervical, thoracic, and lumbar
              regions, bringing an increase of blood supply to these parts.
            </ListItem>
            <ListItem>
              A natural massage is given to the shoulders and neck.
            </ListItem>
            <ListItem>Round-shoulders are corrected.</ListItem>
            <ListItem>
              In the Fish, the rown wide open, so deep breathing may easily be
              practised. This will:
              <UnorderedList>
                <ListItem>Increase lung capacity.</ListItem>
                <ListItem>Relieve spasm in the bronchial tubes.</ListItem>
                <ListItem>Relieve asthma.</ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>
              The cervical and upper dorsal nerves are nourished and toned with
              an increased supply of blood.
            </ListItem>
            <ListItem>
              While in the Fish pose, the energy of the body is focused on the
              Parathyroid glands. There are 4 small endocrine glands embedded in
              the thyroid tissue in the neck. The function of the Parathyroid
              gland is to regulate calcium levels in the blood and absorption by
              the body. This is critically important in relation to:
              <UnorderedList>
                <ListItem>
                  Contraction of all muscles including the heart.
                </ListItem>
                <ListItem>Clotting of blood.</ListItem>
                <ListItem>Bone strength, plasticity, and brittleness.</ListItem>
                <ListItem>Prevention of tooth decay.</ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>
              If calcium is not being assimilated properly by the body, muscle
              spasms may occur which may interfere with such essential functions
              as respiration and may be fatal.
            </ListItem>
            <ListItem>
              The Pituitary and Pineal glands, located in the brain, are
              stimulated and toned. The Pituitary is considered to be the
              “Master Gland” because it regulates secretion of all other
              endocrine glands. However, this gland is itself controlled by the
              brain and mediates the effects of the Central Nervous System on
              hormonal activity in the body.
            </ListItem>
          </UnorderedList>
          <Heading4>Mental Benefits </Heading4>
          <UnorderedList>
            <ListItem>Moods, emotions, and stress are regulated.</ListItem>
          </UnorderedList>
          <Heading4>Psychic Benefits</Heading4>
          <UnorderedList>
            <ListItem>
              Increased Prana is brought to the neck and shoulder area.
            </ListItem>
            <ListItem>
              The Practice of the Fish in the Lotus prevents loss of Prana
              through the lower limbs.
            </ListItem>
          </UnorderedList>
        </Section>
        <ConnectedPages>
          <LayoutSeparator />
          <Section>
            <Paragraph>
              <strong>Connected Articles:</strong>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas">Asanas</InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/sun-salutations">
                Sun Salutations (
                <span className="text-sanskrit">sūrya namaskāra</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/headstand">
                1. Headstand (<span className="text-sanskrit">śirṣāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/plough">
                3. Plough (<span className="text-sanskrit">halāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/fish">
                4. Fish (<span className="text-sanskrit">matsyāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/forward-bend">
                5. Forward Bend (
                <span className="text-sanskrit">pascimottanāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/cobra">
                6. Cobra (<span className="text-sanskrit">bhujaṅgāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/locust">
                7. Locust (<span className="text-sanskrit">śalabhāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/bow">
                8. Bow (<span className="text-sanskrit">dhanurāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/half">
                9. Half Spinal Twist (
                <span className="text-sanskrit">ardha matsyendrāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/crow">
                10. Crow (<span className="text-sanskrit">kakāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/hands">
                11. Hands to Feet (
                <span className="text-sanskrit">pāda hastāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/triangle">
                12. Triangle (<span className="text-sanskrit">trikonāsana</span>
                )
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/savasana">
                Final Relaxation (
                <span className="text-sanskrit">śavāsana</span>)
              </InfoLink>
            </Paragraph>
          </Section>
        </ConnectedPages>
      </InfoPageLayout>
    </InfoContainer>
  )
}
