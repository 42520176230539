import * as React from 'react'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import styled from 'styled-components'
import { StoreContext } from '@sayr/client-models'
import { UseMutationResult } from 'react-query'
import { PrimaryButton } from '../components/common/Buttons'
import {
  getDeferredInstallEvent,
  isInstallable,
  setUninstallable
} from '../loaders/makeInstallable'
import {
  Exact,
  NewSubscriptionInput,
  UserCreateSubscriptionMutation,
  useUserCreateSubscriptionMutation
} from '../graphql/generated/typesAndHooks'
import { convertDataURIToBinary } from '../services/utils'

type SubscriptionObject = {
  endpoint: string
  // expirationTime: unknown
  keys: {
    p256dh: string
    auth: string
  }
}

const StyledHomepage = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 1rem;
`

const Main = styled.main`
  flex: 1;
`

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
`

const MaybeVisibleButton = styled(PrimaryButton)<{ $visible: boolean }>`
  && {
    display: ${({ $visible }) => !$visible && 'none'};
  }
`

type SubscriptionMutation = UseMutationResult<
  UserCreateSubscriptionMutation,
  unknown,
  Exact<{
    newSubscription: NewSubscriptionInput
  }>,
  unknown
>

export const Homepage = () => {
  const store = React.useContext(StoreContext)
  const [, forceUpdate] = React.useReducer(x => x + 1, 0)
  const [canEnableNotifications, setCanEnableNotifications] =
    React.useState(false)

  React.useEffect(() => {
    canSubscribe().then(setCanEnableNotifications)
  }, [])

  const createSubscriptionMutation = useUserCreateSubscriptionMutation({
    onSuccess: () => {
      navigator.serviceWorker.ready.then(swReg => {
        displayConfirmaNotification(swReg)
        setCanEnableNotifications(false)
      })
    },

    onError: () => {
      console.log('Could not subscribe user to notifications.')
    }
  })

  async function installApp() {
    const deferredInstallEvent = getDeferredInstallEvent()

    deferredInstallEvent?.prompt()

    const result = await deferredInstallEvent?.userChoice

    if (result?.outcome === 'accepted') {
      console.log('user agreed to install')

      setUninstallable()
      setTimeout(() => forceUpdate())
    } else {
      console.log('user declined to install')
    }
  }

  function handleEnableClick() {
    if (store.loggedInUser)
      subscribe(createSubscriptionMutation, store.loggedInUser.id)
  }

  if (!store.loggedInUser) return <>Not logged In</>
  return (
    <StyledHomepage>
      <Main>
        <Typography variant="h2">{`Welcome ${store.loggedInUser.user?.nickName}`}</Typography>
        <br />
        <MaybeVisibleButton $visible={isInstallable()} onClick={installApp}>
          Install App
        </MaybeVisibleButton>
        <br />
        <MaybeVisibleButton
          $visible={canEnableNotifications}
          onClick={handleEnableClick}
        >
          Enable Notifications
        </MaybeVisibleButton>
      </Main>
      <hr />
      <Footer>
        <Typography variant="caption">
          Sivananda Ashram Yoga Retreat, Bahamas
        </Typography>
        <Typography variant="caption">
          <Link
            href="https://sivanandabahamas.org/terms-conditions/"
            target="_blank"
          >
            Privacy Policy
          </Link>
        </Typography>
      </Footer>
    </StyledHomepage>
  )
}

async function subscribe(
  createSubscriptionMutation: SubscriptionMutation,
  userId: string
) {
  if ('Notification' in window && (await canSubscribe())) {
    const result = await Notification.requestPermission()

    if (result === 'granted') {
      const swReg = await navigator.serviceWorker.ready
      await configurePushSubscription(swReg, createSubscriptionMutation, userId)
    }
  }
}

async function canSubscribe() {
  if (!('serviceWorker' in navigator)) return false

  const swReg = await navigator.serviceWorker.ready
  const subscription = await swReg.pushManager.getSubscription()

  // can only subscribe if there is no active subscription.
  return subscription === null
}

async function configurePushSubscription(
  swReg: ServiceWorkerRegistration,
  createSubscriptionMutation: SubscriptionMutation,
  userId: string
) {
  if (!('serviceWorker' in navigator)) return

  const vapidPublicKey = convertDataURIToBinary(
    process.env.REACT_APP_VAPID_PUBLIC_KEY as string
  )

  if (await canSubscribe()) {
    const newSubscription = await swReg.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: vapidPublicKey
    })

    const subscriptionObj: SubscriptionObject = JSON.parse(
      JSON.stringify(newSubscription)
    )

    createSubscriptionMutation.mutate({
      newSubscription: {
        endpoint: subscriptionObj.endpoint,
        authKey: subscriptionObj.keys.auth,
        p256dhKey: subscriptionObj.keys.p256dh,
        userAgent: navigator.userAgent,
        userId
      }
    })
  } else {
    alert(
      'Thanks. You are already subscribed to notifications. You can reset your subscription by clearing this permission in the site settings in your browser.'
    )
  }
}

function displayConfirmaNotification(swReg: ServiceWorkerRegistration) {
  swReg.showNotification('Alright!', {
    body: 'You have enabled notifications',
    icon: '/logo-192.png',
    badge: '/logo-96.png'
  })
}
