import React, { MouseEventHandler } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import AdjustIcon from '@mui/icons-material/Adjust'
import Avatar from '@mui/material/Avatar'
import Badge from '@mui/material/Badge'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MuiDrawer from '@mui/material/Drawer'
import Typography from '@mui/material/Typography'
import PeopleIcon from '@mui/icons-material/People'
import AnnouncementIcon from '@mui/icons-material/Announcement'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import ChatIcon from '@mui/icons-material/Chat'
import EventIcon from '@mui/icons-material/Event'
import HomeIcon from '@mui/icons-material/Home'
import InfoIcon from '@mui/icons-material/Info'
import LogoutIcon from '@mui/icons-material/Logout'
import MapIcon from '@mui/icons-material/Map'
import SettingsIcon from '@mui/icons-material/Settings'
import VpnKeyIcon from '@mui/icons-material/VpnKey'

import { StoreContext } from '@sayr/client-models'
import { getPageTitle } from '../services/pageTitles'

const StyledList = styled(List).attrs({ className: 'drawer-list' })`
  && {
    width: 15.625rem;
    padding-top: 0;

    @media (max-width: 20em) {
      & > .MuiListItem-root {
        padding-top: 0.5rem;
      }
    }

    & > .MuiDivider-root + .MuiListItem-root {
      margin-top: 0.5rem;
    }
  }
`

const StyledListItemIcon = styled(ListItemIcon).attrs({
  className: 'list-item-icon'
})`
  && {
    min-width: 2.8rem;
  }
`

function Drawer({
  open,
  toggleDrawer
}: {
  open: boolean
  toggleDrawer: (open: boolean) => MouseEventHandler<HTMLElement>
}) {
  const store = React.useContext(StoreContext)
  if (!store.loggedInUser) return <></>

  return (
    <div>
      <MuiDrawer anchor="left" open={open} onClose={toggleDrawer(false)}>
        <div role="navigation" onClick={toggleDrawer(false)}>
          <StyledList>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  alt={
                    store.loggedInUser.user?.nickName ||
                    store.loggedInUser.email
                  }
                  src={store.loggedInUser.user?.profilePhoto || ''}
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  store.loggedInUser.user?.nickName || store.loggedInUser.email
                }
              />
            </ListItem>
            <Divider />
            <ListItem button onClick={() => store.view.openHomePage()}>
              <StyledListItemIcon>
                <HomeIcon />
              </StyledListItemIcon>
              <Typography>{getPageTitle({ page: '/' })}</Typography>
            </ListItem>
            {(store.loggedInUser.user?.$permissions?.viewPeopleLists ||
              store.loggedInUser.user?.$permissions?.generalPopulation) && (
              <ListItem
                button
                onClick={() => store.view.openRegistrationsPage()}
              >
                <StyledListItemIcon>
                  <PeopleIcon />
                </StyledListItemIcon>
                <Typography>
                  {getPageTitle({ page: '/registrations' })}
                </Typography>
              </ListItem>
            )}
            <ListItem button onClick={() => store.view.openAnnouncementsPage()}>
              <StyledListItemIcon>
                <Badge
                  badgeContent={store.announcements.unread.length}
                  color="secondary"
                >
                  <AnnouncementIcon />
                </Badge>
              </StyledListItemIcon>
              <Typography>
                {getPageTitle({ page: '/announcements' })}
              </Typography>
            </ListItem>
            <ListItem button onClick={() => store.view.openChatPage()}>
              <StyledListItemIcon>
                <Badge
                  badgeContent={Array.from(store.chatResponses.values()).reduce(
                    (totalUnread, chat) => totalUnread + chat.unreadCountShown,
                    0
                  )}
                  color="secondary"
                >
                  <ChatIcon />
                </Badge>
              </StyledListItemIcon>
              <Typography>{getPageTitle({ page: '/chat' })}</Typography>
            </ListItem>
            <ListItem
              button
              onClick={() => store.view.openActivitiesPage()}
              disabled
            >
              <StyledListItemIcon>
                <EventIcon />
              </StyledListItemIcon>
              <Typography>{getPageTitle({ page: '/activities' })}</Typography>
            </ListItem>
            <ListItem button disabled>
              <StyledListItemIcon>
                <AccountBoxIcon />
              </StyledListItemIcon>
              <Typography>My Bookings</Typography>
            </ListItem>
            <ListItem button onClick={() => store.view.openInfoPage()}>
              <StyledListItemIcon>
                <InfoIcon />
              </StyledListItemIcon>
              <Typography>{getPageTitle({ page: '/info' })}</Typography>
            </ListItem>
            <ListItem button onClick={() => store.view.openAshramMapPage()}>
              <StyledListItemIcon>
                <MapIcon />
              </StyledListItemIcon>
              <Typography>{getPageTitle({ page: '/ashram-map' })}</Typography>
            </ListItem>
            <Divider />
            {(store.loggedInUser.user?.$permissions?.adminFollowUpWrite ||
              store.loggedInUser.user?.$permissions?.sendEmails ||
              store.loggedInUser.user?.$permissions?.accessWarnings) && (
              <ListItem button onClick={() => store.view.openAdminPage()}>
                <StyledListItemIcon>
                  <AdjustIcon />
                </StyledListItemIcon>
                <Typography>{getPageTitle({ page: '/admin' })}</Typography>
              </ListItem>
            )}
            {store.loggedInUser.user?.$permissions?.upgradeOtherUsers && (
              <ListItem button onClick={() => store.view.openUsersPage()}>
                <StyledListItemIcon>
                  <VpnKeyIcon />
                </StyledListItemIcon>
                <Typography>{getPageTitle({ page: '/users' })}</Typography>
              </ListItem>
            )}
            <ListItem button onClick={() => store.view.openSettingsPage()}>
              <StyledListItemIcon>
                <SettingsIcon />
              </StyledListItemIcon>
              <Typography>{getPageTitle({ page: '/settings' })}</Typography>
            </ListItem>
            <ListItem button onClick={() => store.logout()}>
              <StyledListItemIcon>
                <LogoutIcon />
              </StyledListItemIcon>
              <Typography>Logout</Typography>
            </ListItem>
          </StyledList>
        </div>
      </MuiDrawer>
    </div>
  )
}

export default observer(Drawer)
