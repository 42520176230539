import styled from 'styled-components'

export type PageState =
  | 'cover-page'
  | 'standard-login-page'
  | 'manual-signup-page'
  | 'forgot-password-prompt-page'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`
