import * as React from 'react'
import { useForm } from 'react-hook-form'
import * as EmailValidator from 'email-validator'
import styled from 'styled-components'
import Alert from '@mui/material/Alert'
import CircularProgress from '@mui/material/CircularProgress'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { observer } from 'mobx-react-lite'
import {
  Field,
  FormError,
  FormTextField,
  Wrapper
} from '../../components/common/Forms'
import { PrimaryButton } from '../../components/common/Buttons'
import { StoreContext } from '@sayr/client-models'
import { PageState } from './LoginCommon'

// todo: duplicate with LoginPage.tsx. Remove one
const Form = styled.form`
  display: flex;
  flex-direction: column;
`

const SignupProgressReport = styled.div.attrs({ className: 'progress-report' })`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

type FormInputs = {
  email: string
}

function ForgotPasswordPromptPage({
  setPageState
}: {
  setPageState: (newState: PageState) => void
}) {
  const store = React.useContext(StoreContext)
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setFocus
  } = useForm<FormInputs>({
    mode: 'onTouched'
  })

  const [state, setState] = React.useState<
    'editing' | 'processing' | 'processed' | 'failed'
  >('editing')

  async function onSubmit(data: FormInputs) {
    setState('processing')
    const { email } = data
    try {
      const { userPromptChangePassword } =
        await store.mutateUserPromptChangePassword({ email })
      if (userPromptChangePassword) setState('processed')
      else setState('failed')
    } catch (e) {
      console.error(e)
      alert('error')
    }
  }

  const email = register('email', {
    required: { value: true, message: 'Email address required' },
    validate: async value => {
      // the if email pattern is valid
      return EmailValidator.validate(value) ? true : 'Email is not valid'
    }
  })
  React.useEffect(() => console.log('erors', errors))
  return (
    <>
      <Typography
        style={{
          marginTop: '2rem',
          marginLeft: '1rem',
          marginBottom: '1rem'
        }}
        variant="h3"
      >
        Forgot your password?
      </Typography>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Wrapper bottomSpacing={!!errors.email}>
          <Field>
            <FormTextField
              autoFocus
              disabled={state !== 'editing'}
              variant="outlined"
              fullWidth
              size="small"
              id="email"
              label="Email"
              autoComplete="username"
              name={email.name}
              onChange={e => {
                e.target.value = e.target.value.toLowerCase()
                email.onChange(e)
              }}
              onBlur={email.onBlur}
              inputRef={email.ref}
            />
            {errors.email && <FormError>{errors.email.message}</FormError>}
          </Field>
        </Wrapper>

        <PrimaryButton disabled={state !== 'editing'} type="submit">
          Submit
        </PrimaryButton>
      </Form>
      <SignupProgressReport>
        {state === 'processing' && (
          <>
            <CircularProgress />
            <Typography>Processing your request...</Typography>
          </>
        )}
        {state === 'processed' && (
          <Alert severity="success">
            Please check your email to change your password. You might have to
            check your spam folder.
          </Alert>
        )}
        {state === 'failed' && (
          <Alert severity="error">
            We do not find a user with the email {getValues('email')} in our
            records.
            <br />
            You can either{' '}
            <Link
              component="button"
              onClick={() => setPageState('manual-signup-page')}
            >
              Sign up
            </Link>{' '}
            or{' '}
            <Link
              component="button"
              onClick={() => {
                setState('editing')
                setTimeout(() => setFocus('email'))
              }}
            >
              try another email address
            </Link>
            .
          </Alert>
        )}
      </SignupProgressReport>
      {state === 'editing' && (
        <Link
          component="button"
          onClick={() => setPageState('standard-login-page')}
        >
          Back to login page
        </Link>
      )}
    </>
  )
}

export default observer(ForgotPasswordPromptPage)
