export function getPageTitle({ page, id }: { page: string; id?: string }) {
  switch (page) {
    case '/':
      return 'Home Page'
    case '/admin':
      return 'Admin Functions'
    case '/admin/additional-data-seasons':
      return 'All seasons'
    case '/admin/additional-data-seasons/new':
      return 'Create a new season'
    case '/admin/warning-setup':
      return 'Warning System Setup'
    case '/admin/warnings':
      return 'Warnings'
    case '/announcements':
      return 'Announcements'
    case '/announcements/new':
      return 'New Announcement'
    case '/announcements/edit':
      return 'Edit Announcement'
    case '/announcements/stats':
      return `Announcement ${id} Stats`
    case '/chat':
      return 'Chat'
    case (page.match(/^\/info\/?/) || {}).input:
      return 'Information'
    case '/login':
      return 'Login'
    case '/ashram-map':
      return 'Ashram Map'
    case '/my-bookings':
      return 'My Bookings'
    case (page.match(/^\/registrations(\/|$)/) || {}).input:
      const subPage = page.replace(/^\/registrations/, '').slice(1)

      switch (subPage) {
        case 'arriving-today':
          return 'Arriving Today'
        case 'arriving-tomorrow':
          return 'Arriving Tomorrow'
        case 'departures-today':
          return 'Departures Today'
        case 'departures-tomorrow':
          return 'Departures Tomorrow'
        case 'cash-flow-report':
          return 'Cash Flow Report'
        case 'population-report':
          return 'Population Report'
        case 'follow-up-intrastay-tests-day-view':
          return 'Follow up on Intrastay Tests - Day view'
        case 'follow-up-on-arrivals':
          return 'Follow up on Arrivals'
        case 'follow-up-on-in-house':
          return 'Follow up on In-House Registrations'
        case 'covid-test-calendar-view':
          return 'COVID Tests - Calendar View'
        default:
          return 'People'
      }
    case '/settings':
      return 'Settings'
    case '/users':
      return 'Users'
    case '/activities':
      return 'Activities'
    default:
      return 'No Title...'
  }
}
