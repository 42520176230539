import { Instance } from 'mobx-state-tree'
import { AnnouncementStatsResponseModelBase } from './AnnouncementStatsResponseModel.base'
import { PersonResponseModelType } from './PersonResponseModel'

/* The TypeScript type of an instance of AnnouncementStatsResponseModel */
export interface AnnouncementStatsResponseModelType
  extends Instance<typeof AnnouncementStatsResponseModel.Type> {}

/* A graphql query fragment builders for AnnouncementStatsResponseModel */
export {
  selectFromAnnouncementStatsResponse,
  announcementStatsResponseModelPrimitives,
  AnnouncementStatsResponseModelSelector
} from './AnnouncementStatsResponseModel.base'

/**
 * AnnouncementStatsResponseModel
 */
export const AnnouncementStatsResponseModel =
  AnnouncementStatsResponseModelBase.views(self => ({
    get $person() {
      return self.person as PersonResponseModelType
    }
  }))
