// @ts-nocheck
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { QueryBuilder } from 'mst-gql'
import { ModelBase } from './ModelBase'
import {
  MessageResponseModel,
  MessageResponseModelType
} from './MessageResponseModel'
import { MessageResponseModelSelector } from './MessageResponseModel.base'
import { RootStoreType } from './index'

/**
 * MessageEdgeBase
 * auto generated base class for the model MessageEdgeModel.
 */
export const MessageEdgeModelBase = ModelBase.named('MessageEdge')
  .props({
    __typename: types.optional(types.literal('MessageEdge'), 'MessageEdge'),
    node: types.union(
      types.undefined,
      types.late((): any => MessageResponseModel)
    ),
    /** Used in `before` and `after` args */
    cursor: types.union(types.undefined, types.string)
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class MessageEdgeModelSelector extends QueryBuilder {
  get cursor() {
    return this.__attr(`cursor`)
  }
  node(
    builder:
      | string
      | MessageResponseModelSelector
      | ((
          selector: MessageResponseModelSelector
        ) => MessageResponseModelSelector)
      | undefined
  ) {
    return this.__child(`node`, MessageResponseModelSelector, builder)
  }
}
export function selectFromMessageEdge() {
  return new MessageEdgeModelSelector()
}

export const messageEdgeModelPrimitives = selectFromMessageEdge().cursor
