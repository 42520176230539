// @ts-nocheck
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { QueryBuilder } from 'mst-gql'
import { ModelBase } from './ModelBase'
import {
  PersonResponseModel,
  PersonResponseModelType
} from './PersonResponseModel'
import { PersonResponseModelSelector } from './PersonResponseModel.base'
import { RootStoreType } from './index'

/**
 * AnnouncementStatsResponseBase
 * auto generated base class for the model AnnouncementStatsResponseModel.
 */
export const AnnouncementStatsResponseModelBase = ModelBase.named(
  'AnnouncementStatsResponse'
)
  .props({
    __typename: types.optional(
      types.literal('AnnouncementStatsResponse'),
      'AnnouncementStatsResponse'
    ),
    person: types.union(
      types.undefined,
      types.late((): any => PersonResponseModel)
    ),
    readTimestamp: types.union(types.undefined, types.null, types.frozen())
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class AnnouncementStatsResponseModelSelector extends QueryBuilder {
  get readTimestamp() {
    return this.__attr(`readTimestamp`)
  }
  person(
    builder:
      | string
      | PersonResponseModelSelector
      | ((selector: PersonResponseModelSelector) => PersonResponseModelSelector)
      | undefined
  ) {
    return this.__child(`person`, PersonResponseModelSelector, builder)
  }
}
export function selectFromAnnouncementStatsResponse() {
  return new AnnouncementStatsResponseModelSelector()
}

export const announcementStatsResponseModelPrimitives =
  selectFromAnnouncementStatsResponse().readTimestamp
