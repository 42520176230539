import React from 'react'
import { observer } from 'mobx-react-lite'
import AdminRegistrationsView from './AdminRegistrationsView'
import RegistrationsList from './RegistrationsList'
import { StoreContext } from '@sayr/client-models'

function RegistrationsEntry() {
  const store = React.useContext(StoreContext)
  return (
    <>
      {store.view.page === '/registrations' && store.view.id && (
        <div style={{ marginTop: '2rem', textAlign: 'center' }}>
          Sorry, not implemented yet...
        </div>
      )}
      {store.view.page === '/registrations' && !store.view.id && (
        <AdminRegistrationsView />
      )}
      {store.view.page.match(/^\/registrations\/.+/) && (
        <RegistrationsList
          filter={store.view.page.replace(/^\/registrations\//, '')}
        />
      )}
    </>
  )
}

export default observer(RegistrationsEntry)
