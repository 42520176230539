import React from 'react'
import { observer } from 'mobx-react-lite'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import { StoreContext } from '@sayr/client-models'

function DarkModeSwitch({ className }: { className?: string }) {
  const store = React.useContext(StoreContext)

  return (
    <>
      <FormControlLabel
        className={className}
        control={
          <Switch
            checked={store.preferences.darkMode}
            onChange={() => store.preferences.toggleDarkMode()}
            name="dark-mode-switch"
          />
        }
        label="Dark Mode"
      />
      {/* <Typography component="label">Dark Mode</Typography> */}
    </>
  )
}

export default observer(DarkModeSwitch)
