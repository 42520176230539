// @ts-nocheck
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { UserResponseModel, UserResponseModelType } from './UserResponseModel'
import { UserResponseModelSelector } from './UserResponseModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  author: UserResponseModelType
  chat: UserResponseModelType
}

/**
 * MessageResponseBase
 * auto generated base class for the model MessageResponseModel.
 */
export const MessageResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named('MessageResponse')
    .props({
      __typename: types.optional(
        types.literal('MessageResponse'),
        'MessageResponse'
      ),
      _id: types.union(types.undefined, types.string),
      author: types.union(
        types.undefined,
        MSTGQLRef(types.late((): any => UserResponseModel))
      ),
      chat: types.union(
        types.undefined,
        MSTGQLRef(types.late((): any => UserResponseModel))
      ),
      content: types.union(types.undefined, types.string)
    })
    .views(self => ({
      get store() {
        return self.__getStore<RootStoreType>()
      }
    }))
)

export class MessageResponseModelSelector extends QueryBuilder {
  get _id() {
    return this.__attr(`_id`)
  }
  get content() {
    return this.__attr(`content`)
  }
  author(
    builder:
      | string
      | UserResponseModelSelector
      | ((selector: UserResponseModelSelector) => UserResponseModelSelector)
      | undefined
  ) {
    return this.__child(`author`, UserResponseModelSelector, builder)
  }
  chat(
    builder:
      | string
      | UserResponseModelSelector
      | ((selector: UserResponseModelSelector) => UserResponseModelSelector)
      | undefined
  ) {
    return this.__child(`chat`, UserResponseModelSelector, builder)
  }
}
export function selectFromMessageResponse() {
  return new MessageResponseModelSelector()
}

export const messageResponseModelPrimitives =
  selectFromMessageResponse()._id.content
