import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Breadcrumbs,
  Heading2,
  Heading3,
  Paragraph,
  ListItem,
  InfoContainer,
  InfoLink,
  Section,
  Hr,
  InfoPageLayout,
  LayoutSeparator,
  Link,
  ConnectedPages,
  OrderedList
} from '../../../../components/InfoTypography'

export function InfoProperBreathing() {
  return (
    <InfoContainer style={{ wordBreak: 'break-word' }}>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <InfoLink target="">Information</InfoLink>
        <InfoLink target="practice-guide">Practice Guide</InfoLink>
        <InfoLink target="practice-guide/introduction">Introduction</InfoLink>
        <Link $disabled>2. Proper Breathing</Link>
      </Breadcrumbs>
      <Heading2>2. Proper Breathing</Heading2>
      <Hr />
      <InfoPageLayout>
        <Section>
          <Heading3>Proper Breathing in General</Heading3>
          <Paragraph>
            Most people use only a fraction of their lung capacity for
            breathing. <br />
            They breathe shallowly, barely expanding the ribcage. Their
            shoulders are hunched, they have painful tension in the upper parts
            of the back and neck, and they suffer from lack of oxygen.
            <br /> These people become tired easily and don’t know why.
          </Paragraph>
          <Paragraph>
            Thoughtful management and husbanding of breath can be of practical
            daily aid; it can tone up and contribute to health and vitality.
            <br /> Increased vital capacity is the object of all breath
            discipline. Even in a day of escalators and elevators there are
            still stairs to climb.
          </Paragraph>
          <Paragraph>
            If you tire easily when climbing stairs, try this: <br />
            Keeping the shoulder blades in position, inhale as you climb the
            first two steps, and exhale on the next two, you can glide up a
            flight of stairs and arrive on the top without grasping for breath.
            <br /> By quickening the breath rhythmically while climbing, we
            expel a greater amount of carbon dioxide and take in a great supply
            of oxygen, thus experiencing less fatigue.
          </Paragraph>

          <Heading3>Breathe to Live</Heading3>
          <Paragraph>
            What the unfortunate majority of us need these days is a breathing
            program that can help us at our desks, stoves, and machines.
            <br />
            Tension and even depression may be overcome by the following
            exercise.
          </Paragraph>
          <Paragraph>
            Place the shoulder blades as close together as you can without
            strain and exhale gently and fully.
            <br /> Pause, and then inhale with a deep, slow, gentle breath until
            the lungs are comfortably filled. <br />
            Breathe out slowly through the nose with a long sigh and without
            altering the position of the shoulder blades.
            <br /> Do this a dozen times and depression will disappear.
            <br /> You will have stimulated the brain and eased nerve tension by
            providing a fuller supply of life-giving oxygen.
          </Paragraph>
          <Paragraph>There are three basic types of breathing.</Paragraph>
          <OrderedList>
            <ListItem>
              <strong>Clavicular</strong> breathing is the most shallow and
              worst possible type. The shoulder and collarbone are raised while
              the abdomen is contracted during inhalation. Maximum effort is
              made, but a minimum amount of air is obtained.
            </ListItem>
            <ListItem>
              <strong>Intercostal</strong> breathing is done with the rib
              muscles expanding the rib cage, and is the second type of
              incomplete breathing.
            </ListItem>
            <ListItem>
              <strong>Deep abdominal</strong> breathing is the best, for it
              brings air to the lowest and largest part of the lungs. Breathing
              is slow and deep, and proper use is made of the diaphragm.
            </ListItem>
          </OrderedList>
          <Paragraph>
            Actually, none of these types are complete.
            <br /> A full Yogic breath combines all three, beginning with a deep
            breath and continuing the inhalation through the intercostal and
            clavicular areas.
          </Paragraph>
          <Paragraph>
            To get the feel of proper diaphragmatic breathing, wear loose
            clothing and lie on the back.
            <br /> Place the hand on the upper abdomen, where the diaphragm is
            located. <br />
            Breathe in and out slowly.
            <br /> The abdomen should expand outward as you inhale and contract
            as you exhale.
            <br /> Try to get the feeling of this motion. <br />
            Breathe in slowly, expand the abdomen, then the ribcage, and finally
            the upper portion of the lungs.
            <br /> Then, breathe out in the same manner, letting the abdomen
            cave in as you exhale. This is the Yogic complete breath.
          </Paragraph>
          <Heading3>Pranayama</Heading3>
          <Paragraph>
            By far, the most important thing about good breathing is the{' '}
            <span className="text-sanskrit">
              <strong>
                <em>Prāṇa</em>
              </strong>
            </span>
            , or subtle energy of the vital breath. <br />
            Control of the Prana leads to control of the mind.
          </Paragraph>
          <Paragraph>
            Breathing exercises are called{' '}
            <span className="text-sanskrit">
              <strong>
                <em>Prāṇāyāma</em>
              </strong>
            </span>
            , which means to control the Prana.
            <br /> The grossest manifestation of Prana in the human body is the
            motion of the lungs.
            <br /> This motion acts like the flywheel that sets the other forces
            of the body in motion.
            <br />
            Pranayama means controlling this motion of the lungs, by which the
            subtle Prana is controlled.
            <br /> When the subtle Prana is controlled, all other gross
            manifestations of Prana in the body with slowly come under control.
          </Paragraph>
          <Paragraph>
            Every part of the body can be filled with Prana, when we are able to
            do this, the whole body will be under our control.
            <br />
            All diseases of the body can be destroyed at the root by controlling
            and regulating the Prana; this is the secret knowledge of healing.
          </Paragraph>
          <Paragraph>
            In ordinary breathing we extract Prana only very little. But when we
            concentrate and consciously regulate the breathing, we are able to
            store a greater amount.
            <br /> He who has abundant Pranic energy radiates vitality and
            strength, which can be felt by those coming into contact with him.
          </Paragraph>
        </Section>
        <ConnectedPages>
          <LayoutSeparator />
          <Section>
            <Paragraph>
              <strong>Connected Articles:</strong>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/introduction/five-points-of-yoga">
                General Introduction
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/introduction/proper-exercise">
                1. Proper Exercise
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/introduction/proper-relaxation">
                3. Proper Relaxation
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/introduction/proper-diet">
                4. Proper Diet
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/introduction/proper-thinking">
                5. Proper Thinking
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/breathing-techniques">
                Breathing Techniques
              </InfoLink>
            </Paragraph>
          </Section>
        </ConnectedPages>
      </InfoPageLayout>
    </InfoContainer>
  )
}
