import React from 'react'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Breadcrumbs,
  InfoLink,
  Link,
  MediaCard,
  StyledCardMedia,
  StyledPageContentWrapper
} from '../../../../components/InfoTypography'
import { StoreContext } from '@sayr/client-models'

export function InfoMeditation() {
  const store = React.useContext(StoreContext)
  return (
    <StyledPageContentWrapper>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <InfoLink target="">Information</InfoLink>
        <InfoLink target="practice-guide">Practice Guide</InfoLink>
        <Link $disabled>Meditation</Link>
      </Breadcrumbs>
      <MediaCard
        topic="An Introduction to the Technique of Meditation"
        description=""
        action={() => {
          store.view.openInfoPage('practice-guide/meditation/introduction')
        }}
      >
        <StyledCardMedia
          image="https://online.sivanandabahamas.org/wp-content/uploads/2020/06/CKarmaYogi-1024x579.jpg"
          title="Krishna walking on the beach with a yoga mat"
        />
      </MediaCard>
      <MediaCard
        topic="The Technique of Meditation - Step by Step"
        description=""
        action={() => {
          store.view.openInfoPage('practice-guide/meditation/technique')
        }}
      >
        <StyledCardMedia
          image="https://online.sivanandabahamas.org/wp-content/uploads/2020/06/CKarmaYogi-1024x579.jpg"
          title="Krishna walking on the beach with a yoga mat"
        />
      </MediaCard>
    </StyledPageContentWrapper>
  )
}
