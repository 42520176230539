import * as React from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

import InfiniteScroll from '../../components/common/infinite-scrolling/InfiniteScrolling'
import { isElementInViewport } from '../../components/common/isElementInViewport'
import SearchBar from '../../components/common/SearchBar'

import {
  List,
  StyledUser,
  StyledUserAvatar,
  UsersPaneContainer,
  LastMessageContent,
  UserTeaser,
  StyledUserName,
  TimeSignature
} from './UsersPaneStyles'
import { useWhenPropSustained } from '../../components/common/hooks'
import { StoreContext } from '@sayr/client-models'

// should move to the styles module
const SearchWrapper = styled.div`
  padding: 0.5rem 0.7rem;
  background-color: ${({ theme: { palette } }) =>
    palette.mode === 'dark' ? '' : palette.grey['200']};
`

//todo: this function has a duplicate in Messages.tsx
const UserAvatar = ({ src, name }: { src: string; name: string }) => {
  if (src) return <StyledUserAvatar alt="user avatar" src={src} />
  else
    return (
      <StyledUserAvatar alt="user avatar">
        {getNameInitials(name)}
      </StyledUserAvatar>
    )
}

//todo: this function has a duplicate in Messages.tsx
function getNameInitials(name: string) {
  return name
    .split(/\s/)
    .map(word => word[0])
    .join('')
}

const User = observer(
  ({ id, switchToChatView }: { id: string; switchToChatView?: () => void }) => {
    const store = React.useContext(StoreContext)
    const domRef = React.createRef<HTMLDivElement>()
    const selected = (store.view.id || '') === id

    // Scroll to selected user if it is not in view
    React.useEffect(() => {
      const asyncTaskID = setTimeout(() => {
        if (
          domRef.current &&
          selected &&
          !isElementInViewport(domRef.current)
        ) {
          domRef.current.scrollIntoView({ block: 'center' })
        }
      })

      return () => clearTimeout(asyncTaskID)
    }, [domRef, selected, id])

    const chat = store.chatResponses.get(id)

    const [boldenName, setBoldenName] = React.useState(!!chat?.unreadCount)

    // show name as normal type if it is shown consecutively for more than 1.5 seconds
    useWhenPropSustained(store.view.id, 3000, () => {
      if (store.view.id === id) setBoldenName(false)
    })

    const lastUserName = chat?.unreadCount
      ? getLastReadMessage()?.$node?.author?.nickName || ''
      : getLastMessage()?.$node?.author?.nickName || ''

    const lastMessageContent = chat?.unreadCount
      ? getLastReadMessage()?.$node?.content?.slice(0, 80)
      : getLastMessage()?.$node?.content?.slice(0, 80)

    function getLastReadMessage() {
      return chat?.orderedMessages.find(
        m => m.$node?.$timestamp > chat?.$lastReadTimestamp
      )
    }

    function getLastMessage() {
      return chat?.orderedMessages[chat?.orderedMessages.length - 1]
    }

    return (
      <li>
        <StyledUser
          onClick={() => {
            switchToChatView?.()
            store.view.openChatPage(id)
          }}
          selected={selected}
          ref={domRef}
        >
          <UserAvatar
            src={store.userResponses.get(id)?.profilePhoto || ''}
            name={store.userResponses.get(id)?.nickName || ''}
          />
          <UserTeaser>
            <StyledUserName $unread={boldenName}>
              {store.loggedInUser?.id === id
                ? 'Front Desk'
                : store.userResponses.get(id)?.nickName || ''}
            </StyledUserName>
            {chat?.orderedMessages.length ? (
              <>
                <LastMessageContent>
                  <Typography variant="body1">{lastUserName}:</Typography>
                  <Typography variant="body2">{lastMessageContent}</Typography>
                </LastMessageContent>
                <TimeSignature>
                  {
                    chat.orderedMessages[chat.orderedMessages.length - 1].$node
                      ?.timeSignature
                  }
                </TimeSignature>
              </>
            ) : (
              <></>
            )}
          </UserTeaser>
        </StyledUser>
      </li>
    )
  }
)

function UsersPaneComponent({
  switchToChatView
}: {
  switchToChatView?: () => void
}) {
  const store = React.useContext(StoreContext)

  const containerDomRef = React.createRef<HTMLDivElement>()
  const [searchTerm, setSearchTerm] = React.useState('')

  // front desk user (empty string) and all other users concatenated to it
  const usersArray = Array.from(store.chatResponses.keys())

  const filteredUsersArray = usersArray.filter(userID => {
    if (!searchTerm.trim()) return true
    return store.userResponses
      .get(userID)
      ?.nickName?.toLowerCase()
      .includes(searchTerm.toLowerCase())
  })

  const loggedInID = store.loggedInUser?.id

  filteredUsersArray.sort((a, b) =>
    a === loggedInID ? -1 : b === loggedInID ? 1 : 0
  )

  function handleKeyDown(e: React.KeyboardEvent<HTMLUListElement>) {
    // navigate up & down via the arrow keys
    const selectedUserIndex = filteredUsersArray.findIndex(
      id => id === (store.view.id || '')
    )

    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      e.preventDefault()
      switchToChatView?.()

      if (selectedUserIndex === 1 && e.key === 'ArrowUp')
        store.view.openChatPage()
      else {
        const nextId =
          filteredUsersArray[selectedUserIndex + (e.key === 'ArrowUp' ? -1 : 1)]
        if (nextId) {
          store.view.openChatPage(nextId)
        }
      }
    }
  }

  if (!loggedInID) return <></>
  return (
    <UsersPaneContainer
      id="users-pane-container"
      ref={containerDomRef}
      role="navigation"
    >
      <InfiniteScroll
        dataLength={store.chatResponses.size}
        hasMore={store.chatsPagination.hasNextPage || false}
        loader={<LinearProgress />}
        next={store.loadChats}
        scrollableTarget="users-pane-container"
      >
        <SearchWrapper>
          <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        </SearchWrapper>
        <List tabIndex={0} onKeyDown={handleKeyDown}>
          {filteredUsersArray.map(id => (
            <User
              key={id || 'front-desk'}
              id={id}
              switchToChatView={switchToChatView}
            />
          ))}
        </List>
      </InfiniteScroll>
    </UsersPaneContainer>
  )
}

export const UsersPane = observer(UsersPaneComponent)
