import { BeforeInstallPromptEvent } from '../@types/InstallEvent'

let deferredInstallEvent: BeforeInstallPromptEvent | null = null

/**
 * This module actually defers the prompt installation.
 * An install button will be exposed, offering the deferred event.
 */
export function makeInstallable() {
  window.addEventListener('beforeinstallprompt', onPromptInstall)
}

function onPromptInstall(event: Event) {
  event.preventDefault()

  deferredInstallEvent = event as BeforeInstallPromptEvent
}

export function isInstallable() {
  return deferredInstallEvent !== null
}

export function getDeferredInstallEvent() {
  return deferredInstallEvent
}

export function setUninstallable() {
  deferredInstallEvent = null
}
