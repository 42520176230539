import { Instance } from 'mobx-state-tree'
import { ProgramResponseModelBase } from './ProgramResponseModel.base'

/* The TypeScript type of an instance of ProgramResponseModel */
export interface ProgramResponseModelType
  extends Instance<typeof ProgramResponseModel.Type> {}

/* A graphql query fragment builders for ProgramResponseModel */
export {
  selectFromProgramResponse,
  programResponseModelPrimitives,
  ProgramResponseModelSelector
} from './ProgramResponseModel.base'

/**
 * ProgramResponseModel
 */
export const ProgramResponseModel = ProgramResponseModelBase.views(self => ({
  get $id() {
    return +self.id
  }
}))
