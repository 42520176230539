import { types } from 'mobx-state-tree'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { now } from 'mobx-utils'
import { UserResponseModel } from './UserResponseModel'

const loggedInUser = types.maybeNull(
  types
    .model('loggedInUser', {
      accessToken: types.string,
      user: types.maybe(types.reference(UserResponseModel))
    })
    .views(self => ({
      get id(): string {
        return self.accessToken
          ? (jwtDecode(self.accessToken) as any).userId
          : ''
      },
      isTokenValidWithMargin(gapInMilliseconds: number) {
        if (self.accessToken) {
          const { exp } = jwtDecode<JwtPayload>(self.accessToken)

          return exp && now() + gapInMilliseconds < exp * 1000
        } else return false
      }
    }))
    .views(self => ({
      get isTokenValid() {
        return self.isTokenValidWithMargin(0)
      },
      get email(): string {
        return self.accessToken
          ? (jwtDecode(self.accessToken) as any).email
          : ''
      }
    }))
    .actions(self => ({
      setAccessToken(newToken: string) {
        console.log('setting new access token')
        self.accessToken = newToken
      },
      setUser() {
        self.user = self.id as any
      }
    }))
)

export default loggedInUser
