import React from 'react'
import dayjs from 'dayjs'
import { Column, IdType, Row } from 'react-table'
import styled from 'styled-components'
import Link from '@mui/material/Link'
import BedIcon from '@mui/icons-material/Bed'
import GroupsIcon from '@mui/icons-material/Groups'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import TerrainIcon from '@mui/icons-material/Terrain'
import HikingIcon from '@mui/icons-material/Hiking'
import SignalWifiStatusbarConnectedNoInternet4Icon from '@mui/icons-material/SignalWifiStatusbarConnectedNoInternet4'
import BalanceIcon from '@mui/icons-material/Balance'
import JoinRightIcon from '@mui/icons-material/JoinRight'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Tooltip from '@mui/material/Tooltip'
import TextField from '@mui/material/TextField'
import { DataGrid } from '../../components/common/dataGrid/DataGrid'
import { DataGridContainer } from '../../components/common/dataGrid/DataGridStyles'
import { PrimaryButton, SecondaryButton } from '../../components/common/Buttons'

import { FormBottomButtons } from '../announcements/AnnouncementEditPageStyles'
import {
  useWarningsGetQuery,
  useWarningUpdateMutation,
  WarningTypes
} from '../../graphql/generated/typesAndHooks'
import { useQueryClient } from 'react-query'

const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  bottom: 0.4rem;
  right: 0.4rem;
`

type WarningStatus = 'active' | 'ignored'
type WarningItem = {
  type: WarningTypes
  discovered: Date
  explanation: string
  registration: { id: number; name: string; link: string }
  notes: string
  arrivalDate: string
  lodging: string
  regComments: string
  status: WarningStatus
}

const columns: Column<WarningItem>[] = [
  {
    accessor: 'type',
    Header: 'Type',
    width: 89,
    Cell: ({ value }) =>
      value === WarningTypes.GapInStay ? (
        <Tooltip title="Oceanview Bed Arrangement Missing">
          <TerrainIcon />
        </Tooltip>
      ) : value === WarningTypes.WrongCharge ? (
        <Tooltip title="Wrong Charge">
          <MonetizationOnIcon />
        </Tooltip>
      ) : value === WarningTypes.IncorrectRegStatus ? (
        <Tooltip title="Incorrect Registration Status">
          <SignalWifiStatusbarConnectedNoInternet4Icon />
        </Tooltip>
      ) : value === WarningTypes.InvalidData ? (
        <Tooltip title="Invalid Data">
          <DoNotDisturbIcon />
        </Tooltip>
      ) : value === WarningTypes.OpenBalance ? (
        <Tooltip title="Open Balance">
          <BalanceIcon />
        </Tooltip>
      ) : value === WarningTypes.OvercrowdedRoom ? (
        <Tooltip title="Overcrowded Room">
          <GroupsIcon />
        </Tooltip>
      ) : value === WarningTypes.PlannedRoomMove ? (
        <Tooltip title="Planned Room Move">
          <HikingIcon />
        </Tooltip>
      ) : value === WarningTypes.UnmatchedRegistration ? (
        <Tooltip title="Registration Not Matched to a Person">
          <JoinRightIcon />
        </Tooltip>
      ) : value === WarningTypes.OceanviewWithoutBedArrangement ? (
        <Tooltip title="Oceanview Deluxe Room Without Bed Arrangement Specified">
          <BedIcon />
        </Tooltip>
      ) : (
        <></>
      )
  },
  {
    accessor: 'discovered',
    Header: 'Discovered On',
    Cell: cellProps => (
      <>{dayjs(cellProps.value).format('MMM D, YYYY, hh:mm a')}</>
    ),
    width: 200
  },
  { accessor: 'explanation', Header: 'Explanation', width: 300 },
  {
    accessor: 'registration',
    Header: 'Name',
    width: 200,
    Cell: cellProps => {
      return (
        <Link href={cellProps.value.link} target="_blank">
          {cellProps.value.name}
        </Link>
      )
    }
  },
  {
    accessor: 'arrivalDate',
    Header: 'Arrival Date',
    Cell: ({ value }) => <>{dayjs(value).format('MMM D, YYYY')}</>
  },
  { accessor: 'lodging', Header: 'Lodging' },
  { accessor: 'regComments', Header: 'Registration Comments', width: 300 },
  {
    accessor: 'notes',
    Header: 'Notes',
    width: 400,
    Cell: cellProps => {
      const [displayed, setDisplayed] = React.useState(cellProps.value)
      const [state, setState] = React.useState<'stale' | 'update'>('stale')

      React.useEffect(() => setDisplayed(cellProps.value), [cellProps.value])

      const queryClient = useQueryClient()
      const warningMutation = useWarningUpdateMutation({
        onMutate: () => {
          queryClient.cancelQueries(useWarningsGetQuery.getKey())
        },
        onSettled: () => {
          queryClient.refetchQueries(useWarningsGetQuery.getKey())
          setState('stale')
        }
      })

      const delayedUpdate = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => {
        setState('update')
        setDisplayed(e.target.value)

        warningMutation.mutate({
          regId: cellProps.row.original.registration.id,
          type: cellProps.row.original.type,
          notes: e.target.value
        })
      }

      return (
        <div style={{ position: 'relative' }}>
          <TextField
            margin="none"
            value={displayed}
            onChange={delayedUpdate}
            multiline
            fullWidth
          />
          {state === 'update' && <StyledCircularProgress size={15} />}
        </div>
      )
    }
  }
]

export function WarningPage() {
  const [confirmIgnoreWarnings, setConfirmIgnoreWarnings] = React.useState<
    WarningItem[]
  >([])
  const toggleRef = React.useRef<{
    toggle: null | ((value?: boolean | undefined) => void)
  }>({ toggle: null })

  const [tabValue, setTabValue] = React.useState<'active' | 'ignored'>('active')

  const { data, isLoading } = useWarningsGetQuery()
  const queryClient = useQueryClient()
  const warningMutation = useWarningUpdateMutation({
    onMutate: () => queryClient.cancelQueries(useWarningsGetQuery.getKey()),
    onSettled: () => queryClient.refetchQueries(useWarningsGetQuery.getKey())
  })
  if (isLoading || !data) return <CircularProgress />

  const filteredData = data.warningsGet
    .filter(
      d =>
        (tabValue === 'active' && d.active) ||
        (tabValue === 'ignored' && !d.active)
    )
    .map<WarningItem>(f => ({
      arrivalDate: f.arrivalDate,
      discovered: f.discoveredOn,
      explanation: f.explanation,
      lodging: f.lodging,
      notes: f.notes,
      regComments: f.registrationComments,
      registration: {
        id: f.registrationID,
        link: f.registrationLink,
        name: f.registrationName
      },
      status: f.active ? 'active' : 'ignored',
      type: f.type
    }))

  return (
    <>
      <Tabs value={tabValue} onChange={(_e, newValue) => setTabValue(newValue)}>
        <Tab label="Active" value="active" />
        <Tab label="Ignored" value="ignored" />
      </Tabs>
      <DataGridContainer>
        <DataGrid
          columns={columns}
          data={filteredData}
          renderBatchActions={(selectedFlatRows, toggleAllRows) => (
            <PrimaryButton
              onClick={() => {
                toggleRef.current.toggle = toggleAllRows
                setConfirmIgnoreWarnings(selectedFlatRows.map(r => r.original))
              }}
            >
              {tabValue === 'active' ? 'Ignore' : 'Activate'} Selected Warnings
            </PrimaryButton>
          )}
          withGlobalFilter
          globalFilterFunction={warningGlobalFilter}
          skipPageReset
        />
        <StyledDialog
          open={!!confirmIgnoreWarnings.length}
          onClose={() => setConfirmIgnoreWarnings([])}
        >
          <DialogTitle>
            Ignore Warning{confirmIgnoreWarnings.length > 1 ? 's' : ''}
          </DialogTitle>

          <List>
            {confirmIgnoreWarnings.map(a => (
              <ListItem
                key={a.registration.id}
                style={{
                  alignItems: 'start',
                  display: 'flex',
                  flexFlow: 'wrap',
                  gap: '1rem'
                }}
              >
                <span style={{ flex: '1 0 2rem' }}>{a.registration.id}</span>
                <span style={{ flex: '1 1 5rem' }}>
                  {a.registration.name}
                </span>{' '}
                <span style={{ flex: '1 0 4rem' }}>{a.arrivalDate}</span>
                <span style={{ flex: '1 1 8rem' }}>{a.notes}</span>
              </ListItem>
            ))}
          </List>
          <FormBottomButtons>
            {tabValue === 'active' ? (
              <>
                <SecondaryButton
                  onClick={() => {
                    confirmIgnoreWarnings.forEach(c => {
                      warningMutation.mutate({
                        regId: c.registration.id,
                        type: c.type,
                        ignoreState: 'indefinitely'
                      })
                    })
                    toggleRef.current.toggle?.(false)
                    setConfirmIgnoreWarnings([])
                  }}
                >
                  Ignore indefinitely
                </SecondaryButton>
                <PrimaryButton
                  onClick={() => {
                    confirmIgnoreWarnings.forEach(c => {
                      warningMutation.mutate({
                        regId: c.registration.id,
                        type: c.type,
                        ignoreState: 'untilNextUpdate'
                      })
                    })
                    toggleRef.current.toggle?.(false)
                    setConfirmIgnoreWarnings([])
                  }}
                >
                  Ignore until changed
                </PrimaryButton>
              </>
            ) : (
              <PrimaryButton
                onClick={() => {
                  confirmIgnoreWarnings.forEach(c => {
                    warningMutation.mutate({
                      regId: c.registration.id,
                      type: c.type,
                      ignoreState: null
                    })
                  })
                  toggleRef.current.toggle?.(false)
                  setConfirmIgnoreWarnings([])
                }}
              >
                Activate
              </PrimaryButton>
            )}
          </FormBottomButtons>
        </StyledDialog>
      </DataGridContainer>
    </>
  )
}

const StyledDialog = styled(Dialog)`
  & [role='dialog'] {
    padding: 1rem 2rem;
  }
`

function warningGlobalFilter<D extends WarningItem>(
  rows: Array<Row<D>>,
  _columnIds: Array<IdType<D>>,
  filterValue: string
): Array<Row<D>> {
  const filterableColumns = [
    'registration',
    'explanation',
    'regComments',
    'notes'
  ]

  return rows.filter(row =>
    filterValue
      .trim()
      .split(/\W/)
      .every(word => {
        let wordMatch = false
        for (const column in row.values) {
          if (
            filterableColumns.some(
              filterableColumn => column === filterableColumn
            )
          ) {
            if (!wordMatch) {
              if (column === 'registration')
                wordMatch = !!row.original.registration.name.match(
                  new RegExp(word, 'i')
                )
              else
                wordMatch = !!(row.values[column] as string).match(
                  new RegExp(word, 'i')
                )
            }
          }
        }
        return wordMatch
      })
  )
}
