// @ts-nocheck
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { QueryBuilder } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { RootStoreType } from './index'

/**
 * MonitoredAttributeMetaDataResponseBase
 * auto generated base class for the model MonitoredAttributeMetaDataResponseModel.
 */
export const MonitoredAttributeMetaDataResponseModelBase = ModelBase.named(
  'MonitoredAttributeMetaDataResponse'
)
  .props({
    __typename: types.optional(
      types.literal('MonitoredAttributeMetaDataResponse'),
      'MonitoredAttributeMetaDataResponse'
    ),
    key: types.union(types.undefined, types.string),
    displayed: types.union(types.undefined, types.string),
    yellowFlagDay: types.union(types.undefined, types.integer),
    redFlagDay: types.union(types.undefined, types.integer),
    flagCountFrom: types.union(types.undefined, types.string)
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class MonitoredAttributeMetaDataResponseModelSelector extends QueryBuilder {
  get key() {
    return this.__attr(`key`)
  }
  get displayed() {
    return this.__attr(`displayed`)
  }
  get yellowFlagDay() {
    return this.__attr(`yellowFlagDay`)
  }
  get redFlagDay() {
    return this.__attr(`redFlagDay`)
  }
  get flagCountFrom() {
    return this.__attr(`flagCountFrom`)
  }
}
export function selectFromMonitoredAttributeMetaDataResponse() {
  return new MonitoredAttributeMetaDataResponseModelSelector()
}

export const monitoredAttributeMetaDataResponseModelPrimitives =
  selectFromMonitoredAttributeMetaDataResponse().key.displayed.yellowFlagDay
    .redFlagDay.flagCountFrom
