import React from 'react'
import { CellProps, Column, Row } from 'react-table'
import { observer } from 'mobx-react-lite'
import Avatar from '@mui/material/Avatar'
import styled from 'styled-components'
import {
  DataGrid,
  UpdateableGridCheckbox
} from '../../components/common/dataGrid/DataGrid'
import { DataGridContainer } from '../../components/common/dataGrid/DataGridStyles'

import { PrimaryButton } from '../../components/common/Buttons'
import { StoreContext, Permission } from '@sayr/client-models'

import Button from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'

export interface SimpleDialogProps {
  open: boolean
  onClose: () => void
}

function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, open } = props

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Set backup account</DialogTitle>
      <div>Hi, I am a dialog</div>
    </Dialog>
  )
}

type UserRow = {
  headshot: string
  email: string
  inviteToApp: boolean
  upgradeOtherUsers: boolean
  viewPeopleLists: boolean
  manageAnnouncements: boolean
  chatAsFrontDesk: boolean
  accounting: boolean
  generalPopulation: boolean
  adminFollowUpRead: boolean
  adminFollowUpWrite: boolean
  sendEmails: boolean
  accessWarnings: boolean
}

const SmallAvatar = styled(Avatar)`
  width: 80%;
  height: 80%;
  border-radius: 50%;
`

const UpdateablePermissionCheckbox = (props: CellProps<UserRow>) => {
  const [shownValue, setShownValue] = React.useState<boolean>(props.value)
  React.useEffect(() => setShownValue(props.value), [props.value])
  console.log(props.column.id)
  const permission = props.column.id as Permission
  const store = React.useContext(StoreContext)
  const userId =
    Array.from(store.userResponses.values()).find(
      u => u.email === props.row.original.email
    )?.id || ''
  if (!permission) return <></>
  const currentPermission =
    !!store.userResponses.get(userId)?.$permissions?.[permission]
  return (
    <UpdateableGridCheckbox
      value={shownValue}
      onChange={() => {
        // do not allow changes on one's own user.
        if (props.row.original.email === store.loggedInUser?.email) return

        store.mutateUserUpdatePermission(
          {
            newPermissionSet: { [permission]: !currentPermission },
            userId
          },
          s =>
            s.id.email.profilePhoto.permissions(
              p =>
                p.chatAsFrontDesk.inviteToApp.manageAnnouncements
                  .upgradeOtherUsers.accounting.viewPeopleLists
                  .generalPopulation.adminFollowUpRead.adminFollowUpWrite
                  .sendEmails.accessWarnings
            ).nickName.googleDisplayName,
          () => setShownValue(v => !v)
        )
      }}
    />
  )
}

const userColumns = [
  {
    Header: '',
    accessor: 'headshot',
    Cell: React.memo(({ value }: { value: string }) => {
      return <SmallAvatar src={value} />
    }),
    width: 70,
    disableSortBy: true,
    disableResizing: true
  },
  {
    Header: 'Email',
    accessor: 'email',
    width: 300
  },
  {
    Header: 'Upgrade Other Users',
    accessor: 'upgradeOtherUsers',
    setState: (...rest: any) => {
      console.log('setting state', rest)
    },
    Cell: UpdateablePermissionCheckbox,
    width: 200
  },
  {
    Header: 'View People Lists',
    accessor: 'viewPeopleLists',
    Cell: UpdateablePermissionCheckbox,
    width: 200
  },
  {
    Header: 'Send Emails',
    accessor: 'sendEmails',
    Cell: UpdateablePermissionCheckbox,
    width: 200
  },
  {
    Header: 'Manage Announcements',
    accessor: 'manageAnnouncements',
    Cell: UpdateablePermissionCheckbox,
    width: 250
  },
  {
    Header: 'Chat As Front Desk',
    accessor: 'chatAsFrontDesk',
    Cell: UpdateablePermissionCheckbox,
    width: 200
  },
  {
    Header: 'Invite To App',
    accessor: 'inviteToApp',
    Cell: UpdateablePermissionCheckbox
  },
  {
    Header: 'General Population',
    accessor: 'generalPopulation',
    Cell: UpdateablePermissionCheckbox,
    width: 200
  },
  {
    Header: 'Accounting',
    accessor: 'accounting',
    Cell: UpdateablePermissionCheckbox
  },
  {
    Header: 'Administrative Follow up - Read Access',
    accessor: 'adminFollowUpRead',
    Cell: UpdateablePermissionCheckbox,
    width: 360
  },
  {
    Header: 'Administrative Follow up - Write Access',
    accessor: 'adminFollowUpWrite',
    Cell: UpdateablePermissionCheckbox,
    width: 360
  },
  {
    Header: 'Access Warnings',
    accessor: 'accessWarnings',
    Cell: UpdateablePermissionCheckbox,
    width: 360
  }
] as Column<UserRow>[]

export const Users = observer(() => {
  const store = React.useContext(StoreContext)

  const columns = React.useMemo(
    () =>
      userColumns.filter(column => {
        if (!column.accessor) return false
        if (['headshot', 'email'].includes(column.accessor as string))
          return true
        return !!store.loggedInUser?.user?.$permissions?.[
          column.accessor as keyof Permission
        ]
      }),
    [store.loggedInUser?.user?.$permissions]
  )

  const data: UserRow[] = Array.from(store.userResponses.values()).map(
    user => ({
      email: user.email || '',
      headshot: user.profilePhoto || '',
      chatAsFrontDesk: !!user.$permissions?.chatAsFrontDesk,
      sendEmails: !!user.$permissions?.sendEmails,
      manageAnnouncements: !!user.$permissions?.manageAnnouncements,
      accounting: !!user.$permissions?.accounting,
      viewPeopleLists: !!user.$permissions?.viewPeopleLists,
      upgradeOtherUsers: !!user.$permissions?.upgradeOtherUsers,
      inviteToApp: !!user.$permissions?.inviteToApp,
      generalPopulation: !!user.$permissions?.generalPopulation,
      adminFollowUpRead: !!user.$permissions?.adminFollowUpRead,
      adminFollowUpWrite: !!user.$permissions?.adminFollowUpWrite,
      accessWarnings: !!user.$permissions?.accessWarnings
    })
  )

  const [open, setOpen] = React.useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <DataGridContainer>
      <DataGrid
        columns={columns}
        data={data}
        setData={newData => {
          console.log('new data', newData)
        }}
        nonSortable={false}
        disableResize={false}
        withGlobalFilter={true}
        renderBatchActions={(
          selectedFlatRows: Row<UserRow>[]
          // toggleAllRowsSelected: (value?: boolean | undefined) => void
        ) => {
          console.log('clicked on a batch action', selectedFlatRows)
          return (
            <>
              <PrimaryButton onClick={() => {}}>Assign Roles</PrimaryButton>
              <div>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setOpen(true)
                  }}
                >
                  Open simple dialog
                </Button>
              </div>
            </>
          )
        }}
      />
      <SimpleDialog open={open} onClose={handleClose} />
    </DataGridContainer>
  )
})
