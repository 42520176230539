import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Breadcrumbs,
  ConnectedPages,
  Heading2,
  Heading3,
  Heading4,
  Paragraph,
  InfoContainer,
  InfoLink,
  Section,
  Hr,
  InfoPageLayout,
  LayoutSeparator,
  Link,
  AsanaImage,
  ListItem,
  OrderedList
} from '../../../../components/InfoTypography'

export function InfoPlough() {
  return (
    <InfoContainer style={{ wordBreak: 'break-word' }}>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <InfoLink target="">Information</InfoLink>
        <InfoLink target="practice-guide">Practice Guide</InfoLink>
        <InfoLink target="practice-guide/asanas">Asanas</InfoLink>
        <Link $disabled>Plough</Link>
      </Breadcrumbs>
      <Heading2>
        3. Plough (<span className="text-sanskrit">halāsana</span>)
      </Heading2>
      <Hr />
      <InfoPageLayout>
        <Section>
          <Heading3>Preparatory Exercises</Heading3>
          <Paragraph>
            From the Shoulderstand, lower one foot to the ground behind the
            head, exhaling. Inhale, raising the leg. Repeat with the other leg.
            Coming into the position:
          </Paragraph>
          <AsanaImage src="/images/asanas/halasana.png" alt="Plough" />
          <OrderedList>
            <ListItem>
              Come into the{' '}
              <InfoLink target="practice-guide/asanas/shoulderstand">
                shoulderstand
              </InfoLink>
              .
            </ListItem>
            <ListItem>
              From the Shoulderstand, drop both feet down to the ground behind
              the head.
            </ListItem>
            <ListItem>
              If the toes are touching the floor, bring the hands flat on the
              floor behind the back, palms on the floor, hands parallel to each
              other. People who are not able to bring the toes to the floor
              should keep the hands on the back to avoid straining the back
              muscles.
            </ListItem>
            <ListItem>
              Intermediate / advanced students may try clasping the hands
              together behind the back.
            </ListItem>
          </OrderedList>
          <Heading3>Holding the position:</Heading3>
          <Paragraph>
            Keep the knees straight; point the toes in towards the head, stretch
            the heels towards the floor. <br />
            Hold the Plough for 30 seconds to begin, increasing gradually to 2
            minutes.
          </Paragraph>
          <Heading3>Return to Shoulderstand</Heading3>
          <Heading4>To come out of the position:</Heading4>
          <Paragraph>
            <strong>Beginners</strong>:
            <br /> bring the hands flat on the flood behind the back. Raise the
            feet up from the floor behind the head. Using the hands as a brake,
            slowly roll out of the position. Try to lower each vertebrae to the
            ground separately keeping the head on the ground. When you have come
            down completely, relax for a moment and then push up into the
            Bridge.
          </Paragraph>
          <Paragraph>
            <strong>Intermediate/Advanced Students:</strong>
            <br />
            Bend both knees and come over into the bridge.
          </Paragraph>
          <Heading3>Benefits of the Plough</Heading3>
          <Paragraph>
            The plough is a forward-bending position which stretches the entire
            spine, but the main focus is on the cervical region.
          </Paragraph>
          <Heading4>Physical Benefits</Heading4>
          <OrderedList>
            <ListItem>
              All regions of the spine are stretched bringing increased
              flexibility to the neck and spine. Spinal nerves are nourished.
            </ListItem>
            <ListItem>Tension is released from the cervical region.</ListItem>
            <ListItem>
              Muscles of back, shoulders, and arms are strengthened.
            </ListItem>
            <ListItem>
              Internal organs are massaged; indigestion and constipation are
              relieved.
            </ListItem>
          </OrderedList>
          <Heading3>Variations of the Plough</Heading3>
          <Heading4>Variation 1</Heading4>
          <Paragraph>
            Keeping the hands on the back, walk both feet to the right. Be sure
            that the left shoulder remains firmly on the ground. Bend both knees
            down towards the right shoulder. Straighten knees and walk feet to
            the left, repeating asana on the other side.
          </Paragraph>
          <Heading4>Variation 2</Heading4>
          <Paragraph>
            Straighten knees and walk feet to centre. Bend both knees and drop
            them down to ground on either side of the head. Make sure that feet
            remain on the ground. Wrap arms around the knees and clasp hands
            together.
          </Paragraph>
        </Section>
        <ConnectedPages>
          <LayoutSeparator />
          <Section>
            <Paragraph>
              <strong>Connected Articles:</strong>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas">Asanas</InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/sun-salutations">
                Sun Salutations (
                <span className="text-sanskrit">sūrya namaskāra</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/headstand">
                1. Headstand (<span className="text-sanskrit">śirṣāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/shoulderstand">
                2. Shoulderstand (
                <span className="text-sanskrit">sarvāṅgāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/fish">
                4. Fish (<span className="text-sanskrit">matsyāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/forward-bend">
                5. Forward Bend (
                <span className="text-sanskrit">pascimottanāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/cobra">
                6. Cobra (<span className="text-sanskrit">bhujaṅgāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/locust">
                7. Locust (<span className="text-sanskrit">śalabhāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/bow">
                8. Bow (<span className="text-sanskrit">dhanurāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/half">
                9. Half Spinal Twist (
                <span className="text-sanskrit">ardha matsyendrāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/crow">
                10. Crow (<span className="text-sanskrit">kakāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/hands">
                11. Hands to Feet (
                <span className="text-sanskrit">pāda hastāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/triangle">
                12. Triangle (<span className="text-sanskrit">trikonāsana</span>
                )
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/savasana">
                Final Relaxation (
                <span className="text-sanskrit">śavāsana</span>)
              </InfoLink>
            </Paragraph>
          </Section>
        </ConnectedPages>
      </InfoPageLayout>
    </InfoContainer>
  )
}
