import { types, getRoot } from 'mobx-state-tree'
import { ObservableMap } from 'mobx'
import { ProgramResponseModelType } from './ProgramResponseModel'
import { allLodgingProgramTypes } from '@sayr/base'
export const ProgramsModel = types.model({}).views(self => ({
  get lodgingPrograms() {
    return Array.from(
      (
        (getRoot(self) as any).programResponses as ObservableMap<
          string,
          ProgramResponseModelType
        >
      ).values()
    )
      .filter(p =>
        allLodgingProgramTypes.some(lodgingCategory =>
          p.categories?.includes(lodgingCategory as typeof p.categories[number])
        )
      )
      .map(p => p.$id)
  }
}))
