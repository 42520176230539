import dayjs from 'dayjs'
import { GetAllSeasonsQuery } from '../graphql/generated/typesAndHooks'

type Season = GetAllSeasonsQuery['getAllSeasons'][number]

export const matchSeasonByDates =
  ({
    arrivalDate,
    currentDate = dayjs().format('YYYY-MM-DD')
  }: {
    arrivalDate: string
    currentDate?: string
  }) =>
  (season: Season) =>
    (season.seasonDateClassification === 'by arrival date' &&
      season.arrivalDateStart <= arrivalDate &&
      season.arrivalDateEnd > arrivalDate) ||
    (season.seasonDateClassification === 'by date' &&
      season.startDate <= currentDate &&
      season.endDate > currentDate) ||
    (season.seasonDateClassification === 'by both arrival date and date' &&
      season.arrivalDateStart <= arrivalDate &&
      season.arrivalDateEnd > arrivalDate &&
      season.startDate <= currentDate &&
      season.endDate > currentDate)
