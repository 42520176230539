// @ts-nocheck
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { QueryBuilder } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { RootStoreType } from './index'

/**
 * PersonResponseBase
 * auto generated base class for the model PersonResponseModel.
 */
export const PersonResponseModelBase = ModelBase.named('PersonResponse')
  .props({
    __typename: types.optional(
      types.literal('PersonResponse'),
      'PersonResponse'
    ),
    _id: types.union(types.undefined, types.null, types.string),
    email: types.union(types.undefined, types.string),
    name: types.union(types.undefined, types.string)
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class PersonResponseModelSelector extends QueryBuilder {
  get _id() {
    return this.__attr(`_id`)
  }
  get email() {
    return this.__attr(`email`)
  }
  get name() {
    return this.__attr(`name`)
  }
}
export function selectFromPersonResponse() {
  return new PersonResponseModelSelector()
}

export const personResponseModelPrimitives =
  selectFromPersonResponse()._id.email.name
