import React from 'react'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Breadcrumbs,
  InfoLink,
  Link,
  MediaCard,
  StyledCardMedia,
  StyledPageContentWrapper
} from '../../../../components/InfoTypography'
import { StoreContext } from '@sayr/client-models'

export function InfoAsanas() {
  const store = React.useContext(StoreContext)
  return (
    <StyledPageContentWrapper>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <InfoLink target="">Information</InfoLink>
        <InfoLink target="practice-guide">Practice Guide</InfoLink>
        <Link $disabled>Asanas</Link>
      </Breadcrumbs>
      <MediaCard
        topic="Surya Namaskara - Sun Saluations"
        description=""
        action={() => {
          store.view.openInfoPage('practice-guide/asanas/sun-salutations')
        }}
      >
        <StyledCardMedia image="" title="" />
      </MediaCard>
      <MediaCard
        topic="1. Sirshasana - Headstand"
        description=""
        action={() => {
          store.view.openInfoPage('practice-guide/asanas/headstand')
        }}
      >
        <StyledCardMedia
          image="/images/asanas/sirshasana.png"
          title="Krishna walking on the beach with a yoga mat"
          $backgroundPosition="50%"
          $backgroundSize="contain"
        />
      </MediaCard>
      <MediaCard
        topic="2. Sarvangasana - Shoulderstand"
        description=""
        action={() => {
          store.view.openInfoPage('practice-guide/asanas/shoulderstand')
        }}
      >
        <StyledCardMedia
          image="/images/asanas/sarvangasana.png"
          title="Krishna walking on the beach with a yoga mat"
          $backgroundPosition="50%"
          $backgroundSize="contain"
        />
      </MediaCard>
      <MediaCard
        topic="3. Halasana - Plough"
        description=""
        action={() => {
          store.view.openInfoPage('practice-guide/asanas/plough')
        }}
      >
        <StyledCardMedia
          image="/images/asanas/halasana.png"
          title="Krishna walking on the beach with a yoga mat"
          $backgroundSize="contain"
          $backgroundPosition="50%"
        />
      </MediaCard>
      <MediaCard
        topic="4. Matsyasana - Fish"
        description=""
        action={() => {
          store.view.openInfoPage('practice-guide/asanas/fish')
        }}
      >
        <StyledCardMedia
          image="/images/asanas/matsyasana.png"
          title="Krishna walking on the beach with a yoga mat"
          $backgroundPosition="50%"
          $backgroundSize="contain"
        />
      </MediaCard>
      <MediaCard
        topic="5. Paschimotanasana - Forward Bend"
        description=""
        action={() => {
          store.view.openInfoPage('practice-guide/asanas/forward-bend')
        }}
      >
        <StyledCardMedia
          image="/images/asanas/paschimotanasana.png"
          title="Krishna walking on the beach with a yoga mat"
          $backgroundSize="contain"
        />
      </MediaCard>
      <MediaCard
        topic="6. Bhujangasana - Cobra"
        description=""
        action={() => {
          store.view.openInfoPage('practice-guide/asanas/cobra')
        }}
      >
        <StyledCardMedia
          image="/images/asanas/bhujangasana.png"
          title="Krishna walking on the beach with a yoga mat"
          $backgroundSize="contain"
          $backgroundPosition="50%"
        />
      </MediaCard>
      <MediaCard
        topic="7. Salabhasana - Locust"
        description=""
        action={() => {
          store.view.openInfoPage('practice-guide/asanas/locust')
        }}
      >
        <StyledCardMedia
          image="/images/asanas/salabhasana.png"
          title="Krishna walking on the beach with a yoga mat"
          $backgroundSize="contain"
          $backgroundPosition="50%"
        />
      </MediaCard>
      <MediaCard
        topic="8. Dhanurasana - Bow"
        description=""
        action={() => {
          store.view.openInfoPage('practice-guide/asanas/bow')
        }}
      >
        <StyledCardMedia
          image="/images/asanas/dhanurasana.png"
          title="Krishna walking on the beach with a yoga mat"
          $backgroundSize="contain"
          $backgroundPosition="50%"
        />
      </MediaCard>
      <MediaCard
        topic="9. Ardha Matsyendrasana - Half Spinal Twist"
        description=""
        action={() => {
          store.view.openInfoPage('practice-guide/asanas/half-spinal-twist')
        }}
      >
        <StyledCardMedia
          image="/images/asanas/ardha-matsyentrasana.png"
          title="Krishna walking on the beach with a yoga mat"
          $backgroundSize="contain"
          $backgroundPosition="50%"
        />
      </MediaCard>
      <MediaCard
        topic="10. Kakasana - Crow"
        description=""
        action={() => {
          store.view.openInfoPage('practice-guide/asanas/crow')
        }}
      >
        <StyledCardMedia
          image="/images/asanas/kakasana.png"
          title="Krishna walking on the beach with a yoga mat"
          $backgroundPosition="50%"
          $backgroundSize="contain"
        />
      </MediaCard>
      <MediaCard
        topic="11. Pada Hastasana - Hands to Feet"
        description=""
        action={() => {
          store.view.openInfoPage('practice-guide/asanas/hands-to-feet')
        }}
      >
        <StyledCardMedia
          image="/images/asanas/surya-namaskara-10.png"
          title="Krishna walking on the beach with a yoga mat"
          $backgroundSize="contain"
          $backgroundPosition="50%"
        />
      </MediaCard>
      <MediaCard
        topic="12. Trikonasana - Triangle"
        description=""
        action={() => {
          store.view.openInfoPage('practice-guide/asanas/triangle')
        }}
      >
        <StyledCardMedia
          image="/images/asanas/trikonasana.png"
          title="Krishna walking on the beach with a yoga mat"
          $backgroundSize="contain"
          $backgroundPosition="50%"
        />
      </MediaCard>
      <MediaCard
        topic="Savasana - Final Relaxation"
        description=""
        action={() => {
          store.view.openInfoPage('practice-guide/asanas/savasana')
        }}
      >
        <StyledCardMedia
          image="/images/asanas/savasana.png"
          title="Krishna walking on the beach with a yoga mat"
          $backgroundSize="contain"
          $backgroundPosition="50%"
        />
      </MediaCard>
    </StyledPageContentWrapper>
  )
}
