/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from 'mobx-state-tree'

/**
 * Typescript enum
 */

export enum SeasonTypes {
  COVID_TEST_FREQUENCY_FIXED_GAP = 'COVID_TEST_FREQUENCY_FIXED_GAP',
  COVID_TEST_FREQUENCY_DAYS_OF_WEEK = 'COVID_TEST_FREQUENCY_DAYS_OF_WEEK',
  MONITORED_ATTRIBUTES = 'MONITORED_ATTRIBUTES',
  COVID_TEST_COSTS = 'COVID_TEST_COSTS'
}

/**
 * SeasonTypes
 *
 * types of seasons
 */
export const SeasonTypesEnumType = types.enumeration('SeasonTypes', [
  'COVID_TEST_FREQUENCY_FIXED_GAP',
  'COVID_TEST_FREQUENCY_DAYS_OF_WEEK',
  'MONITORED_ATTRIBUTES',
  'COVID_TEST_COSTS'
])
