import * as React from 'react'
import ButtonBase from '@mui/material/ButtonBase'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { rgba } from 'polished'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import EmailValidator from 'email-validator'

import { StoreContext } from '@sayr/client-models'
import {
  FormTextField,
  Field,
  Wrapper,
  FormError
} from '../../components/common/Forms'
import { PrimaryButton } from '../../components/common/Buttons'
import LoginCoverPage from './LoginCoverPage'
import ForgotPasswordPromptPage from './ForgotPasswordPromptPage'
import ChangePasswordPage from './ChangePasswordPage'
import { Form, PageState } from './LoginCommon'
import ManualSignUpPage from './ManualSignUpPage'

export const LoginBackground = styled.div`
  &:before {
    content: '';
    background-image: url('./images/login-page-beach-hands-up.jpg');
    background-position: 50% 40%;
    background-size: cover;
    position: fixed;
    height: 100vh;
    width: 100vw;
  }

  height: 100vh;
  position: relative;
  width: 100vw;
`

const GoogleIcon = styled.img.attrs({
  src: 'https://developers.google.com/identity/images/g-logo.png',
  alt: 'google-sign-in'
})`
  height: 1rem;
`

const FacebookIcon = styled.img.attrs({
  src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Facebook_Logo_%282019%29.png/240px-Facebook_Logo_%282019%29.png',
  alt: 'facebooks-sign-in'
})`
  height: 1rem;
`

const OAuthButton = styled(ButtonBase).attrs({ focusRipple: true })`
  && {
    border: 1px solid ${({ theme }) => rgba(theme.palette.primary.main, 0.5)};

    background-color: ${({ theme: { palette }, disabled }) =>
      disabled && palette.grey[palette.mode === 'dark' ? '600' : '200']};
    color: ${({ theme: { palette }, disabled }) =>
      disabled && palette.grey[palette.mode === 'dark' ? '500' : '500']};

    &:hover {
      background-color: ${({ theme }) =>
        rgba(theme.palette.primary.main, 0.04)};
      border: 1px solid ${({ theme }) => theme.palette.primary.main};
    }

    border-radius: 0.25rem;
    display: flex;
    font-family: inherit;
    height: 2rem;
    margin-bottom: 0.3rem;
    padding: 0.2rem 0.5rem;
    text-align: inherit;
    transition: background-color 0.25s, border-color 0.25s;
  }
`

const OAuthLabel = styled.div`
  flex: 1;
  font-weight: 300;
  padding-left: 0.8rem;
  letter-spacing: 0.01rem;

  & strong {
    font-weight: 400;
  }
`

const DividerLines = styled.div`
  align-items: center;
  display: flex;
  margin: 0.9rem 0;
  width: 100%;

  &::before,
  &::after {
    content: '';
    height: 1px;
    background-color: ${({ theme: { palette } }) => palette.grey['300']};
    flex: 1;
  }
`

const RoundOr = styled.div`
  &::before {
    content: '';
    width: 2.2rem;
    height: 2.2rem;
    z-index: 100;
    position: absolute;
    transform: translate(-16%, -15%);
    top: 0;
    left: 0;
    background-color: ${({ theme: { palette } }) => palette.grey['300']};
    border-radius: 50%;
  }
  color: ${({ theme: { palette } }) => palette.grey['100']};
  text-transform: uppercase;
  padding: 0.7rem;
  font-size: 0.6rem;
  font-weight: 500;
  position: relative;
`

type LoginFormInput = { email: string; password: string }

const ForgotPassword = styled(Typography)`
  color: ${({ theme: { palette } }) => palette.grey['500']};

  & > a {
    color: inherit;
    font-size: 0.8rem;
    text-decoration-color: currentColor;
  }
`

const Divider = () => (
  <DividerLines>
    <RoundOr>
      <div
        style={{
          position: 'absolute',
          zIndex: 200,
          fontSize: '.9rem',
          transform: 'translate(-50%,-50%)',
          fontWeight: 600,
          color: 'white'
        }}
      >
        or
      </div>
    </RoundOr>
  </DividerLines>
)

const NarrowWidth = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  width: 14rem;
  height: 100%;
`

function LoginPage() {
  const store = React.useContext(StoreContext)
  const [pageState, setPageState] = React.useState<PageState>('cover-page')
  const [globalError, setGlobalError] = React.useState<Error | null>(null)

  if (store.view.page === '/forgot-password')
    return (
      <>
        {globalError && <div className="error-div">An Error Occured</div>}
        <ChangePasswordPage token={store.view.id || ''} />
      </>
    )

  return (
    <>
      {pageState === 'manual-signup-page' ? (
        <ManualSignUpPage setPageState={setPageState} />
      ) : (
        <NarrowWidth>
          {pageState === 'cover-page' ? (
            <LoginCoverPage
              setClosed={() => setPageState('standard-login-page')}
            />
          ) : pageState === 'standard-login-page' ? (
            <>
              <OAuthButton
                onClick={() => {
                  store.loginWithGoogle()
                }}
              >
                <GoogleIcon />
                <OAuthLabel>
                  Sign in with <strong>Google</strong>
                </OAuthLabel>
              </OAuthButton>
              <OAuthButton disabled>
                <FacebookIcon />
                <OAuthLabel>
                  Sign in with <strong>Facebook</strong>
                </OAuthLabel>
              </OAuthButton>
              <Divider />
              <ManualSignIn
                setPageState={setPageState}
                setGlobalError={setGlobalError}
              />
              <Divider />
              <PrimaryButton
                onClick={() => {
                  setPageState('manual-signup-page')
                }}
              >
                Sign Up
              </PrimaryButton>
            </>
          ) : pageState === 'forgot-password-prompt-page' ? (
            <ForgotPasswordPromptPage setPageState={setPageState} />
          ) : (
            <></>
          )}
        </NarrowWidth>
      )}
    </>
  )
}

function ManualSignIn({
  setPageState,
  setGlobalError
}: {
  setPageState: React.Dispatch<React.SetStateAction<PageState>>
  setGlobalError: (error: Error) => void
}) {
  const store = React.useContext(StoreContext)
  const { register, handleSubmit, formState, setError } =
    useForm<LoginFormInput>({
      mode: 'onTouched'
    })
  const { errors } = formState

  const email = register('email', {
    required: true,
    validate: value => EmailValidator.validate(value)
  })
  const password = register('password', { required: true })

  async function onSubmit(data: LoginFormInput) {
    try {
      await store.login(data)
    } catch (e) {
      if ((e as Error).message.match(/^wrong password/)) {
        setError('password', { type: 'wrong' }, { shouldFocus: true })
        return
      }
      if ((e as Error).message.match(/^User not verified/)) {
        setError('password', { type: 'unverified' }, { shouldFocus: true })
        return
      }
      if ((e as Error).message.match(/^could not find user/)) {
        setError('email', { type: 'non-existing' }, { shouldFocus: true })
        return
      }
      console.log('setting global error to', e)
      setGlobalError(e as Error)
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Wrapper bottomSpacing={!!errors.email}>
        <Field>
          <FormTextField
            variant="outlined"
            fullWidth
            size="small"
            id="email"
            label="Email"
            autoComplete="username"
            name={email.name}
            onChange={e => {
              e.target.value = e.target.value.toLowerCase()
              email.onChange(e)
            }}
            onBlur={email.onBlur}
            inputRef={email.ref}
            defaultValue={
              Object.fromEntries(new URL(window.location.href).searchParams)
                ?.email
            }
          />
          {errors.email?.type === 'validate' && (
            <FormError>Email not valid</FormError>
          )}
          {errors.email?.type === 'non-existing' && (
            <FormError>Email not registered</FormError>
          )}
        </Field>
      </Wrapper>
      <Wrapper bottomSpacing={!!errors.password}>
        <Field>
          <FormTextField
            variant="outlined"
            fullWidth
            size="small"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            name={password.name}
            onChange={password.onChange}
            onBlur={password.onBlur}
            inputRef={password.ref}
          />
          {errors.password?.type === 'required' && (
            <FormError>Password missing</FormError>
          )}
          {errors.password?.type === 'wrong' && (
            <FormError>Password incorrect</FormError>
          )}
          {errors.password?.type === 'unverified' && (
            <FormError>User not verified</FormError>
          )}
        </Field>
      </Wrapper>
      <PrimaryButton type="submit">Sign In</PrimaryButton>
      <ForgotPassword style={{ marginLeft: '14px' }}>
        <Link
          component="button"
          variant="body2"
          onClick={() => setPageState('forgot-password-prompt-page')}
        >
          Forgot password?
        </Link>
      </ForgotPassword>
    </Form>
  )
}

export default LoginPage
