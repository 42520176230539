import React from 'react'
import { Column } from 'react-table'
import dayjs, { Dayjs } from 'dayjs'
import { Chart, AxisOptions } from 'react-charts'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import styled from 'styled-components'

import { DataGrid } from '../../components/common/dataGrid/DataGrid'
import {
  useGetGeneralPopulationQuery,
  ProgramTypes
} from '../../graphql/generated/typesAndHooks'
import { DataGridContainer } from '../../components/common/dataGrid/DataGridStyles'

type PopulationRow = {
  date: string
  total: number | undefined
  [ProgramTypes.Attc]: number | undefined
  [ProgramTypes.Babies]: number | undefined
  [ProgramTypes.Children]: number | undefined
  [ProgramTypes.Commuters]: number | undefined

  [ProgramTypes.KarmaYoga]: number | undefined
  [ProgramTypes.Speaker]: number | undefined
  [ProgramTypes.Ttc]: number | undefined
  [ProgramTypes.Vacation]: number | undefined
  [ProgramTypes.VisitingStaff]: number | undefined
}

const GrowthIndicator = styled(ArrowForwardIcon)<{ growth: number }>`
  color: ${({ growth }) =>
    growth <= -15
      ? 'darkBlue'
      : -15 < growth && growth <= -5
      ? 'lightBlue'
      : -5 < growth && growth <= 5
      ? '#999'
      : 5 < growth && growth <= 15
      ? 'orange'
      : 'red'};
  transform: rotate(
    ${({ growth }) =>
      growth <= -15
        ? 90
        : -15 < growth && growth <= -5
        ? 45
        : -5 < growth && growth <= 5
        ? 0
        : 5 < growth && growth <= 15
        ? -45
        : -90}deg
  );
`
const DateAndGrowth = styled(Typography)`
  && {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr max-content;
    margin-right: 1rem;
  }
`

const DatePickersContainer = styled.div`
  height: 3.2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
`
const populationReportColumns = (screenSize: 'tiny' | 'small' | 'regular') =>
  [
    {
      Header: 'Date',
      accessor: 'date',
      Cell: ({ row, rows }) => {
        const i = rows.findIndex(r => r.id === row.id)
        const growth =
          rows[i - 1]?.original.total &&
          ((row.original.total || 0) /
            (rows[i - 1]?.original.total || Infinity)) *
            100 -
            100

        return (
          <Tooltip
            title={`${dayjs(row.original.date).format('dddd, MMMM D, YYYY')}${
              growth === undefined
                ? ''
                : ` Growth: ${Math.round(growth * 100) / 100}%`
            }`}
          >
            {screenSize === 'tiny' ? (
              <DateAndGrowth variant="body2">
                {dayjs(row.original.date).format('MMM D')}
              </DateAndGrowth>
            ) : (
              <DateAndGrowth variant="body2">
                {dayjs(row.original.date).format(
                  screenSize === 'regular' ? 'MMM D, ddd' : 'MMM D'
                )}{' '}
                {growth !== undefined && <GrowthIndicator growth={growth} />}
              </DateAndGrowth>
            )}
          </Tooltip>
        )
      },
      sticky: 'left',
      width: screenSize === 'regular' ? 160 : screenSize === 'small' ? 95 : 75
    },
    {
      Header: 'Total',
      accessor: 'total',
      width: 70,
      sticky: 'left'
    },
    { Header: 'Guests', accessor: ProgramTypes.Vacation, width: 110 },
    { Header: 'TTC', accessor: ProgramTypes.Ttc, width: 90 },
    { Header: 'ATTC', accessor: ProgramTypes.Attc, width: 90 },
    { Header: 'Speakers', accessor: ProgramTypes.Speaker, width: 110 },
    {
      Header: 'Staff',
      accessor: ProgramTypes.VisitingStaff,
      width: 90
    },
    {
      Header: 'KYs',
      accessor: ProgramTypes.KarmaYoga,
      width: 90
    },
    {
      Header: 'Children',
      accessor: ProgramTypes.Children,
      width: 100
    },
    { Header: 'Babies', accessor: ProgramTypes.Babies, width: 100 }
  ] as Column<PopulationRow>[]

// firstDayOfWeek is the day of the week of the first row.
const SpecialDataGridContainer = styled(DataGridContainer)<{
  firstDayOfWeek: number
}>`
  // change scrolling to show graph at the bottom
  && {
    height: 100%;
    overflow: auto;
  }

  .table {
    overflow: visible;
  }
  // assuming the first day in the population report is the previous day,
  // paint the bottom-border in primary color after every Sunday.
  .table
    .tr:nth-child(7n + ${({ firstDayOfWeek }) => (8 - firstDayOfWeek) % 7})
    .td {
    border-bottom: 1px solid #ffde8a;
  }
`

const GraphContainer = styled.div`
  /* display: flex; */
  height: 20rem;
  /* justify-items: center; */
  width: 65rem;
  margin: 3rem auto;
`

function PopulationDataGrid(
  props: React.ComponentProps<typeof DataGrid> & { from: Dayjs }
) {
  const { data, from, ...rest } = props

  const crossingYears =
    data.length &&
    dayjs((data as PopulationRow[])[0].date).year() !==
      dayjs((data as PopulationRow[])[data.length - 1].date).year()

  const graphData = React.useMemo(
    () =>
      [
        { label: 'Staff', key: ProgramTypes.VisitingStaff },
        { label: 'Karma Yogis', key: ProgramTypes.KarmaYoga },
        { label: 'Speakers', key: ProgramTypes.Speaker },
        { label: 'TTC', key: ProgramTypes.Ttc },
        { label: 'ATTC', key: ProgramTypes.Attc },
        { label: 'Guests', key: ProgramTypes.Vacation },
        { label: 'Children', key: ProgramTypes.Children },
        { label: 'Babies', key: ProgramTypes.Babies }
      ].map(segment => ({
        label: segment.label,
        data: (data as ({ date: string } & Record<string, number>)[]).map(
          day => ({
            primary: dayjs(day.date).format(
              crossingYears ? 'YYYY-MM-D' : 'MM-D'
            ),
            secondary: day[segment.key] || 0
          })
        )
      })),
    [data, crossingYears]
  )

  const primaryAxis = React.useMemo<
    AxisOptions<typeof graphData[number]['data'][number]>
  >(
    () => ({
      getValue: datum => datum.primary
    }),
    []
  )

  const secondaryAxes = React.useMemo<
    AxisOptions<typeof graphData[number]['data'][number]>[]
  >(
    () => [
      {
        getValue: datum => datum.secondary,
        elementType: 'area'
      }
    ],
    []
  )

  return (
    <SpecialDataGridContainer firstDayOfWeek={from.day()}>
      <DataGrid {...rest} data={data as PopulationRow[]} nonSortable />
      {data.length ? (
        <GraphContainer>
          <Chart
            options={{
              data: graphData,
              primaryAxis,
              secondaryAxes
            }}
          />
        </GraphContainer>
      ) : (
        <></>
      )}
    </SpecialDataGridContainer>
  )
}

export function PopulationReport() {
  const [from, setFrom] = React.useState<Dayjs>(dayjs().subtract(1, 'day'))
  const [to, setTo] = React.useState<Dayjs>(dayjs().add(1, 'month'))
  const smallScreen = useMediaQuery('(max-width: 21.5em)')
  const verySmallScreen = useMediaQuery('(max-width: 14em)')

  const screenSize = verySmallScreen
    ? 'tiny'
    : smallScreen
    ? 'small'
    : 'regular'
  const columns = React.useMemo(
    () => populationReportColumns(screenSize),
    [screenSize]
  )

  const { data: dataFromQuery, isLoading } = useGetGeneralPopulationQuery({
    minimumDate: from.format('YYYY-MM-DD'),
    maximumDate: to.format('YYYY-MM-DD')
  })

  const data: PopulationRow[] =
    dataFromQuery?.getGeneralPopulation.map(result => ({
      date: result.date || '',
      total: result.total,
      [ProgramTypes.Attc]: result[ProgramTypes.Attc] || 0,
      [ProgramTypes.Babies]: result[ProgramTypes.Babies] || 0,
      [ProgramTypes.Children]: result[ProgramTypes.Children] || 0,
      [ProgramTypes.Commuters]: result[ProgramTypes.Commuters] || 0,
      [ProgramTypes.KarmaYoga]: result[ProgramTypes.KarmaYoga] || 0,
      [ProgramTypes.Speaker]: result[ProgramTypes.Speaker] || 0,
      [ProgramTypes.Ttc]: result[ProgramTypes.Ttc] || 0,
      [ProgramTypes.Vacation]: result[ProgramTypes.Vacation] || 0,
      [ProgramTypes.VisitingStaff]: result[ProgramTypes.VisitingStaff] || 0
    })) || []

  return (
    <PopulationDataGrid
      from={from}
      columns={columns as Column<{}>[]}
      data={data}
      renderButtons={() => (
        <DatePickersContainer>
          <DatePicker
            inputFormat="MM/DD/YYYY"
            label="From"
            value={from}
            onChange={date => {
              if (date) setFrom(date)
            }}
            renderInput={params => (
              <Tooltip title="Starting date range">
                <TextField autoComplete="off" {...params} size="small" />
              </Tooltip>
            )}
          />
          <DatePicker
            minDate={from}
            inputFormat="MM/DD/YYYY"
            label="To"
            value={to}
            onChange={date => {
              if (date) setTo(date)
            }}
            renderInput={params => (
              <Tooltip title="Ending date range">
                <TextField autoComplete="off" {...params} size="small" />
              </Tooltip>
            )}
          />
        </DatePickersContainer>
      )}
      removeSelectColumn
      disablePagination
      isLoading={isLoading}
    />
  )
}
