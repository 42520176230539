import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Breadcrumbs,
  Heading2,
  Heading3,
  Paragraph,
  Link,
  ListItem,
  InfoContainer,
  InfoLink,
  Section,
  Hr,
  InfoPageLayout,
  LayoutSeparator,
  ConnectedPages,
  Heading4,
  UnorderedList
} from '../../../../components/InfoTypography'

export function InfoFivePointsOfYoga() {
  return (
    <InfoContainer style={{ wordBreak: 'break-word' }}>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <InfoLink target="">Information</InfoLink>
        <InfoLink target="practice-guide">Practice Guide</InfoLink>
        <InfoLink target="practice-guide/introduction">Introduction</InfoLink>
        <Link $disabled>General Introduction</Link>
      </Breadcrumbs>
      <Heading2>General Introduction to the Practice of Yoga</Heading2>
      <Hr />
      <InfoPageLayout>
        <Section>
          <Heading3>Life and Yoga</Heading3>
          <Paragraph>
            The Yogi sees life as a triangle.
            <br />
            The body undergoes birth, growth, decay, and death.
            <br /> The growth period reaches a plateau at about the age of 18-20
            years.
          </Paragraph>
          <Paragraph>
            In the first years of life, the “youthful” period, the rate of cell
            rejuvenation (anabolic) exceeds the rate of cell decay (catabolic).
            In a normal person, the body maintains an equilibrium of these
            processes from the age of 20 until around the age of 35, when the
            decaying, or catabolic process, begins to gain precedence. <br />
            The body machine starts its decline. This process later results in
            “old age” with its accompanying ills and despair.
          </Paragraph>
          <Paragraph>
            However, the Yogis say that we were not born merely to be subject to
            pain and suffering, disease and death. <br />
            There is a far greater purpose to Life.
            <br /> But, the spiritual investigation of Life's purposes requires
            a keen intellect and a strong will; these are the products of a
            healthy body and mind.
          </Paragraph>
          <Paragraph>
            For this reason, the ancient sages developed an integral system to
            ward off or retard the decaying or catabolic process, and to keep
            the physical and mental faculties strong.
            <br /> This is the system of Yoga, a simple and natural programme
            involving five main principles:
          </Paragraph>

          <Heading3>Five Points Points of Yoga</Heading3>
          <Paragraph>
            Yoga is a life of self-discipline based on the tenets of{' '}
            <em>“simple living and high thinking.”</em> <br />
            The body is a temple or a vehicle for the soul, and has specific
            requirements which must be fulfilled for it to function smoothly and
            supply the optimum mileage.
            <br /> These requirements are the same as for any vehicle.
            <br /> An automobile requires five things:
            <UnorderedList>
              <ListItem>a lubricating system</ListItem>
              <ListItem>a battery</ListItem>
              <ListItem>a cooling system</ListItem>
              <ListItem>fuel</ListItem>
              <ListItem>and a responsible driver behind the wheel.</ListItem>
            </UnorderedList>
            Now let us look at the human needs.
          </Paragraph>
          <Heading4>Proper Exercise</Heading4>
          <Paragraph>
            Acts as a lubricating routine to the joints, muscles, ligaments,
            tendons, and other parts of the body by increasing circulation and
            flexibility. <br />
            <br />
            For further details, see chapter 4 of The Complete Illustrated Book
            of Yoga by Swami Vishnu-devanananda.
          </Paragraph>
          <Heading4> Proper Breathing</Heading4>
          <Paragraph>
            Aids the body in connecting to its battery, the Solar Plexus, where
            tremendous potential energy is stored.
            <br /> When tapped through specific Yoga breathing techniques
            (Pranayama), this energy is released for physical and mental
            rejuvenation.
            <br />
            <br />
            For further details, see chapter 8 of The Complete Illustrated Book
            of Yoga.
          </Paragraph>
          <Heading4> Proper Relaxation</Heading4>
          <Paragraph>
            Cools down the system, as does the radiator of a car, When the body
            and mind are continually overworked, their efficiency diminishes.
            <br />
            Relaxation is Nature's way of recharging the body.
            <br />
            <br />
            For further details, see chapter 6 of The Complete Illustrated Book
            of Yoga.
          </Paragraph>
          <Heading4> Proper Diet</Heading4>
          <Paragraph>
            Provides the correct fuel for the body.
            <br /> Optimum utilization of food, air, water, and sunlight is
            essential.
            <br />
            <br />
            For further details, see chapter 7 of The Complete Illustrated Book
            of Yoga.
          </Paragraph>
          <Heading4> Positive Thinking and Meditation</Heading4>
          <Paragraph>
            Puts you in control.
            <br /> The intellect is purified. <br />
            The lower nature is brought under conscious control through
            steadiness and concentration of the mind.
            <br />
            <br />
            For further details, see Meditation and Mantras by Swami
            Vishnu-devananda.
          </Paragraph>
        </Section>
        <ConnectedPages>
          <LayoutSeparator />
          <Section>
            <Paragraph>
              <strong>Connected Articles:</strong>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/introduction/proper-exercise">
                1. Proper Exercise
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/introduction/proper-breathing">
                2. Proper Breathing
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/introduction/proper-relaxation">
                3. Proper Relaxation
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/introduction/proper-diet">
                4. Proper Diet
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/introduction/proper-thinking">
                5. Proper Thinking
              </InfoLink>
            </Paragraph>
          </Section>
        </ConnectedPages>
      </InfoPageLayout>
    </InfoContainer>
  )
}
