// @ts-nocheck
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { QueryBuilder } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { RootStoreType } from './index'

/**
 * InHouseRegResponseBase
 * auto generated base class for the model InHouseRegResponseModel.
 */
export const InHouseRegResponseModelBase = ModelBase.named('InHouseRegResponse')
  .props({
    __typename: types.optional(
      types.literal('InHouseRegResponse'),
      'InHouseRegResponse'
    ),
    id: types.identifier,
    firstName: types.union(types.undefined, types.string),
    lastName: types.union(types.undefined, types.string),
    spiritualName: types.union(types.undefined, types.string),
    rgLink: types.union(types.undefined, types.string),
    headshot: types.union(types.undefined, types.string),
    room: types.union(types.undefined, types.string),
    programType: types.union(types.undefined, types.string),
    balance: types.union(types.undefined, types.number),
    comments: types.union(types.undefined, types.string),
    arrivalDate: types.union(types.undefined, types.string),
    departureDate: types.union(types.undefined, types.string),
    status: types.union(types.undefined, types.string)
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class InHouseRegResponseModelSelector extends QueryBuilder {
  get id() {
    return this.__attr(`id`)
  }
  get firstName() {
    return this.__attr(`firstName`)
  }
  get lastName() {
    return this.__attr(`lastName`)
  }
  get spiritualName() {
    return this.__attr(`spiritualName`)
  }
  get rgLink() {
    return this.__attr(`rgLink`)
  }
  get headshot() {
    return this.__attr(`headshot`)
  }
  get room() {
    return this.__attr(`room`)
  }
  get programType() {
    return this.__attr(`programType`)
  }
  get balance() {
    return this.__attr(`balance`)
  }
  get comments() {
    return this.__attr(`comments`)
  }
  get arrivalDate() {
    return this.__attr(`arrivalDate`)
  }
  get departureDate() {
    return this.__attr(`departureDate`)
  }
  get status() {
    return this.__attr(`status`)
  }
}
export function selectFromInHouseRegResponse() {
  return new InHouseRegResponseModelSelector()
}

export const inHouseRegResponseModelPrimitives =
  selectFromInHouseRegResponse().firstName.lastName.spiritualName.rgLink
    .headshot.room.programType.balance.comments.arrivalDate.departureDate.status
