import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Typography from '@mui/material/Typography'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { useQueryClient } from 'react-query'

import {
  useGetAllSeasonsWithIdQuery,
  useRegAdditionalDataSeasonDeleteMutation,
  GetAllSeasonsQuery,
  SeasonTypes
} from '../../graphql/generated/typesAndHooks'
import { PrimaryButton } from '../../components/common/Buttons'
import { StoreContext } from '@sayr/client-models'

const CardsGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
`

const Grid = styled.div`
  display: grid;
  gap: 2rem;
`

const StyledCard = styled(Card)<{ disabled: boolean }>`
  && {
    color: ${({ disabled, theme }) => disabled && theme.palette.grey['400']};
    overflow-x: auto;
  }
`

const Field = styled.div``
const key = useGetAllSeasonsWithIdQuery.getKey()
export function Seasons() {
  const queryClient = useQueryClient()

  const deleteSeasonMutation = useRegAdditionalDataSeasonDeleteMutation<
    { message: string },
    { previousValue: any }
  >({
    onMutate: async () => {
      await queryClient.cancelQueries(key)
      const previousValue = queryClient.getQueryData<GetAllSeasonsQuery>(key)

      if (!previousValue) throw new Error('old does not exist')

      return { previousValue }
    },
    onError: (err, vars, context) => {
      console.error(err, vars)
      if (context) queryClient.setQueryData(key, context.previousValue)
    },
    onSettled: () => queryClient.invalidateQueries(key)
  })
  const store = React.useContext(StoreContext)
  const { data, status } = useGetAllSeasonsWithIdQuery()

  if (status === 'loading') return <CircularProgress />
  if (status === 'error') return <div>Sorry, error...</div>
  if (status === 'idle') return <div>Idle....</div>

  if (data !== undefined)
    return (
      <div style={{ margin: '3rem' }}>
        <CardsGrid>
          {data.getAllSeasons.map(s => (
            <StyledCard key={s._id} disabled={s.deleted}>
              <CardContent>
                <Grid>
                  <Field>
                    <Typography variant="h3">Precedence:</Typography>
                    <Typography variant="body2">{s.precedence}</Typography>
                  </Field>
                  <Field>
                    <Typography variant="h3">Season Type:</Typography>
                    <Typography variant="body2">
                      {s.seasonType === SeasonTypes.MonitoredAttributes
                        ? 'Monitored Attributes'
                        : s.seasonType === SeasonTypes.CovidTestCosts
                        ? 'Covid Test Costs'
                        : s.seasonType ===
                          SeasonTypes.CovidTestFrequencyDaysOfWeek
                        ? 'Covid Test Frequency by Day of Week'
                        : 'Covid Test Frequency - Fixed Gap'}
                    </Typography>
                  </Field>
                  <Field>
                    <Typography variant="h3">For Program Types:</Typography>
                    <ul style={{ marginLeft: '2rem' }}>
                      {s.programTypes.map(s => (
                        <li key={s}>{s}</li>
                      ))}
                    </ul>
                  </Field>
                  {s.seasonDateClassification === 'by date' && (
                    <Field>
                      <Typography variant="h3">Applied in Dates:</Typography>
                      <Typography variant="h6">From:</Typography>
                      <Typography variant="body2">
                        {dayjs(s.startDate).format('MMMM D, YYYY	')}
                      </Typography>
                      <Typography variant="h6">To:</Typography>
                      <Typography variant="body2">
                        {dayjs(s.endDate).format('MMMM D, YYYY	')}
                      </Typography>
                    </Field>
                  )}
                  {s.seasonDateClassification === 'by arrival date' && (
                    <Field>
                      <Typography variant="h3">
                        Applied For Arrivals between:
                      </Typography>
                      <Typography variant="h6">From:</Typography>
                      <Typography variant="body2">
                        {dayjs(s.arrivalDateStart).format('MMMM D, YYYY	')}
                      </Typography>
                      <Typography variant="h6">To:</Typography>
                      <Typography variant="body2">
                        {dayjs(s.arrivalDateEnd).format('MMMM D, YYYY	')}
                      </Typography>
                    </Field>
                  )}
                  {s.seasonDateClassification ===
                    'by both arrival date and date' && (
                    <Field>
                      <Typography variant="h3">
                        Applied for Arrivals and Dates:
                      </Typography>
                    </Field>
                  )}
                  {s.seasonType === SeasonTypes.MonitoredAttributes &&
                    s.monitoredAttributes && (
                      <Field>
                        <Typography variant="h3">
                          Monitored Attributes:
                        </Typography>
                        <div style={{ display: 'flex', gap: '1rem' }}>
                          {s.monitoredAttributes.map(attr => (
                            <div
                              key={attr.key}
                              style={{
                                display: 'flex',
                                flexDirection: 'column'
                              }}
                            >
                              <Typography variant="h6">
                                Attribute Key:
                              </Typography>
                              <Typography variant="body2">
                                {attr.key}
                              </Typography>
                              <br />
                              <Typography variant="h6">Displayed:</Typography>
                              <Typography variant="body2">
                                {attr.displayed}
                              </Typography>
                              <br />
                              <Typography variant="h6">Yellow Flag:</Typography>
                              <Typography variant="body2">
                                {attr.yellowFlagDay} Days {attr.flagCountFrom}
                              </Typography>
                              <br />
                              <Typography variant="h6">Displayed:</Typography>
                              <Typography variant="body2">
                                {attr.redFlagDay} Days {attr.flagCountFrom}
                              </Typography>
                            </div>
                          ))}
                        </div>
                      </Field>
                    )}
                  {s.seasonType === SeasonTypes.CovidTestCosts && (
                    <>
                      <Field>
                        <Typography variant="h3">Test Type:</Typography>
                        <Typography variant="body2">{s.testType}</Typography>
                      </Field>
                      <Field>
                        <Typography variant="h3">Cost:</Typography>
                        <Typography variant="body2">{s.cost}</Typography>
                      </Field>
                    </>
                  )}
                  {s.seasonType === SeasonTypes.CovidTestFrequencyFixedGap && (
                    <Field>
                      <Typography variant="h3">Day Gap:</Typography>
                      <Typography variant="body2">{s.dayGap}</Typography>
                    </Field>
                  )}
                  {s.seasonType ===
                    SeasonTypes.CovidTestFrequencyDaysOfWeek && (
                    <Field>
                      <Typography variant="h3">Days of Week:</Typography>
                      <Typography variant="body2">
                        {s.daysOfWeek
                          ?.map(dayOfWeek =>
                            dayjs().day(dayOfWeek).format('dddd')
                          )
                          .join(', ')}
                      </Typography>
                    </Field>
                  )}
                </Grid>
              </CardContent>
              <CardActions>
                {!s.deleted && (
                  <PrimaryButton
                    onClick={() => deleteSeasonMutation.mutate({ id: s._id })}
                  >
                    Delete Season
                  </PrimaryButton>
                )}
              </CardActions>
            </StyledCard>
          ))}
        </CardsGrid>
        <br />
        <PrimaryButton
          onClick={() => store.view.openAdminSeasonsNewDraftPage()}
        >
          New Season
        </PrimaryButton>
      </div>
    )
  else throw new Error('unknown state')
}
