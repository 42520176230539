import { Person } from '../@types/PersonTypes'
import dayjs, { Dayjs } from 'dayjs'

export function getPersonalCreditInfo(person: Person) {
  // sort person's credits by trans date.
  const credits = person.creditTransactions
    .map(c => ({ ...c }))
    .sort((a, b) =>
      // sort by trans_date and place person-credit transactions before person-debit as secondary sorting column.
      a.trans_date === b.trans_date
        ? a.class === 'person-debit'
          ? 1
          : -1
        : a.trans_date > b.trans_date
        ? 1
        : -1
    )

  // reduce all credits into a balance amount, taking into consideration that
  // each positive credit transaction has an expiry date, and each negative
  // credit transaction takes away credit only from the valid (not-expired)
  // positive credits relevant for the date of that negative credit
  // transaction.
  const creditBalance = credits.reduce<{
    coldBalance: number // cold sumtotal of all positive and negative credits, even with expired credits
    previousExpirableCredits: {
      id: number
      expiryDate: Dayjs
      trans_date: Dayjs
      originalAmount: number
      remainingAmount: number
    }[]
  }>(
    (subTotalCredits, creditTransaction) => {
      switch (creditTransaction.class) {
        // handle positive credit
        case 'person-credit':
          // 1. add to list of expirable credits
          // 2. add to the cold balance
          return {
            coldBalance:
              subTotalCredits.coldBalance + creditTransaction.credit_amount,
            previousExpirableCredits: subTotalCredits.previousExpirableCredits
              .concat({
                id: creditTransaction.id,
                originalAmount: creditTransaction.credit_amount,
                remainingAmount: creditTransaction.credit_amount,
                expiryDate: dayjs(creditTransaction.expiry_date),
                trans_date: dayjs(creditTransaction.trans_date)
              })
              .sort((a, b) =>
                a.expiryDate.isSame(b.expiryDate)
                  ? a.trans_date.isAfter(b.trans_date)
                    ? 1
                    : -1
                  : a.expiryDate.isAfter(b.expiryDate)
                  ? 1
                  : -1
              )
          }

        // handle credit deduction
        case 'person-debit': {
          // subtract amounts of previous credits, starting with the earliest expiry date.
          let remainingAmountToSubtract = creditTransaction.charge_amount
          subTotalCredits.previousExpirableCredits.forEach(previousCredit => {
            // if previous credit is not valid compared to this credit, ignore it.
            if (
              dayjs(previousCredit.expiryDate).isBefore(
                creditTransaction.trans_date
              )
            )
              return

            const previousRemainingAmount = previousCredit.remainingAmount
            previousCredit.remainingAmount = Math.max(
              0,
              previousCredit.remainingAmount - remainingAmountToSubtract
            )
            remainingAmountToSubtract = Math.max(
              0,
              remainingAmountToSubtract - previousRemainingAmount
            )
          })

          const coldBalance =
            subTotalCredits.coldBalance - creditTransaction.charge_amount

          return {
            coldBalance,
            previousExpirableCredits: subTotalCredits.previousExpirableCredits
          }
        }
        default:
          return subTotalCredits
      }
    },
    { coldBalance: 0, previousExpirableCredits: [] }
  )

  const expiredCreditsTotal = creditBalance.previousExpirableCredits
    .filter(c => dayjs().isAfter(c.expiryDate))
    .reduce(
      (totalPositiveBalance, credit) =>
        totalPositiveBalance + credit.remainingAmount,
      0
    )

  return { creditBalance, expiredCreditsTotal }
}

export function getPersonalCredit(person: Person) {
  const { creditBalance, expiredCreditsTotal } = getPersonalCreditInfo(person)
  const currentlyValidBalance = creditBalance.coldBalance - expiredCreditsTotal

  return +currentlyValidBalance.toFixed(2)
}

export function getVouchersUsed(
  person: Person,
  voucherTransactionIds: number[]
): {
  person: Person
  vouchersUsed: { id: number; amount: number }[]
  transactionsUsingVoucher: { id: number; usedAmount: number }[]
} {
  // sort person's credits by trans date.
  const credits = person.creditTransactions
    .map(c => ({ ...c }))
    .sort((a, b) =>
      // sort by trans_date and place person-credit transactions before person-debit as secondary sorting column.
      a.trans_date === b.trans_date
        ? a.class === 'person-debit'
          ? 1
          : -1
        : a.trans_date > b.trans_date
        ? 1
        : -1
    )

  const creditBalance = credits.reduce<{
    overallBalance: number
    previousExpirableCredits: {
      id: number
      expiryDate: Dayjs
      trans_date: Dayjs
      originalAmount: number
      remainingAmount: number
    }[]
    usedBy: { id: number; usedAmount: number }[]
  }>(
    (subTotalCredits, creditTransaction) => {
      switch (creditTransaction.class) {
        case 'person-credit':
          // handle positive credit
          // 1. add to list of expirable credits
          // 2. add to overall balance
          return {
            overallBalance:
              subTotalCredits.overallBalance + creditTransaction.credit_amount,
            previousExpirableCredits: subTotalCredits.previousExpirableCredits
              .concat({
                id: creditTransaction.id,
                originalAmount: creditTransaction.credit_amount,
                remainingAmount: creditTransaction.credit_amount,
                expiryDate: dayjs(creditTransaction.expiry_date),
                trans_date: dayjs(creditTransaction.trans_date)
              })
              .sort((a, b) =>
                a.expiryDate.isSame(b.expiryDate)
                  ? a.trans_date.isAfter(b.trans_date)
                    ? 1
                    : -1
                  : a.expiryDate.isAfter(b.expiryDate)
                  ? 1
                  : -1
              ),
            usedBy: subTotalCredits.usedBy
          }
        case 'person-debit': {
          // handle deduction of credit

          const usedBy = subTotalCredits.usedBy

          // subtract amounts of previous credits, starting with the earliest expiry date.
          let remainingAmountToSubtract = creditTransaction.charge_amount
          subTotalCredits.previousExpirableCredits.forEach(previousCredit => {
            // if previous credit is not valid compared to this credit, ignore it.
            if (
              dayjs(previousCredit.expiryDate).isBefore(
                creditTransaction.trans_date
              )
            )
              return

            const previousRemainingAmount = previousCredit.remainingAmount
            previousCredit.remainingAmount = Math.max(
              0,
              previousCredit.remainingAmount - remainingAmountToSubtract
            )
            remainingAmountToSubtract = Math.max(
              0,
              remainingAmountToSubtract - previousRemainingAmount
            )
            if (
              voucherTransactionIds.includes(previousCredit.id) &&
              previousCredit.originalAmount > previousCredit.remainingAmount
            ) {
              usedBy.push({
                id: creditTransaction.id,
                usedAmount:
                  previousRemainingAmount - previousCredit.remainingAmount
              })
            }
          })

          const overallBalance =
            subTotalCredits.overallBalance - creditTransaction.charge_amount

          return {
            overallBalance,
            previousExpirableCredits: subTotalCredits.previousExpirableCredits,
            usedBy
          }
        }
        default:
          return subTotalCredits
      }
    },
    {
      overallBalance: 0,
      previousExpirableCredits: [],
      usedBy: []
    }
  )

  const usedVouchers: { id: number; amount: number }[] = []
  // if previousValidCredits share a transaction in common with any of the voucherTransactionIds
  // add them to the usedVouchers, with the amount used
  creditBalance.previousExpirableCredits.forEach(credit => {
    if (
      voucherTransactionIds.includes(credit.id) &&
      credit.originalAmount > credit.remainingAmount
    )
      usedVouchers.push({
        id: credit.id,
        amount: credit.originalAmount - credit.remainingAmount
      })
  })

  return {
    person,
    vouchersUsed: usedVouchers,
    transactionsUsingVoucher: creditBalance.usedBy
  }
}
