// @ts-nocheck
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { QueryBuilder } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { RootStoreType } from './index'

/**
 * ArrivingRegResponseBase
 * auto generated base class for the model ArrivingRegResponseModel.
 */
export const ArrivingRegResponseModelBase = ModelBase.named(
  'ArrivingRegResponse'
)
  .props({
    __typename: types.optional(
      types.literal('ArrivingRegResponse'),
      'ArrivingRegResponse'
    ),
    id: types.identifier,
    firstName: types.union(types.undefined, types.string),
    lastName: types.union(types.undefined, types.string),
    spiritualName: types.union(types.undefined, types.string),
    rgLink: types.union(types.undefined, types.string),
    headshot: types.union(types.undefined, types.string),
    room: types.union(types.undefined, types.string),
    programType: types.union(types.undefined, types.string),
    balance: types.union(types.undefined, types.number),
    flightNumber: types.union(types.undefined, types.string),
    landingTime: types.union(types.undefined, types.string),
    comments: types.union(types.undefined, types.string),
    arrivalDate: types.union(types.undefined, types.string),
    departureDate: types.union(types.undefined, types.string),
    fromRoom: types.union(types.undefined, types.string),
    roomSetUp: types.union(types.undefined, types.string),
    status: types.union(types.undefined, types.string)
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class ArrivingRegResponseModelSelector extends QueryBuilder {
  get id() {
    return this.__attr(`id`)
  }
  get firstName() {
    return this.__attr(`firstName`)
  }
  get lastName() {
    return this.__attr(`lastName`)
  }
  get spiritualName() {
    return this.__attr(`spiritualName`)
  }
  get rgLink() {
    return this.__attr(`rgLink`)
  }
  get headshot() {
    return this.__attr(`headshot`)
  }
  get room() {
    return this.__attr(`room`)
  }
  get programType() {
    return this.__attr(`programType`)
  }
  get balance() {
    return this.__attr(`balance`)
  }
  get flightNumber() {
    return this.__attr(`flightNumber`)
  }
  get landingTime() {
    return this.__attr(`landingTime`)
  }
  get comments() {
    return this.__attr(`comments`)
  }
  get arrivalDate() {
    return this.__attr(`arrivalDate`)
  }
  get departureDate() {
    return this.__attr(`departureDate`)
  }
  get fromRoom() {
    return this.__attr(`fromRoom`)
  }
  get roomSetUp() {
    return this.__attr(`roomSetUp`)
  }
  get status() {
    return this.__attr(`status`)
  }
}
export function selectFromArrivingRegResponse() {
  return new ArrivingRegResponseModelSelector()
}

export const arrivingRegResponseModelPrimitives =
  selectFromArrivingRegResponse().firstName.lastName.spiritualName.rgLink
    .headshot.room.programType.balance.flightNumber.landingTime.comments
    .arrivalDate.departureDate.fromRoom.roomSetUp.status
