/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from 'mobx-state-tree'

/**
 * Typescript enum
 */

export enum CovidTestType {
  IN_HOUSE = 'IN_HOUSE',
  COMPANY_SELF_TEST_KIT = 'COMPANY_SELF_TEST_KIT',
  COMPANY_FAST_PCR = 'COMPANY_FAST_PCR',
  COMPANY_PCR = 'COMPANY_PCR',
  COMPANY_ANTIGEN = 'COMPANY_ANTIGEN'
}

/**
 * CovidTestType
 *
 * types of tests
 */
export const CovidTestTypeEnumType = types.enumeration('CovidTestType', [
  'IN_HOUSE',
  'COMPANY_SELF_TEST_KIT',
  'COMPANY_FAST_PCR',
  'COMPANY_PCR',
  'COMPANY_ANTIGEN'
])
