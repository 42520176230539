import React from 'react'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { CellProps, Column } from 'react-table'

import {
  GetAllSeasonsQuery,
  useGetAllSeasonsQuery,
  useRegFollowUpGetArrivalsQuery,
  SeasonTypes
} from '../../../graphql/generated/typesAndHooks'
import { DataGrid } from '../../../components/common/dataGrid/DataGrid'
import { DataGridContainer } from '../../../components/common/dataGrid/DataGridStyles'
import {
  Balance,
  getSeasonFromBatch,
  SmallAvatar,
  UpdateableAttributeCheckbox,
  ReportRowWithAttributes
} from './FollowUpUtils'

const getColumnsBySeasons = (
  seasons: GetAllSeasonsQuery['getAllSeasons'] | undefined
) => {
  const standardColumns = [
    {
      Header: '',
      accessor: 'headshot',
      Cell: React.memo(({ value }: { value: string }) => {
        return <SmallAvatar src={value} />
      }),
      width: 50,
      disableSortBy: true,
      disableResizing: true,
      sticky: 'left'
    },
    {
      Header: 'RG Link',
      accessor: 'rgLink',
      width: 140,
      Cell: React.memo(({ value }: any) => (
        <Link href={value} target="_blank">
          Details
        </Link>
      )),
      sticky: 'left'
    },
    {
      Header: 'Arrival Date',
      accessor: 'arrivalDate',
      width: 120
    },
    {
      Header: 'Departure Date',
      accessor: 'departureDate'
    },
    { Header: 'First Name', accessor: 'firstName' },
    { Header: 'Last Name', accessor: 'lastName' },
    { Header: 'Spiritual Name', accessor: 'spiritualName' },
    { Header: 'Program', accessor: 'program' },
    { Header: 'Room', accessor: 'room' },
    { Header: 'Flight Time', accessor: 'flightTime' },
    { Header: 'Notes', accessor: 'notes' },
    { Header: 'Balance', accessor: 'balance', Cell: Balance }
  ] as Column<ReportRowWithAttributes>[]

  const columnsWithAttributes = standardColumns.concat(
    seasons
      ?.filter(season => season.seasonType === SeasonTypes.MonitoredAttributes)
      .flatMap(season => season.monitoredAttributes)
      .filter(
        attr =>
          attr?.displayed === 'before arrival' ||
          attr?.displayed === 'before or during stay'
      )
      .map(attr => attr?.key as string)
      .reduce<string[]>(function (prev, cur) {
        // reduce to unique values
        return prev.indexOf(cur) < 0 ? prev.concat([cur]) : prev
      }, [])
      .map<Column<ReportRowWithAttributes>>(attr => {
        return {
          Header: attr,
          accessor: attr,
          Cell: UpdateableAttributeCheckbox as (
            props: CellProps<ReportRowWithAttributes>
          ) => JSX.Element
        }
      }) || []
  )
  return columnsWithAttributes
}

export function FollowUpOnArrivals() {
  const [daysDraft, setDaysDraft] = React.useState(14)
  const [days, setDays] = React.useState(daysDraft)

  // load monitored attributes season for registration follow up
  const { data: monitoredAttrSeasonsData } = useGetAllSeasonsQuery()

  const { data: queryData, isLoading } = useRegFollowUpGetArrivalsQuery({
    days
  })

  const columns = React.useMemo(() => {
    if (queryData && monitoredAttrSeasonsData) {
      const relevantSeasons = Array.from(
        new Set(
          queryData.RegFollowUpGetArrivals.map(r => ({
            arrivalDate: r.arrivalDate,
            programTypes: r.programTypes
          })).map(({ arrivalDate, programTypes }) => {
            return getSeasonFromBatch({
              seasons: monitoredAttrSeasonsData,
              arrivalDate,
              programTypes
            })
          })
        )
      )
        .filter(s => s)
        .map(s => s as GetAllSeasonsQuery['getAllSeasons'][number])
        .filter(s => !s?.deleted)
      return getColumnsBySeasons(relevantSeasons)
    } else
      return getColumnsBySeasons(
        monitoredAttrSeasonsData?.getAllSeasons.filter(s => !s.deleted)
      )
  }, [monitoredAttrSeasonsData, queryData])

  if (!queryData) return <div>No data yet...</div>
  if (!monitoredAttrSeasonsData) return <div>No Seasonal data yet...</div>
  const data = queryData.RegFollowUpGetArrivals.map<ReportRowWithAttributes>(
    r => {
      return {
        regId: r.regId,
        arrivalDate: r.arrivalDate || '',
        departureDate: r.departureDate || '',
        headshot: r.headshot || '',
        rgLink: r.adminLink || '',
        balance: r.balance,
        firstName: r.firstName || '',
        lastName: r.lastName || '',
        spiritualName: r.spiritualName || '',
        flightNumber: r.flightNumber || '',
        flightTime: r.flightTime || '',
        notes: r.registrationNotes || '',
        program: r.program || '',
        programTypes: r.programTypes,
        room: r.room || '',
        ...Object.fromEntries(
          r.monitoredAttributes.map(attr => [attr.key, attr])
        ),
        highlightRow: !r.personId
      }
    }
  )

  return (
    <DataGridContainer>
      <DataGrid
        columns={columns}
        data={data}
        withGlobalFilter
        renderButtons={() => (
          <>
            <span>Arrivals for the upcoming {days} days</span>
            <TextField
              style={{ width: '6rem' }}
              type="number"
              margin="none"
              label="Upcoming Days"
              value={daysDraft}
              onChange={e => setDaysDraft(+(e.target.value || ''))}
            />
            <Button onClick={() => setDays(daysDraft)}>Load</Button>
          </>
        )}
        queryKey={useRegFollowUpGetArrivalsQuery.getKey({ days })}
        skipPageReset
        seasons={monitoredAttrSeasonsData}
        filterableColumns={['firstName', 'lastName', 'spiritualName', 'notes']}
        removeSelectColumn
        queryIdentifier="RegFollowUpGetArrivals"
        isLoading={isLoading}
      />
    </DataGridContainer>
  )
}
