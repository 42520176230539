import styled from 'styled-components'
import { rgb, rgba } from 'polished'
import Checkbox from '@mui/material/Checkbox'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'

import PageContentWrapper from '../../PageContentWrapper'

export const DataGridContainer = styled(PageContentWrapper)`
  && {
    align-content: start;
    align-items: start;
    grid-template-rows: max-content 1fr;
    grid-template-columns: 1fr;
    height: 100%;
    justify-content: safe center;
    overflow-y: hidden;
    width: 100%;

    @media (max-height: 31em) {
      padding: 0;
    }
  }

  [data-sticky-last-left-td] {
    border-right: 1px #ddd solid;
  }

  [data-sticky-first-right-td] {
    border-left: 1px #ddd solid;
  }

  .table .thead [data-sticky-td='true'] {
    background-color: ${({ theme: { palette } }) =>
      palette.grey[palette.mode === 'dark' ? '900' : '50']};
  }
`

export const TableControlSection = styled.div<{
  tableWidth: number
}>`
  align-items: center;
  column-gap: 1rem;
  display: flex;
  justify-content: space-between;
  margin: 0.5rem;
  min-height: 4rem;
  row-gap: 0.5rem;
  min-width: ${({ tableWidth }) => `min(${tableWidth}px, calc(100vw - 4rem))`};
  max-width: 100vw;
  position: sticky;
  left: 0;

  /** 
  media query wrap (a workaround for not being able to switch the 
  justify-content to "center" when the content is wrapped) 
   */
  @media (max-width: 51em) {
    flex-direction: column;

    && > * {
      flex-basis: unset;
    }
  }

  @media (max-width: 23em) {
    padding: 0;
    min-width: ${({ tableWidth }) => `min(${tableWidth}px, 100vw)`};
  }

  @media (max-width: 18.5em) {
    & > div:nth-child(2) {
      margin-left: 0.2rem;
      margin-right: -0.3rem;
    }
  }

  @media (max-width: 16em) {
    font-size: 85%;
  }
`

export const PaginationWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: min(2rem, calc(100vw / 30));
  justify-content: space-around;

  /* rows per page selection */
  & > div:nth-child(1) {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media (max-width: 22em) {
      & > span {
        display: none;
      }
    }
  }

  /* rows indicator */
  & > div:nth-child(2) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1ch;
    flex: 1 0 4rem;
    text-align: center;
  }
`

export const ActionsContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 1;
  justify-content: start;
  width: max-content;
`

export const Centered = styled.div`
  display: grid;
  justify-items: center;
  width: 100vw;
`

export const CenteredSelect = styled(Select)`
  & > [role='button'] {
    padding-top: 0;
    padding-bottom: 0;
  }
`

export const TableBody = styled.div`
  flex: 1;
  width: 100%;
`

export const TableRow = styled.div`
  margin: 0 auto;
  min-height: 2rem;
`

export const TableHead = styled.div`
  flex-basis: 3.5rem;
  flex-shrink: 0;
  /* In this example we use an absolutely position resizer, 
so a relative position is required, and sticky is also relative. */
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 5;

  :last-child {
    border-right: 0;
  }
`

export const TableRowInHeader = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.grey['200']};
  border-radius: 0;
  border-spacing: 0;
  margin: 0 auto;
  background-color: ${({ theme: { palette } }) =>
    palette.grey[palette.mode === 'dark' ? '900' : '50']};
`

export const StyledColumn = styled.div`
  align-items: center;
  display: flex;
  font-weight: 400;
  padding: 1rem 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const Heading = styled.div.attrs({ className: 'heading' })`
  display: flex;
  flex-grow: 1;
  margin-left: 0.3rem;
`

const OverflowCellContent = styled.div`
  flex: 1;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`

export const StyledCell = styled.div<{ odd?: boolean; highlight?: boolean }>`
  background-color: ${({ odd, highlight }) =>
    highlight ? 'lightYellow' : odd && rgba(240, 240, 240, 0.05)};

  &[data-sticky-td='true'] {
    background-color: ${({ odd, highlight }) =>
      highlight ? 'lightYellow' : odd ? rgb(253, 253, 253) : 'white'};
  }
`

export const HeadingCellContent = styled(OverflowCellContent).attrs({
  className: 'datagrid--heading-cell'
})`
  flex-shrink: 1;
  height: max-content;
`

export const CellContent = styled(OverflowCellContent)``

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  font-size: 0.85rem;

  td,
  .td {
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey['200']};
    display: flex;
    margin: 0;
    padding: 0.1rem 0.3rem;
  }
`

export const SortIconHoverComponent = styled.div`
  align-items: center;
  border-radius: 50%;
  display: flex;
  flex-basis: 1.8em;
  flex-shrink: 0;
  justify-content: center;
  padding: 0.2rem;

  &:hover {
    background-color: ${({ theme }) => theme.palette.grey['200']};
  }
`

export const PageInputTextField = styled(TextField)`
  width: 2.5rem;

  & input {
    padding: 0.3rem 0;
    text-align: center;
  }
`

export const SortIconComponent = styled.svg<{
  show: boolean
  hint: boolean
  upDirection: boolean
}>`
  opacity: ${({ show }) => (show ? 1 : 0)};
  color: ${({ hint, theme: { palette } }) =>
    palette.grey[hint ? '500' : palette.mode === 'dark' ? '200' : '900']};
  fill: currentColor;
  transform: rotate(${({ upDirection }) => (upDirection ? 0 : 180)}deg);
  transition: opacity 0.2s, color 0.4s, transform 0.3s;
  height: 80%;
  width: 80%;
  display: flex;
  align-items: center;
`

export const ResizerComponent = styled.svg`
  color: #ddd;
  display: inline-block;
  fill: currentColor;
  font-size: 1.5rem;
  flex-shrink: 0;
  height: 1em;
  margin-left: auto;
  right: 0;
  touch-action: none; // prevents from scrolling while dragging on touch devices
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  width: 1em;
  z-index: 1;
`

export const SmallCheckbox = styled(Checkbox).attrs({
  size: 'small'
})`
  && {
    padding: 0.4rem;
  }
`

export const ReadOnlyCheckbox = styled(SmallCheckbox).attrs<{
  checked: boolean
}>({
  disabled: true
})`
  &&& {
    color: ${({ theme, checked }) =>
      checked
        ? theme.palette.primary.light
        : theme.palette.mode === 'dark'
        ? ''
        : theme.palette.grey['600']};
  }
`
