import { MessageEdgeModelType, RootStoreType } from '@sayr/client-models'
import { reaction, autorun } from 'mobx'
import { GraphQLClient } from 'graphql-request'
// import { applySnapshot, getSnapshot } from 'mobx-state-tree'
// import { ViewSnapshotType } from '@sayr/client-models'

import { ActionsFunctions } from '../components/WarningsNotifier'
import { bindViewReactions } from './viewLoader'
import { initializeLoggedInState } from './loginStateLoader'
import { showWarningWhenOffline } from './offlineStatusIndicationLoader'
import { getPageTitle } from '../services/pageTitles'

export default async function onStart(
  rootStore: RootStoreType,
  gqlHttpClient: GraphQLClient,
  actions: ActionsFunctions,
  accessTokenForWS: { token: string }
) {
  console.log('running onstart', new Date().toISOString())

  await initializeLoggedInState(
    rootStore,
    gqlHttpClient,
    actions,
    accessTokenForWS
  )

  console.log('initialized logged in state')

  // handle URL updates and update browser history
  bindViewReactions(rootStore)

  // synchronize login and logout on all tabs
  // relying on the localStorageMixin to update the "loggedInUser"
  // window.addEventListener('storage', (e: StorageEvent) => {
  //   if (e.key === 'mst-gql-rootstore') {
  //     const storageOldValue: RootStoreType = JSON.parse(e.oldValue || '{}')
  //     const storageNewValue: RootStoreType = JSON.parse(e.newValue || '{}')

  //     if (
  //       (storageOldValue.loggedInUser &&
  //         !storageNewValue.loggedInUser &&
  //         rootStore.loggedInUser) ||
  //       (!storageOldValue.loggedInUser &&
  //         storageNewValue.loggedInUser &&
  //         !rootStore.loggedInUser)
  //     ) {
  //       rootStore.setLoggedInUser(storageNewValue.loggedInUser)
  //     }
  //   }
  // })

  // automatically jump back if accessed an unauthorized page.
  // let prevViewSnapshotForRegistrations: ViewSnapshotType | null
  // autorun(() => {
  //   if (
  //     ['/registrations', '/announcements/edit', '/announcements/stats'].every(
  //       privileged => rootStore.view.page !== privileged
  //     )
  //   ) {
  //     // store previous page
  //     prevViewSnapshotForRegistrations = getSnapshot(rootStore.view)
  //   } else if (rootStore.loggedInUser?.type !== 'staff') {
  //     // this is an unauthorized state.

  //     if (prevViewSnapshotForRegistrations)
  //       // restore view to previous page
  //       applySnapshot(rootStore.view, prevViewSnapshotForRegistrations)
  //     // or to homepage
  //     else rootStore.view.openHomePage()
  //   }
  // })

  const resetWarnings = () => rootStore.warnings.clearAll()

  reaction(() => rootStore.loggedInUser, resetWarnings)
  resetWarnings()
  // setTimeout(() => rootStore.announcements.endSnackbarInitialDelay(), 15000)

  showWarningWhenOffline(rootStore, actions)

  // switch into edit mode when page is on a new annoncement
  autorun(() => {
    if (
      ['/announcements/new', '/announcements/edit'].some(
        editingPage => rootStore.view.page === editingPage
      ) &&
      rootStore.loggedInUser?.user?.$permissions?.manageAnnouncements &&
      !rootStore.announcements.editMode
    ) {
      rootStore.announcements.enterIntoEditMode()
    }
  })

  // when a chat view is changed after being sustained for over 3 seconds,
  // set it as has been read. (while swapping between chat users)
  // let previousChatUserID: string | undefined = rootStore.view.id
  // let chatUserIDLastChange: Dayjs
  // autorun(() => {
  //   if (rootStore.view.page !== '/chat') return
  //   if (rootStore.view.id === previousChatUserID) return

  //   // the following code will run whenever chat view is switched.

  //   // If the chat is sustained for over the seconds - SHOW as read.
  //   if (dayjs().diff(chatUserIDLastChange, 'seconds') >= 3) {
  //     const previousChat = rootStore.chats.findChat(
  //       +(previousChatUserID || '')
  //     )
  //     previousChat?.setToSeeAllMessagesRead()
  //   }

  //   previousChatUserID = rootStore.view.id
  //   chatUserIDLastChange = dayjs()

  //   // If the chat is sustained for over the seconds - MARK as read.
  //   setTimeout(() => {
  //     if (dayjs().diff(chatUserIDLastChange, 'milliseconds') >= 3000) {
  //       const newChat = rootStore.chats.findChat(+(rootStore.view.id || ''))
  //       if (!!newChat?.unreadCount) {
  //         newChat?.sendReadConfirmation()
  //         newChat?.setAllMessagesRead()
  //       }
  //     }
  //   }, 3000)
  // })

  // check if emailer service is active
  const loadData = async () => {
    if (!rootStore.loggedInUser) return

    await rootStore.queryUsers(
      {},
      s =>
        s.id.email.profilePhoto.permissions(
          p =>
            p.chatAsFrontDesk.inviteToApp.manageAnnouncements.upgradeOtherUsers
              .accounting.viewPeopleLists.generalPopulation.adminFollowUpRead
              .adminFollowUpWrite.sendEmails.accessWarnings
        ).nickName.googleDisplayName
    )
    rootStore.loggedInUser.setUser()
    if (rootStore.loggedInUser.user?.$permissions?.chatAsFrontDesk) {
      await rootStore.loadChats()
      rootStore.subscribeChatNewMessageInAnyChat(
        undefined,
        s =>
          s.node(
            n =>
              n._id.author(a => a.nickName.id).chat(c => c.id.nickName).content
          ).cursor,
        (message: MessageEdgeModelType) => {
          console.log('in onData function', message)
          rootStore.chatResponses
            .get(message.$node.chat.id || '')
            ?.addMessages([message])
        },
        error => console.log('catching an error in subscribe', error)
      )
    } else {
      await rootStore.loadChats()
      rootStore.subscribeChatNewMessageInMyChat(
        undefined,
        s =>
          s.node(
            n =>
              n._id.author(a => a.nickName.id).chat(c => c.id.nickName).content
          ).cursor,
        (message: MessageEdgeModelType) => {
          console.log('in onData function', message)
          rootStore.chatResponses
            .get(rootStore.loggedInUser?.id || '')
            ?.addMessages([message])
        },
        error => console.log('catching an error in subscribe', error)
      )
    }

    if (rootStore.loggedInUser.user?.$permissions?.viewPeopleLists) {
      await rootStore.queryGetLodgingPrograms()
    }

    await rootStore.loadAnnouncements()
  }

  autorun(() => {
    window.document.title = `${getPageTitle({
      page: rootStore.view.page
    })} - SanApp - Sivananda Bahamas Communication App`
  })

  // todo: error handling
  autorun(() => {
    if (rootStore.loggedInUser) loadData()
  })

  // scroll to top whenever navigating through information pages
  reaction(
    () => rootStore.view.page,
    (page, previousPage) => {
      if (page.match(/^\/info/) && previousPage.match(/^\/info/))
        document
          .querySelector('.scrollable-background')
          ?.scrollTo({ top: 0, behavior: 'smooth' })
    }
  )
  console.log('finished onstart', new Date().toISOString())
}
