// @ts-nocheck
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { QueryBuilder } from 'mst-gql'
import { ModelBase } from './ModelBase'
import {
  MonitoredAttributeResponseModel,
  MonitoredAttributeResponseModelType
} from './MonitoredAttributeResponseModel'
import { MonitoredAttributeResponseModelSelector } from './MonitoredAttributeResponseModel.base'
import { ProgramTypesEnumType } from './ProgramTypesEnum'
import { RootStoreType } from './index'

/**
 * RegFollowUpResponseBase
 * auto generated base class for the model RegFollowUpResponseModel.
 */
export const RegFollowUpResponseModelBase = ModelBase.named(
  'RegFollowUpResponse'
)
  .props({
    __typename: types.optional(
      types.literal('RegFollowUpResponse'),
      'RegFollowUpResponse'
    ),
    adminLink: types.union(types.undefined, types.string),
    regId: types.union(types.undefined, types.integer),
    personId: types.union(types.undefined, types.null, types.integer),
    headshot: types.union(types.undefined, types.string),
    firstName: types.union(types.undefined, types.string),
    lastName: types.union(types.undefined, types.string),
    spiritualName: types.union(types.undefined, types.string),
    program: types.union(types.undefined, types.string),
    programTypes: types.union(
      types.undefined,
      types.array(ProgramTypesEnumType)
    ),
    room: types.union(types.undefined, types.string),
    arrivalDate: types.union(types.undefined, types.string),
    departureDate: types.union(types.undefined, types.string),
    registrationNotes: types.union(types.undefined, types.string),
    flightTime: types.union(types.undefined, types.string),
    flightNumber: types.union(types.undefined, types.string),
    balance: types.union(types.undefined, types.number),
    monitoredAttributes: types.union(
      types.undefined,
      types.array(types.late((): any => MonitoredAttributeResponseModel))
    )
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class RegFollowUpResponseModelSelector extends QueryBuilder {
  get adminLink() {
    return this.__attr(`adminLink`)
  }
  get regId() {
    return this.__attr(`regId`)
  }
  get personId() {
    return this.__attr(`personId`)
  }
  get headshot() {
    return this.__attr(`headshot`)
  }
  get firstName() {
    return this.__attr(`firstName`)
  }
  get lastName() {
    return this.__attr(`lastName`)
  }
  get spiritualName() {
    return this.__attr(`spiritualName`)
  }
  get program() {
    return this.__attr(`program`)
  }
  get programTypes() {
    return this.__attr(`programTypes`)
  }
  get room() {
    return this.__attr(`room`)
  }
  get arrivalDate() {
    return this.__attr(`arrivalDate`)
  }
  get departureDate() {
    return this.__attr(`departureDate`)
  }
  get registrationNotes() {
    return this.__attr(`registrationNotes`)
  }
  get flightTime() {
    return this.__attr(`flightTime`)
  }
  get flightNumber() {
    return this.__attr(`flightNumber`)
  }
  get balance() {
    return this.__attr(`balance`)
  }
  monitoredAttributes(
    builder:
      | string
      | MonitoredAttributeResponseModelSelector
      | ((
          selector: MonitoredAttributeResponseModelSelector
        ) => MonitoredAttributeResponseModelSelector)
      | undefined
  ) {
    return this.__child(
      `monitoredAttributes`,
      MonitoredAttributeResponseModelSelector,
      builder
    )
  }
}
export function selectFromRegFollowUpResponse() {
  return new RegFollowUpResponseModelSelector()
}

export const regFollowUpResponseModelPrimitives =
  selectFromRegFollowUpResponse().adminLink.regId.personId.headshot.firstName
    .lastName.spiritualName.program.programTypes.room.arrivalDate.departureDate
    .registrationNotes.flightTime.flightNumber.balance
