import * as React from 'react'
import styled from 'styled-components'
import Typography from '@mui/material/Typography'

import { PrimaryButton } from '../../components/common/Buttons'

const WelcomeTitle = styled(Typography)`
  && {
    margin-top: 1.4rem;
    margin-bottom: 0.4rem;
    font-size: 2.3rem;
  }
`

const AppLogo = styled.div`
  display: flex;
  align-items: center;
  font-family: cursive;
  flex-basis: 10rem;
  flex-grow: 1;
  font-size: 2.7rem;
  justify-content: center;
  text-align: center;
  background-image: url(/images/sanapp-logo.svg);
  background-repeat: no-repeat;
  background-position: center 42.5%;
  background-size: 576%;
  width: 100% !important;
`

function LoginCoverPage({ setClosed }: { setClosed: () => void }) {
  const connectRef = React.createRef<HTMLButtonElement>()

  return (
    <>
      <WelcomeTitle align="center" variant="h2">
        Welcome
      </WelcomeTitle>
      <Typography align="center" variant="h4">
        to
      </Typography>
      <AppLogo />
      <PrimaryButton
        style={{ marginBottom: '2rem' }}
        onClick={() => setClosed()}
        ref={connectRef}
        autoFocus
      >
        Connect with us
      </PrimaryButton>
    </>
  )
}

export default LoginCoverPage
