import React from 'react'
import { StoreContext } from '@sayr/client-models'
import {
  MediaCard,
  StyledCardMedia,
  StyledPageContentWrapper,
  SVGCardMedia
} from '../../components/InfoTypography'

export function InfoHomepage() {
  const store = React.useContext(StoreContext)

  return (
    <StyledPageContentWrapper>
      <MediaCard
        disabled
        topic="COVID 19 Guidelines"
        description="Guests (vacationers, TTC & ATTC students, and karma yogis) must show proof of full
        vaccination. The safety of our guests and staff is our highest priority. To this end, we require that all
        guests and staff take an on-site COVID-19 rapid antigen test upon arrival and then
        every two days during their stay. "
        action={() => store.view.openInfoPage('covid-19-guidelines')}
      >
        <SVGCardMedia
          image="/images/jagadambika-beach-anjaneyasana.jpeg"
          title="Jagadmabika in Anjaneyasana on the beach"
          className="covid-jagadambika"
        />
      </MediaCard>
      <MediaCard
        topic="Trip Logistics"
        description="Before Arrival, trip considerations, what to bring, the check-in and check-out process and room move procedure"
        action={() => store.view.openInfoPage('trip-logistics')}
      >
        <StyledCardMedia
          image="https://online.sivanandabahamas.org/wp-content/uploads/2020/06/CKarmaYogi-1024x579.jpg"
          title="Krishna walking on the beach with a yoga mat"
        />
      </MediaCard>
      <MediaCard
        topic="Amenities"
        description="Dolorem sequi esse inventore temporibus facere vitae vero
          laborum illum eveniet animi optio quos
          quam, nihil, corrupti
          amet repellat distinctio,"
        action={() => store.view.openInfoPage('trip-logistics')}
      >
        <StyledCardMedia
          image="https://online.sivanandabahamas.org/wp-content/uploads/2020/06/CKarmaYogi-1024x579.jpg"
          title="Krishna walking on the beach with a yoga mat"
        />
      </MediaCard>
      <MediaCard
        topic="Practice Guide"
        description="How to meditate? Yoga asanas | Pranayama | Chants"
        action={() => store.view.openInfoPage('practice-guide')}
      >
        <StyledCardMedia
          image="https://online.sivanandabahamas.org/wp-content/uploads/2020/06/CKarmaYogi-1024x579.jpg"
          title="Krishna walking on the beach with a yoga mat"
        />
      </MediaCard>
      <MediaCard
        topic="Our Community"
        description="Dolorem sequi esse inventore temporibus facere vitae vero
          laborum illum eveniet animi optio quos
          quam, nihil, corrupti
          amet repellat distinctio,"
      >
        <StyledCardMedia
          image="https://online.sivanandabahamas.org/wp-content/uploads/2020/06/CKarmaYogi-1024x579.jpg"
          title="Krishna walking on the beach with a yoga mat"
        />
      </MediaCard>
      <MediaCard
        topic="Our Tradition"
        description="Dolorem sequi esse inventore temporibus facere vitae vero
          laborum illum eveniet animi optio quos
          quam, nihil, corrupti
          amet repellat distinctio,"
      >
        <StyledCardMedia
          image="https://online.sivanandabahamas.org/wp-content/uploads/2020/06/CKarmaYogi-1024x579.jpg"
          title="Krishna walking on the beach with a yoga mat"
        />
      </MediaCard>
      <MediaCard
        topic="What you can do during your stay"
        description="Dolorem sequi esse inventore temporibus facere vitae vero
          laborum illum eveniet animi optio quos
          quam, nihil, corrupti
          amet repellat distinctio,"
      >
        <StyledCardMedia
          image="https://images.unsplash.com/photo-1563097013-a1df1d1fd1c7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
          title="Volunteer handing bulletin"
        />
      </MediaCard>
      <MediaCard
        topic="Where things are"
        description="Dolorem sequi esse inventore temporibus facere vitae vero
          laborum illum eveniet animi optio quos
          quam, nihil, corrupti
          amet repellat distinctio,"
      >
        <StyledCardMedia
          image="https://online.sivanandabahamas.org/wp-content/uploads/2020/06/CKarmaYogi-1024x579.jpg"
          title="Krishna walking on the beach with a yoga mat"
        />
      </MediaCard>
      <MediaCard
        topic="Guidelines, Expectations and Safety"
        description="Dolorem sequi esse inventore temporibus facere vitae vero
          laborum illum eveniet animi optio quos
          quam, nihil, corrupti
          amet repellat distinctio,"
      >
        <StyledCardMedia
          image="https://images.unsplash.com/photo-1509239129736-8c395375220d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
          title="Krishna walking on the beach with a yoga mat"
        />
      </MediaCard>
      <MediaCard
        topic="About Satsangs and Pujas"
        description="Dolorem sequi esse inventore temporibus facere vitae vero
          laborum illum eveniet animi optio quos
          quam, nihil, corrupti
          amet repellat distinctio,"
      >
        <StyledCardMedia
          image="https://online.sivanandabahamas.org/wp-content/uploads/2020/06/CKarmaYogi-1024x579.jpg"
          title="Krishna walking on the beach with a yoga mat"
        />
      </MediaCard>
      <MediaCard
        topic="Get involved and find new friends"
        description="Dolorem sequi esse inventore temporibus facere vitae vero
          laborum illum eveniet animi optio quos
          quam, nihil, corrupti
          amet repellat distinctio,"
      >
        <StyledCardMedia
          image="https://online.sivanandabahamas.org/wp-content/uploads/2020/06/CKarmaYogi-1024x579.jpg"
          title="Krishna walking on the beach with a yoga mat"
        />
      </MediaCard>
      <MediaCard
        topic="Well Being Center"
        description="Dolorem sequi esse inventore temporibus facere vitae vero
          laborum illum eveniet animi optio quos
          quam, nihil, corrupti
          amet repellat distinctio,"
      >
        <StyledCardMedia
          image="https://online.sivanandabahamas.org/wp-content/uploads/2020/06/CKarmaYogi-1024x579.jpg"
          title="Krishna walking on the beach with a yoga mat"
        />
      </MediaCard>
    </StyledPageContentWrapper>
  )
}
