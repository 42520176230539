import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Breadcrumbs,
  ConnectedPages,
  Heading2,
  Heading3,
  Paragraph,
  ListItem,
  InfoContainer,
  InfoLink,
  Section,
  Hr,
  InfoPageLayout,
  LayoutSeparator,
  Link,
  LeadText,
  Heading4,
  UnorderedList
} from '../../../../components/InfoTypography'

export function InfoAnulomaViloma() {
  return (
    <InfoContainer style={{ wordBreak: 'break-word' }}>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <InfoLink target="">Information</InfoLink>
        <InfoLink target="practice-guide">Practice Guide</InfoLink>
        <InfoLink target="practice-guide/breathing-techniques">
          Breathing Techniques
        </InfoLink>
        <Link $disabled>Anuloma Viloma Pranayama</Link>
      </Breadcrumbs>
      <Heading2>Anuloma Viloma - Alternate Nostril Breath</Heading2>
      <Hr />
      <InfoPageLayout>
        <Section>
          <LeadText>
            Pranayama is the link between the mental and physical disciplines.
            While the action is physical, the effect is to make the mind calm,
            lucid and steady.
            <br />
            <cite>&emsp; Swami Vishnudevananda</cite>
          </LeadText>
          <Heading3>Preparing for Anuloma Viloma </Heading3>
          <Paragraph>
            Raise the right hand. Make the Vishnu Mudra by folding down the
            index and middle fingers.
            <br /> Close the right nostril with the thumb and exhale completely
            through the left nostril
          </Paragraph>
          <Heading3>How to practice Anuloma Viloma</Heading3>
          <Paragraph>
            Inhale completely through the left nostril, keeping the right
            nostril closed. This should be done to a count of 4.
            <br /> Close the left nostril with the two end fingers so that both
            nostrils are closed. Retain the breath to a count of 16.
            <br /> Release the right nostril and exhale completely to a count of
            8.
            <br /> Inhale fully through the right nostril to a count of 4.
            <br /> Pinch nostrils closed and retain the breath to a count of 16.
            <br /> Release the left nostril and exhale completely to a count of
            8.
          </Paragraph>
          <Paragraph>
            This is one round. At least 10 rounds should be practised daily.
          </Paragraph>
          <Paragraph>
            As you become more advanced, the count of the exercise may be
            increased, but always in a ratio of 1-4-2. This means that for every
            second that you inhale, you retain the breath 4 seconds and exhale
            for 2 seconds. Never change this ratio.
          </Paragraph>
          <Paragraph>
            You may also increase the number of rounds of Anuloma Viloma which
            is practised.
          </Paragraph>
          <Heading3>Benefits of Anuloma Viloma </Heading3>
          <Heading4>Physical Benefits</Heading4>
          <UnorderedList>
            <ListItem>
              Anuloma Viloma cleanses and strengthens the lungs and entire
              Respiratory System
            </ListItem>
            <ListItem>It harmonizes the entire system</ListItem>
            <ListItem>
              During retention, there is the highest rate of gaseous exchange in
              the lungs. Because of the increase in the pressure, more oxygen
              goes from the lungs into the blood and more carbon dioxide and
              other waste products pass from the blood into the lungs for
              elimination during exhalation
            </ListItem>
            <ListItem>
              As exhalation is twice the time of inhalation, stale air and waste
              products are drained from the lungs
            </ListItem>
            <ListItem>
              The anabolic and catabolic processes of the body are brought into
              equilibrium
            </ListItem>
          </UnorderedList>
          <Heading4>Mental / Psychic Benefits</Heading4>
          <Paragraph>
            “When the breath wanders, i.e. is irregular, the mind is also
            unsteady. But when the breath is still, so is the Mind.” ~ Hatha
            Yoga Pradipika
          </Paragraph>
          <UnorderedList>
            <ListItem>
              Anuloma Viloma helps to calm the mind, making it lucid and steady
            </ListItem>
            <ListItem>
              Practice of Anuloma Viloma purifies the nadis. It should be
              mastered and practised on a regular basis before going on to more
              advanced pranayamas
            </ListItem>
            <ListItem>
              Prana, the vital energy, is stored and controlled
            </ListItem>
            <ListItem>The nadis (psychic meridians) are purified</ListItem>
            <ListItem>It makes the body light and the eyes shiny</ListItem>
            <ListItem>The psychic system is balanced</ListItem>
          </UnorderedList>
          <Heading3>Common Mistakes</Heading3>
          <UnorderedList>
            <ListItem>The wrong fingers are used</ListItem>
            <ListItem>Back is not straight, head drops</ListItem>
            <ListItem>The chest is dropped with exhalation</ListItem>
            <ListItem>The breath is not smooth</ListItem>
          </UnorderedList>
        </Section>
        <ConnectedPages>
          <LayoutSeparator />
          <Section>
            <Paragraph>
              <strong>Connected Articles:</strong>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/breathing-techniques">
                Breathing Techniques
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/breathing-techniques/kapalabhati">
                Kapalabhati
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/introduction/proper-breathing">
                Proper Breathing
              </InfoLink>
            </Paragraph>
          </Section>
        </ConnectedPages>
      </InfoPageLayout>
    </InfoContainer>
  )
}
