import * as React from 'react'
import {
  FieldError,
  useForm,
  useFieldArray,
  SubmitHandler,
  Controller
} from 'react-hook-form'
import dayjs, { Dayjs } from 'dayjs'
import styled from 'styled-components'
import {
  Field,
  FormTextField,
  Wrapper,
  FormError
} from '../../components/common/Forms'
import { PrimaryButton } from '../../components/common/Buttons'

import {
  CovidTestType,
  ProgramTypes,
  SeasonTypes,
  useRegAdditionalDataSeasonCreateMutation
} from '../../graphql/generated/typesAndHooks'

import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import ToggleButton from '@mui/material/ToggleButton'
import Tooltip from '@mui/material/Tooltip'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import IconButton from '@mui/material/IconButton'

import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { StoreContext } from '@sayr/client-models'
import {
  MultipleSelectProgramTypes,
  MultipleSelectWeekdayChip
} from '../../components/common/MultipleSelectWithChips'

const AttributesContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 90rem;
  row-gap: 2rem;
`

type Inputs = {
  precedence: number // the higher the number, the higher the precedence (if two seasons conflict)
  programTypes: ProgramTypes[] // relevant for these program types
  seasonDateClassification:
    | 'by date'
    | 'by arrival date'
    | 'by both arrival date and date'
  startDate: Dayjs
  endDate: Dayjs
  arrivalDateStart: Dayjs // season applicable for guests arriving from
  arrivalDateEnd: Dayjs // season applicaple for guests arriving until (and not including)
  seasonType: SeasonTypes
  monitoredAttributes: {
    key: string
    yellowFlagDay: number // since when to raise yellow flag
    redFlagDay: number // since when to raise red flag
    flagCountFrom: 'before arrival' | 'before departure'
    displayed: 'before arrival' | 'during stay' | 'before or during stay'
  }[]
  testType: CovidTestType | null
  cost: number | null
  dayGap: number | null
  daysOfWeek: number[] | null
}

const CreateSeasonParent = styled.div`
  margin: 2rem;

  & .chip-container {
    flex-wrap: wrap;
  }
`

const attributePrototype: Inputs['monitoredAttributes'][number] = {
  key: '',
  displayed: 'before arrival',
  flagCountFrom: 'before arrival',
  yellowFlagDay: 14,
  redFlagDay: 7
}

export function CreateSeason() {
  const store = React.useContext(StoreContext)
  const [selectedProgramTypes, setSelectedProgramTypes] = React.useState<
    ProgramTypes[]
  >([])
  const [selectedWeekdays, setSelectedWeekdays] = React.useState<number[]>([])

  const createSeasonMutation = useRegAdditionalDataSeasonCreateMutation<{
    message: string
  }>({
    onError: (err, { newSeason }) => {
      alert(
        `Could not create season because ${
          err.message.match(/E11000 duplicate key error collection/)
            ? `precedence ${newSeason.precedence} already exists.`
            : err
        }`
      )
    },
    onSuccess: () => {
      store.view.openAdminSeasonsPage()
    }
  })
  const [seasonDateClass, setSeasonDateClass] =
    React.useState<Inputs['seasonDateClassification']>('by arrival date')

  const [arrivalDateStart, setArrivalDateStart] = React.useState(dayjs())
  const [arrivalDateEnd, setArrivalDateEnd] = React.useState(
    dayjs().add(1, 'day')
  )

  const [startDate, setStartDate] = React.useState(dayjs())
  const [endDate, setEndDate] = React.useState(dayjs().add(1, 'day'))

  const [seasonType, setSeasonType] = React.useState(
    SeasonTypes.MonitoredAttributes
  )

  const {
    control,
    clearErrors,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setError,
    setValue,
    trigger
  } = useForm<Inputs>({
    defaultValues: {
      precedence: 1,
      arrivalDateStart,
      arrivalDateEnd,
      startDate,
      endDate,
      monitoredAttributes: [attributePrototype],
      seasonType: SeasonTypes.MonitoredAttributes
    }
  })

  const { fields, append, remove } = useFieldArray({
    name: 'monitoredAttributes',
    control
  })

  const onSubmit: SubmitHandler<Inputs> = data => {
    const { arrivalDateStart, arrivalDateEnd, startDate, endDate, ...rest } =
      data

    const newSeason = {
      ...rest,
      arrivalDateStart: arrivalDateStart
        ? (arrivalDateStart as Dayjs).format('YYYY-MM-DD')
        : '',
      arrivalDateEnd: arrivalDateEnd
        ? (arrivalDateEnd as Dayjs).format('YYYY-MM-DD')
        : '',
      startDate: startDate ? (startDate as Dayjs).format('YYYY-MM-DD') : '',
      endDate: endDate ? (endDate as Dayjs).format('YYYY-MM-DD') : ''
    }

    createSeasonMutation.mutate({ newSeason })
  }

  // update programTypes form values with local state
  React.useEffect(() => {
    setValue('programTypes', selectedProgramTypes)
    if (!selectedProgramTypes.length)
      setError('programTypes', { type: 'no-programs-selected' })
    else clearErrors('programTypes')
  }, [selectedProgramTypes, setValue, setError, clearErrors])

  // update seasonDateClassification form values with local state
  React.useEffect(() => {
    setValue('seasonDateClassification', seasonDateClass)
  }, [seasonDateClass, setValue])

  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    newDateClassification: Inputs['seasonDateClassification']
  ) => {
    if (newDateClassification) setSeasonDateClass(newDateClassification)
  }

  return (
    <CreateSeasonParent>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Wrapper>
          <FormTextField
            type="number"
            label="Precedence"
            {...register('precedence', { valueAsNumber: true })}
          />
        </Wrapper>
        <Wrapper bottomSpacing>
          <Field>
            <MultipleSelectProgramTypes
              selectedProgramTypes={selectedProgramTypes}
              setSelectedProgramTypes={setSelectedProgramTypes}
              {...register('programTypes', {
                validate: { 'no-programs-selected': v => !!v.length }
              })}
            />
            {(errors.programTypes as any)?.type === 'no-programs-selected' && (
              <FormError>* Choose at least one program type</FormError>
            )}
          </Field>
        </Wrapper>
        <Wrapper bottomSpacing>
          <ToggleButtonGroup
            color="primary"
            value={seasonDateClass}
            exclusive
            onChange={handleChange}
          >
            <ToggleButton value="by date">By Date</ToggleButton>
            <ToggleButton value="by arrival date">By Arrival Date</ToggleButton>
            <ToggleButton value="by both arrival date and date">
              By Both Arrival Date and Date
            </ToggleButton>
          </ToggleButtonGroup>
        </Wrapper>

        {(seasonDateClass === 'by arrival date' ||
          seasonDateClass === 'by both arrival date and date') && (
          <>
            <Field minimumDesiredWidth="14rem">
              <Controller
                control={control}
                name="arrivalDateStart"
                shouldUnregister
                render={({ field }) => (
                  <DatePicker
                    value={arrivalDateStart}
                    onChange={date => {
                      if (date === null) return
                      setArrivalDateStart(date)
                      field.onChange(date)
                      trigger('arrivalDateEnd')
                    }}
                    inputFormat="MM/DD/YYYY"
                    label="Arrival Date Start"
                    inputRef={field.ref}
                    renderInput={params => (
                      <Tooltip title="The season applies to stays starting on this date">
                        <FormTextField
                          autoComplete="off"
                          {...params}
                          onBlur={field.onBlur}
                        />
                      </Tooltip>
                    )}
                  />
                )}
              />
            </Field>
            <Field minimumDesiredWidth="14rem">
              <Controller
                control={control}
                name="arrivalDateEnd"
                shouldUnregister
                rules={{
                  validate: {
                    afterArrivalDateStart: date => {
                      const arrivalDateStart = dayjs(
                        getValues('arrivalDateStart') || undefined
                      )
                      return date ? date.isAfter(arrivalDateStart) : true
                    }
                  }
                }}
                render={({ field }) => (
                  <DatePicker
                    minDate={arrivalDateStart.add(1, 'day')}
                    value={arrivalDateEnd}
                    onChange={date => {
                      if (date === null) return
                      setArrivalDateEnd(date)
                      field.onChange(date)
                      trigger('arrivalDateEnd')
                    }}
                    inputFormat="MM/DD/YYYY"
                    label="Arrival Date End"
                    inputRef={field.ref}
                    renderInput={params => (
                      <Tooltip title="The season applies before this date">
                        <FormTextField
                          autoComplete="off"
                          {...params}
                          onBlur={field.onBlur}
                        />
                      </Tooltip>
                    )}
                  />
                )}
              />
              {(errors.arrivalDateEnd as FieldError)?.type ===
                'afterArrivalDateStart' && (
                <FormError>* Must be later than arrival date start</FormError>
              )}
            </Field>
          </>
        )}
        {(seasonDateClass === 'by date' ||
          seasonDateClass === 'by both arrival date and date') && (
          <>
            <Field minimumDesiredWidth="14rem">
              <Controller
                control={control}
                name="startDate"
                shouldUnregister
                render={({ field }) => (
                  <DatePicker
                    value={startDate}
                    onChange={date => {
                      if (date === null) return
                      setStartDate(date)
                      field.onChange(date)
                      trigger('endDate')
                    }}
                    inputFormat="MM/DD/YYYY"
                    label="Start Date"
                    inputRef={field.ref}
                    renderInput={params => (
                      <Tooltip title="The season applies as of this date">
                        <FormTextField
                          autoComplete="off"
                          {...params}
                          onBlur={field.onBlur}
                        />
                      </Tooltip>
                    )}
                  />
                )}
              />
            </Field>
            <Field minimumDesiredWidth="14rem">
              <Controller
                control={control}
                name="endDate"
                shouldUnregister
                rules={{
                  validate: {
                    afterStartDate: date => {
                      const startDate = dayjs(
                        getValues('startDate') || undefined
                      )
                      return date.isAfter(startDate)
                    }
                  }
                }}
                render={({ field }) => (
                  <DatePicker
                    minDate={startDate.add(1, 'day')}
                    value={endDate}
                    onChange={date => {
                      if (date === null) return
                      setEndDate(date)
                      field.onChange(date)
                      trigger('endDate')
                    }}
                    inputFormat="MM/DD/YYYY"
                    label="End Date"
                    inputRef={field.ref}
                    renderInput={params => (
                      <Tooltip title="The season applies to stays ending before this date">
                        <FormTextField
                          autoComplete="off"
                          {...params}
                          onBlur={field.onBlur}
                        />
                      </Tooltip>
                    )}
                  />
                )}
              />
              {(errors.endDate as FieldError)?.type === 'afterStartDate' && (
                <FormError>* Must be later than start date</FormError>
              )}
            </Field>
          </>
        )}
        <Wrapper bottomSpacing>
          <Field>
            <InputLabel id="select-season-type">Season Type</InputLabel>
            <Controller
              control={control}
              name="seasonType"
              render={({ field }) => (
                <Select
                  labelId="select-season-type"
                  id="demo-simple-select"
                  label="Season Type"
                  {...field}
                  onChange={e => {
                    setSeasonType(e.target.value as SeasonTypes)
                    field.onChange(e)
                  }}
                  inputRef={field.ref}
                >
                  <MenuItem value={SeasonTypes.MonitoredAttributes}>
                    Monitored Attributes
                  </MenuItem>
                  <MenuItem value={SeasonTypes.CovidTestFrequencyDaysOfWeek}>
                    Covid Test Frequency - Days of week
                  </MenuItem>
                  <MenuItem value={SeasonTypes.CovidTestFrequencyFixedGap}>
                    Covid Test Frequency - Fixed Gap
                  </MenuItem>
                  <MenuItem value={SeasonTypes.CovidTestCosts}>
                    Covid Test Cost
                  </MenuItem>
                </Select>
              )}
            />
          </Field>
        </Wrapper>
        {seasonType === SeasonTypes.MonitoredAttributes && (
          <>
            <h4>Monitored Attributes:</h4>
            <AttributesContainer>
              {fields.map((field, index, fields) => (
                <div
                  key={field.id}
                  style={{ display: 'flex', flexWrap: 'wrap' }}
                >
                  <FormTextField
                    style={{ flex: 1, flexBasis: '20rem' }}
                    placeholder="key"
                    {...register(`monitoredAttributes.${index}.key` as const, {
                      required: true
                    })}
                  />
                  <FormControl style={{ width: '10rem' }}>
                    <InputLabel id="displayed">Displayed</InputLabel>
                    <Select
                      labelId="displayed"
                      defaultValue={field.displayed}
                      {...register(
                        `monitoredAttributes.${index}.displayed` as const,
                        { required: true }
                      )}
                    >
                      <MenuItem value="before arrival">Before Arrival</MenuItem>
                      <MenuItem value="during stay">During Stay</MenuItem>
                      <MenuItem value="before or during stay">
                        Before or During Stay
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <InputLabel id="flag-counts-from">
                      Flag counts from
                    </InputLabel>
                    <Select
                      labelId="flag-counts-from"
                      defaultValue={field.flagCountFrom}
                      {...register(
                        `monitoredAttributes.${index}.flagCountFrom` as const,
                        { required: true }
                      )}
                    >
                      <MenuItem value="before arrival">Before Arrival</MenuItem>
                      <MenuItem value="before departure">
                        Before Departure
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <FormTextField
                    type="number"
                    label="Yellow Flag Days Count"
                    {...register(
                      `monitoredAttributes.${index}.yellowFlagDay` as const,
                      { required: true, min: 0, valueAsNumber: true }
                    )}
                  />
                  <FormTextField
                    type="number"
                    label="Red Flag Days Count"
                    {...register(
                      `monitoredAttributes.${index}.redFlagDay` as const,
                      { required: true, min: 0, valueAsNumber: true }
                    )}
                  />
                  <IconButton
                    disabled={fields.length === 1}
                    onClick={() => remove(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              ))}
            </AttributesContainer>
            <IconButton onClick={() => append(attributePrototype)}>
              <AddIcon />
            </IconButton>
          </>
        )}
        {seasonType === SeasonTypes.CovidTestCosts && (
          <>
            <Field>
              <InputLabel id="test-type">Test Type</InputLabel>
              <Select
                labelId="test-type"
                {...register('testType')}
                defaultValue={CovidTestType.InHouse}
              >
                <MenuItem value={CovidTestType.InHouse}>In house</MenuItem>
                <MenuItem value={CovidTestType.CompanyAntigen}>
                  Company Antigen
                </MenuItem>
                <MenuItem value={CovidTestType.CompanyFastPcr}>
                  Company Fast PCR
                </MenuItem>
                <MenuItem value={CovidTestType.CompanyPcr}>
                  Company PCR
                </MenuItem>
                <MenuItem value={CovidTestType.CompanySelfTestKit}>
                  Company Self Test Kit
                </MenuItem>
              </Select>
            </Field>
            <FormTextField
              type="number"
              label="Cost"
              {...register('cost', {
                valueAsNumber: true,
                required: true
              })}
            />
          </>
        )}
        {seasonType === SeasonTypes.CovidTestFrequencyFixedGap && (
          <FormTextField
            type="number"
            label="Day Gap"
            {...register('dayGap', {
              valueAsNumber: true,
              required: true
            })}
          />
        )}
        {seasonType === SeasonTypes.CovidTestFrequencyDaysOfWeek && (
          <Wrapper bottomSpacing>
            <Field>
              <MultipleSelectWeekdayChip
                selectedWeekdays={selectedWeekdays}
                setSelectedWeekdays={setSelectedWeekdays}
                {...register('daysOfWeek', {
                  validate: { 'no-weekday-selected': v => !!v?.length }
                })}
              />
              {(errors.daysOfWeek as any)?.type === 'no-weekday-selected' && (
                <FormError>* Choose at least one weekday</FormError>
              )}
            </Field>
          </Wrapper>
        )}

        <br />
        <PrimaryButton type="submit">Submit</PrimaryButton>
      </form>
    </CreateSeasonParent>
  )
}
