// @ts-nocheck
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { QueryBuilder } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { RootStoreType } from './index'

/**
 * ItemIdResponseBase
 * auto generated base class for the model ItemIdResponseModel.
 */
export const ItemIdResponseModelBase = ModelBase.named('ItemIdResponse')
  .props({
    __typename: types.optional(
      types.literal('ItemIdResponse'),
      'ItemIdResponse'
    ),
    id: types.union(types.undefined, types.integer)
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class ItemIdResponseModelSelector extends QueryBuilder {
  get id() {
    return this.__attr(`id`)
  }
}
export function selectFromItemIdResponse() {
  return new ItemIdResponseModelSelector()
}

export const itemIdResponseModelPrimitives = selectFromItemIdResponse()
