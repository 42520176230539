import { autorun, reaction } from 'mobx'
import { GraphQLClient } from 'graphql-request'

import { RootStoreType } from '@sayr/client-models'
import { ActionsFunctions } from '../components/WarningsNotifier'
import { setGQLAuthentication } from '../graphql/config'

export async function initializeLoggedInState(
  rootStore: RootStoreType,
  gqlHttpClient: GraphQLClient,
  actions: ActionsFunctions,
  accessTokenForWS: { token: string }
) {
  console.log('starting login state loader')
  // update authentication header for GraphQL whenever the access token updates.
  // this needs to be set before the initial access token refresh.
  reaction(
    () => rootStore.loggedInUser?.accessToken,
    newToken => {
      gqlHttpClient.setHeaders({
        authentication: newToken ? `bearer ${newToken}` : ''
      })
      accessTokenForWS.token = newToken || 'NOT_SET'
      setGQLAuthentication(`bearer ${newToken}`)
      console.log('set local access token')
    }
  )

  console.log('set up authorization token', rootStore.loggedInUser?.accessToken)

  // log in if a cookie is in place.
  await rootStore.refreshAccessToken()

  console.log('refresed access token')
  // refresh token whenever it's 5 seconds away from being expired.
  autorun(
    () => {
      if (
        !rootStore.loggedInUser?.isTokenValidWithMargin(10000) &&
        rootStore.status.online
      )
        refreshToken()
    },
    { delay: 5000 }
  )

  async function refreshToken() {
    try {
      await rootStore.refreshAccessToken()
      rootStore.status.setServerOnlineStatus(true)
    } catch (e) {
      const key = 'failedToRefreshAccssToken'
      const retryFnKey = key + 'Retry'
      const dismiss = key + 'Dismiss'

      // add warning snackbar
      rootStore.warnings.add({
        message: 'Could not access the server',
        actions: [
          { functionKey: dismiss, title: 'Dismiss' },
          { functionKey: retryFnKey, title: 'Retry' }
        ],
        key,
        autoHideDuration: null
      })

      // storing the action functions
      actions[dismiss] = () => rootStore.warnings.dismissOne(key)
      actions[retryFnKey] = () => {
        rootStore.warnings.removeOne(key)
        refreshToken()
      }

      // grey out the screen
      rootStore.status.setServerOnlineStatus(false)
    }
  }
}
