// @ts-nocheck
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { QueryBuilder } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { RootStoreType } from './index'

/**
 * MonitoredAttributeResponseBase
 * auto generated base class for the model MonitoredAttributeResponseModel.
 */
export const MonitoredAttributeResponseModelBase = ModelBase.named(
  'MonitoredAttributeResponse'
)
  .props({
    __typename: types.optional(
      types.literal('MonitoredAttributeResponse'),
      'MonitoredAttributeResponse'
    ),
    key: types.union(types.undefined, types.string),
    value: types.union(types.undefined, types.boolean)
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class MonitoredAttributeResponseModelSelector extends QueryBuilder {
  get key() {
    return this.__attr(`key`)
  }
  get value() {
    return this.__attr(`value`)
  }
}
export function selectFromMonitoredAttributeResponse() {
  return new MonitoredAttributeResponseModelSelector()
}

export const monitoredAttributeResponseModelPrimitives =
  selectFromMonitoredAttributeResponse().key.value
