import { reaction } from 'mobx'

import { RootStoreType } from '@sayr/client-models'
import { ActionsFunctions } from '../components/WarningsNotifier'

export function showWarningWhenOffline(
  rootStore: RootStoreType,
  actions: ActionsFunctions
) {
  window.addEventListener('online', () =>
    rootStore.status.setOnlineStatus(true)
  )
  window.addEventListener('offline', () =>
    rootStore.status.setOnlineStatus(false)
  )

  // register listeners to observe navigator.online status via Mobx.
  reaction(
    () => rootStore.status.online,
    online => {
      if (online) {
        rootStore.warnings.removeOne('offline')
      } else {
        const functionKey = 'dismiss'

        actions[functionKey] = () => rootStore.warnings.dismissOne('offline')
        rootStore.warnings.add({
          key: 'offline',
          message: 'You are offline',
          actions: [{ title: 'dismiss', functionKey }],
          autoHideDuration: null
        })
      }
    }
  )
}
