import React from 'react'
import styled from 'styled-components'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import { StoreContext } from '@sayr/client-models'
import { useIsUserConfirmedAsEmailerQuery } from '../../graphql/generated/typesAndHooks'
const Wrap = styled.div`
  margin: 4rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: start;
`

export function Admin() {
  const store = React.useContext(StoreContext)
  const { data, isLoading } = useIsUserConfirmedAsEmailerQuery()

  if (isLoading)
    return (
      <Wrap>
        <CircularProgress />
      </Wrap>
    )

  if (
    !(
      store.loggedInUser?.user?.$permissions?.adminFollowUpWrite ||
      (store.loggedInUser?.user?.$permissions?.sendEmails &&
        !data?.isUserConfirmedAsEmailer) ||
      store.loggedInUser?.user?.$permissions?.accessWarnings
    )
  )
    return (
      <Wrap>
        <Typography>
          There are no relevant admin actions at this time.
        </Typography>
      </Wrap>
    )

  return (
    <Wrap>
      {store.loggedInUser?.user?.$permissions?.adminFollowUpWrite && (
        <Button
          variant="contained"
          onClick={() => store.view.openAdminSeasonsPage()}
        >
          Edit/View Seasons of Registrations Additional Info
        </Button>
      )}
      {/* Prompt emailer to authorize GMail */}
      {store.loggedInUser?.user?.$permissions?.sendEmails &&
        !data?.isUserConfirmedAsEmailer && (
          <Button
            variant="contained"
            onClick={() => {
              if (!store.loggedInUser) return
              window.history.pushState(null, '', window.location.href)
              window.location.replace(
                `${process.env.REACT_APP_SERVER_BASE_URL}/auth/authorize-gmail/${store.loggedInUser.email}`
              )
            }}
          >
            Authorize Gmail
          </Button>
        )}
      {store.loggedInUser.user.$permissions.accessWarnings && (
        <>
          <Button
            variant="contained"
            onClick={() => store.view.openAdminWarningSetupPage()}
          >
            Setup Warnings
          </Button>
          <Button
            variant="contained"
            onClick={() => store.view.openAdminWarningPage()}
          >
            Warnings Page
          </Button>
        </>
      )}
    </Wrap>
  )
}
