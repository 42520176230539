import { types, Instance, SnapshotOut } from 'mobx-state-tree'
import { match, compile } from 'path-to-regexp'

export interface ViewType extends Instance<typeof ViewModel> {}
export interface ViewSnapshotType extends SnapshotOut<typeof ViewModel> {}
const ViewModel = types
  .model('View', {
    page: types.union(
      types.literal(''),
      types.literal('/'),
      types.literal('/activities'),
      types.literal('/admin'),
      types.literal('/admin/additional-data-seasons'),
      types.literal('/admin/additional-data-seasons/new'),
      types.literal('/admin/additional-data-seasons/edit'),
      types.literal('/admin/warning-setup'),
      types.literal('/admin/warnings'),
      types.literal('/announcements'),
      types.literal('/announcements/stats'),
      types.literal('/announcements/edit'),
      types.literal('/announcements/new'),
      types.literal('/chat'),
      types.literal('/custom'),
      types.literal('/ashram-map'),
      types.literal('/info'),
      types.literal('/info/trip-logistics'),
      types.literal('/info/trip-logistics/before-arrrival'),
      types.literal('/info/trip-logistics/packing-necessities'),
      types.literal('/info/trip-logistics/check-in-process'),
      types.literal('/info/practice-guide'),
      types.literal('/info/practice-guide/introduction'),
      types.literal('/info/practice-guide/introduction/five-points-of-yoga'),
      types.literal('/info/practice-guide/introduction/proper-exercise'),
      types.literal('/info/practice-guide/introduction/proper-breathing'),
      types.literal('/info/practice-guide/introduction/proper-relaxation'),
      types.literal('/info/practice-guide/introduction/proper-diet'),
      types.literal('/info/practice-guide/introduction/proper-thinking'),
      types.literal('/info/practice-guide/invocations-and-mantras'),
      types.literal('/info/practice-guide/meditation'),
      types.literal('/info/practice-guide/meditation/introduction'),
      types.literal('/info/practice-guide/meditation/technique'),
      types.literal('/info/practice-guide/breathing-techniques'),
      types.literal('/info/practice-guide/breathing-techniques/kapalabhati'),
      types.literal('/info/practice-guide/breathing-techniques/anuloma-viloma'),
      types.literal('/info/practice-guide/asanas'),
      types.literal('/info/practice-guide/asanas/sun-salutations'),
      types.literal('/info/practice-guide/asanas/headstand'),
      types.literal('/info/practice-guide/asanas/shoulderstand'),
      types.literal('/info/practice-guide/asanas/plough'),
      types.literal('/info/practice-guide/asanas/fish'),
      types.literal('/info/practice-guide/asanas/forward-bend'),
      types.literal('/info/practice-guide/asanas/cobra'),
      types.literal('/info/practice-guide/asanas/locust'),
      types.literal('/info/practice-guide/asanas/bow'),
      types.literal('/info/practice-guide/asanas/half-spinal-twist'),
      types.literal('/info/practice-guide/asanas/crow'),
      types.literal('/info/practice-guide/asanas/hands-to-feet'),
      types.literal('/info/practice-guide/asanas/triangle'),
      types.literal('/info/practice-guide/asanas/savasana'),
      types.literal('/info/covid-19-guidelines'),
      types.literal('/forgot-password'),
      types.literal('/registrations'),
      types.literal('/registrations/in-house'),
      types.literal('/registrations/arriving-today'),
      types.literal('/registrations/arriving-tomorrow'),
      types.literal('/registrations/arriving-soon'),
      types.literal('/registrations/departures-today'),
      types.literal('/registrations/departures-tomorrow'),
      types.literal('/registrations/search'),
      types.literal('/registrations/cash-flow-report'),
      types.literal('/registrations/population-report'),
      types.literal('/registrations/follow-up-intrastay-tests-day-view'),
      types.literal('/registrations/follow-up-intrastay-tests-scheduling-view'),
      types.literal('/registrations/follow-up-on-arrivals'),
      types.literal('/registrations/follow-up-on-in-house'),
      types.literal('/registrations/covid-test-calendar-view'),
      types.literal('/users'),
      types.refinement(
        types.string,
        value => !!value.match(/^\/registrations\/\w+$/)
      ),
      types.literal('/settings')
    ),
    id: types.maybe(types.string)
  })
  .views(self => ({
    get currentURL() {
      switch (self.page) {
        case '':
        case '/':
          return '/'
        case '/custom':
          const customToPath = compile('/custom/:id')
          return customToPath({ id: self.id || '' })
        case '/info':
          if (self.id) {
            const infoToPath = compile('/info/:id')
            return infoToPath({ id: self.id })
          }
          return self.page
        case '/registrations':
        case '/chat':
        case '/admin/additional-data-seasons/edit':
        case '/admin/warning-setup':
        case '/admin/warnings':
        case '/announcements/edit':
        case '/announcements/stats':
          if (self.id) return `${self.page}/${self.id}`
          return self.page
        default:
          return self.page
      }
    }
  }))

  // notice: both self.page & self.id have to be updated
  .actions(self => ({
    openActivitiesPage: () => {
      self.page = '/activities'
      self.id = undefined
    },
    openAdminPage: () => {
      self.page = '/admin'
      self.id = undefined
    },
    openAdminSeasonsPage: () => {
      self.page = '/admin/additional-data-seasons'
      self.id = undefined
    },
    openAdminSeasonsNewDraftPage: () => {
      self.page = '/admin/additional-data-seasons/new'
      self.id = undefined
    },
    openAdminSeasonsEditPage: (id: string) => {
      self.page = '/admin/additional-data-seasons/edit'
      self.id = id
    },
    openAdminWarningSetupPage: () => {
      self.page = '/admin/warning-setup'
      self.id = undefined
    },
    openAdminWarningPage: () => {
      self.page = '/admin/warnings'
      self.id = undefined
    },
    openAnnouncementsPage: () => {
      self.page = '/announcements'
      self.id = undefined
    },
    openAnnouncementsNewDraftPage: () => {
      self.page = '/announcements/new'
      self.id = undefined
    },
    openAnnouncementsEditPage: (id: string) => {
      self.page = '/announcements/edit'
      self.id = id
    },
    openAnnouncementsStatsPage: (id: string) => {
      self.page = '/announcements/stats'
      self.id = id
    },
    openChatPage: (id?: string) => {
      self.page = '/chat'
      self.id = id
    },
    openCustomPage(id: string) {
      self.page = '/custom'
      self.id = id
    },
    openHomePage() {
      self.page = '/'
      self.id = undefined
    },
    openInfoPage: (id?: string) => {
      self.id = undefined
      if (id) self.page = `/info/${id}`
      else self.page = `/info`
    },
    openRegistrationsPage: (subPage?: string) => {
      self.id = subPage
      if (subPage?.match(/^\w+$/)) {
        self.page = '/registrations'
      } else
        self.page = subPage ? `/registrations/${subPage}` : '/registrations'
    },
    openSettingsPage: () => {
      self.page = '/settings'
      self.id = undefined
    },
    openUsersPage: () => {
      self.page = '/users'
      self.id = undefined
    },
    openAshramMapPage: () => {
      self.page = '/ashram-map'
      self.id = undefined
    },
    setFromURL() {
      const newView = getViewFromURL()
      self.page = newView.page
      self.id = newView.id
    }
  }))

export const CurrentViewModel = types.optional(ViewModel, getViewFromURL())

function getViewFromURL() {
  const { pathname } = window.location

  // custom page (template, not in use)
  const matchedCustom = match<{ id: string }>('/custom/:id')(pathname)
  if (matchedCustom) return { page: '/custom', id: matchedCustom.params.id }

  // match custom registration pages
  const matchedRegistrations = match<{ subpage: string }>(
    '/registrations/:subpage'
  )(pathname)
  if (
    matchedRegistrations &&
    matchedRegistrations.params.subpage.match(/^\w+$/)
  ) {
    return { page: '/registrations', id: matchedRegistrations.params.subpage }
  }

  // match custom chat pages
  const matchedChat = match<{ subpage: string }>('/chat/:subpage')(pathname)
  if (matchedChat) {
    if (matchedChat.params.subpage.match(/^\w+$/)) {
      return { page: '/chat', id: matchedChat.params.subpage }
    }
  }

  // catch either an edit or stats page of an announcement
  const matchedAnnouncements = match<{ id: string }>(
    '/announcements/(stats|edit)/:id'
  )(pathname)
  if (matchedAnnouncements) {
    return {
      page:
        // page will be either /announcements/stats or /announcements/edit
        matchedAnnouncements &&
        matchedAnnouncements.path.replace(/\/[^/]*$/, ''),
      id: matchedAnnouncements.params.id
    }
  }

  // catch an edit page of admin seasons
  const matchedSeasons = match<{ id: string }>(
    '/admin/additional-data-seasons/edit/:id'
  )(pathname)
  if (matchedSeasons) {
    return {
      page:
        // page will be /admin/additional-data-seasons/edit
        matchedSeasons && matchedSeasons.path.replace(/\/[^/]*$/, ''),
      id: matchedSeasons.params.id
    }
  }

  // match change password request
  const matchedForgotPassword = match<{ id: string }>('/forgot-password/:id')(
    pathname
  )
  if (matchedForgotPassword)
    return { page: '/forgot-password', id: matchedForgotPassword.params.id }
  const matchGeneral = match<{
    page: string
    subpage?: string
    subpage2?: string
  }>('/:page/:subpage?/:subpage2?/:subpage3?')
  const matchedGeneral = matchGeneral(pathname)
  if (matchedGeneral)
    switch (matchedGeneral && matchedGeneral.path) {
      case '':
      case '/':
        return { page: '/' }
      case '/activities':
      case '/admin':
      case '/admin/additional-data-seasons':
      case '/admin/additional-data-seasons/new':
      case '/admin/warning-setup':
      case '/admin/warnings':
      case '/announcements':
      case '/announcements/new':
      case '/chat':
      case '/ashram-map':
      case '/info':
      case '/info/trip-logistics':
      case '/info/trip-logistics/before-arrrival':
      case '/info/trip-logistics/packing-necessities':
      case '/info/trip-logistics/check-in-process':
      case '/info/practice-guide':
      case '/info/practice-guide/introduction':
      case '/info/practice-guide/introduction/five-points-of-yoga':
      case '/info/practice-guide/introduction/proper-exercise':
      case '/info/practice-guide/introduction/proper-breathing':
      case '/info/practice-guide/introduction/proper-relaxation':
      case '/info/practice-guide/introduction/proper-diet':
      case '/info/practice-guide/introduction/proper-thinking':
      case '/info/practice-guide/invocations-and-mantras':
      case '/info/practice-guide/meditation':
      case '/info/practice-guide/meditation/introduction':
      case '/info/practice-guide/meditation/technique':
      case '/info/practice-guide/breathing-techniques':
      case '/info/practice-guide/breathing-techniques/kapalabhati':
      case '/info/practice-guide/breathing-techniques/anuloma-viloma':
      case '/info/practice-guide/asanas':
      case '/info/practice-guide/asanas/':
      case '/info/practice-guide/asanas/sun-salutations':
      case '/info/practice-guide/asanas/headstand':
      case '/info/practice-guide/asanas/shoulderstand':
      case '/info/practice-guide/asanas/plough':
      case '/info/practice-guide/asanas/fish':
      case '/info/practice-guide/asanas/forward-bend':
      case '/info/practice-guide/asanas/cobra':
      case '/info/practice-guide/asanas/locust':
      case '/info/practice-guide/asanas/bow':
      case '/info/practice-guide/asanas/half-spinal-twist':
      case '/info/practice-guide/asanas/crow':
      case '/info/practice-guide/asanas/hands-to-feet':
      case '/info/practice-guide/asanas/triangle':
      case '/info/practice-guide/asanas/savasana':
      case '/info/covid-19-guidelines':
      case '/login':
      case '/manualSignup':
      case '/registrations':
      case '/registrations/in-house':
      case '/registrations/arriving-today':
      case '/registrations/arriving-tomorrow':
      case '/registrations/arriving-soon':
      case '/registrations/departures-today':
      case '/registrations/departures-tomorrow':
      case '/registrations/cash-flow-report':
      case '/registrations/population-report':
      case '/registrations/follow-up-intrastay-tests-day-view':
      case '/registrations/follow-up-intrastay-tests-scheduling-view':
      case '/registrations/follow-up-on-arrivals':
      case '/registrations/follow-up-on-in-house':
      case '/registrations/covid-test-calendar-view':
      case '/registrations/search':
      case '/users':
      case '/settings': {
        return {
          page: matchedGeneral && matchedGeneral.path
        }
      }
      case '/registrations/':
        return { page: '/registrations' }
      default:
        return { page: '/' }
    }
  else return { page: '/' }
}
