// @ts-nocheck
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from 'mobx'
import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import {
  SingleTestResponseModel,
  SingleTestResponseModelType
} from './SingleTestResponseModel'
import { SingleTestResponseModelSelector } from './SingleTestResponseModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  tests: IObservableArray<SingleTestResponseModelType>
}

/**
 * RegistrationTestsBase
 * auto generated base class for the model RegistrationTestsModel.
 */
export const RegistrationTestsModelBase = withTypedRefs<Refs>()(
  ModelBase.named('RegistrationTests')
    .props({
      __typename: types.optional(
        types.literal('RegistrationTests'),
        'RegistrationTests'
      ),
      regId: types.union(types.undefined, types.integer),
      name: types.union(types.undefined, types.string),
      rgLink: types.union(types.undefined, types.string),
      email: types.union(types.undefined, types.string),
      arrivalDate: types.union(types.undefined, types.string),
      departureDate: types.union(types.undefined, types.string),
      tests: types.union(
        types.undefined,
        types.array(MSTGQLRef(types.late((): any => SingleTestResponseModel)))
      )
    })
    .views(self => ({
      get store() {
        return self.__getStore<RootStoreType>()
      }
    }))
)

export class RegistrationTestsModelSelector extends QueryBuilder {
  get regId() {
    return this.__attr(`regId`)
  }
  get name() {
    return this.__attr(`name`)
  }
  get rgLink() {
    return this.__attr(`rgLink`)
  }
  get email() {
    return this.__attr(`email`)
  }
  get arrivalDate() {
    return this.__attr(`arrivalDate`)
  }
  get departureDate() {
    return this.__attr(`departureDate`)
  }
  tests(
    builder:
      | string
      | SingleTestResponseModelSelector
      | ((
          selector: SingleTestResponseModelSelector
        ) => SingleTestResponseModelSelector)
      | undefined
  ) {
    return this.__child(`tests`, SingleTestResponseModelSelector, builder)
  }
}
export function selectFromRegistrationTests() {
  return new RegistrationTestsModelSelector()
}

export const registrationTestsModelPrimitives =
  selectFromRegistrationTests().regId.name.rgLink.email.arrivalDate
    .departureDate
