import React from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Typography, { TypographyProps } from '@mui/material/Typography'
import styled from 'styled-components'
import DarkModeSwitch from './DarkModeSwitch'
import { TooltipOnOverflow } from './common/Tooltip'

const StyledToolbar = styled(Toolbar).attrs({ className: 'toolbar' })`
  && {
    display: flex;
    width: 100vw;

    @media screen and (max-width: 37.5em), screen and (max-height: 25em) {
      min-height: 2.9rem;

      & > * {
        padding: 0.3rem;
      }
    }

    @media screen and (max-width: 20em), screen and (max-height: 20em) {
      & > * {
        padding: 0 0.3rem;
      }
    }
  }
`

const PageTitle = styled(Typography)<
  TypographyProps<'h1', { component: 'h1' }>
>`
  && {
    font-size: 1.4rem;
    margin-left: 0.5rem;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media screen and (max-width: 37.5em), screen and (max-height: 25em) {
      font-size: 1.1rem;
      padding: 0;
    }
  }
`

const breakpointShrinkDarkModeSwitch = '(max-width: 21.8em)'

const OneLineDarkModeSwitch = styled(DarkModeSwitch)`
  && {
    display: grid;
    grid-template-columns: max-content minmax(min-content, max-content);
    justify-self: end;
    margin: 0;
    padding: 0;

    @media ${breakpointShrinkDarkModeSwitch} {
      transform: scale(0.75);
      margin-right: -1.1rem;
      /* sc-dOSReg iDtCxH dark-mode-switch-shrinkable-container */

      & .MuiFormControlLabel-label {
        // hide label but keep label for accessibility
        position: absolute;
        top: -40rem;
      }
    }
  }
`

const SwitchContainer = styled.div.attrs({
  className: 'dark-mode-switch-shrinkable-container'
})`
  && {
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
  @media ${breakpointShrinkDarkModeSwitch} {
    height: 3rem;
    display: grid;
    align-content: center;
  }
`

const StackedAppBar = styled(AppBar)`
  z-index: 1000;
`

function ShrinkableDarkModeSwitch() {
  return (
    <SwitchContainer>
      <OneLineDarkModeSwitch className="" />
    </SwitchContainer>
  )
}

export default function ProminentAppBar({
  toggleDrawer,
  pageTitle
}: {
  toggleDrawer: (open: boolean) => React.ReactEventHandler
  pageTitle: string
}) {
  return (
    <div>
      <StackedAppBar position="relative" /* set stacking context for shadow*/>
        <StyledToolbar>
          <IconButton
            onClick={toggleDrawer(true)}
            edge="start"
            color="inherit"
            aria-label="open drawer"
          >
            <MenuIcon />
          </IconButton>
          <TooltipOnOverflow tooltip={pageTitle} style={{ flex: '1 1 10rem' }}>
            <PageTitle component="h1">{pageTitle}</PageTitle>
          </TooltipOnOverflow>

          <ShrinkableDarkModeSwitch />
        </StyledToolbar>
      </StackedAppBar>
    </div>
  )
}
