import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Breadcrumbs,
  Heading2,
  Heading3,
  Paragraph,
  InfoContainer,
  Section,
  Hr,
  InfoPageLayout,
  LayoutSeparator,
  Link,
  ConnectedPages,
  Heading4,
  InfoLink
} from '../../../../components/InfoTypography'

export function InfoProperExercise() {
  return (
    <InfoContainer style={{ wordBreak: 'break-word' }}>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <InfoLink target="">Information</InfoLink>
        <InfoLink target="practice-guide">Practice Guide</InfoLink>
        <InfoLink target="practice-guide/introduction">Introduction</InfoLink>
        <Link $disabled>1. Proper Exercise</Link>
      </Breadcrumbs>
      <Heading2>1. Proper Exercise</Heading2>
      <Hr />
      <InfoPageLayout>
        <Section>
          <Heading3>Asanas</Heading3>
          <Paragraph>
            There are numerous modern physical culture systems designed to
            develop the muscles through mechanical movements and exercises.
          </Paragraph>
          <Heading4>Asanas for Both the Body &amp; the Mind</Heading4>
          <Paragraph>
            As Yoga regards the body as a vehicle for the soul in its journey
            towards perfection, Asanas (Yogic physical exercise) are designed to
            develop not only the body.
            <br /> They also broaden the mental faculties and the spiritual
            capacities.
            <Heading4>
              The Difference Between Yogic Exercices and Physical Exercises
            </Heading4>
            The fundamental difference between Yogic exercises and ordinary
            physical exercises is that physical culture emphasizes violent
            movement of the muscles; whereas Yogic exercises oppose violent
            muscle movements as they produce large quantities of lactic acid in
            the muscle fibres, thus causing fatigue.
          </Paragraph>
          <Paragraph>
            When practicing asanas, the effect of excess acid and the fatigue it
            causes are neutralized by the alkali in the muscle fibres, as well
            as by the inhalation of oxygen (deep breathing).
            <br /> Muscle development does not necessarily mean a healthy body,
            as is commonly assumed.
          </Paragraph>
          <Heading4>The Goal of the Practice of Asanas</Heading4>
          <Paragraph>
            Health is a state wherein all organs function perfectly under the
            intelligent control of the mind.
          </Paragraph>
          <Paragraph>
            The Yogic physical exercises are called “Asanas”, a term which means
            steady pose. This is because the Yogic Asana (or posture) is meant
            to be held for some time.
            <br /> However, this is quite an advanced practice.
            <br /> Initially, our concern is simply to increase body
            flexibility.
          </Paragraph>
          <Heading4>The Way The Asanas Work</Heading4>
          <Paragraph>
            Your body is as young as it is flexible. Yoga exercises focus first
            on the health of the spine, its strength and flexibility.
            <br /> The spinal column houses the all-important nervous system,
            the telegraphic system of the body.
            <br /> By maintaining the spine's flexibility and strength through
            exercise, circulation is increased and the nerves are ensured their
            supply of nutrients and oxygen.
          </Paragraph>
          <Paragraph>
            Asanas work on the integral machinery of the body, the glands and
            organs, as well as the muscles.
            <br /> They affect the deeper and more subtle parts of the body.
            <br /> The internal organs receive massage and stimulation through
            the various movements of the Asanas, and are toned into more
            efficient functioning. <br />
            The endocrine system (glands and hormones) is rejuvenated, helping
            to balance the emotions and improve the mental outlook on life.
          </Paragraph>
          <Heading4>How One Should Approach the Practice</Heading4>
          <Paragraph>
            Hand in hand with the practice of yoga postures, we practice deep
            breathing and concentration of the mind. <br />
            One hour of practice will give one hour of exercise, one hour of
            deep relaxation and one hour of meditation - through developing the
            concentration powers within the pose.
          </Paragraph>
          <Paragraph>
            The Yoga system of exercise can be compared to none other in its
            complete rehauling of the entire being. <br />
            Performed slowly and consciously, the Asanas goe far beyond mere
            physical benefits, becoming a mental exercise in concentration and
            meditation.
          </Paragraph>
        </Section>
        <ConnectedPages>
          <LayoutSeparator />
          <Section>
            <Paragraph>
              <strong>Connected Articles:</strong>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas">Asanas</InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/introduction/five-points-of-yoga">
                General Introduction
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/introduction/proper-breathing">
                2. Proper Breathing
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/introduction/proper-relaxation">
                3. Proper Relaxation
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/introduction/proper-diet">
                4. Proper Diet
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/introduction/proper-thinking">
                5. Proper Thinking
              </InfoLink>
            </Paragraph>
          </Section>
        </ConnectedPages>
      </InfoPageLayout>
    </InfoContainer>
  )
}
