import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Breadcrumbs,
  ConnectedPages,
  Heading2,
  Heading3,
  Heading4,
  Paragraph,
  InfoContainer,
  InfoLink,
  Section,
  Hr,
  InfoPageLayout,
  LayoutSeparator,
  Link,
  AsanaImage,
  ListItem,
  UnorderedList
} from '../../../../components/InfoTypography'

export function InfoShoulderstand() {
  return (
    <InfoContainer style={{ wordBreak: 'break-word' }}>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <InfoLink target="">Information</InfoLink>
        <InfoLink target="practice-guide">Practice Guide</InfoLink>
        <InfoLink target="practice-guide/asanas">Asanas</InfoLink>
        <Link $disabled>Shoulderstand</Link>
      </Breadcrumbs>
      <Heading2>
        2. Shoulderstand (<span className="text-sanskrit">sarvāṅgāsana</span>)
      </Heading2>
      <Hr />
      <InfoPageLayout>
        <Section>
          <Paragraph>
            The word <span className="text-sanskrit">sarva</span> means “all",
            and <span className="text-sanskrit">aṅga</span> means "part” in
            Sanskrit, hence <span className="text-sanskrit">sarvāṅgāsana </span>
            or Shoulderstand is said to benefit the entire body.
            <br /> According to Swami Sivananda there are three asanas which
            alone will keep the body in perfect health: Headstand,
            Shoulderstand, and Forward-Bend.
          </Paragraph>
          <Heading3>Leg Raises, Preparatory Exercises</Heading3>
          <Paragraph>
            In order to strengthen the Abdominal and Lumbar (lower back)
            muscles, the following exercises may be performed:
          </Paragraph>
          <Heading4>Single Leg Raises</Heading4>
          <Paragraph>
            Lying flat on the back, bring the feet together. <br />
            Press the small of the back into the floor and keep the chin towards
            the chest, so that the neck stays on the ground.
            <br /> Inhale, raise the right leg, keeping the toes back towards
            the head.
            <br /> Exhale, lower the leg. Repeat the exercise with the left leg.
            <br /> Alternating legs, repeat three times on each side.
          </Paragraph>
          <Paragraph>
            The third time that each leg is raised, catch hold on it with both
            hands. Stretch the leg towards the head, then try to bring the
            forehead towards the knee.
          </Paragraph>
          <Heading4>Double Leg Raises</Heading4>
          <Paragraph>
            Remain flat on the back with feet together, lower back flat against
            the floor and chin towards the chest.
            <br /> Inhaling, bring both legs (at the same time) up to a right
            angle. <br />
            Exhale, lower legs to ground, concentrating on keeping the back flat
            on the floor.
            <br /> Repeat 5 times, working up to 10 Double Leg Raises.
            <br /> People who are unable to keep the back flat on the ground
            should do only Single Leg Raises, until the back is strengthened
            sufficiently.
          </Paragraph>
          <AsanaImage
            src="/images/asanas/sarvangasana.png"
            alt="Shoulderstand"
          />
          <Heading3>Coming into the position </Heading3>
          <UnorderedList>
            <ListItem>
              Lying flat on the back, bring the feet together.
            </ListItem>
            <ListItem>Inhale, bringing the legs up to a right angle.</ListItem>
            <ListItem>Lift the hips off the ground.</ListItem>
            <ListItem>Support the back with the hands.</ListItem>
            <ListItem>
              Straighten the body, walking the hands down the back until you are
              resting on the shoulders.
            </ListItem>
          </UnorderedList>
          <Heading3>Holding the position:</Heading3>
          <Paragraph>
            Straighten the back by bringing the hands as close to the shoulders
            as you can.
            <br /> Fingers should be pointing towards the spine with thumbs
            towards the chest.
            <br /> Keep the legs up. Relax the calf muscles; keep the knees
            straight and feet together.
            <br /> Relax into the position.
            <br />
            breathe deeply through the nose.
          </Paragraph>
          <Paragraph>
            Beginners hold the Shoulderstand for 30 seconds. More advanced
            students may increase the time gradually to 3 minutes.
          </Paragraph>
          <Heading3>To come out of the position:</Heading3>
          <UnorderedList>
            <ListItem>Reverse the procedure of going up</ListItem>
            <ListItem>
              Lower the feet to a 45 degree angle over the head.
            </ListItem>
            <ListItem>
              Bring the hands (palms downward) flat on the floor behind the
              back. Push the hands into the floor, using them as a brake.
              Concentrate on keeping the head on the ground.
            </ListItem>
            <ListItem>
              Slowly lower yourself out of the position, unrolling the body
              slowly. Try to feel each vertebrae as it is lowered to the ground
              separately.
            </ListItem>
            <ListItem>
              When you have come out of the position, rest and relax in the
              corpse pose
            </ListItem>
          </UnorderedList>
          <Heading3>Common Mistakes:</Heading3>
          <UnorderedList>
            <ListItem>Knees are bent.</ListItem>
            <ListItem>
              Feet are allowed to separatie, causing the hips to rotate outward.
            </ListItem>
            <ListItem>
              Weight of the body is unevenly distributed, causing the body to
              lean to one side.
            </ListItem>
            <ListItem>The body should be relaxed, but straight.</ListItem>
          </UnorderedList>
          <Heading3>Benefits of the Shoulderstand</Heading3>
          <Heading4>Physical benefits</Heading4>
          <UnorderedList>
            <ListItem>
              Because of the chin pressing on the throat, the thyroid gland is
              affected and regulated. The thyroid is perhaps the most important
              gland of the endocrine system, regulating all other glands. It is
              a butterfly shaped gland located in the front of and next to the
              larynx.
            </ListItem>
            <ListItem>
              The Shoulderstand brings a rich blood supply to the thyroid.
            </ListItem>
            <ListItem>
              The thyroid regulates the body's metabolism and heat production.
            </ListItem>
            <ListItem>
              The thyroid hormones regulate the growth and development of the
              body.
            </ListItem>
            <ListItem>
              The thyroid promotes protein synthesis and proper growth of the
              muscle and bone tissues.
            </ListItem>
            <ListItem>
              It controls the heart rate, heart contractability and blood
              pressure.
            </ListItem>
            <ListItem>
              Weight problems (over and under) are often the result of thyroid
              problems. The Shoulderstand helps to bring the thyroid to its
              proper level of activity.
            </ListItem>
            <ListItem>
              The chin pressing onto the base of the throat also stimulates and
              regulates the parathyroid gland.
            </ListItem>
            <ListItem>
              The parathyroid helps to maintain proper calcium levels in the
              blood and tissues.
            </ListItem>
            <ListItem>
              Normal skeletal and cardiac muscle activity, as well as blood
              clotting, depend on proper calcium levels.
            </ListItem>
            <ListItem>
              Insufficiency of the parathyroid functioning can cause stiffness,
              cramps, spasms and even convulsions.
            </ListItem>
            <ListItem>
              Overactive parathyroid functioning can lead to kidney disease,
              bone disease, and muscle weakness.
            </ListItem>
            <ListItem>
              The Shoulderstand centralized the blood supply in the spinal
              column and feeds the spine abundantly. It keeps the spine strong
              and elastic.
            </ListItem>
            <ListItem>
              Because of the inverted body position, blood is not permitted to
              stagnate in the veins of the lower limbs. This stagnation often
              occurs as a result of broken capillaries or lack of tone in the
              circulatory system (varicose veins). The Shoulderstand causes the
              blood to recirculate. Hence it is a very good tonic for people who
              stand on their feet all day and/or have a tendency towards
              varicose veins.
            </ListItem>
            <ListItem>
              The Shoulderstand encourages deep abdominal breathing as it limits
              the use of the top portion of the lungs.
            </ListItem>
            <ListItem>A gentle massage is given to the heart.</ListItem>
            <ListItem>
              The throat and thoracic regions are strengthened.
            </ListItem>
            <ListItem>
              The cervical region is stretched as well as the shoulder muscles.
            </ListItem>
            <ListItem>
              The Shoulderstand helps to keep a youthful figure and smooth skin.
            </ListItem>
          </UnorderedList>
          <Heading4>Mental benefits</Heading4>
          <UnorderedList>
            <ListItem>The Shoulderstand relieves mental sluggishness</ListItem>
            <ListItem>It helps cure insomnia &amp; depression</ListItem>
          </UnorderedList>
          <Heading4>Psychic benefits</Heading4>
          <UnorderedList>
            <ListItem>
              The focus is on{' '}
              <span className="text-sanskrit">viśuddhī cakra</span> (located at
              the neck).
            </ListItem>
          </UnorderedList>
          <Heading3>Variations of the Shoulderstand</Heading3>
          <Heading4>Variation 1</Heading4>
          <Paragraph>
            Supporting the back with the hands, exhale and lower the right leg
            to the floor behind the head. Inhale and bring it back up. Keep both
            legs straight. Repeat three times with each leg.
          </Paragraph>
          <Heading4>Variation 2 </Heading4>
          <Paragraph>
            Slowly bring your right then your left hand up alongside your
            thighs. Hold the position, breathing normally.
          </Paragraph>
        </Section>
        <ConnectedPages>
          <LayoutSeparator />
          <Section>
            <Paragraph>
              <strong>Connected Articles:</strong>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas">Asanas</InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/sun-salutations">
                Sun Salutations (
                <span className="text-sanskrit">sūrya namaskāra</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/headstand">
                1. Headstand (<span className="text-sanskrit">śirṣāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/plough">
                3. Plough (<span className="text-sanskrit">halāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/fish">
                4. Fish (<span className="text-sanskrit">matsyāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/forward-bend">
                5. (<span className="text-sanskrit">pascimottanāsana</span>)
                Forward Bend
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/cobra">
                6. Cobra (<span className="text-sanskrit">bhujaṅgāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/locust">
                7. Locust (<span className="text-sanskrit">śalabhāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/bow">
                8. Bow (<span className="text-sanskrit">dhanurāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/half">
                9. (<span className="text-sanskrit">ardha matsyendrāsana</span>)
                Half Spinal Twist
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/crow">
                10. Crow (<span className="text-sanskrit">kakāsana</span>)
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/hands">
                11. (<span className="text-sanskrit">pāda hastāsana</span>)
                Hands to Feet
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/triangle">
                12. (<span className="text-sanskrit">trikonāsana</span>)
                Triangle
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas/savasana">
                Final Relaxation (
                <span className="text-sanskrit">śavāsana</span>)
              </InfoLink>
            </Paragraph>
          </Section>
        </ConnectedPages>
      </InfoPageLayout>
    </InfoContainer>
  )
}
