// @ts-nocheck
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { QueryBuilder } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { ProgramTypesEnumType } from './ProgramTypesEnum'
import { RootStoreType } from './index'

/**
 * ProgramResponseBase
 * auto generated base class for the model ProgramResponseModel.
 */
export const ProgramResponseModelBase = ModelBase.named('ProgramResponse')
  .props({
    __typename: types.optional(
      types.literal('ProgramResponse'),
      'ProgramResponse'
    ),
    id: types.identifier,
    self_url: types.union(types.undefined, types.null, types.string),
    name: types.union(types.undefined, types.null, types.string),
    excerpt: types.union(types.undefined, types.null, types.string),
    content: types.union(types.undefined, types.null, types.string),
    date_type: types.union(types.undefined, types.null, types.string),
    start_date: types.union(types.undefined, types.null, types.string),
    end_date: types.union(types.undefined, types.null, types.string),
    list_until_date: types.union(types.undefined, types.null, types.string),
    _categories: types.union(
      types.undefined,
      types.null,
      types.array(types.string)
    ),
    notes: types.union(types.undefined, types.null, types.array(types.string)),
    external_code: types.union(types.undefined, types.null, types.string),
    program_link: types.union(types.undefined, types.null, types.string),
    registration_link: types.union(types.undefined, types.null, types.string),
    admin_link: types.union(types.undefined, types.null, types.string),
    location: types.union(types.undefined, types.null, types.string),
    categories: types.union(types.undefined, types.array(ProgramTypesEnumType))
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class ProgramResponseModelSelector extends QueryBuilder {
  get id() {
    return this.__attr(`id`)
  }
  get self_url() {
    return this.__attr(`self_url`)
  }
  get name() {
    return this.__attr(`name`)
  }
  get excerpt() {
    return this.__attr(`excerpt`)
  }
  get content() {
    return this.__attr(`content`)
  }
  get date_type() {
    return this.__attr(`date_type`)
  }
  get start_date() {
    return this.__attr(`start_date`)
  }
  get end_date() {
    return this.__attr(`end_date`)
  }
  get list_until_date() {
    return this.__attr(`list_until_date`)
  }
  get _categories() {
    return this.__attr(`_categories`)
  }
  get notes() {
    return this.__attr(`notes`)
  }
  get external_code() {
    return this.__attr(`external_code`)
  }
  get program_link() {
    return this.__attr(`program_link`)
  }
  get registration_link() {
    return this.__attr(`registration_link`)
  }
  get admin_link() {
    return this.__attr(`admin_link`)
  }
  get location() {
    return this.__attr(`location`)
  }
  get categories() {
    return this.__attr(`categories`)
  }
}
export function selectFromProgramResponse() {
  return new ProgramResponseModelSelector()
}

export const programResponseModelPrimitives =
  selectFromProgramResponse().self_url.name.excerpt.content.date_type.start_date
    .end_date.list_until_date._categories.notes.external_code.program_link
    .registration_link.admin_link.location.categories
