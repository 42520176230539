// @ts-nocheck
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { QueryBuilder } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { ChatEdgeModel, ChatEdgeModelType } from './ChatEdgeModel'
import { ChatEdgeModelSelector } from './ChatEdgeModel.base'
import { PageInfoModel, PageInfoModelType } from './PageInfoModel'
import { PageInfoModelSelector } from './PageInfoModel.base'
import { RootStoreType } from './index'

/**
 * ChatConnectionBase
 * auto generated base class for the model ChatConnectionModel.
 */
export const ChatConnectionModelBase = ModelBase.named('ChatConnection')
  .props({
    __typename: types.optional(
      types.literal('ChatConnection'),
      'ChatConnection'
    ),
    pageInfo: types.union(
      types.undefined,
      types.late((): any => PageInfoModel)
    ),
    edges: types.union(
      types.undefined,
      types.array(types.late((): any => ChatEdgeModel))
    )
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class ChatConnectionModelSelector extends QueryBuilder {
  pageInfo(
    builder:
      | string
      | PageInfoModelSelector
      | ((selector: PageInfoModelSelector) => PageInfoModelSelector)
      | undefined
  ) {
    return this.__child(`pageInfo`, PageInfoModelSelector, builder)
  }
  edges(
    builder:
      | string
      | ChatEdgeModelSelector
      | ((selector: ChatEdgeModelSelector) => ChatEdgeModelSelector)
      | undefined
  ) {
    return this.__child(`edges`, ChatEdgeModelSelector, builder)
  }
}
export function selectFromChatConnection() {
  return new ChatConnectionModelSelector()
}

export const chatConnectionModelPrimitives = selectFromChatConnection()
