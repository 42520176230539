import React from 'react'
import { StoreContext } from '@sayr/client-models'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Breadcrumbs,
  InfoLink,
  Link,
  MediaCard,
  StyledPageContentWrapper,
  StyledCardMedia
} from '../../../components/InfoTypography'

export function InfoPracticeGuide() {
  const store = React.useContext(StoreContext)
  return (
    <StyledPageContentWrapper>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <InfoLink target="">Information</InfoLink>
        <Link $disabled>Practice Guide</Link>
      </Breadcrumbs>
      <MediaCard
        topic="Introduction to Yoga Practice"
        description="Learn the five points of yoga"
        action={() => {
          store.view.openInfoPage('practice-guide/introduction')
        }}
      >
        <StyledCardMedia
          image="https://online.sivanandabahamas.org/wp-content/uploads/2020/06/CKarmaYogi-1024x579.jpg"
          title="Krishna walking on the beach with a yoga mat"
        />
      </MediaCard>
      <MediaCard
        topic="Invocations and Mantras"
        description="Invocations (Dhyāna Ślokas) and Śānti Mantras for beginning and ending of classes"
        action={() => {
          store.view.openInfoPage('practice-guide/invocations-and-mantras')
        }}
      >
        <StyledCardMedia
          image="https://online.sivanandabahamas.org/wp-content/uploads/2020/06/CKarmaYogi-1024x579.jpg"
          title="Krishna walking on the beach with a yoga mat"
        />
      </MediaCard>
      <MediaCard
        topic="Meditation"
        description="Learn the basic technique for meditation."
        action={() => {
          store.view.openInfoPage('practice-guide/meditation')
        }}
      >
        <StyledCardMedia
          image="https://online.sivanandabahamas.org/wp-content/uploads/2020/06/CKarmaYogi-1024x579.jpg"
          title="Krishna walking on the beach with a yoga mat"
        />
      </MediaCard>
      <MediaCard
        topic="Breathing Techniques"
        description="Learn the practice of prāṇāyāma."
        action={() => {
          store.view.openInfoPage('practice-guide/breathing-techniques')
        }}
      >
        <StyledCardMedia
          image="https://online.sivanandabahamas.org/wp-content/uploads/2020/06/CKarmaYogi-1024x579.jpg"
          title="Krishna walking on the beach with a yoga mat"
        />
      </MediaCard>
      <MediaCard
        topic="Asanas"
        description="Learn all the basic Āsanas."
        action={() => {
          store.view.openInfoPage('practice-guide/asanas')
        }}
      >
        <StyledCardMedia
          image="https://online.sivanandabahamas.org/wp-content/uploads/2020/06/CKarmaYogi-1024x579.jpg"
          title="Krishna walking on the beach with a yoga mat"
        />
      </MediaCard>
    </StyledPageContentWrapper>
  )
}
