import React from 'react'
import { StoreContext } from '@sayr/client-models'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Breadcrumbs,
  InfoLink,
  Link,
  MediaCard,
  StyledPageContentWrapper,
  StyledCardMedia
} from '../../../../components/InfoTypography'

export function InfoIntroduction() {
  const store = React.useContext(StoreContext)
  return (
    <StyledPageContentWrapper>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <InfoLink target="">Information</InfoLink>
        <InfoLink target="practice-guide">Practice Guide</InfoLink>
        <Link $disabled>Introduction</Link>
      </Breadcrumbs>
      <MediaCard
        topic="General Introduction"
        description="General introduction to the five points of Yoga Practice of Yoga"
        action={() => {
          store.view.openInfoPage(
            'practice-guide/introduction/five-points-of-yoga'
          )
        }}
      >
        <StyledCardMedia
          image="https://online.sivanandabahamas.org/wp-content/uploads/2020/06/CKarmaYogi-1024x579.jpg"
          title="Krishna walking on the beach with a yoga mat"
        />
      </MediaCard>
      <MediaCard
        topic="1. Proper Exercise"
        description=""
        action={() => {
          store.view.openInfoPage('practice-guide/introduction/proper-exercise')
        }}
      >
        <StyledCardMedia
          image="https://online.sivanandabahamas.org/wp-content/uploads/2020/06/CKarmaYogi-1024x579.jpg"
          title="Krishna walking on the beach with a yoga mat"
        />
      </MediaCard>
      <MediaCard
        topic="2. Proper Breathing"
        description=""
        action={() => {
          store.view.openInfoPage(
            'practice-guide/introduction/proper-breathing'
          )
        }}
      >
        <StyledCardMedia
          image="https://online.sivanandabahamas.org/wp-content/uploads/2020/06/CKarmaYogi-1024x579.jpg"
          title="Krishna walking on the beach with a yoga mat"
        />
      </MediaCard>
      <MediaCard
        topic="3. Proper Relaxation"
        description=""
        action={() => {
          store.view.openInfoPage(
            'practice-guide/introduction/proper-relaxation'
          )
        }}
      >
        <StyledCardMedia
          image="https://online.sivanandabahamas.org/wp-content/uploads/2020/06/CKarmaYogi-1024x579.jpg"
          title="Krishna walking on the beach with a yoga mat"
        />
      </MediaCard>
      <MediaCard
        topic="4. Proper Diet"
        description=""
        action={() => {
          store.view.openInfoPage('practice-guide/introduction/proper-diet')
        }}
      >
        <StyledCardMedia
          image="https://online.sivanandabahamas.org/wp-content/uploads/2020/06/CKarmaYogi-1024x579.jpg"
          title="Krishna walking on the beach with a yoga mat"
        />
      </MediaCard>
      <MediaCard
        topic="5. Proper Thinking"
        description=""
        action={() => {
          store.view.openInfoPage('practice-guide/introduction/proper-thinking')
        }}
      >
        <StyledCardMedia
          image="https://online.sivanandabahamas.org/wp-content/uploads/2020/06/CKarmaYogi-1024x579.jpg"
          title="Krishna walking on the beach with a yoga mat"
        />
      </MediaCard>
    </StyledPageContentWrapper>
  )
}
