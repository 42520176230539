import dayjs from 'dayjs'
import { Compound, CompoundPerson } from '../@types/CompoundTypes'
import { Registration } from '../@types/RegistrationTypes'
import { Item, Payment } from '../@types/TransactionTypes'

export function getAllRegistrations(compound: Compound) {
  const { persons, unmatchedRegistrations } = compound

  const registrations = [
    ...(persons?.flatMap(p => p.registrations) || []),
    ...(unmatchedRegistrations || [])
  ] as Registration[]

  if (!registrations.length)
    throw new Error(
      `the following compound does not contain any registrations:
      ${JSON.stringify(compound, null, 2)}`
    )
  return registrations
}

export function getAllPersonRegistrations(compoundPerson: CompoundPerson) {
  return compoundPerson.registrations
}

// returns all stays in a compound
export function getAllRoomStays(
  compound: Compound,
  lodgingProgramIds: number[],
  validStatusOnly = true
) {
  return getAllRegistrations(compound)
    .filter(reg => !(validStatusOnly && reg.status.match(/cancelled/)))
    .filter(reg => lodgingProgramIds.some(id => id === reg.program_id))
}

// returns stays of a person sorted by arrival date
export function getPersonRoomStays(
  compoundPerson: CompoundPerson,
  lodgingProgramIds: number[],
  validStatusOnly = true
) {
  return getAllPersonRegistrations(compoundPerson)
    .filter(reg => !(validStatusOnly && reg.status.match(/cancelled/)))
    .filter(reg => lodgingProgramIds.some(id => id === reg.program_id))
    .sort((a, b) => (a.start_date > b.start_date ? 1 : -1))
}

export function getAllItems(compound: Compound) {
  return getAllRegistrations(compound).flatMap(reg => reg.items)
}

export function getAllPayments(compound: Compound) {
  return getAllRegistrations(compound).flatMap(reg => reg.payments)
}

const isStatusComplete = (t: Item) => t.status === 'complete'
const isPaymentStatusComplete = (t: Payment) => t.status === 'complete'

// returns item total with an optional filter function
// (including all credits transactions)
export function getItemTotal(
  compound: Compound,
  filter: (item: Item) => boolean = () => true
) {
  return +getAllItems(compound)
    .filter(isStatusComplete)
    .filter(filter)
    .reduce(
      (aggregate, item) =>
        aggregate -
        item.credit_amount +
        item.charge_amount +
        // discount_amount only matters when charge_amount is not 0.
        (item.charge_amount ? item.discount_amount : 0),
      0
    )
    .toFixed(2)
}

export function getRealItemTotal(compound: Compound) {
  return getItemTotal(
    compound,
    (item: Item) =>
      !item.category.match(
        /^applied-personal-credit|transfer-to-personal-credit$/
      )
  )
}

export function getTaxTotal(compound: Compound) {
  return +getAllItems(compound)
    .filter(isStatusComplete)
    .reduce(
      (aggregate, item) => aggregate + item.tax_1_amount + item.tax_2_amount,
      0
    )
    .toFixed(2)
}

// total amount of payments only (regardless of credits used)
export function getRealPaymentTotal(compound: Compound) {
  return +getAllPayments(compound)
    .filter(isPaymentStatusComplete)
    .reduce(
      (aggregate, payment) =>
        aggregate - payment.charge_amount + payment.credit_amount,
      0
    )
    .toFixed(2)
}

// total amount taken from personal credit
export function getRealCreditPayments(compound: Compound) {
  return +(getRealItemTotal(compound) - getItemTotal(compound)).toFixed(2)
}

// total amount of payments and credits used from personal credit
// this should match the "RealItemTotal" and "taxTotal" to have 0 balance.
export function getPaymentTotal(compound: Compound) {
  return +(
    getRealPaymentTotal(compound) + getRealCreditPayments(compound)
  ).toFixed(2)
}

export function getTotalBalance(compound: Compound) {
  return +(
    getRealItemTotal(compound) +
    getTaxTotal(compound) -
    getPaymentTotal(compound)
  ).toFixed(2)
}

export function getTotalNights(compound: Compound, lodgingPrograms: number[]) {
  return getAllRoomStays(compound, lodgingPrograms).reduce<number>(
    (totalDays, visit) =>
      totalDays + dayjs(visit.end_date).diff(visit.start_date, 'days'),
    0
  )
}

// This can only be called in a server node process.
export function populateAdminLinks(compound: Compound) {
  if (!process.env.RG_SITE) throw new Error('env variable RG_SITE is not set.')

  const adminLinkToReg = (r: Registration) => {
    r.admin_link = `${process.env.RG_SITE}/wp-admin/admin.php?registration=${r.id}&page=registrations&action=edit`
  }

  compound.persons?.forEach(p => {
    p.registrations.forEach(adminLinkToReg)
  })
  compound.unmatchedRegistrations?.forEach(adminLinkToReg)
}

export function getPersonArrivalDate({
  compoundPerson,
  lodgingProgramIds,
  options = { relativeToDate: '1900-01-01', searchDirection: 'forward' }
}: {
  compoundPerson: CompoundPerson
  lodgingProgramIds: number[]
  options?: {
    // In case there are a few separate stays for a compoundPerson (rare),
    // get the stay relative to a certain date with a direction in mind.
    // for example:
    // options: { searchDirection: 'forward', relativeToDate: '2022-04-05' }
    // will search for the arrival date of the nearest stay on or after April 5th, 2022.

    // options: { searchDirection: 'strict', relativeToDate: '2022-04-05' }
    // will search for the arrival date of a stay on April 5th, 2022.

    searchDirection: 'forward' | 'backward' | 'strict'
    relativeToDate: string // YYYY-MM-DD format
  }
}) {
  const stayChunks = getPersonStayChunks({ compoundPerson, lodgingProgramIds })

  const relevantChunk =
    options.searchDirection === 'strict'
      ? stayChunks.find(
          s =>
            s[0].start_date <= options.relativeToDate &&
            s[s.length - 1].end_date >= options.relativeToDate
        )
      : options.searchDirection === 'forward'
      ? // forward-find the first relevant chunk.
        stayChunks.find(s => s[0].start_date >= options.relativeToDate)
      : // searchDirection === 'backward'
        // backward-find the first relevant chunk.
        stayChunks
          .reverse()
          .find(s => s[0].start_date <= options.relativeToDate)

  return relevantChunk?.[0].start_date
}

export function getPersonDepartureDate({
  compoundPerson,
  lodgingProgramIds,
  options = { relativeToDate: '1900-01-01', searchDirection: 'forward' }
}: {
  compoundPerson: CompoundPerson
  lodgingProgramIds: number[]
  options?: {
    // In case there are a few separate stays for a compoundPerson (rare),
    // get the stay relative to a certain date with a direction in mind.
    // for example:
    // options: { searchDirection: 'forward', relativeToDate: '2022-04-05' }
    // will search for the arrival date of the nearest stay on or after April 5th, 2022.

    // options: { searchDirection: 'strict', relativeToDate: '2022-04-05' }
    // will search for the arrival date of a stay on April 5th, 2022.

    searchDirection: 'forward' | 'backward' | 'strict'
    relativeToDate: string // YYYY-MM-DD format
  }
}) {
  const stayChunks = getPersonStayChunks({ compoundPerson, lodgingProgramIds })

  const relevantChunk =
    options.searchDirection === 'strict'
      ? stayChunks.find(
          s =>
            s[0].start_date <= options.relativeToDate &&
            s[s.length - 1].end_date >= options.relativeToDate
        )
      : options.searchDirection === 'forward'
      ? // forward-find the first relevant chunk.
        stayChunks.find(s => s[s.length - 1].end_date >= options.relativeToDate)
      : // searchDirection === 'backward'
        // backward-find the first relevant chunk.
        stayChunks
          .reverse()
          .find(s => s[s.length - 1].end_date <= options.relativeToDate)

  return relevantChunk?.[relevantChunk.length - 1].end_date
}

export function getStayArrivalDate({
  compound,
  stay,
  lodgingProgramIds
}: {
  compound: Compound
  stay: Registration
  lodgingProgramIds: number[]
}) {
  const arrivalDate = (
    stay.person_id
      ? getPersonArrivalDate({
          compoundPerson: compound.persons?.find(
            p => p.id === stay.person_id
          ) as CompoundPerson,
          lodgingProgramIds,
          options: {
            relativeToDate: stay.start_date,
            searchDirection: 'strict'
          }
        })
      : stay.start_date
  ) as string

  return arrivalDate
}

// separate stays into chunks
export function getPersonStayChunks({
  compoundPerson,
  lodgingProgramIds
}: {
  compoundPerson: CompoundPerson
  lodgingProgramIds: number[]
}) {
  const personStays = getPersonRoomStays(compoundPerson, lodgingProgramIds)

  const stayChunks = personStays.reduce<Registration[][]>((chunks, stay) => {
    if (!chunks.length) return [[stay]]

    const lastChunk = chunks[chunks.length - 1]
    if (stay.start_date > lastChunk[lastChunk.length - 1].end_date)
      return chunks.concat([[stay]])
    else {
      lastChunk.push(stay)
      return chunks
    }
  }, [])

  return stayChunks
}

export function getParentReg({
  compound,
  regId
}: {
  compound: Compound
  regId: number
}) {
  const allRegs = getAllRegistrations(compound)
  const subjectReg = allRegs.find(r => r.id === regId)
  const parent_registration_id =
    subjectReg?.parent_registration_id || subjectReg?.id

  return allRegs.find(r => r.id === parent_registration_id)
}
