// @ts-nocheck
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { QueryBuilder } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { RootStoreType } from './index'

/**
 * PopulationResponseBase
 * auto generated base class for the model PopulationResponseModel.
 */
export const PopulationResponseModelBase = ModelBase.named('PopulationResponse')
  .props({
    __typename: types.optional(
      types.literal('PopulationResponse'),
      'PopulationResponse'
    ),
    date: types.union(types.undefined, types.string),
    total: types.union(types.undefined, types.integer),
    VACATION: types.union(types.undefined, types.integer),
    ATTC: types.union(types.undefined, types.integer),
    BABIES: types.union(types.undefined, types.integer),
    CHILDREN: types.union(types.undefined, types.integer),
    COMMUTERS: types.union(types.undefined, types.integer),
    KARMA_YOGA: types.union(types.undefined, types.integer),
    SPEAKER: types.union(types.undefined, types.integer),
    TTC: types.union(types.undefined, types.integer),
    VISITING_STAFF: types.union(types.undefined, types.integer)
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class PopulationResponseModelSelector extends QueryBuilder {
  get date() {
    return this.__attr(`date`)
  }
  get total() {
    return this.__attr(`total`)
  }
  get VACATION() {
    return this.__attr(`VACATION`)
  }
  get ATTC() {
    return this.__attr(`ATTC`)
  }
  get BABIES() {
    return this.__attr(`BABIES`)
  }
  get CHILDREN() {
    return this.__attr(`CHILDREN`)
  }
  get COMMUTERS() {
    return this.__attr(`COMMUTERS`)
  }
  get KARMA_YOGA() {
    return this.__attr(`KARMA_YOGA`)
  }
  get SPEAKER() {
    return this.__attr(`SPEAKER`)
  }
  get TTC() {
    return this.__attr(`TTC`)
  }
  get VISITING_STAFF() {
    return this.__attr(`VISITING_STAFF`)
  }
}
export function selectFromPopulationResponse() {
  return new PopulationResponseModelSelector()
}

export const populationResponseModelPrimitives =
  selectFromPopulationResponse().date.total.VACATION.ATTC.BABIES.CHILDREN
    .COMMUTERS.KARMA_YOGA.SPEAKER.TTC.VISITING_STAFF
