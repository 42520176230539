// @ts-nocheck
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { CovidTestTypeEnumType } from './CovidTestTypeEnum'
import {
  MonitoredAttributeMetaDataResponseModel,
  MonitoredAttributeMetaDataResponseModelType
} from './MonitoredAttributeMetaDataResponseModel'
import { MonitoredAttributeMetaDataResponseModelSelector } from './MonitoredAttributeMetaDataResponseModel.base'
import { ProgramTypesEnumType } from './ProgramTypesEnum'
import { SeasonTypesEnumType } from './SeasonTypesEnum'
import { UserResponseModel, UserResponseModelType } from './UserResponseModel'
import { UserResponseModelSelector } from './UserResponseModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  createdByUser: UserResponseModelType
}

/**
 * RegAdditionalDataSeasonResponseBase
 * auto generated base class for the model RegAdditionalDataSeasonResponseModel.
 */
export const RegAdditionalDataSeasonResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named('RegAdditionalDataSeasonResponse')
    .props({
      __typename: types.optional(
        types.literal('RegAdditionalDataSeasonResponse'),
        'RegAdditionalDataSeasonResponse'
      ),
      _id: types.identifier,
      programTypes: types.union(
        types.undefined,
        types.array(ProgramTypesEnumType)
      ),
      deleted: types.union(types.undefined, types.boolean),
      createdByUser: types.union(
        types.undefined,
        MSTGQLRef(types.late((): any => UserResponseModel))
      ),
      precedence: types.union(types.undefined, types.integer),
      arrivalDateStart: types.union(types.undefined, types.string),
      arrivalDateEnd: types.union(types.undefined, types.string),
      startDate: types.union(types.undefined, types.string),
      endDate: types.union(types.undefined, types.string),
      seasonDateClassification: types.union(types.undefined, types.string),
      seasonType: types.union(types.undefined, SeasonTypesEnumType),
      dayGap: types.union(types.undefined, types.null, types.integer),
      daysOfWeek: types.union(
        types.undefined,
        types.null,
        types.array(types.integer)
      ),
      testType: types.union(types.undefined, types.null, CovidTestTypeEnumType),
      cost: types.union(types.undefined, types.null, types.number),
      monitoredAttributes: types.union(
        types.undefined,
        types.null,
        types.array(
          types.late((): any => MonitoredAttributeMetaDataResponseModel)
        )
      )
    })
    .views(self => ({
      get store() {
        return self.__getStore<RootStoreType>()
      }
    }))
)

export class RegAdditionalDataSeasonResponseModelSelector extends QueryBuilder {
  get _id() {
    return this.__attr(`_id`)
  }
  get programTypes() {
    return this.__attr(`programTypes`)
  }
  get deleted() {
    return this.__attr(`deleted`)
  }
  get precedence() {
    return this.__attr(`precedence`)
  }
  get arrivalDateStart() {
    return this.__attr(`arrivalDateStart`)
  }
  get arrivalDateEnd() {
    return this.__attr(`arrivalDateEnd`)
  }
  get startDate() {
    return this.__attr(`startDate`)
  }
  get endDate() {
    return this.__attr(`endDate`)
  }
  get seasonDateClassification() {
    return this.__attr(`seasonDateClassification`)
  }
  get seasonType() {
    return this.__attr(`seasonType`)
  }
  get dayGap() {
    return this.__attr(`dayGap`)
  }
  get daysOfWeek() {
    return this.__attr(`daysOfWeek`)
  }
  get testType() {
    return this.__attr(`testType`)
  }
  get cost() {
    return this.__attr(`cost`)
  }
  createdByUser(
    builder:
      | string
      | UserResponseModelSelector
      | ((selector: UserResponseModelSelector) => UserResponseModelSelector)
      | undefined
  ) {
    return this.__child(`createdByUser`, UserResponseModelSelector, builder)
  }
  monitoredAttributes(
    builder:
      | string
      | MonitoredAttributeMetaDataResponseModelSelector
      | ((
          selector: MonitoredAttributeMetaDataResponseModelSelector
        ) => MonitoredAttributeMetaDataResponseModelSelector)
      | undefined
  ) {
    return this.__child(
      `monitoredAttributes`,
      MonitoredAttributeMetaDataResponseModelSelector,
      builder
    )
  }
}
export function selectFromRegAdditionalDataSeasonResponse() {
  return new RegAdditionalDataSeasonResponseModelSelector()
}

export const regAdditionalDataSeasonResponseModelPrimitives =
  selectFromRegAdditionalDataSeasonResponse()._id.programTypes.deleted
    .precedence.arrivalDateStart.arrivalDateEnd.startDate.endDate
    .seasonDateClassification.seasonType.dayGap.daysOfWeek.testType.cost
