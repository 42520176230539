// @ts-nocheck
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { QueryBuilder } from 'mst-gql'
import { ModelBase } from './ModelBase'
import {
  PermissionsResponseModel,
  PermissionsResponseModelType
} from './PermissionsResponseModel'
import { PermissionsResponseModelSelector } from './PermissionsResponseModel.base'
import { RootStoreType } from './index'

/**
 * UserResponseBase
 * auto generated base class for the model UserResponseModel.
 */
export const UserResponseModelBase = ModelBase.named('UserResponse')
  .props({
    __typename: types.optional(types.literal('UserResponse'), 'UserResponse'),
    id: types.identifier,
    email: types.union(types.undefined, types.string),
    nickName: types.union(types.undefined, types.null, types.string),
    profilePhoto: types.union(types.undefined, types.null, types.string),
    permissions: types.union(
      types.undefined,
      types.null,
      types.late((): any => PermissionsResponseModel)
    ),
    googleDisplayName: types.union(types.undefined, types.null, types.string)
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class UserResponseModelSelector extends QueryBuilder {
  get id() {
    return this.__attr(`id`)
  }
  get email() {
    return this.__attr(`email`)
  }
  get nickName() {
    return this.__attr(`nickName`)
  }
  get profilePhoto() {
    return this.__attr(`profilePhoto`)
  }
  get googleDisplayName() {
    return this.__attr(`googleDisplayName`)
  }
  permissions(
    builder:
      | string
      | PermissionsResponseModelSelector
      | ((
          selector: PermissionsResponseModelSelector
        ) => PermissionsResponseModelSelector)
      | undefined
  ) {
    return this.__child(
      `permissions`,
      PermissionsResponseModelSelector,
      builder
    )
  }
}
export function selectFromUserResponse() {
  return new UserResponseModelSelector()
}

export const userResponseModelPrimitives =
  selectFromUserResponse().email.nickName.profilePhoto.googleDisplayName
