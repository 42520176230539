import React from 'react'

import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { StoreContext } from '@sayr/client-models'
import {
  Breadcrumbs,
  Heading2,
  Heading3,
  Paragraph,
  InfoContainer,
  InfoLink,
  Image,
  Section,
  Figure,
  Hr,
  InfoPageLayout,
  LayoutSeparator,
  ConnectedPages,
  TipsFrame,
  UnorderedList,
  ListItem,
  Link
} from '../../../components/InfoTypography'

export function InfoCheckInProcess() {
  const store = React.useContext(StoreContext)
  return (
    <InfoContainer>
      <Figure>
        <Image src="/images/sign-document.jpg" alt="Document Signing" />
        <figcaption>
          Photo by{' '}
          <Link href="https://unsplash.com/@cytonn_photography?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
            Cytonn Photography
          </Link>{' '}
          on{' '}
          <Link href="https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
            Unsplash
          </Link>
        </figcaption>
      </Figure>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <InfoLink target="">Information</InfoLink>
        <InfoLink target="trip-logistics"> Trip Logistics</InfoLink>
        <Link $disabled>The Check-in Process</Link>
      </Breadcrumbs>
      <Heading2>The Check-in Process</Heading2>
      <Hr />
      <InfoPageLayout>
        <Section>
          <Heading3>As soon as you arrive</Heading3>
          <Paragraph>
            As you arrive you will go directly from the dock to the front desk.
            <br />
            First, we conduct a 15-minute Covid test and explain our Covid
            safety protocols.
            <br />
            Then you will proceed to check in.
          </Paragraph>

          <Heading3>The check in process</Heading3>
          <Paragraph>
            We will scan your passport and ask you to sign a waiver (this waiver
            is sent to you when you make your booking.)
            <br />
            If it is your first time in the ashram, we will orient you for a
            minute or two on the whereabouts of the key places and the ashram's
            schedule.
          </Paragraph>

          <Heading3>Getting into your room</Heading3>
          <Paragraph>
            The Check in time is at 3:00 pm. We do our best to have your room
            much earlier, so it is likely that if you come earlier you will get
            your room, but not guaranteed. When you room is clean, we will show
            you to your room, where you should have a bath towel and a bottle of
            water. You are welcome to join all the ashram activities from this
            point on.
          </Paragraph>

          <Heading3>The Front Desk</Heading3>
          <Paragraph>
            We are available for you 8:00 am - 6:00 pm daily at the front desk.
            Feel free to chat with us or to approach us in person for anything
            you might need. For emergencies, please call us at:.... Don't mind
            the time. If something is wrong - we want to fix it right away.
          </Paragraph>

          <Heading3>Ashram Welcome &amp; History Tour</Heading3>
          <Paragraph>
            Every day at 11:00 we conduct a 45-minute tour for all the newly
            arrived guests at the ashram. In this tour you will get to know our
            tradition, history, philosophy and our lineage. This tour will
            enable you to make the most out of your stay by understanding our
            values and practices.
          </Paragraph>

          <TipsFrame $color="#eee">
            <Heading3>Tips:</Heading3>
            <UnorderedList>
              <ListItem>
                Communicate to us your arrival time so we can prioritize the
                cleaning of your room accordingly.
              </ListItem>
              <ListItem>
                Guests who have taken the Ashram Tour report having a richer
                experience. Don't miss.
              </ListItem>
            </UnorderedList>
          </TipsFrame>
        </Section>
        <ConnectedPages>
          <LayoutSeparator />
          <Section>
            <Paragraph>
              <strong>Connected Articles:</strong>
            </Paragraph>
            <Paragraph>
              <InfoLink target="trip-logistics/before-arrrival">
                Before Arrival
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="trip-logistics/packing-necessities">
                Packing Necessities
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <Link
                tabIndex={0}
                href={`/ashram-map`}
                onClick={e => {
                  e.preventDefault()
                  store.view.openAshramMapPage()
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    store.view.openAshramMapPage()
                  }
                }}
              >
                Ashram Map
              </Link>
            </Paragraph>
          </Section>
        </ConnectedPages>
      </InfoPageLayout>
    </InfoContainer>
  )
}
