import React from 'react'
import dayjs from 'dayjs'
import Avatar from '@mui/material/Avatar'
import styled from 'styled-components'
import { CellProps } from 'react-table'
import { useQueryClient, QueryKey } from 'react-query'
import { produce } from 'immer'

import {
  GetAllSeasonsQuery,
  ProgramTypes,
  useRegFollowUpUpdateMonitoredAttributeMutation
} from '../../../graphql/generated/typesAndHooks'
import { UpdateableGridCheckbox } from '../../../components/common/dataGrid/DataGrid'
import { CellContent } from '../../../components/common/dataGrid/DataGridStyles'
import { StoreContext } from '@sayr/client-models'
import { matchSeasonByDates } from '../../../services/seasonService'

export function getSeasonFromBatch({
  seasons,
  arrivalDate,
  programTypes
}: {
  seasons: GetAllSeasonsQuery
  arrivalDate: string
  programTypes: ProgramTypes[]
}) {
  const season = seasons.getAllSeasons
    .filter(s => !s.deleted)
    .filter(s =>
      s.programTypes
        .map(p => p)
        .some(seasonProgramType => programTypes.includes(seasonProgramType))
    )
    .find(matchSeasonByDates({ arrivalDate }))

  return season
}

export type ReportRow = {
  regId: number
  rgLink: string
  headshot: string
  firstName: string
  lastName: string
  spiritualName: string
  program: string
  programTypes: ProgramTypes[]
  room: string
  arrivalDate: string
  departureDate: string
  flightTime: string
  flightNumber: string
  notes: string
  balance: number
  highlightRow: boolean
}
export type Attributes = {
  [monitoredAttr: string]: any
}

export type ReportRowWithAttributes = ReportRow & Attributes

export const SmallAvatar = styled(Avatar)`
  width: 80%;
  height: 80%;
  border-radius: 50%;
`

export const StyledUpdateableGridCheckbox = styled(UpdateableGridCheckbox)<{
  $flag: string
}>`
  &&& {
    color: ${({ $flag, disabled }) =>
      disabled ? undefined : $flag === 'yellow' ? `orange` : $flag};
  }
`

const StyledDataGridCell = styled(CellContent)<{
  $flag: string
}>`
  &&& {
    color: ${({ $flag }) => ($flag === 'yellow' ? `orange` : $flag)};
  }
`

export function UpdateableAttributeCheckbox(
  props: CellProps<ReportRowWithAttributes> & {
    queryKey: QueryKey
    seasons: GetAllSeasonsQuery
    queryIdentifier: string
  }
) {
  const store = React.useContext(StoreContext)

  const queryClient = useQueryClient()
  const regId = props.row.original.regId
  const key = props.column.id
  const toggleMutationReal = useRegFollowUpUpdateMonitoredAttributeMutation<
    Error,
    { previousValue: any }
  >({
    onMutate: async () => {
      await queryClient.cancelQueries(props.queryKey)
      const previousValue = queryClient.getQueryData<{
        [queryIdentifier: string]: {
          regId: number
          monitoredAttributes: { key: string; value: boolean }[]
        }[]
      }>(props.queryKey)

      if (!previousValue || !previousValue[props.queryIdentifier])
        throw new Error('old does not exist')
      const newValue = produce(previousValue, draft => {
        const regAdditionalRecord = draft[props.queryIdentifier].find(
          a => a.regId === regId
        )
        const val = regAdditionalRecord?.monitoredAttributes.find(
          attr => attr.key === key
        )

        if (val) val.value = !val.value
        // if no previous key found, create with true value
        else
          regAdditionalRecord?.monitoredAttributes.push({
            key: key,
            value: true
          })
      })

      queryClient.setQueryData(props.queryKey, newValue)

      return { previousValue }
    },

    onError: (_err, vars, context) => {
      console.error('monitored attribute mutation error', _err, vars, context)
      if (context)
        queryClient.setQueryData(props.queryKey, context.previousValue)
    },

    onSettled: () => queryClient.invalidateQueries(props.queryKey)
  })

  const arrivalDate = props.row.original.arrivalDate
  const departureDate = props.row.original.departureDate
  const value = props.value?.value || false
  const programTypes = props.row.original.programTypes

  const flag =
    value ||
    (key === process.env.REACT_APP_PAYMENT_FOLLOW_UP_KEY &&
      !props.row.original.balance) ||
    (key === process.env.REACT_APP_ARRIVAL_TIME_REQUEST_KEY &&
      props.row.original.flightTime)
      ? 'green' // set as green if input is checked or if specific conditions are met
      : getFlagColorByDate({
          seasons: props.seasons,
          key,
          arrivalDate,
          departureDate,
          programTypes
        })

  const disabled = !isAttributeRelevant({
    key,
    programTypes,
    arrivalDate,
    seasons: props.seasons
  })

  return (
    <StyledUpdateableGridCheckbox
      value={value}
      $flag={flag}
      onChange={e => {
        if (store.loggedInUser?.user?.$permissions?.adminFollowUpWrite)
          toggleMutationReal.mutate({
            regId,
            attribute: key,
            value: !props.value?.value
          })
        else e.preventDefault()
      }}
      disabled={disabled}
    />
  )
}

function getFlagColorByDate({
  seasons,
  key,
  arrivalDate,
  departureDate,
  programTypes
}: {
  seasons: GetAllSeasonsQuery
  key: string
  arrivalDate: string
  departureDate: string
  programTypes: ProgramTypes[]
}) {
  const season = getSeasonFromBatch({ seasons, arrivalDate, programTypes })

  const seasonAttribute = season?.monitoredAttributes?.find(
    seasonAttr => key === seasonAttr.key
  )

  if (!seasonAttribute) return 'yellow'
  else
    return seasonAttribute.flagCountFrom === 'before arrival'
      ? dayjs(arrivalDate).diff(dayjs(), 'days') <= seasonAttribute.redFlagDay
        ? 'red'
        : dayjs(arrivalDate).diff(dayjs(), 'days') <=
          seasonAttribute.yellowFlagDay
        ? 'yellow'
        : 'green'
      : dayjs(departureDate).diff(dayjs(), 'days') <= seasonAttribute.redFlagDay
      ? 'red'
      : dayjs(departureDate).diff(dayjs(), 'days') <=
        seasonAttribute.yellowFlagDay
      ? 'yellow'
      : 'green'
}

export function isAttributeRelevant({
  seasons,
  key,
  arrivalDate,
  programTypes
}: {
  seasons: GetAllSeasonsQuery
  key: string
  arrivalDate: string
  programTypes: ProgramTypes[]
}) {
  let isRelevant: boolean
  const season = getSeasonFromBatch({ seasons, arrivalDate, programTypes })

  if (!season?.monitoredAttributes?.some(attr => attr.key === key))
    isRelevant = false
  else isRelevant = season?.programTypes.some(sp => programTypes.includes(sp))
  return isRelevant
}

export function Balance(
  props: CellProps<ReportRowWithAttributes> & {
    queryKey: QueryKey
    seasons: GetAllSeasonsQuery
  }
) {
  const { arrivalDate, departureDate, programTypes } = props.row.original
  const key = props.column.id
  const { seasons } = props
  const value = props.value ?? NaN
  const flag =
    value === 0
      ? 'green'
      : getFlagColorByDate({
          seasons,
          key,
          arrivalDate,
          departureDate,
          programTypes
        })

  return <StyledDataGridCell $flag={flag}>{value}</StyledDataGridCell>
}
