import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

import LoginPage from '../pages/login/LoginPage'
import { StoreContext } from '@sayr/client-models'
import ChooseNickName from '../pages/login/ChooseNickName'

const CoverPageBackground = styled.div`
  &:before {
    content: '';
    background-image: url('/images/login-page-beach-hands-up.jpg');
    background-position: 50% 40%;
    background-size: cover;
    height: 100vh;
    position: fixed;
    width: 100vw;
    z-index: -1;
  }

  height: 100vh;
  position: relative;
  width: 100vw;
`

const FixedSizedPaper = styled(Paper)<{
  $opacity?: number
}>`
  align-items: center;
  && {
    box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.5);
  }
  display: flex;
  flex-direction: column;
  height: 26rem;
  left: 45%;
  max-width: 22rem;
  opacity: ${({ $opacity }) => ($opacity ? $opacity : 0.975)};
  padding: 1rem 0;
  position: relative;
  top: 30%;
  transform: translate(-115%, -18%);

  @media (max-height: 32.2em) {
    top: max(0px, calc(10% - (31.2em - 100vh) / 2));
    left: 0;
    transform: initial;
  }

  @media (max-width: 42em) {
    left: 0;
    min-height: 100vh;
    top: 0;
    transform: initial;
    max-width: 100vw;
  }

  & > * {
    max-width: 18rem;
  }
`

const Loading = styled.div`
  margin-top: 2rem;
  text-align: center;
`

function FrameBeforeApp({ loaded }: { loaded: boolean }) {
  const store = React.useContext(StoreContext)

  return (
    <CoverPageBackground>
      <FixedSizedPaper>
        {!loaded || (store.loggedInUser && !store.loggedInUser.user) ? (
          <Loading>
            <Typography style={{ marginBottom: '1rem' }} variant="h2">
              Loading
            </Typography>
            <CircularProgress />
          </Loading>
        ) : !store.loggedInUser ? (
          <LoginPage />
        ) : (
          <ChooseNickName />
        )}
      </FixedSizedPaper>
    </CoverPageBackground>
  )
}

export default observer(FrameBeforeApp)
