import React from 'react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import AssessmentIcon from '@mui/icons-material/Assessment'
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff'
import FlightLand from '@mui/icons-material/FlightLand'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import SearchIcon from '@mui/icons-material/Search'
import styled from 'styled-components'
import PageContentWrapper from '../../components/PageContentWrapper'
import { StoreContext } from '@sayr/client-models'
import {
  useGetArrivalRegsQuery,
  useGetDeparturesQuery,
  useGetGeneralPopulationQuery
} from '../../graphql/generated/typesAndHooks'
import dayjs from 'dayjs'

const StyledTeaser = styled(Button)`
  && {
    background-color: ${({ theme: { palette } }) =>
      palette.grey[palette.mode === 'dark' ? '800' : '100']};
    color: ${({ theme }) => theme.palette.grey['900']};
    flex: 1;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    display: grid;
    grid-template-columns: max-content 1fr;

    & .sub-label {
      font-size: 2rem;
    }
  }
`

const CustomPageContentWrapper = styled(PageContentWrapper).attrs({
  className: 'registrations-teasers-container'
})`
  grid-template-rows: unset;
  grid-auto-rows: max-content;

  @media (max-width: 36.5em) {
    grid-template-columns: 95%;
  }
`

const MainTeasers = styled.div.attrs({
  className: 'main-teasers'
})`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
  margin-bottom: 1rem;

  & > div {
    flex: 1;
    min-width: 15rem;
  }
`
const SubTeasers = styled(MainTeasers)`
  & > div {
    background-color: #97f7d7;
  }
`

function Teaser({
  label,
  subLabel,
  link,
  Icon
}: {
  label: string
  subLabel: string | React.ReactNode
  link: string
  Icon?: React.ElementType
}) {
  const store = React.useContext(StoreContext)
  if (subLabel instanceof String) return <div>String</div>
  return (
    <StyledTeaser
      onClick={() => store.view.openRegistrationsPage(link)}
      onKeyUp={e => {
        if (e.key === 'enter') store.view.openRegistrationsPage(link)
      }}
      tabIndex={0}
    >
      <div className="label">{label}</div>
      <div className="sub-label">{subLabel}</div>
      {Icon && (
        <Icon
          fontSize="large"
          style={{ gridColumn: '1/2', gridRow: '1/3', alignSelf: 'center' }}
        />
      )}
    </StyledTeaser>
  )
}

const Heading = styled(Typography).attrs({ variant: 'h4' })`
  text-align: left;
  text-transform: uppercase;

  && {
    margin-bottom: 0.5rem;
    margin-top: 2rem;
  }
`

function AdminRegistrationsView() {
  const store = React.useContext(StoreContext)
  const { data: dataTodayArrivals } = useGetArrivalRegsQuery({
    date: dayjs().format('YYYY-MM-DD')
  })
  const { data: dataTomorrowArrivals } = useGetArrivalRegsQuery({
    date: dayjs().add(1, 'day').format('YYYY-MM-DD')
  })

  const { data: dataTodayDepartures } = useGetDeparturesQuery({
    date: dayjs().format('YYYY-MM-DD')
  })

  const { data: dataTomorrowDepartures } = useGetDeparturesQuery({
    date: dayjs().add(1, 'day').format('YYYY-MM-DD')
  })

  const { data: dataPopulation } = useGetGeneralPopulationQuery({
    minimumDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
    maximumDate: dayjs().add(1, 'month').format('YYYY-MM-DD')
  })

  return (
    <CustomPageContentWrapper>
      <Heading>Arrivals</Heading>
      <MainTeasers>
        <Teaser
          label="Today"
          subLabel={
            dataTodayArrivals
              ? dataTodayArrivals.getArrivalRegs
                  .filter(a => a.fromRoom === '')
                  .length.toString()
              : '?'
          }
          link="arriving-today"
          Icon={FlightLand}
        />
        <Teaser
          label="Tomorrow"
          subLabel={
            dataTomorrowArrivals
              ? dataTomorrowArrivals.getArrivalRegs
                  .filter(a => a.fromRoom === '')
                  .length.toString()
              : '?'
          }
          link="arriving-tomorrow"
          Icon={FlightLand}
        />
      </MainTeasers>
      <Heading>Departures</Heading>
      <MainTeasers>
        <Teaser
          label="Today"
          subLabel={
            dataTodayDepartures
              ? dataTodayDepartures.getDepartures.length.toString()
              : '?'
          }
          link="departures-today"
          Icon={FlightTakeoffIcon}
        />
        <Teaser
          label="Tomorrow"
          subLabel={
            dataTomorrowDepartures
              ? dataTomorrowDepartures.getDepartures.length.toString()
              : '?'
          }
          link="departures-tomorrow"
          Icon={FlightTakeoffIcon}
        />
      </MainTeasers>

      <Heading>Reports</Heading>
      {(store.loggedInUser?.user?.$permissions?.accounting ||
        store.loggedInUser?.user?.$permissions?.generalPopulation) && (
        <>
          <SubTeasers>
            {store.loggedInUser?.user?.$permissions?.accounting && (
              <Teaser
                label="Cash Flow Report"
                subLabel=""
                link="cash-flow-report"
                Icon={MonetizationOnIcon}
              />
            )}
          </SubTeasers>
          <SubTeasers>
            {store.loggedInUser?.user?.$permissions?.generalPopulation && (
              <Teaser
                label="Population Report"
                subLabel={
                  dataPopulation ? (
                    <>
                      <Tooltip title="Last night/this morning's count">
                        <span>
                          {dataPopulation.getGeneralPopulation
                            .find(
                              a =>
                                a.date ===
                                dayjs().subtract(1, 'day').format('YYYY-MM-DD')
                            )
                            ?.total.toString() || '?'}
                        </span>
                      </Tooltip>
                      <span> → </span>
                      <Tooltip title="tonight/tomorrow morning's count">
                        <span>
                          {dataPopulation.getGeneralPopulation
                            .find(a => a.date === dayjs().format('YYYY-MM-DD'))
                            ?.total.toString() || '?'}
                        </span>
                      </Tooltip>
                      <span> → </span>
                      <Tooltip title="tomorrow night's count">
                        <span>
                          {dataPopulation.getGeneralPopulation
                            .find(
                              a =>
                                a.date ===
                                dayjs().add(1, 'day').format('YYYY-MM-DD')
                            )
                            ?.total.toString() || '?'}
                        </span>
                      </Tooltip>
                    </>
                  ) : (
                    '?'
                  )
                }
                link="population-report"
                Icon={AssessmentIcon}
              />
            )}
          </SubTeasers>
        </>
      )}
      {(store.loggedInUser?.user?.$permissions?.adminFollowUpRead ||
        store.loggedInUser?.user?.$permissions?.adminFollowUpWrite) && (
        <>
          <Heading>Follow Ups</Heading>
          <SubTeasers>
            <Teaser
              label="Follow up on arrivals"
              subLabel=""
              link="follow-up-on-arrivals"
              Icon={SearchIcon}
            />
            <Teaser
              label="Follow up on in-house registrations"
              subLabel=""
              link="follow-up-on-in-house"
              Icon={SearchIcon}
            />
          </SubTeasers>
        </>
      )}
      <>
        <Heading>Covid Tests</Heading>
        <SubTeasers>
          <Teaser
            label="Covid Tests Calendar View"
            subLabel=""
            link="covid-test-calendar-view"
            Icon={SearchIcon}
          />
        </SubTeasers>
      </>
    </CustomPageContentWrapper>
  )
}

export default AdminRegistrationsView
