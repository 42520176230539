// @ts-nocheck
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { QueryBuilder } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { CovidTestTypeEnumType } from './CovidTestTypeEnum'
import { ProgramTypesEnumType } from './ProgramTypesEnum'
import { RootStoreType } from './index'

/**
 * SingleTestResponseBase
 * auto generated base class for the model SingleTestResponseModel.
 */
export const SingleTestResponseModelBase = ModelBase.named('SingleTestResponse')
  .props({
    __typename: types.optional(
      types.literal('SingleTestResponse'),
      'SingleTestResponse'
    ),
    id: types.identifier,
    date: types.union(types.undefined, types.string),
    regId: types.union(types.undefined, types.integer),
    type: types.union(types.undefined, types.null, CovidTestTypeEnumType),
    freeOfCharge: types.union(types.undefined, types.boolean),
    chargedInTransaction: types.union(
      types.undefined,
      types.null,
      types.integer
    ),
    chargedAmount: types.union(types.undefined, types.number),
    outcome: types.union(types.undefined, types.null, types.boolean),
    stayCapacity: types.union(
      types.undefined,
      types.null,
      ProgramTypesEnumType
    ),
    confirmedByGuest: types.union(types.undefined, types.boolean),
    notes: types.union(types.undefined, types.string)
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class SingleTestResponseModelSelector extends QueryBuilder {
  get id() {
    return this.__attr(`id`)
  }
  get date() {
    return this.__attr(`date`)
  }
  get regId() {
    return this.__attr(`regId`)
  }
  get type() {
    return this.__attr(`type`)
  }
  get freeOfCharge() {
    return this.__attr(`freeOfCharge`)
  }
  get chargedInTransaction() {
    return this.__attr(`chargedInTransaction`)
  }
  get chargedAmount() {
    return this.__attr(`chargedAmount`)
  }
  get outcome() {
    return this.__attr(`outcome`)
  }
  get stayCapacity() {
    return this.__attr(`stayCapacity`)
  }
  get confirmedByGuest() {
    return this.__attr(`confirmedByGuest`)
  }
  get notes() {
    return this.__attr(`notes`)
  }
}
export function selectFromSingleTestResponse() {
  return new SingleTestResponseModelSelector()
}

export const singleTestResponseModelPrimitives =
  selectFromSingleTestResponse().date.regId.type.freeOfCharge
    .chargedInTransaction.chargedAmount.outcome.stayCapacity.confirmedByGuest
    .notes
