import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Breadcrumbs,
  ConnectedPages,
  Figure,
  Heading2,
  Heading3,
  Heading4,
  Hr,
  Image,
  InfoContainer,
  InfoLink,
  InfoPageLayout,
  LayoutSeparator,
  Link,
  Paragraph,
  Section
} from '../../../components/InfoTypography'
import styled from 'styled-components'

const SideBySideText = styled.div`
  display: grid;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: start;
  grid-template-columns: repeat(auto-fit, minmax(min-content, 19rem));
  justify-content: space-between;
  line-height: 1.5;
`

export function InfoInvocationsAndMantras() {
  return (
    <InfoContainer style={{ wordBreak: 'break-word' }}>
      <Figure>
        <Image
          src="/images/swami-swaroopananda-prayer.jpg"
          alt="Swami Swaroopananda Prayer"
        />
      </Figure>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <InfoLink target="">Information</InfoLink>
        <InfoLink target="practice-guide">Practice Guide</InfoLink>
        <Link $disabled>Invocations and Mantras</Link>
      </Breadcrumbs>
      <Heading2>Invocations and Mantras</Heading2>
      <Hr />
      <InfoPageLayout className="invocation">
        <Section>
          <Heading3>Invocation at the Beginning of a class</Heading3>
          <Paragraph>
            At the beginning of any class, lecture, or even personal sadhana, we
            chant the Dhyāna Ślokas. This helps to tune the mind to the divine
            in its different aspects.
          </Paragraph>
          <Heading4 className="text-sanskrit">Dhyāna Ślokas</Heading4>
          <SideBySideText className="text-sanskrit">
            <div>
              gajānanaṃ bhūtagaṇādi sevitaṃ
              <br /> kapittha jambū phala sāra bhakṣitam <br />
              umā sutaṃ śoka vināśa kāraṇaṃ <br />
              namāmi vighneśvara pādapaṅkajam
            </div>
            <div>
              गजाननं भूतगणादि सेवितं <br />
              कपित्थ जम्बू फल सार भक्षितम् <br />
              उमा सुतं शोक विनाश कारणं
              <br /> नमामि विघ्नेश्वर पादपङ्कजम् <br />
            </div>
            <div>
              ṣaḍānanaṃ kuṅkuma rakta varṇaṃ
              <br /> mahāmatiṃ divyamayūravāhanam <br /> rudrasya sūnuṃ
              surasainyanāthaṃ <br /> guhaṃ sadāhaṃ śaraṇaṃ prapadye
            </div>
            <div>
              षडाननं कुङ्कुम रक्त वर्णं <br /> महामतिं दिव्यमयूरवाहनम् <br />
              रुद्रस्य सूनुं सुरसैन्यनाथं <br /> गुहं सदाहं शरणं प्रपद्ये
            </div>
            <div>
              yā kundendu tuṣāra hāra dhavalā
              <br /> yā śubhra vastrāvṛtā <br /> yā vīṇā vara daṇḍa maṇḍita karā
              <br /> yā śveta padmāsanā
              <br /> yā brahmācyuta śaṅkara prabhṛtibhir <br /> devaiḥ sadā
              pūjitā
              <br /> sā māṃ pātu sarasvatī
              <br /> bhagavatī niḥśeṣa jāḍyāpahā
            </div>
            <div>
              या कुन्देन्दु तुषार हार धवला
              <br /> या शुभ्र वस्त्रावृता
              <br /> या वीणा वर दण्ड मण्डित करा
              <br /> या श्वेत पद्मासना
              <br /> या ब्रह्माच्युत शङ्कर प्रभृतिभिर्
              <br /> देवैः सदा पूजिता <br /> सा मां पातु सरस्वती भगवती
              <br /> निःशेष जाड्यापहा
            </div>
            <div>
              om namaḥ śivāya gurave
              <br /> saccidānanda mūrtaye <br /> niṣprapañcāya śāntāya <br />
              śrī śivānandāya te namaḥ
              <br /> śrī viṣṇu devānandāya te namaḥ
            </div>
            <div>
              ॐ नमः शिवाय गुरवे <br /> सच्चिदानन्द मूर्तये <br /> निष्प्रपञ्चाय
              शान्ताय
              <br /> श्री शिवानन्दाय ते नमः <br /> श्री विष्णु देवानन्दाय ते नमः
            </div>
            <div>
              oṃ sarva maṅgala māṅgalye
              <br /> śive sarvārtha sādhike
              <br /> śaraṇye tryambake gauri
              <br /> nārāyaṇi namo'stu te
              <br /> nārāyaṇi namo'stu te
            </div>
            <div>
              ॐ सर्व मङ्गल माङ्गल्ये
              <br /> शिवे सर्वार्थ साधिके
              <br /> शरण्ये त्र्यम्बके गौरि
              <br /> नारायणि नमोऽस्तु ते
              <br /> नारायणि नमोऽस्तु ते
            </div>
          </SideBySideText>
          <br />
          <Heading3>Mantras at the end of the class</Heading3>
          <Paragraph>
            The Mahāmṛtyuñjaya Mantra is a life-giving Mantra. In these days,
            when life is very complex, accidents are an everyday affair;
            <br /> this Mantra wards off deaths by snakebite, lightening, motor
            accidents, fire, cycle, water, air accidents and accidents of all
            kinds.
            <br /> Besides, it has a great curative effect for diseases. It
            should be repeated before any travel.
          </Paragraph>
          <Paragraph>
            Following the Mahāmṛtyuñjaya Mantra, we chant various Śānti Mantras
            from the vedas.
          </Paragraph>
          <Heading4 className="text-sanskrit">Mahāmṛtyuñjaya Mantra</Heading4>
          <SideBySideText className="text-sanskrit">
            <div>
              oṃ tryámbakaṃ yajāmahe
              <br /> sugandhíṃ puṣṭi-vardhánam ।
              <br />
              urvārukam íva bandhánān
              <br /> mṛtyor mukṣīya mā'mṛtā́t ॥
            </div>
            <div>
              ॐ त्र्य॑म्बकं यजामहे
              <br /> सु॒गन्धिं॑ पुष्टि॒वर्ध॑नम् ।<br />
              उ॒र्वा॒रु॒कमि॑व॒ बन्ध॑नान्
              <br /> मृ॒त्योर्मु॑क्षीय॒ माऽमृता॑॑त् ॥
            </div>
          </SideBySideText>
          <Heading4 className="text-sanskrit">Śānti Mantras</Heading4>
          <SideBySideText className="text-sanskrit">
            <div>
              oṃ sarveṣāṃ svastir bhavatu ।<br />
              sarveṣāṃ śāntir bhavatu ।<br />
              sarveṣāṃ pūrṇaṃ bhavatu ।<br />
              sarveṣāṃ maṅgalaṃ bhavatu ॥<br />
            </div>
            <div>
              ॐ सर्वेषाऺ स्वस्तिर्भवतु ।<br />
              सर्वेषाऺ शान्तिर्भवतु ।<br />
              सर्वेषाऺ पूर्णं भवतु ।<br />
              सर्वेषाऺ मङ्गलं भवतु ॥
            </div>
            <div>
              sarve bhavantu sukhinaḥ ।
              <br />
              sarve santu nirāmayāḥ ।<br />
              sarve bhadrāṇi paśyantu ।<br />
              mā kaścit duḥkha bhāgbhavet ॥
            </div>
            <div>
              सर्वे भवन्तु सुखिनः ।<br />
              सर्वे सन्तु निरामयाः ।<br />
              सर्वे भद्राणि पश्यन्तु ।<br />
              मा कश्चित् दुःख भाग्भवेत् ॥
            </div>
            <div>
              oṃ asato mā sad gamaya ।
              <br />
              tamaso mā jyotir gamaya ।
              <br />
              mṛtyor mā 'mṛtaṃ gamaya ॥
            </div>
            <div>
              ॐ असतो मा सद्गमय ।<br />
              तमसो मा ज्योतिर्गमय ।<br />
              मृत्योर्माऽमृतं गमय ॥<br />
            </div>
            <div>
              oṃ pūrṇamadaḥ pūrṇamidam
              <br /> pūrṇāt pūrṇam udacyate ।
              <br />
              pūrṇasya pūrṇam ādāya
              <br /> pūrṇam evāvaśiṣyate ॥
            </div>
            <div>
              ॐ पूर्णमदः पूर्णमिदम्
              <br /> पूर्णात् पूर्णमुदच्यते ।<br />
              पूर्णस्य पूर्णमादाय <br />
              पूर्णमेवावशिष्यते ॥
            </div>

            <div>oṃ śāntiḥ śāntiḥ śāntiḥ ॥</div>
            <div>ॐ शान्तिः शान्तिः शान्तिः ॥</div>
            <br />
          </SideBySideText>

          <Heading4>Universal Prayer by Swami Sivananda</Heading4>
          <Paragraph>
            O Adorable Lord of Mercy and Love!
            <br />
            Salutations and prostrations unto Thee.
            <br />
            Thou art Omnipresent, Omnipotent and Omniscient.
            <br />
            Thou art Satchidananda (Existence-Consciousness-Bliss Absolute).
            <br />
            Thou art the Indweller of all beings.
            <br />
            <br />
            Grant us an understanding heart,
            <br />
            Equal vision, balanced mind,
            <br />
            Faith, devotion and wisdom.
            <br />
            Grant us inner spiritual strength
            <br />
            To resist temptations and to control the mind.
            <br />
            Free us from egoism, lust, greed, hatred, anger and jealousy.
            <br />
            Fill our hearts with divine virtues.
            <br />
            <br />
            Let us behold Thee in all these names and forms.
            <br />
            Let us serve Thee in all these names and forms.
            <br />
            Let us ever remember Thee.
            <br />
            Let us ever sing Thy glories.
            <br />
            Let Thy Name be ever on our lips.
            <br />
            Let us abide in Thee for ever and ever.
          </Paragraph>
        </Section>
        <ConnectedPages>
          <LayoutSeparator />
          <Section>
            <Paragraph>
              <strong>Connected Articles:</strong>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/meditation">Meditation</InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/breathing-techniques">
                Breathing Techniques
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/asanas">Asanas</InfoLink>
            </Paragraph>
          </Section>
        </ConnectedPages>
      </InfoPageLayout>
    </InfoContainer>
  )
}
