import React from 'react'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import styled from 'styled-components'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak'
import GpsFixedIcon from '@mui/icons-material/GpsFixed'

import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { rgba } from 'polished'
import { useMediaQuery } from '@mui/material'

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const CustomStyleWrapper = styled.div<{ $smallSize: boolean }>`
  & .tools {
    align-items: justify;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: 100%;
    justify-content: center;
    position: absolute;
    right: 2rem;
    top: 0;
  }

  svg.map {
    height: calc(100vh - 64px) !important;
    width: calc(
      100vw ${({ $smallSize }) => !$smallSize && ' - 8rem'}
    ) !important;
  }
`

const StyledButton = styled(Button)`
  && {
    background-color: ${({ theme }) => rgba(theme.palette.primary.main, 0.8)};
  }
`

const AshramMapComponent = React.lazy(() => import(`./MapComponent`))
console.log(AshramMapComponent)
export function AshramMap() {
  const $smallSize = useMediaQuery('(max-width: 48em)')

  return (
    <React.Suspense
      fallback={
        <Center>
          <CircularProgress />
        </Center>
      }
    >
      <CustomStyleWrapper $smallSize={$smallSize}>
        <TransformWrapper initialScale={1} initialPositionY={0} centerOnInit>
          {({ zoomIn, zoomOut, resetTransform, centerView }) => (
            <React.Fragment>
              <TransformComponent>
                <AshramMapComponent className="map" />
              </TransformComponent>
              <div className="tools">
                <StyledButton
                  onClick={() => zoomIn()}
                  variant="contained"
                  color="primary"
                >
                  <ZoomInIcon />
                </StyledButton>
                <StyledButton
                  onClick={() => zoomOut()}
                  variant="contained"
                  color="primary"
                >
                  <ZoomOutIcon />
                </StyledButton>
                <StyledButton
                  onClick={() => resetTransform()}
                  variant="contained"
                  color="primary"
                >
                  <CenterFocusWeakIcon />
                </StyledButton>
                <StyledButton
                  onClick={() => centerView()}
                  variant="contained"
                  color="primary"
                >
                  <GpsFixedIcon />
                </StyledButton>
              </div>
            </React.Fragment>
          )}
        </TransformWrapper>
      </CustomStyleWrapper>
    </React.Suspense>
  )
}
