import React from 'react'
import { StoreContext } from '@sayr/client-models'
import {
  Breadcrumbs,
  Heading2,
  Heading3,
  Heading4,
  Paragraph,
  Link,
  InfoContainer,
  UnorderedList,
  OrderedList,
  ListItem,
  EmbeddedVideo,
  Image,
  Section,
  Figure,
  Hr,
  InfoPageLayout,
  LayoutSeparator,
  ConnectedPages,
  TipsFrame,
  InfoLink
} from '../../../components/InfoTypography'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  PrimaryButton,
  SecondaryButton
} from '../../../components/common/Buttons'
import { rgba } from 'polished'

export function InfoBeforeArrival() {
  const store = React.useContext(StoreContext)

  return (
    <InfoContainer>
      <Figure>
        <Image src="/images/airport-travel.jpg" alt="Airport Travel" />
        <figcaption>
          Photo by{' '}
          <Link href="https://unsplash.com/@lfcsfilipesousa?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
            L.Filipe C.Sousa
          </Link>{' '}
          on{' '}
          <Link href="https://unsplash.com/s/photos/airport?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
            Unsplash
          </Link>
        </figcaption>
      </Figure>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <InfoLink target="">Information</InfoLink>
        <InfoLink target="trip-logistics">Trip Logistics</InfoLink>
        <Link $disabled>Before Arrival</Link>
      </Breadcrumbs>
      <Heading2>
        Before Arrival - Everything You Need to Know Prior to Your Arrival to
        the Ashram
      </Heading2>
      <Hr $color="#92d3e3" />
      <InfoPageLayout>
        <Section>
          <Heading3>Before Purchasing the Flight Ticket</Heading3>
          <Paragraph>
            Take a look at the{' '}
            <Link
              href="https://www.bahamas.com/plan-your-trip/island-faq/visa-immigration"
              target="_blank"
              rel="noopener"
            >
              Bahamas Government official travel requirements
            </Link>{' '}
            for Visa requirements and for{' '}
            <Link
              href="https://www.bahamas.com/tourism-reopening"
              target="_blank"
              rel="noopener"
            >
              COVID 19 procedure
            </Link>
            .
          </Paragraph>
          <Heading3>Flight</Heading3>
          <Paragraph>Fly in to Nassau International Airport.</Paragraph>
          <br />
          <PrimaryButton
            onClick={() =>
              window
                .open(
                  'https://nassaulpia.com/flight-info/airlines-serving-lpia/',
                  '_blank'
                )
                ?.focus()
            }
          >
            Airport Website
          </PrimaryButton>
          <SecondaryButton
            onClick={() =>
              window
                .open(
                  'https://nassaulpia.com/flight-info/airlines-serving-lpia/',
                  '_blank'
                )
                ?.focus()
            }
          >
            Available Airlines
          </SecondaryButton>
          <Heading3>From the Airport to the Ashram</Heading3>
          <Paragraph>
            After clearing your belongings and immigration, please chat with us
            to let us know you have arrived, then please take a taxi to{' '}
            <strong>Elizabeth avenue &amp; Bay Street</strong>. The Ashram Boat
            will be waiting for you at the dock, to take you directly to the
            Ashram.
          </Paragraph>
          <Heading4>Steps of Communication:</Heading4>
          <OrderedList>
            <ListItem>
              Contact us once you have collected your bags, just before taking
              the taxi.
              <UnorderedList>
                <ListItem>
                  Using the{' '}
                  <Link onClick={() => store.view.openChatPage()}>
                    Chat option
                  </Link>
                </ListItem>
                <ListItem>
                  At our main phone number:{' '}
                  <Link href="tel:+1-416-479-0199">416-479-0199</Link>
                </ListItem>
                <ListItem>
                  Using Whatsapp:{' '}
                  <Link href="https://wa.me/12424266780">+1-242-426-6780</Link>
                </ListItem>
                <ListItem>
                  Local call: <Link href="tel:12423632902">363-2902</Link>
                </ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>We will schedule a boat to wait for you.</ListItem>
            <ListItem>
              From <strong>Elizabeth avenue &amp; Bay Street</strong>, walk to
              the dock, and catch our boat.
              <br /> See for yourself:
            </ListItem>
          </OrderedList>
          <br />
          <EmbeddedVideo src="https://www.youtube.com/embed/RUdTwEINgc4" />
          <Heading3>Taxi Information</Heading3>
          <UnorderedList>
            <ListItem>It takes about 30 minutes.</ListItem>
            <ListItem>
              Taxi cost ranges between $35-40, or up to $60 for three.
            </ListItem>
            <ListItem>
              Make sure to confirm the cost before entering the taxi.
            </ListItem>
            <ListItem>
              All taxis accept US Dollars and Bahamian Dollars. Most taxis
              accept cash only.
            </ListItem>
          </UnorderedList>
          <br />
          <Paragraph>
            See{' '}
            <Link
              target="_blank"
              href="https://www.google.com/maps/dir/Lynden+Pindling+International+Airport,+Windsor+Field+Road,+Nassau/Elizabeth+Ave,+Nassau/@25.0621664,-77.436674,13z/data=!4m14!4m13!1m5!1m1!1s0x892f63fc3dabbc03:0x350924fa73534238!2m2!1d-77.4655212!2d25.0439288!1m5!1m1!1s0x892f7d067c4dfead:0x8283687988163c58!2m2!1d-77.3377902!2d25.0785235!3e0!5m1!1e2"
            >
              Route from Airport to Elizabeth on Bay
            </Link>
            .
          </Paragraph>
          <Heading3>Boat Information</Heading3>
          <Paragraph>
            The boatman will meet you at the dock (near the bench as you can see
            from the video) and the boat ride is about 2 minutes long.
          </Paragraph>
          <Paragraph>
            <strong>Please note:</strong> Wait for the boatman to confirm the
            boat is ready before you climb unto the boat.
          </Paragraph>
          <TipsFrame $color={rgba('#92d3e3', 0.4)}>
            <Heading3>Tips for your travel:</Heading3>
            <Paragraph>
              You might not have Internet service after leaving the airport.
            </Paragraph>
            <br />
            <OrderedList>
              <ListItem>
                Watch the video above ahead of your travels to know how to find
                our dock.
              </ListItem>
              <ListItem>
                Communicate before leaving the airport and make sure we
                acknowledged your message before taking the taxi.
              </ListItem>
            </OrderedList>
          </TipsFrame>
        </Section>
        <ConnectedPages>
          <LayoutSeparator />
          <Section>
            <Paragraph>
              <strong>Connected Articles:</strong>
            </Paragraph>
            <Paragraph>
              <Link
                onClick={() =>
                  store.view.openInfoPage('trip-logistics/packing-necessities')
                }
              >
                Packing Necessities?
              </Link>
            </Paragraph>
            <Paragraph>
              <Link>The Check-in Process</Link>
            </Paragraph>
            <Paragraph>
              <Link>COVID 19 protocols</Link>
            </Paragraph>
          </Section>
        </ConnectedPages>
      </InfoPageLayout>
    </InfoContainer>
  )
}
