// @ts-nocheck
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from 'mobx-state-tree'
import { MSTGQLRef, QueryBuilder, withTypedRefs } from 'mst-gql'
import { ModelBase } from './ModelBase'
import { ChatResponseModel, ChatResponseModelType } from './ChatResponseModel'
import { ChatResponseModelSelector } from './ChatResponseModel.base'
import { RootStoreType } from './index'

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  node: ChatResponseModelType
}

/**
 * ChatEdgeBase
 * auto generated base class for the model ChatEdgeModel.
 */
export const ChatEdgeModelBase = withTypedRefs<Refs>()(
  ModelBase.named('ChatEdge')
    .props({
      __typename: types.optional(types.literal('ChatEdge'), 'ChatEdge'),
      node: types.union(
        types.undefined,
        MSTGQLRef(types.late((): any => ChatResponseModel))
      ),
      /** Used in `before` and `after` args */
      cursor: types.union(types.undefined, types.string)
    })
    .views(self => ({
      get store() {
        return self.__getStore<RootStoreType>()
      }
    }))
)

export class ChatEdgeModelSelector extends QueryBuilder {
  get cursor() {
    return this.__attr(`cursor`)
  }
  node(
    builder:
      | string
      | ChatResponseModelSelector
      | ((selector: ChatResponseModelSelector) => ChatResponseModelSelector)
      | undefined
  ) {
    return this.__child(`node`, ChatResponseModelSelector, builder)
  }
}
export function selectFromChatEdge() {
  return new ChatEdgeModelSelector()
}

export const chatEdgeModelPrimitives = selectFromChatEdge().cursor
