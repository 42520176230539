import { ProgramTypes } from '../@types/ProgramTypes'

export const allLodgingProgramTypes = [
  ProgramTypes.ATTC,
  ProgramTypes.BABIES,
  ProgramTypes.CHILDREN,
  ProgramTypes.KARMA_YOGA,
  ProgramTypes.SPEAKER,
  ProgramTypes.TTC,
  ProgramTypes.VISITING_STAFF,
  ProgramTypes.VACATION
]
