import React from 'react'

import Checkbox from '@mui/material/Checkbox'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { StoreContext } from '@sayr/client-models'
import {
  Breadcrumbs,
  Heading2,
  Heading3,
  Heading4,
  Paragraph,
  Link,
  InfoContainer,
  Image,
  Section,
  Figure,
  Hr,
  InfoPageLayout,
  LayoutSeparator,
  ConnectedPages,
  TipsFrame,
  UnorderedList,
  ListItem,
  InfoLink
} from '../../../components/InfoTypography'
import styled from 'styled-components'
import { FormControlLabel, FormGroup } from '@mui/material'

const CheckList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 17rem);
  row-gap: 1rem;
`

export function InfoPackingNecessities() {
  const store = React.useContext(StoreContext)
  return (
    <InfoContainer>
      <Figure>
        <Image src="/images/suitcases.jpg" alt="Packing for trip" />
        <figcaption>
          Photo by{' '}
          <Link href="https://unsplash.com/@cselfors?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
            Caroline Selfors
          </Link>{' '}
          on{' '}
          <Link href="https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
            Unsplash
          </Link>
        </figcaption>
      </Figure>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <InfoLink target="">Information</InfoLink>
        <InfoLink target="trip-logistics">Trip Logistics</InfoLink>
        <Link $disabled>Packing Necessities</Link>
      </Breadcrumbs>
      <Heading2>Packing Necessities</Heading2>
      <Hr />
      <InfoPageLayout>
        <Section>
          <Heading3>Clothing</Heading3>
          <Paragraph>
            As the ashram is a spiritual community and study center, we ask that
            clothing be respectable and modest. <br />
            Guests are invited to dress in casual, comfortable clothes suitable
            for warm days and cool evenings.
          </Paragraph>
          <Paragraph>
            Around the ashram, summer attire is completely appropriate,
            including shorts, skirts, and summer tops (although halter tops, low
            necklines, and short shorts and mini-skirts are not).
            <br /> Bathing suits are worn on the beach; please wear a cover-up
            on the way to and from the beach.
          </Paragraph>
          <Paragraph>
            In satsang and the temples, knees and shoulders should be covered;
            we recommend bringing a meditation shawl.
          </Paragraph>
          <Heading3>Other items to pack:</Heading3>
          <CheckList>
            <MyCheckBox label="Yoga mat" />
            <MyCheckBox label="Beach Towel" />
            <MyCheckBox label="Meditation cushion" />
            <MyCheckBox label="Toiletries" />
            <MyCheckBox label="Slip-on sandals" />
            <MyCheckBox label="Rainwear" />
            <MyCheckBox label="Flashlight" />
            <MyCheckBox label="Insect repellent" />
            <MyCheckBox label="Alarm clock" />
            <MyCheckBox label="Insulated travel mug" />
            <MyCheckBox label="Water bottle" />
            <MyCheckBox label="Sunscreen" />
          </CheckList>
          <FormGroup></FormGroup>
          <br />
          <Paragraph style={{ marginBottom: '0.5rem' }}>
            <strong>And maybe even</strong>:
          </Paragraph>
          <CheckList>
            <MyCheckBox label="Snorkeling equipment" />
            <MyCheckBox label="Nuts and energy bars" />
          </CheckList>
          <br />
          <br />
          <Paragraph>
            <strong>Please note:</strong> We do not allow bringing any type of
            meat, alcohol or recreational drugs into the ashram premises.
          </Paragraph>
          <Heading3>In case you are taking a Tent Space</Heading3>
          <Paragraph>Bring your own tent, bedding, and towels.</Paragraph>
          <Heading3>What’s Available On-Site</Heading3>
          <Heading4>Meals</Heading4>
          <Paragraph>
            We serve two delicious vegetarian meals a day, prepared with love
            and care according to yogic dietary principles. The meals are served
            at 9:45 am and at 5:45 pm.
            <br /> Both brunch and dinner offer nourishing vegetable, grain,
            and/or bean dishes as well as hearty soups.
          </Paragraph>
          <Paragraph>
            Brunch also includes fruit, homemade granola, and freshly made
            yogurt. Raw fixings to make your own salad are offered at both
            meals, as well as our own baked bread. We also serve a daily herbal
            tea selection.
          </Paragraph>
          <Paragraph>
            In accordance with a yogic diet, absent from the menu are meat,
            fish, fowl, eggs, garlic, onions, and caffeinated tea and coffee.
          </Paragraph>
          <Heading4>Snacks</Heading4>
          <Paragraph>
            Our Health Hut kitchen offers fresh-baked goods for purchase, as
            well as smoothies, frozen fruit bars, and other selected treats. Dry
            snack foods, such as nuts, energy bars, and chips are also available
            for purchase.
          </Paragraph>
          <Heading4>Specialty Items</Heading4>
          <Paragraph>
            The Boutique and Bookstore contains an extensive selection of
            spiritual books and CDs, clothing for yoga classes, yoga mats,
            meditation cushions, other yoga-related items, gifts, and spiritual
            paintings. Certain food supplements, toiletries, notebooks and pens
            are also available.
            <Image
              src="https://media-cdn.tripadvisor.com/media/photo-s/0d/ee/d8/3a/wellness-boutique.jpg"
              alt="Boutique &amp; Health Hut"
            />
          </Paragraph>
          <TipsFrame $color="#eee">
            <Heading3>Tips:</Heading3>
            <img
              style={{
                float: 'right',
                width: '10rem',
                borderRadius: '0.5rem',
                marginLeft: '.1rem'
              }}
              src="/images/220px-NEMA-AC-Power-Plugs.png"
              alt="NEMA connector"
            />
            <UnorderedList>
              <ListItem>
                The electricity system in the Bahamas is the same as in the
                United States. 120V, 60Hz.
                <br />
                <br />
                Consider getting a travel adapter if you need one.
              </ListItem>
              <ListItem>
                There is free WiFi service throughout the ashram, but it is
                turned off during the Satsangs (6:00-7:30 am and 8:00-9:45 pm).
              </ListItem>
              <ListItem>
                You can bring your own Covid test kits. Otherwise, using our
                test kits will incur extra charges.
              </ListItem>
            </UnorderedList>
          </TipsFrame>
        </Section>
        <ConnectedPages>
          <LayoutSeparator />
          <Section>
            <Paragraph>
              <strong>Connected Articles:</strong>
            </Paragraph>
            <Paragraph>
              <Link
                onClick={() =>
                  store.view.openInfoPage('trip-logistics/before-arrrival')
                }
              >
                Before Arrival
              </Link>
            </Paragraph>
            <Paragraph>
              <Link
                onClick={() =>
                  store.view.openInfoPage('trip-logistics/check-in-process')
                }
              >
                The Check-in Process
              </Link>
            </Paragraph>
            <Paragraph>
              <Link>COVID 19 protocols</Link>
            </Paragraph>
          </Section>
        </ConnectedPages>
      </InfoPageLayout>
    </InfoContainer>
  )
}

function MyCheckBox({ label }: { label: string }) {
  const [value, setValue] = React.useState(false)

  React.useEffect(() => {
    if (
      localStorage.getItem(
        `/info/trip-logistics/packing-necessities/${label}`
      ) === 'true'
    )
      setValue(true)
  }, [label])

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={value}
          onChange={e => {
            setValue(e.target.checked)
            localStorage.setItem(
              `/info/trip-logistics/packing-necessities/${label}`,
              e.target.checked.toString()
            )
          }}
        />
      }
      label={label}
    />
  )
}
