import React, { ReactEventHandler } from 'react'
import styled from 'styled-components'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import MuiBreadcrumbs from '@mui/material/Breadcrumbs'
import MuiLink from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import PageContentWrapper from './PageContentWrapper'
import { StoreContext } from '@sayr/client-models'

const minTeaserWidth = '21.25rem'
const maxTeasersInRow = 3
const gridGap = '2rem'

const StyledTeaserContainer = styled(PageContentWrapper).attrs({
  className: 'info-container'
})`
  grid-template-columns: repeat(
    auto-fit,
    minmax(min-content, ${minTeaserWidth})
  );
  justify-content: center;
  grid-gap: ${gridGap};
  grid-template-rows: unset;
  grid-auto-rows: max-content;
  align-items: stretch;
  font-size: 11.5pt;

  padding: 2rem
    calc(
      (
          100% -
            calc(
              ${minTeaserWidth} * ${maxTeasersInRow} + ${gridGap} *
                ${maxTeasersInRow - 1}
            )
        ) / 2
    ); // set padding to limit the number of items in a row, while maintaining the vertical scroller on the far right.
  // the calculation of the padding is the whole width, subtracting the items and the gaps, dividing by 2 (for the left and right paddings)
  // if this would be done in grid-template-columns, the vertical scroller would be misplaced.
  margin-bottom: 2rem;

  @media (max-width: 69em) {
    padding: 2rem 5%;
  }

  @media (max-width: 55em) {
    padding: 2rem;
  }

  @media (max-width: 28em) {
    padding: 2rem 0.4rem;
  }
`

export function StyledPageContentWrapper({
  children
}: {
  children: React.ReactNode
}) {
  return (
    <StyledTeaserContainer>
      {children}
      <div style={{ gridColumn: '1 / -1' }}>
        <br />
      </div>
    </StyledTeaserContainer>
  )
}

const CenteringFrame = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
`

const StyledInfoContainer = styled.div.attrs({ tabIndex: 0 })`
  padding: 2rem 0;
  width: min(
    100%,
    calc(
      ${minTeaserWidth} * ${maxTeasersInRow} + ${gridGap} *
        ${maxTeasersInRow - 1}
    )
  );
  margin: auto;
  color: ${({ theme }) => theme.palette.grey['700']};
  font-size: 11.5pt;

  & strong {
    font-weight: 500;
  }

  & p {
    font-size: inherit;
  }

  .text-sanskrit {
    font-family: Serif;

    p {
      margin-top: 0;
    }
  }

  p .text-sanskrit {
    font-size: 12pt;
  }

  .invocation .text-sanskrit {
    font-size: 15pt;
  }
`

export function InfoContainer({
  children,
  ...props
}: {
  children: React.ReactNode
} & Omit<React.HTMLAttributes<HTMLOrSVGElement>, 'tabIndex'>) {
  return (
    <CenteringFrame>
      <StyledInfoContainer {...props}>
        {children}
        <br />
        <br />
      </StyledInfoContainer>
    </CenteringFrame>
  )
}

export const StyledCard = styled(Card)`
  && {
    overflow: visible;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`

export const StyledCardMedia = styled(CardMedia)<{
  $backgroundPosition?: string
  $backgroundSize?: string
}>`
  && {
    width: 100%;
    height: 148px;
    background-size: ${({ $backgroundSize }) => $backgroundSize || 'cover'};
    background-position: ${({ $backgroundPosition }) =>
      $backgroundPosition || 'top'};
  }
`

export const SVGCardMedia = styled(StyledCardMedia).attrs(({ className }) => ({
  className
}))`
  && {
    background-size: max(9rem, 30%);
  }

  &.covid-jagadambika {
    background-size: cover;
    background-position: bottom;
  }
`

export function MediaCard({
  topic,
  description,
  action,
  children,
  disabled
}: {
  topic: string
  description: string
  action?: ReactEventHandler
  children: React.ReactNode
  disabled?: boolean | undefined
}) {
  return (
    <StyledCard>
      <CardActionArea disabled={disabled} onClick={action}>
        {children}
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {topic}
          </Typography>
          <Typography
            variant="body2"
            color={disabled ? 'GrayText' : 'textSecondary'}
            component="p"
          >
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          disabled={disabled}
          size="small"
          color="primary"
          onClick={action}
        >
          Learn More
        </Button>
      </CardActions>
    </StyledCard>
  )
}

export const Article = styled(PageContentWrapper).attrs({
  className: 'scrollable'
})`
  && {
    align-items: end;
    box-sizing: content-box;
    margin-top: 1rem;
    overflow-y: scroll;

    @media (max-width: 40em) {
      grid-template-columns: 90%;
    }
  }
`

export const Section = styled.section`
  margin-top: 1rem;
`

export const Heading1 = styled(Typography).attrs({ variant: 'h1' })`
  && {
    margin: 1rem 0 0.6rem;
  }
`

export const Heading2 = styled(Typography).attrs({ variant: 'h2' })`
  && {
    margin: 1rem 0 0.4rem;
    font-size: 2.2rem;
    color: #333;
  }
`

export const Heading3 = styled(Typography).attrs({ variant: 'h3' })`
  && {
    margin-bottom: 0.25rem;

    &:not(&:first-of-type) {
      margin-top: 2rem;
    }
  }

  & + p {
    margin-top: 0;
  }
`

export const Heading4 = styled(Typography).attrs({ variant: 'h4' })`
  && {
    margin: 0.8rem 0 0.25rem;
    font-weight: 600;
    font-size: 1.1rem;
  }

  & + p {
    margin-top: 0;
  }
`

export const Paragraph = styled(Typography).attrs({
  variant: 'body1'
})`
  && {
    line-height: 1.3;
    font-family: inherit;
    /* // space out headings after paragraphs
    &&& + :is(h2, h3) {
      margin-top: 1rem;
    } */
  }

  /* &:not(&:first-of-type) {
    margin-top: 0.5rem;
  } */

  & + & {
    margin-top: 0.5rem;
  }
`

export const LeadText = styled(Typography).attrs({
  variant: 'body1'
})`
  &&& {
    font-weight: 500;
    line-height: 1.5;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
`

export const Link = styled(MuiLink)<{ $disabled?: boolean | undefined }>`
  && {
    color: ${({ $disabled }) => ($disabled ? '#999' : 'currentcolor')};
    cursor: ${({ $disabled }) => !$disabled && 'pointer'};
    text-decoration-color: currentcolor;

    &:hover {
      text-decoration: ${({ $disabled }) => $disabled && 'none'};
    }
  }
`

export function InfoLink({
  target,
  children,
  disabled = false
}: {
  target: string
  children: React.ReactNode
  disabled?: boolean | undefined
}) {
  const store = React.useContext(StoreContext)

  return (
    <Link
      tabIndex={0}
      href={disabled ? '' : `/info/${target}`}
      onClick={e => {
        e.preventDefault()
        if (disabled) return

        store.view.openInfoPage(target)
      }}
      onKeyDown={e => {
        if (disabled) return

        if (e.key === 'Enter') {
          store.view.openInfoPage(target)
        }
      }}
      $disabled={disabled}
    >
      {children}
    </Link>
  )
}

export const OrderedList = styled.ol`
  margin-left: 1rem;
`

export const UnorderedList = styled.ul`
  margin-left: 1rem;
`

export const ListItem = styled.li`
  margin-bottom: 0.5rem;
`

const VideoContainer = styled.div`
  position: relative;
  width: min(100%, 30rem);
  height: 0;
  margin: 0 auto;
  padding-bottom: 56.25%;
  overflow: hidden;

  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const VideoFrame = styled.div`
  background-color: #ddd;
  padding-top: 0;
  padding-bottom: 0;
`

export function EmbeddedVideo({ src }: { src: string }) {
  return (
    <VideoFrame>
      <VideoContainer>
        <iframe
          title="How to get to the Ashram Dock Video"
          src={src}
          frameBorder="0"
          allowFullScreen
          className="video"
        ></iframe>
      </VideoContainer>
    </VideoFrame>
  )
}

export const Image = styled.img`
  width: 100%;
`

export const AsanaImage = styled.img`
  max-height: 75vh;
  display: block;
  margin: auto;
  width: fit-content;
  max-width: 100%;
`

export const Figure = styled.figure`
  position: relative;

  figcaption {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    color: ${({ theme }) => theme.palette.grey['500']};
    font: italic smaller sans-serif;

    a:link,
    a:visited {
      color: currentcolor;
    }
  }
`

export const Hr = styled.hr<{ $color?: string }>`
  height: 0.35rem;
  border: none;
  background-color: ${({ $color, theme }) =>
    $color || theme.palette.primary.main};
  border-radius: 0.1rem;
  margin-bottom: 0.8rem;
  margin-top: 1.3rem;
`

export const InfoPageLayout = styled.article`
  display: grid;
  grid-template-columns: 1fr 15rem;

  @media (max-width: 50em) {
    grid-template-columns: 1fr;
  }
`

export const ConnectedPages = styled.div`
  margin-top: 4rem;
  display: grid;
  grid-template-columns: 2rem 1fr;
  height: calc(max-content + 4rem);
  height: max-content;

  && a:not(:hover) {
    text-decoration: none;
  }
`

export const LayoutSeparator = styled.div`
  background-color: #ddd;
  width: 0.1px;
  margin-top: 3rem;

  justify-self: center;
`

export const TipsFrame = styled.section<{ $color: string }>`
  margin-top: 2rem;
  padding: 2rem;
  background-color: ${({ $color }) => $color};

  && h3 {
    margin-bottom: 1.5rem;
  }
`

export const Breadcrumbs = styled(MuiBreadcrumbs)`
  && {
    margin-top: 1rem;
    color: #555;
    grid-column: 1 / -1;
    font-size: inherit;
  }

  && a:not(:hover) {
    text-decoration: none;
  }

  & > ol > li {
    margin-left: 0;
    margin-right: 0;
  }
`
