import React from 'react'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import TextField from '@mui/material/TextField'
import SendIcon from '@mui/icons-material/Send'
import useMediaQuery from '@mui/material/useMediaQuery'
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined'
import DateMessages from './DateMessages'
import { OtherMessage, SelfMessage, UnreadMessagesDivider } from './Message'
import { UsersPane } from './UsersPane'
import {
  ChatContainer,
  ChatPageContainer,
  MessagesScrollable
} from './Containers'
import {
  ScrollDownButton,
  SwitchBack,
  StickyDayLabel,
  StyledIconButton,
  StyledInfiniteScroll,
  StyledLinearProgress,
  UserInputSection
} from './ChatPageStyles'
import {
  ChatResponseModelType,
  StoreContext,
  UserResponseModelType,
  MessageEdgeModelType
} from '@sayr/client-models'

const ChatContainerPage = observer(function ChatContainerPage() {
  const store = React.useContext(StoreContext)

  const smallScreen = useMediaQuery('(max-width: 40em)')

  if (smallScreen && store.loggedInUser?.user?.$permissions?.chatAsFrontDesk) {
    return <StaffChatSinglePane />
  } else {
    return (
      <ChatPageContainer
        staffView={
          store.loggedInUser?.user?.$permissions?.chatAsFrontDesk ?? false
        }
      >
        {store.loggedInUser?.user?.$permissions?.chatAsFrontDesk && (
          <UsersPane />
        )}
        <ChatPage
          staffView={!!store.loggedInUser?.user?.$permissions?.chatAsFrontDesk}
        />
      </ChatPageContainer>
    )
  }
})

function StaffChatSinglePane() {
  const [visiblePane, setVisiblePane] = React.useState<'users' | 'chat'>(
    'users'
  )

  if (visiblePane === 'users') {
    return <UsersPane switchToChatView={() => setVisiblePane('chat')} />
  } else return <ChatPage selectAnotherUser={() => setVisiblePane('users')} />
}

function ScrollToBottomIcon({ scrollFn }: { scrollFn: () => void }) {
  return (
    <ScrollDownButton onClick={scrollFn}>
      <ArrowDownwardOutlinedIcon color="action" />
    </ScrollDownButton>
  )
}

const ChatPage = observer(function ChatPage({
  staffView = false,
  selectAnotherUser
}: {
  staffView?: boolean
  selectAnotherUser?: () => void
}) {
  const store = React.useContext(StoreContext)
  if (!store.loggedInUser)
    throw new Error('not logged in, cannot access chat page')
  const withPerson = store.view.id || store.loggedInUser.id // chat identifier - person ID

  if (!withPerson) throw new Error('person is not set correctly')
  const [userInput, setUserInput] = React.useState('') // user's compose message section input (controlled elememt)

  // holding the "New" messages DOM divider for scrolling purposes
  const dividerRef = React.createRef<HTMLDivElement>()

  // holding the DOM messages container
  const containerDomRef = React.createRef<HTMLDivElement>()
  // control the DOM messages container's height
  const [containerHeight, setContainerHeight] = React.useState(0)

  // reliminary scrolling to unread section is done automatically, only when the chat loads.
  const beforeAnyScroll = React.useRef(true)

  // sets to true whenever another chat is selected.
  React.useEffect(() => {
    beforeAnyScroll.current = true
  }, [store.view.id])

  // track divHeight whenever DOM element changes.
  React.useEffect(() => {
    function setHeight() {
      if (containerDomRef.current) {
        if (containerDomRef.current.clientHeight !== containerHeight)
          setContainerHeight(containerDomRef.current.clientHeight)
      }
    }

    // get initial container height
    setHeight()

    // track DOM changes to container height
    if (containerDomRef.current) {
      const observer = new ResizeObserver(setHeight)
      observer.observe(containerDomRef.current)
      return () => {
        observer.disconnect()
      }
    }
    return () => {}
  }, [containerDomRef, containerHeight])

  // holds the DOM user input element.
  const userInputRef = React.createRef<HTMLDivElement>()

  function loadNext() {
    store.chatResponses.get(withPerson)?.loadNextMessages()
  }

  // build message react components
  const chat = store.chatResponses.get(withPerson)

  const messagesInDays = React.useMemo(
    () =>
      chat && buildMessagesElements(chat, dividerRef, store.loggedInUser?.user),
    [chat, dividerRef, store.loggedInUser?.user]
  )

  const initialUnreadCount = React.useRef(chat?.unreadCountShown)
  React.useEffect(() => {
    initialUnreadCount.current = chat?.unreadCountShown
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.view.id])

  // scroll to last read message or first unread messages on any update
  React.useEffect(() => {
    if (beforeAnyScroll.current)
      setTimeout(() => {
        if (initialUnreadCount.current) dividerRef.current?.scrollIntoView(true)
        else {
          containerDomRef.current
            ?.querySelector('.date-messages:last-of-type section:last-child')
            ?.scrollIntoView()
        }
        beforeAnyScroll.current = false
      })
  }, [chat?.messages?.length, dividerRef, containerDomRef])

  function submitMessage() {
    if (!userInput.trim()) return

    // post the mesage
    chat &&
      store.mutateChatPostMessage({
        newMessage: { chatId: chat.id, content: userInput.trim() }
      })

    // clear the text area for new input.
    setUserInput('')

    //  focus back on the text area.
    const userInputElement = userInputRef.current?.querySelector('textarea')
    if (userInputElement) {
      // focus on user input without popping up the virtual keyboard on mobile
      userInputElement.readOnly = true
      userInputElement.focus()
      userInputElement.readOnly = false
    }
  }

  const [notAtBottom, setNotAtBottom] = React.useState(true)

  if (!store.loggedInUser) return <h1>Not Logged In</h1>

  return (
    <>
      {selectAnotherUser && <SwitchBack fn={selectAnotherUser} />}
      <ChatContainer staffView={staffView}>
        <MessagesScrollable
          id="chat-messages-scrollable"
          ref={containerDomRef}
          staffView={staffView}
        >
          <StyledInfiniteScroll
            keysScroll
            dataLength={chat?.orderedMessages.length || 0}
            hasMore={!!chat?.$messages?.$pageInfo.hasNextPage}
            loader={<StyledLinearProgress />}
            next={loadNext}
            height={containerHeight - 1 - 4 - 1}
            inverse
            onScroll={e => {
              const scrollingDiv = e.target as HTMLDivElement

              setNotAtBottom(
                scrollingDiv.scrollTop + scrollingDiv.clientHeight <
                  scrollingDiv.scrollHeight - 20
              )
            }}
            scrollableTarget="chat-messages-scrollable"
          >
            {messagesInDays}
          </StyledInfiniteScroll>
          {notAtBottom && (
            <ScrollToBottomIcon
              scrollFn={() =>
                containerDomRef.current
                  ?.querySelector(
                    '.date-messages:last-of-type section:last-child'
                  )
                  ?.scrollIntoView({ behavior: 'smooth' })
              }
            />
          )}
        </MessagesScrollable>
        <UserInputSection staffView={staffView}>
          <TextField
            id="user-input"
            label={
              chat?.id === store.loggedInUser?.user?.id
                ? 'Chat with us'
                : `Write to ${
                    store.userResponses.get(withPerson)?.nickName ||
                    store.userResponses.get(withPerson)?.email
                  }`
            }
            placeholder={
              chat?.id === withPerson ? 'Hi, I would like to' : `Blessed Self,`
            }
            multiline
            maxRows={5}
            margin="dense"
            variant="outlined"
            value={userInput}
            onChange={e => setUserInput(e.target.value)}
            ref={userInputRef}
          />
          <StyledIconButton
            $engaged={!!userInput.trim()}
            aria-label="send"
            onClick={() => submitMessage()}
          >
            <SendIcon />
          </StyledIconButton>
        </UserInputSection>
      </ChatContainer>
    </>
  )
})

function arrangeChatInDays(chat: ChatResponseModelType) {
  return chat.orderedMessages?.reduce<
    { date: string; messages: MessageEdgeModelType[] }[]
  >((allComponents, message) => {
    const shortDate = getUserFriendlyDate(message.$node.$timestamp)
    // first iteration - return short only date and first message
    if (allComponents.length === 0)
      return [{ date: shortDate, messages: [message] }]

    // if current message's date is the same as previous message, add the message

    if (
      dayjs(message.$node.$timestamp)
        .startOf('day')
        .isSame(
          dayjs(
            allComponents[allComponents.length - 1].messages[0].$node.$timestamp
          ).startOf('day')
        )
    )
      return [
        ...allComponents.slice(0, -1),
        {
          date: shortDate,
          messages: allComponents.slice(-1)[0].messages.concat(message)
        }
      ]
    // if current message starts a new date - add the new date label
    else return allComponents.concat({ date: shortDate, messages: [message] })
  }, [])
}

function buildMessagesElements(
  chat: ChatResponseModelType,
  dividerRef: React.RefObject<HTMLDivElement>,
  loggedInUser?: UserResponseModelType
) {
  const days = arrangeChatInDays(chat)

  return days.map(date => {
    const messages = date.messages.map(message => {
      if (!message.$node) throw new Error('no node in message')
      const props = {
        key: message.$node._id,
        src: message.$node.author?.profilePhoto || '',
        name: message.$node.author?.nickName || '',
        timeSignature: message.$node.timeSignature,
        children: <>{message.$node.content}</>
      }

      return message.$node.author?.id === loggedInUser?.id ? (
        <OtherMessage {...props} />
      ) : (
        <SelfMessage {...props} />
      )
    })

    // add "New messages" divider
    if (chat.unreadCountShown) {
      // find last read message and stick the unread divider under it.
      const lastReadMessageShown = date.messages.findIndex(
        message =>
          message.$node.$timestamp.getTime() ===
          chat.lastReadTimestampShown.getTime()
      )
      // remember findIndex returns -1 if not found, therefore add "~"
      if (~lastReadMessageShown) {
        messages.splice(
          lastReadMessageShown + 1,
          0,
          <UnreadMessagesDivider key="unread-divider" ref={dividerRef} />
        )
      }
    }

    return (
      <DateMessages key={date.date}>
        <StickyDayLabel day={date.date} />
        {messages}
      </DateMessages>
    )
  })
}

function getUserFriendlyDate(timestamp: Date) {
  return dayjs(timestamp).startOf('day').isSame(dayjs().startOf('day'))
    ? 'Today'
    : dayjs(timestamp)
        .startOf('day')
        .isSame(dayjs().subtract(1, 'days').startOf('day'))
    ? 'Yesterday'
    : dayjs(timestamp).startOf('week').isSame(dayjs().startOf('week'))
    ? dayjs(timestamp).format('dddd')
    : dayjs(timestamp).startOf('year').isSame(dayjs().startOf('year'))
    ? dayjs(timestamp).format('MMM D')
    : dayjs(timestamp).format('MMM D, YYYY')
}

export default ChatContainerPage
