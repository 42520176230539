import * as React from 'react'
import {
  useTable,
  useBlockLayout,
  useResizeColumns,
  useColumnOrder,
  useSortBy,
  HeaderGroup,
  useRowSelect,
  useRowState,
  UseRowSelectRowProps,
  usePagination,
  Column,
  Hooks,
  useGlobalFilter,
  Row,
  IdType,
  useAsyncDebounce,
  TableSortByToggleProps
} from 'react-table'
import { useSticky } from 'react-table-sticky'

import GlobalFilter from './GlobalFilter'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { TooltipOnOverflow } from '../Tooltip'

import {
  TableControlSection,
  PaginationWrapper,
  ActionsContainer,
  CenteredSelect,
  TableBody,
  TableRow,
  TableRowInHeader,
  TableHead,
  StyledColumn,
  Heading,
  HeadingCellContent,
  CellContent,
  StyledCell,
  TableContainer,
  PageInputTextField,
  ResizerComponent,
  SmallCheckbox,
  ReadOnlyCheckbox,
  SortIconComponent,
  SortIconHoverComponent,
  Centered
} from './DataGridStyles'

const Loading = () => {
  return (
    <Centered>
      <CircularProgress />
    </Centered>
  )
}

const SortIcon = ({
  isSorted,
  isSortedDesc,
  onHover
}: {
  isSorted: boolean
  isSortedDesc: boolean | undefined
  onHover: boolean
}) => {
  return (
    <SortIconHoverComponent>
      <SortIconComponent
        show={onHover || isSorted}
        className="MuiSvgIcon-root MuiDataGrid-sortIcon MuiSvgIcon-fontSizeSmall"
        focusable="false"
        viewBox="0 0 24 24"
        aria-hidden="true"
        hint={!isSorted && onHover}
        upDirection={!isSortedDesc}
      >
        <path d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z"></path>
      </SortIconComponent>
    </SortIconHoverComponent>
  )
}

function ColumnComponent<DataStructure extends {}>({
  column,
  setIsResizing,
  nonSortable,
  disableResize
}: {
  column: HeaderGroup<DataStructure>
  setIsResizing: React.Dispatch<React.SetStateAction<boolean>>
  nonSortable: boolean
  disableResize: boolean
}) {
  const [onHover, setOnHover] = React.useState(false)

  const { title, ...sortProperties } =
    column.getSortByToggleProps() as TableSortByToggleProps & { title: string }

  const { style: styleWithDisplay, ...columnHeaderProps } =
    column.getHeaderProps(!nonSortable ? sortProperties : undefined)

  const { display, ...style } = styleWithDisplay as React.CSSProperties

  return (
    <StyledColumn
      style={style}
      className="th"
      {...columnHeaderProps}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
    >
      <Heading>
        <TooltipOnOverflow
          tooltip={
            column.id === 'selection' ? '' : column.render('Header') || ''
          }
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <HeadingCellContent>{column.render('Header')}</HeadingCellContent>
        </TooltipOnOverflow>
        {!nonSortable && (
          <SortIcon
            isSorted={column.isSorted}
            isSortedDesc={column.isSortedDesc}
            onHover={onHover}
          ></SortIcon>
        )}
        {!disableResize && (
          <Resizer
            {...column.getResizerProps()}
            onMouseEnter={() => setIsResizing(true)}
            onMouseLeave={() => setIsResizing(false)}
          />
        )}
      </Heading>
    </StyledColumn>
  )
}

const Resizer = (props: React.SVGAttributes<SVGElement>) => (
  <ResizerComponent
    {...props}
    onClick={
      e => e.stopPropagation() /* prevent sorting when clicking the resizer */
    }
  >
    <path d="M11 19V5h2v14z"></path>
  </ResizerComponent>
)

function pushSelectColumn<DataStructure extends {}>(
  hooks: Hooks<DataStructure>
) {
  hooks.visibleColumns.push(columns => {
    return [
      {
        id: 'selection',
        Header: React.memo(({ getToggleAllRowsSelectedProps }) => {
          // exclude title from props
          const { title, ...toggleAllRowsProps } =
            getToggleAllRowsSelectedProps()
          return <SmallCheckbox {...toggleAllRowsProps} />
        }),
        Cell: React.memo(
          ({ row }: { row: UseRowSelectRowProps<DataStructure> }) => {
            // exclude title from props
            const { title, ...toggleRowProps } = row.getToggleRowSelectedProps()
            return <SmallCheckbox {...toggleRowProps} />
          }
        ),
        width: 40,
        minWidth: 40,
        disableSortBy: true,
        disableResizing: true
      },
      ...columns
    ]
  })
}

function standardFilterFunction(filterableColumns: string[]) {
  return function <D extends object>(
    rows: Array<Row<D>>,
    _columnIds: Array<IdType<D>>,
    filterValue: string
  ): Array<Row<D>> {
    return rows.filter(row =>
      filterValue
        .trim()
        .split(/\W/)
        .every(word => {
          let wordMatch = false
          for (const column in row.values) {
            if (
              filterableColumns.some(
                filterableColumn => column === filterableColumn
              )
            ) {
              if (!wordMatch)
                wordMatch = !!(row.values[column] as string).match(
                  new RegExp(word, 'i')
                )
            }
          }
          return wordMatch
        })
    )
  }
}

export function DataGrid<
  DataStructure extends {
    // the field { highlightRow?: boolean } can be used to highlight a row.
  }
>({
  columns,
  isLoading = false,
  data,
  setData,
  nonSortable = false,
  disablePagination = false,
  disableResize = false,
  withGlobalFilter = false,
  GlobalFilterComponent = GlobalFilter,
  removeSelectColumn = false,
  renderButtons,
  renderBatchActions,
  skipPageReset = false,
  filterableColumns = ['name', 'comments', ''],
  globalFilterFunction = standardFilterFunction(filterableColumns),
  removeTooltipOnColumns = [],
  defaultColumn: defaultColumnProp,
  ...customCellProps
}: {
  columns: Column<DataStructure>[]
  isLoading?: boolean
  data: DataStructure[]
  setData?: React.Dispatch<React.SetStateAction<DataStructure[]>>
  nonSortable?: boolean
  disablePagination?: boolean
  disableResize?: boolean
  withGlobalFilter?: boolean
  GlobalFilterComponent?: (props: {
    filter: string | undefined
    setFilter: (filterValue: string) => void
  }) => JSX.Element
  renderButtons?: () => JSX.Element
  renderBatchActions?: (
    selectedFlatRows: Row<DataStructure>[],
    toggleAllRowsSelected: (value?: boolean | undefined) => void
  ) => JSX.Element
  removeSelectColumn?: boolean
  skipPageReset?: boolean
  filterableColumns?: string[]
  globalFilterFunction?:
    | ((
        rows: Array<Row<DataStructure>>,
        columnIds: Array<IdType<DataStructure>>,
        filterValue: any
      ) => Array<Row<DataStructure>>)
    | string
  removeTooltipOnColumns?: string[]
  defaultColumn?: Partial<Column<DataStructure>>
  [customProp: string]: unknown
}) {
  const defaultColumn =
    defaultColumnProp ||
    ({
      minWidth: 70,
      width: 160,
      maxWidth: 700
    } as Partial<Column<DataStructure>>)

  const [queryPageGlobalFilter, setQueryPageGlobalFilter] = React.useState<
    string | undefined
  >(undefined)

  const {
    canNextPage,
    canPreviousPage,
    getTableProps,
    getTableBodyProps,
    gotoPage,
    headerGroups,
    nextPage,
    page,
    pageCount,
    pageOptions,
    prepareRow,
    previousPage,
    rows,
    selectedFlatRows,
    setGlobalFilter,
    setPageSize,
    state: { pageIndex, pageSize },
    toggleAllRowsSelected,
    totalColumnsWidth
  } = useTable<DataStructure>(
    {
      columns,
      data,
      autoResetPage: !skipPageReset,
      defaultColumn,
      disableSortBy: nonSortable,
      disableResizing: disableResize,
      disableGlobalFilter: !withGlobalFilter,
      setData, // a custom prop which is passed to the Cells
      // set global filter to search only in certain columns
      globalFilter: globalFilterFunction,
      initialState: { pageSize: disablePagination ? 10e10 : 25 },
      autoResetExpanded: false,
      autoResetGroupBy: false,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetRowState: false,
      ...customCellProps
    },
    useBlockLayout,
    useResizeColumns,
    useSticky,
    useColumnOrder,
    useGlobalFilter,
    useSortBy,
    removeSelectColumn
      ? (_hooks: Hooks<DataStructure>) => {}
      : pushSelectColumn,
    usePagination,
    useRowSelect,
    useRowState
  )

  React.useEffect(() => {
    setGlobalFilter(queryPageGlobalFilter)
  }, [queryPageGlobalFilter, setGlobalFilter, data])

  const tableRef = React.createRef<HTMLDivElement>()

  React.useEffect(() => {
    tableRef.current?.addEventListener('copy', copyListener)
  }, [tableRef])

  const [, setIsResizing] = React.useState(false)

  return (
    <>
      <TableControlSection
        tableWidth={totalColumnsWidth}
        className="table-control-section"
      >
        <div style={{ flex: 1, overflow: 'auto' }}>
          <ActionsContainer>
            {withGlobalFilter && (
              <GlobalFilterComponent
                filter={queryPageGlobalFilter}
                setFilter={setQueryPageGlobalFilter}
              />
            )}
            {renderButtons ? renderButtons() : null}
            {selectedFlatRows.length
              ? renderBatchActions
                ? renderBatchActions(selectedFlatRows, toggleAllRowsSelected)
                : null
              : null}
          </ActionsContainer>
        </div>
        {!disablePagination && (
          <Pagination
            pageIndex={pageIndex}
            pageOptions={pageOptions}
            pageSize={pageSize}
            setPageSize={setPageSize}
            gotoPage={gotoPage}
            canPreviousPage={canPreviousPage}
            previousPage={previousPage}
            nextPage={nextPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
            totalItems={rows.length}
          />
        )}
      </TableControlSection>
      <TableContainer {...getTableProps()} className="table" ref={tableRef}>
        <TableHead className="thead">
          {headerGroups.map(headerGroup => (
            <TableRowInHeader
              {...headerGroup.getHeaderGroupProps()}
              className="tr"
            >
              {headerGroup.headers.map((column, i) => (
                <ColumnComponent
                  key={i}
                  column={column}
                  setIsResizing={setIsResizing}
                  nonSortable={nonSortable || !!column.disableSortBy}
                  disableResize={disableResize || !!column.disableResizing}
                ></ColumnComponent>
              ))}
            </TableRowInHeader>
          ))}
        </TableHead>
        {isLoading ? (
          <Loading />
        ) : (
          <TableBody {...getTableBodyProps()} className="tbody">
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <TableRow className="tr" {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    const cellValue = cell.render('Cell')
                    return (
                      <StyledCell
                        className="td"
                        {...cell.getCellProps()}
                        style={{
                          ...cell.getCellProps().style,
                          display: ''
                        }}
                        odd={i % 2 !== 0}
                        highlight={
                          !!(row.original as { highlightRow?: boolean })
                            .highlightRow
                        }
                      >
                        <TooltipOnOverflow
                          tooltip={
                            cell.column.id === 'selection' || // manually exclude "selection" column
                            removeTooltipOnColumns.includes(cell.column.id)
                              ? ''
                              : cellValue || ''
                          }
                        >
                          <CellContent className="cell-content">
                            {cellValue}
                          </CellContent>
                        </TooltipOnOverflow>
                      </StyledCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        )}
      </TableContainer>
    </>
  )
}

function Pagination({
  pageIndex,
  pageOptions,
  pageSize,
  setPageSize,
  gotoPage,
  canPreviousPage,
  previousPage,
  nextPage,
  canNextPage,
  pageCount,
  totalItems
}: {
  pageIndex: number
  pageOptions: number[]
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void
  pageSize: number
  setPageSize: (pageSize: number) => void
  canPreviousPage: any
  previousPage: () => void
  nextPage: () => void
  canNextPage: boolean
  pageCount: number
  totalItems: number
}) {
  const [pageInput, setPageInput] = React.useState<number | ''>(pageIndex + 1)
  React.useEffect(() => {
    setPageInput(pageIndex + 1)
  }, [pageIndex])

  const delayedGotoPage = useAsyncDebounce(value => {
    gotoPage(value)
  }, 100)

  return (
    <PaginationWrapper>
      <div>
        <span style={{ marginRight: '2ch' }}>Showing:</span>
        <CenteredSelect
          labelId="page-size"
          value={pageSize}
          onChange={e => setPageSize(Number(e.target.value))}
          variant="standard"
          disableUnderline
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={200}>200</MenuItem>
        </CenteredSelect>
      </div>
      <div>
        <span>
          {pageIndex * pageSize + 1}-
          {Math.min(pageIndex * pageSize + pageSize, totalItems)}
        </span>
        <span> of {totalItems}</span>
      </div>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={() => previousPage()} disabled={!canPreviousPage}>
          <ArrowBackIosNewIcon />
        </IconButton>
        <PageInputTextField
          margin="none"
          value={pageInput}
          onChange={e => {
            // limit input to numbers or empty value, debounce the page turning
            if (
              !isNaN(+e.target.value) &&
              +e.target.value > 0 &&
              +e.target.value <= pageOptions.length
            ) {
              setPageInput(+e.target.value)
              delayedGotoPage(+e.target.value - 1)
              return
            }
            if (e.target.value === '') setPageInput('')
          }}
          onBlur={e => {
            // restore the value when it's empty and blurred.
            // Empty value is only allowed while editing the page number
            if (e.target.value === '') setPageInput(pageIndex + 1)
          }}
          onKeyDown={e => {
            if (e.key === 'ArrowUp') previousPage()
            else if (e.key === 'ArrowDown') nextPage()
            else if (e.key === 'End') gotoPage(pageCount - 1)
            else if (e.key === 'Home') gotoPage(0)
            else return
            e.preventDefault()
          }}
        />
        <IconButton onClick={() => nextPage()} disabled={!canNextPage}>
          <ArrowForwardIosIcon />
        </IconButton>
      </div>
    </PaginationWrapper>
  )
}

export const EditableCell = React.memo(function EditableCell<
  DataStructure extends {}
>({
  value: initialValue,
  row: { index },
  column: { id },
  setData
}: {
  value: string
  row: { index: number }
  column: { id: string }
  setData: React.Dispatch<React.SetStateAction<DataStructure[]>>
}) {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    setData(data => {
      const abd = data.map<DataStructure>(function mapData(currentRow, i) {
        if (i === index) {
          return { ...currentRow, [id]: value }
        } else return currentRow
      })
      return abd
    })
  }

  // If the initialValue is changed externally, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return (
    <TextField
      variant="standard"
      multiline
      maxRows={4}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
  )
})

export function GridCheckbox({ value }: { value: boolean }) {
  return <ReadOnlyCheckbox checked={value} size="small" />
}

export function UpdateableGridCheckbox({
  value,
  onChange,
  className,
  disabled = false
}: {
  value: boolean
  onChange?:
    | ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void)
    | undefined
  className?: string
  disabled?: boolean
}) {
  return (
    <SmallCheckbox
      checked={value}
      size="small"
      onChange={onChange}
      className={className}
      disabled={disabled}
    />
  )
}

// replace div structure with table structure when copying from data grid
function copyListener(event: ClipboardEvent) {
  const selection = window.getSelection()
  if (!selection) return

  // get fragment in divs
  const fragment = selection.getRangeAt(0).cloneContents()

  // create an in-memory table with the data to copy
  const table = document.createElement('table')
  const tbody = document.createElement('tbody')
  table.insertAdjacentElement('afterbegin', tbody)

  if (fragment.children?.length) {
    if (fragment.children.item(0)?.getAttribute('role') === 'row')
      Array.from(fragment.children).forEach(row =>
        tbody.insertAdjacentHTML(
          'beforeend',
          `<tr>${Array.from(row.children)
            .map(cell => {
              const $input = cell.querySelector<HTMLInputElement>(
                '.cell-content input'
              )

              if ($input && cell.textContent === '')
                return `<td>${$input.checked}</td>`
              return `<td>${cell.outerHTML}</td>`
            })
            .join('')}</tr>`
        )
      )
    else if (fragment.children.item(0)?.getAttribute('role') === 'cell')
      tbody.insertAdjacentHTML(
        'beforeend',
        `<tr>${Array.from(fragment.children).map(cell => {
          const $input = cell.querySelector<HTMLInputElement>(
            '.cell-content input'
          )

          if ($input && cell.textContent === '')
            return `<td>${$input.checked}</td>`
          return `<td>${cell.outerHTML}</td>`
        })}</tr>`
      )
  } else
    tbody.insertAdjacentHTML(
      'beforeend',
      `<tr><td>${fragment.textContent}</td></tr>`
    )

  // set clipboard text/plain data with tabs and new lines
  event.clipboardData?.setData(
    'text/plain',
    Array.from(tbody.children)
      .map(row =>
        Array.from(row.children)
          .map(cell => cell.textContent)
          .join('\t')
      )
      .join('\n')
  )

  // set clipboard text/html data with the table html.
  event.clipboardData?.setData('text/html', table.outerHTML)

  event.preventDefault()
}
