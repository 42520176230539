import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Breadcrumbs,
  Heading2,
  Heading3,
  Paragraph,
  InfoContainer,
  InfoLink,
  Section,
  Hr,
  InfoPageLayout,
  LayoutSeparator,
  Link,
  ConnectedPages
} from '../../../../components/InfoTypography'

export function InfoProperRelaxation() {
  return (
    <InfoContainer style={{ wordBreak: 'break-word' }}>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <InfoLink target="">Information</InfoLink>
        <InfoLink target="practice-guide">Practice Guide</InfoLink>
        <InfoLink target="practice-guide/introduction">Introduction</InfoLink>
        <Link $disabled>3. Proper Relaxation</Link>
      </Breadcrumbs>
      <Heading2>3. Proper Relaxation</Heading2>
      <Hr />
      <InfoPageLayout>
        <Section>
          <Heading3>
            Relaxation Pose <span className="text-sanskrit">śavāsana</span>
          </Heading3>

          <Paragraph>
            When the body and the mind are constantly overworked, their natural
            efficiency to perform work diminishes. Modern social life, food,
            work and even the so called entertainment, such as disco dancing,
            make it difficult for modern people to relax. Many have even
            forgotten that rest and relaxation are Nature's way of recharging.
            Even while trying to rest, the average person expends a lot of
            physical and mental energy through tension. Much of the body's
            energy is wasted uselessly.
          </Paragraph>
          <Paragraph>
            More of our energy is spent is keeping the muscles in continual
            readiness for work than in the actual useful work done. In order to
            regulate and balance the work of the body and mind, it is best to
            learn to economize the energy produced by our body. This may be done
            by learning to relax.
          </Paragraph>
          <Paragraph>
            It may be remembered that in the course of one day, our body usually
            produces all the substances and energy necessary for the next day.
            But it often happens that all these substances and energy may be
            consumed within a few minutes by bad moods, anger, injury, or
            intense irritation. The process of eruption and repression of
            violent emotions often grows into a regular habit. The result is
            disastrous not only for the body, but also for the mind.
          </Paragraph>
          <Paragraph>
            During complete relaxation, there is practically no energy or{' '}
            <span className="text-sanskrit">prāṇa</span> being consumed,
            although a little is kept in circulation to keep the body in normal
            condition while the remaining portion is being stored and conserved.
          </Paragraph>
          <Paragraph>
            In order to achieve perfect relaxation, three methods are used by
            Yogis: “physical,” “mental” and “spiritual” relaxation. Relaxation
            is not complete until the person reaches that stage of spiritual
            relaxation, which only advanced Spiritual Aspirants know.
          </Paragraph>
          <Heading3>Physical Relaxation</Heading3>
          <Paragraph>
            We all know that every action is the result of thought. Thoughts
            take form in action, the body reacting to the thought. Just as the
            mind may send a message to the muscles ordering them to contract,
            the mind may also send another message to bring the relaxation to
            the tired muscles.
          </Paragraph>
          <Paragraph>
            Physical relaxation first begins with the toes and then moves
            upward. The autosuggestion passes through the muscles and reaches
            the eyes and ears at the top. Then, slowly, messages are sent to the
            kidneys, liver and the other internal organs. This relaxation
            position is known as Savasan, or the “Corpse Pose.”
            <br />
            For further reading, please see chapter 6 of “The Complete
            Illustrated Book of Yoga” by Swami Vishnu-devananda.
          </Paragraph>
          <Heading3>Mental Relaxation</Heading3>
          <Paragraph>
            When experiencing mental tension, it is advisable to breathe slowly
            and rhythmically for a few minutes. Soon the mind will become calm.
            You may experience a kind of floating sensation.
          </Paragraph>
          <Heading3>Spiritual Relaxation</Heading3>
          <Paragraph>
            However one may try to relax the mind, all tensions and worries
            cannot be completely removed until one reaches spiritual relaxation.{' '}
          </Paragraph>
          <Paragraph>
            As long as a person identifies with the body and mind, there will be
            worries, sorrows, anxieties, fear and anger. These emotions, in
            turn, bring tension. Yogis know that unless a person can withdraw
            from the body/mind idea and separate himself from the
            ego-consciousness, there is no way of obtaining completion
            relaxation.
          </Paragraph>
          <Paragraph>
            The Yogi identifies himself with the all-pervading, all-powerful,
            all-peaceful and joyful Self, or pure Consciousness within. He knows
            that the source of all power, knowledge, peace, and strength is in
            the Self, not in the body. We tune to this by asserting the real
            nature, that is “I am that pure Consciousness or Self.” This
            identification with the Self completes the process of relaxation.
          </Paragraph>
        </Section>
        <ConnectedPages>
          <LayoutSeparator />
          <Section>
            <Paragraph>
              <strong>Connected Articles:</strong>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/introduction/five-points-of-yoga">
                General Introduction
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/introduction/proper-exercise">
                1. Proper Exercise
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/introduction/proper-breathing">
                2. Proper Breathing
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/introduction/proper-diet">
                4. Proper Diet
              </InfoLink>
            </Paragraph>
            <Paragraph>
              <InfoLink target="practice-guide/introduction/proper-thinking">
                5. Proper Thinking
              </InfoLink>
            </Paragraph>
          </Section>
        </ConnectedPages>
      </InfoPageLayout>
    </InfoContainer>
  )
}
